import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    Card,
    CardBody,
    Col,
  } from 'reactstrap';
import { getInvoiceById } from '../../../redux/actions/invoiceAction';


const CheckoutSuccess = (props) => {
    const paramsId = props.match.params.id;
    const currentUrl = window.location.href;


    const fetchInvoiceById = async () => {
        const data =  await props.getInvoiceById(paramsId);
        if (data) {
            return data;
        }

        return window.location.href = process.env.REACT_APP_SITE_URL;
    }

    useEffect(() => {

        if (currentUrl.includes('invoice')) {
          fetchInvoiceById();
        }
    
    
      }, []);

return (
    <>
    <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
            <h1>SUCCESS!</h1>
            <h3>We received your payment.</h3>
            </div>
        </CardBody>
        </Card>
    </Col>
    </>

);

};

const mapStateToProps = state => ({
    invoice: state.invoice.invoice
  });


  export default connect(mapStateToProps, { getInvoiceById })(
    withRouter(CheckoutSuccess)
  );
