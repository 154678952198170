import React from 'react';
// import { Link } from 'react-router-dom';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
} from 'reactstrap';

import { connect } from 'react-redux';
import { change } from '../../redux/actions/searchAction';
import { logoutUser } from '../../redux/actions/authAction';

class AdminNavbar extends React.Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.userLogged) {
      return nextProps.history.push('/');
    }

    return {...prevState};
  }

  handleLogout = () => {
    this.props.logoutUser();
  };

  // handleSearch = e => {
  //   e.persist();
  //   this.props.change(e.target.value);
  // };

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.props.history.goBack()}
            >
              Go Back
            </Button>{' '}
            &nbsp;
            {/* <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link> */}
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Search"
                    type="text"
                    onChange={this.handleSearch}
                  />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                      <i className="fas fa-user-circle fa-3x" />

                        {/* {this.props.userLogged
                          ? this.props.userLogged.name
                          : ''} */}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">                        
                    {this.props.userLogged
                          ? this.props.userLogged.name
                          : ''}</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.handleLogout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = state => ({
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, { logoutUser, change })(AdminNavbar);
