import {
    FETCH_PAYMENT_ADD_FAILURE,
    FETCH_PAYMENT_ADD_SUCCESS,
  } from '../actions/paymentAction';
  import { LOG_LOGOUT_USER } from '../actions/authAction';
  import { merger, adder } from './actionReducers';
  
  const initialState = {
    list: [],
    didInvalidate: true
  };
  
  export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOG_LOGOUT_USER:
        state = initialState;
        return state;
      case FETCH_PAYMENT_ADD_SUCCESS:
        return { ...state, paymentStripe: action.payload};
      case FETCH_PAYMENT_ADD_FAILURE:
        return merger(state, { paymentStripe: action.payload });  
      default:
        return state;
    }
  };
  