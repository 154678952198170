import React from 'react';
import { find, set } from 'lodash';
import {
  Button,
  Card,
  CardBody,
  Table,
  UncontrolledCollapse,
  Row,
  Col,
  Input,
} from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';

import { compareValues } from '../../global';

const DropDownExpense = props => (
  <UncontrolledCollapse key={props.id} toggler={`#toggle${props.id}`}>
    {!props.isMobileVersion ? (
      <Card>
        <CardBody>
          <Table
            className="align-items-center table-flush col-md-6 col-xs-12"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th>Date</th>
                <th>Hours</th>
              </tr>
            </thead>
            <tbody>
              {props.time
                ? props.time.sort(compareValues('date', 'desc')).map((e, i) => (
                  <tr key={i}>
                    <td>
                      <Moment add={{ days: 1 }} format={'MMM D, YY'}>
                        {e.date}
                      </Moment>
                    </td>
                    <td>{e.hours}</td>
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    ) : (
      <>
        <ul style={{ 'listStyleType': 'none' }}>
          {props.time
            ? props.time.sort(compareValues('date', 'desc')).map((e, i) => (
              <li key={i}>
                Date:
                <b>
                  {' '}
                  <Moment add={{ days: 1 }} format={'MMM D, YY'}>
                    {e.date}
                  </Moment>
                </b>{' '}
                - Hours:<b> {e.hours}</b>
              </li>
            ))
            : null}
        </ul>
      </>
    )}
  </UncontrolledCollapse>
);

class ReportJobs extends React.Component {
  handleModal = expenseId => e => {
    this.props.openModal(expenseId);
  };

  handleModalEdit = expenseId => e => {
    this.props.openModalEdit(expenseId);
  };

  componentWillUnmount() {
    localStorage.removeItem('reportJobCollapse');
  }

  /**
   * Gets called when user clicks checkbox on expense row.
   * 
   * @param {string} jobId 
   * @param {string} expenseId 
   * @param {boolean} checked 
   */
  changeCheckBox = (jobId, expenseId, checked) => {
    const { jobsFilter, updateReportDataExpense } = this.props;
    if (checked) {
      // We reconcile this row.
      document.getElementById('spinner').style.visibility = 'visible';
      this.props.sendReconciled([expenseId], 'reconciled')
        .then((response) => {
          const job = find(jobsFilter, { '_id': jobId });
          if (job) {
            const expense = find(job.expenses, { '_id': expenseId });
            set(expense, 'status', 'reconciled');
            updateReportDataExpense(expense);
          }
          document.getElementById('spinner').style.visibility = 'hidden';
        })
        .catch(() => {
          document.getElementById('spinner').style.visibility = 'hidden';
        });
    } else {
      document.getElementById('spinner').style.visibility = 'visible';
      this.props.sendReconciled([expenseId], 'unreconciled')
        .then((response) => {
          const job = find(jobsFilter, { '_id': jobId });
          if (job) {
            const expense = find(job.expenses, { '_id': expenseId });
            set(expense, 'status', 'unreconciled');
            updateReportDataExpense(expense);
          }
          document.getElementById('spinner').style.visibility = 'hidden';
        })
        .catch(() => {
          document.getElementById('spinner').style.visibility = 'hidden';
        });
    }
  };

  render() {
    const { jobsFilter, paymentAccount, search } = this.props;

    let reportJobs = [...(jobsFilter || [])];

    if (search !== '') {
      reportJobs = jobsFilter.filter(
        job =>
          JSON.stringify(job).toLowerCase().indexOf(search.toLowerCase()) > 0
      );
    }
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {!this.props.isMobileVersion ? (
              <>
                <th></th>
                <th>Job Name</th>
                <th>Invoices</th>
                <th>Expenses</th>
                <th>Effective Labor</th>
                <th>Profit</th>
              </>
            ) : (
              <>
                <th>Details</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {reportJobs.length === 0 ? (
            <tr>
              <td>No results.</td>
            </tr>
          ) : (
            reportJobs.map((e, i) => {
              if (!e.clientId) return <th>Client Delete</th>;
              const totalEstimate = e.items.reduce(
                (acc, cv, i) => acc + cv.subtotal,
                0
              );

              const totalExpenses = e.expenses
                ? e.expenses.reduce((acc, current) => acc + current.total, 0)
                : 0;
              const clientName = e.clientId.name;
              const totalInvoices = e.invoices
                ? e.invoices.reduce((acc, cv) => acc + cv.invoiceTotal, 0)
                : 0;
              const time = [];
              const payment = [];
              const effective = [];
              const effectiveTotal = [];
              const timeTotal = [];

              let totalTime = 0;
              let totalEffective = 0;
              e.workers.map((wx, i) => {
                let timeWx;
                let paymentWx;
                let effectiveWx;
                let effectiveWxTotal;
                let paymentWxTotal;

                if (!wx.workerId) return 'Worker Delete';
                timeWx = wx.time
                  ? wx.time.reduce((acc, current, i) => acc + current.hours, 0)
                  : 0;

                let totalRate = wx.user && wx.user.rateHistory ?  wx.time.reduce(
                  (acc, current, i) => {
                    const currentTimeDate = moment(current.date).format('YYYY-MM-DD');
                    const currentRate = wx.user.rateHistory.find(itm => {
                      return moment(itm.date).diff(currentTimeDate, 'days') < 1;
                    })

                    if (currentRate) {
                      return {
                        ...acc,
                        payRate: acc.payRate + (currentRate.payRate * current.hours),
                        effectiveRate: acc.effectiveRate + (currentRate.effectiveRate * current.hours),
                      }
                    }


                    return {
                      ...acc,
                      payRate: acc.payRate + (wx.user.payRate * current.hours),
                      effectiveRate: acc.effectiveRate + (wx.user.effectiveRate * current.hours),
                    }
                  },
                  {
                    payRate: 0,
                    effectiveRate: 0
                  }
                ) : null;


                paymentWx = wx.user ? wx.user.payRate : 0;
                effectiveWx = wx.user ? wx.user.effectiveRate : 0;
      
                if(wx.user && wx.user.rateHistory && totalRate) {

                  effectiveWxTotal =  totalRate.effectiveRate;
                  paymentWxTotal = totalRate.payRate;
                }
                else {
                  effectiveWxTotal = timeWx * effectiveWx;
                  paymentWxTotal = timeWx * paymentWx;
                }

             
                
                // effectiveWxTotal = timeWx * effectiveWx;
                // paymentWxTotal = timeWx * paymentWx;
                timeTotal.push(paymentWxTotal);
                effectiveTotal.push(effectiveWxTotal);

                payment.push(paymentWx);
                effective.push(effectiveWx);
                time.push(timeWx);

                totalTime += paymentWxTotal;
                totalEffective += effectiveWxTotal;

                return {
                  totalTime,
                  totalEffective,
                  time,
                  payment,
                  effective,
                  effectiveTotal,
                  paymentWxTotal,
                };
              });

              const totalProfit = !totalEffective
                ? totalInvoices - totalExpenses
                : totalInvoices - totalExpenses - totalEffective;
              const { jobName } = e;
              const nameClient = jobName.split('-')[0];

              return (
                <React.Fragment key={i}>
                  <tr>
                    {!this.props.isMobileVersion ? (
                      <React.Fragment key={i}>
                        <td>
                          <Button id={`toggle${i}`} color="primary" onClick={(e) => {localStorage.setItem('reportJobCollapse', i)}}>
                            <i className="ni ni-bold-down"></i>
                          </Button>
                        </td>
                        <td>
                          {e.jobName}
                          <br /> <b>Estimate total:</b> $
                          {parseFloat(
                            Math.round(totalEstimate * 100) / 100
                          ).toFixed(2)}
                        </td>
                        <td>
                          $
                          {parseFloat(
                            Math.round(totalInvoices * 100) / 100
                          ).toFixed(2)}
                        </td>
                        <td>
                          $
                          {parseFloat(
                            Math.round(totalExpenses * 100) / 100
                          ).toFixed(2)}
                        </td>
                        <td>
                          $
                          {!totalTime
                            ? 0
                            : parseFloat(
                              Math.round(totalEffective * 100) / 100
                            ).toFixed(2)}
                        </td>
                        <td>
                          $
                          {parseFloat(
                            Math.round(totalProfit * 100) / 100
                          ).toFixed(2)}
                        </td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={i}>
                        <td className="tdMobile">
                          {e.jobName} <br />
                          <small>Estimate total: </small>
                          <b>
                            $
                            {parseFloat(
                              Math.round(totalEstimate * 100) / 100
                            ).toFixed(2)}
                          </b>
                          <br />
                          <Row>
                            <Col>
                              <small>Invoices: </small>
                              <b>
                                $
                                {parseFloat(
                                  Math.round(totalInvoices * 100) / 100
                                ).toFixed(2)}
                              </b>
                            </Col>
                            <Col>
                              <small>Expenses: </small>
                              <b>
                                $
                                {parseFloat(
                                  Math.round(totalExpenses * 100) / 100
                                ).toFixed(2)}
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <small>Effective Labor: </small>
                              <b>
                                $
                                {parseFloat(
                                  Math.round(totalEffective * 100) / 100
                                ).toFixed(2)}
                              </b>
                            </Col>
                            <Col>
                              <small>Profit: </small>
                              <b>
                                $
                                {parseFloat(
                                  Math.round(totalProfit * 100) / 100
                                ).toFixed(2)}
                              </b>
                            </Col>
                          </Row>
                          <Button id={`toggle${i}`} color="primary">
                            <i className="ni ni-bold-down"></i>
                          </Button>
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                  <tr>
                    <td colSpan={this.props.isMobileVersion ? 1 : 7}>
                      <UncontrolledCollapse toggler={`#toggle${i}`} key={i} defaultOpen={parseInt(localStorage.getItem('reportJobCollapse')) === i ? true : false}>
                        {!this.props.isMobileVersion ? (
                          <Card>
                            <CardBody>
                              <h3>- Invoices</h3>
                              <Table
                                className="align-items-center table-flush col-md-6 col-xs-12"
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th>Date</th>
                                    <th>Client Name</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!e.invoices ? (
                                    <tr>
                                      <td>No invoices</td>
                                    </tr>
                                  ) : e.invoices.length === 0 ? (
                                    <tr>
                                      <td>No invoices</td>
                                    </tr>
                                  ) : (
                                    e.invoices.map((e, i) => {
                                      const paid = e.payments.reduce(
                                        (acc, current, i) =>
                                          acc + current.paidAmount,
                                        0
                                      );
                                      return (
                                        <tr key={i}>
                                          <td>
                                            <Moment
                                              add={{ days: 1 }}
                                              format={'MMM D, YY'}
                                            >
                                              {e.invoiceDate}
                                            </Moment>
                                          </td>
                                          <td>{clientName || nameClient}</td>
                                          <td align="right">
                                            ${' '}
                                            {parseFloat(
                                              Math.round(e.invoiceTotal * 100) /
                                              100
                                            ).toFixed(2)}
                                          </td>
                                          <td>
                                            {e.total - paid === 0
                                              ? 'Paid'
                                              : e.invoiceStatus}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  )}
                                  <tr>
                                    <td></td>
                                    <td>Total:</td>
                                    <td align="right">
                                      ${' '}
                                      {parseFloat(
                                        Math.round(totalInvoices * 100) / 100
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <h3>- Labor</h3>
                              <Table
                                className="align-items-center table-flush col-md-6 col-xs-12"
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th></th>
                                    <th>Worker</th>
                                    <th>Payroll Expense</th>
                                    <th>Labor Expense (Efective Rate)</th>
                                    <th>Hours</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {e.workers.length === 0 ? (
                                    <tr>
                                      <td>No workers</td>
                                    </tr>
                                  ) : (
                                    e.workers
                                      .sort(compareValues('date', 'asc'))
                                      .map((wx, i) => {
                                        if (!wx.workerId)
                                          return (
                                            <tr>
                                              <td>Worker Delete</td>
                                            </tr>
                                          );

                                
                                        // const currentExpense = e.expenses.find(itm => itm.userId === wx.workerId)
                                        // if (currentExpense) {

                                        //   const expenseDate = moment(currentExpense.date).format('YYYY-MM-DD');
                                        //   if (wx.user && wx.user.rateHistory) {
                                        //     currentExpenseRate = wx.user.rateHistory.reduce((accum, itm) => {
                                        //       const currentDiff = moment(itm.date).diff(expenseDate, 'days');

                                        //       if (accum && (accum.date && accum.diff)) {
                                        //         if ((currentDiff >= accum.diff) && currentDiff <= 0) {
                                        //           return {
                                        //             date: itm.date,
                                        //             diff: currentDiff,
                                        //             payRate: itm.payRate,
                                        //             effectiveRate: itm.effectiveRate
                                        //           }
                                        //         }
                                        //         // else {
                                        //         return {
                                        //           ...(accum || {})
                                        //         }
                                        //         // }
                                        //       }
                                        //       else if (currentDiff <= 0) {
                                        //         return {
                                        //           date: itm.date,
                                        //           diff: currentDiff,
                                        //           payRate: itm.payRate,
                                        //           effectiveRate: itm.effectiveRate
                                        //         }
                                        //       }

                                        //       // const dateDiff = moment(itm.date).diff(expenseDate, 'days');
                                        //       // return {
                                        //       //   date: itm.date,
                                        //       //   diff: dateDiff,
                                        //       //   payRate: itm.payRate,
                                        //       //   effectiveRate: itm.effectiveRate
                                        //       // };
                                        //     }, null);
                                        //   }
                                        // }


                                        const time = wx.time
                                          ? wx.time.reduce(
                                            (acc, current, i) =>
                                              acc + current.hours,
                                            0
                                          )
                                          : 0;
                                        let effective = wx.user
                                          ? wx.user.effectiveRate
                                          : 0;
                                        let payment = wx.user
                                          ? wx.user.payRate
                                          : 0;

                                        let totalPayRatehours = parseFloat(
                                          Math.round(
                                            payment * time * 100
                                          ) / 100
                                        ).toFixed(2);

                                        let totalEffectiveRatehours = parseFloat(
                                          Math.round(
                                            effective * time * 100
                                          ) / 100
                                        ).toFixed(2);

                                        totalPayRatehours = isNaN(totalPayRatehours) ? 0 : totalPayRatehours;

                                        totalEffectiveRatehours = isNaN(totalEffectiveRatehours) ? 0 : totalEffectiveRatehours;

                                        if (wx.user && wx.user.rateHistory !== undefined) {

                                          let totalRate = wx.time.reduce(
                                            (acc, current) => {
                                              const currentTimeDate = moment(current.date).format('YYYY-MM-DD');
                                              const currentRate = wx.user.rateHistory.find(itm => {
                                                return moment(itm.date).diff(currentTimeDate, 'days') < 1;
                                              })

                                              if (currentRate) {
                                                return {
                                                  ...acc,
                                                  payRate: acc.payRate + (currentRate.payRate * current.hours),
                                                  effectiveRate: acc.effectiveRate + (currentRate.effectiveRate * current.hours),
                                                }
                                              }

                                              return {
                                                ...acc,
                                                payRate: acc.payRate + (wx.user.payRate * current.hours),
                                                effectiveRate: acc.effectiveRate + (wx.user.effectiveRate * current.hours),
                                              }
                                            },
                                            {
                                              payRate: 0,
                                              effectiveRate: 0
                                            }
                                          );

                                          if (totalRate) {
                                            totalPayRatehours = totalRate.payRate;
                                            totalEffectiveRatehours = totalRate.effectiveRate;
                                          }

                                        }


                                        return (
                                          <React.Fragment key={i}>
                                            <tr>
                                              <td>
                                                <Button
                                                  id={`toggle${wx._id}`}
                                                  color="primary"
                                                >
                                                  <i className="ni ni-bold-down"></i>
                                                </Button>
                                              </td>
                                              <td>
                                                {wx.user ? wx.user.name : ''}
                                              </td>
                                              <td align="right">
                                                ${' '}
                                                {/* {isNaN(
                                                  parseFloat(
                                                    Math.round(
                                                      payment * time * 100
                                                    ) / 100
                                                  ).toFixed(2)
                                                )
                                                  ? 0
                                                  : parseFloat(
                                                    Math.round(
                                                      payment * time * 100
                                                    ) / 100
                                                  ).toFixed(2)}{' '} */}
                                                {totalPayRatehours}
                                              </td>
                                              <td align="right">
                                                ${' '}
                                                {/* {isNaN(
                                                  parseFloat(
                                                    Math.round(
                                                      effective * time * 100
                                                    ) / 100
                                                  ).toFixed(2)
                                                )
                                                  ? 0
                                                  : parseFloat(
                                                    Math.round(
                                                      effective * time * 100
                                                    ) / 100
                                                  ).toFixed(2)} */}
                                                {totalEffectiveRatehours}
                                              </td>
                                              <td align="right">
                                                {isNaN(time) ? 0 : time}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colSpan={7}>
                                                <DropDownExpense
                                                  key={wx._id}
                                                  id={wx._id}
                                                  time={wx.time}
                                                ></DropDownExpense>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        );
                                      })
                                  )}
                                  <tr>
                                    <td></td>
                                    <td>Total:</td>
                                    <td align="right">
                                      $
                                      {parseFloat(
                                        Math.round(totalTime * 100) / 100
                                      ).toFixed(2)}
                                    </td>
                                    <td align="right">
                                      $
                                      {parseFloat(
                                        Math.round(totalEffective * 100) / 100
                                      ).toFixed(2)}
                                    </td>
                                    <td align="right">
                                      {isNaN(
                                        time.reduce((ac, cv) => ac + cv, 0)
                                      )
                                        ? 0
                                        : time.reduce((ac, cv) => ac + cv, 0)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <div className="d-flex col-md-8 col-xs-12 justify-content-between align-items-end">
                                <h3>- Expenses</h3>
                              </div>
                              <Table
                                className="align-items-center table-flush col-md-8 col-xs-12"
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th colSpan="2"></th>
                                    <th>Date</th>
                                    <th>Expense Type</th>
                                    <th>Amount</th>
                                    <th>Payment Account</th>
                                    <th>Vendor</th>
                                    <th>Employee</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!e.expenses || e.expenses.length === 0 ? (
                                    <tr>
                                      <td>No expenses.</td>
                                    </tr>
                                  ) : (
                                    e.expenses.map((ex, ix) => {

                                      const currentPaymentAccount = paymentAccount.find(item => ex.paymentAccountId && (ex.paymentAccountId === item._id))

                                      return <tr
                                        key={ix}
                                        className={
                                          ex.status !== 'reconciled'
                                            ? ''
                                            : 'tr-reconcile-bg'
                                        }
                                      >
                                        <td>
                                          <Button
                                            onClick={this.handleModal(ex._id)}
                                          >
                                            <i className="fas fa-receipt"></i>{' '}
                                            View
                                          </Button>
                                          <Button
                                            color="primary"
                                            onClick={this.handleModalEdit(
                                              ex._id
                                            )}
                                          >
                                            <i className="fas fa-edit"></i>{' '}
                                            Update
                                          </Button>
                                        </td>
                                        <td>
                                          <Input
                                            style={{width: '25px', height: '25px'}}
                                            type="checkbox"
                                            value={ex._id}
                                            className="btn"
                                            checked={ex.status === 'reconciled' ? true : false}
                                            onChange={event => {
                                                this.changeCheckBox(
                                                  e._id,
                                                  ex._id,
                                                  event.target.checked
                                                );

                                                ex.status === 'reconciled' ? ex.status = 'unreconciled' : e.status = 'reconciled';
                                              }
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Moment
                                            add={{ days: 1 }}
                                            format={'MMM D, YY'}
                                          >
                                            {ex.date}
                                          </Moment>
                                        </td>
                                        <td>{ex.category}</td>
                                        <td align="right">
                                          ${' '}
                                          {parseFloat(
                                            Math.round(ex.total * 100) / 100
                                          ).toFixed(2)}
                                        </td>
                                        <td>{currentPaymentAccount && currentPaymentAccount.name}</td>
                                        <td>{ex.vendor}</td>
                                        <td>{ex.users.map((user, index) => <div key={`exUsers-${index}`}>{user.name}</div>)}</td>

                                        <td>{ex.description}</td>
                                      </tr>

                                    })
                                  )}
                                  <tr>
                                    <td colSpan={2}></td>
                                    <td>Total:</td>
                                    <td align="right">
                                      ${' '}
                                      {!totalExpenses
                                        ? 0
                                        : parseFloat(
                                          Math.round(totalExpenses * 100) /
                                          100
                                        ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        ) : (
                          <>
                            <Table className="align-items-center table-flush table-striped col-xs-12">
                              <thead className="thead-light">
                                <tr>
                                  <th align="center">
                                    <b>Invoices</b>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!e.invoices ? (
                                  <tr>
                                    <td>No invoices register</td>
                                  </tr>
                                ) : e.invoices.length === 0 ? (
                                  <tr>
                                    <td>No invoices register</td>
                                  </tr>
                                ) : (
                                  e.invoices.map((e, i) => {
                                    const paid = e.payments.reduce(
                                      (acc, current, i) => acc + current.paid,
                                      0
                                    );
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <Moment
                                            add={{ days: 1 }}
                                            format={'MMM D, YY'}
                                          >
                                            {e.date}
                                          </Moment>{' '}
                                          <b>
                                            (
                                            {e.total - paid === 0
                                              ? 'Paid'
                                              : e.status}
                                            )
                                          </b>{' '}
                                          ${' '}
                                          {parseFloat(
                                            Math.round(e.total * 100) / 100
                                          ).toFixed(2)}
                                          <br />
                                          Client: {clientName || nameClient}
                                          <br />
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </Table>
                            <Table className="align-items-center table-flush table-striped col-xs-12">
                              <thead className="thead-light">
                                <tr>
                                  <th align="center">
                                    <b>Labor</b>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {e.workers.length === 0 ? (
                                  <tr>
                                    <td>No workers</td>
                                  </tr>
                                ) : (
                                  e.workers
                                    .sort(compareValues('date', 'asc'))
                                    .map((wx, i) => {
                                      if (!wx.workerId)
                                        return (
                                          <tr>
                                            <td>Worker Delete</td>
                                          </tr>
                                        );
                                      const time = wx.time
                                        ? wx.time.reduce(
                                          (acc, current, i) =>
                                            acc + current.hours,
                                          0
                                        )
                                        : 0;
                                      const time2 = [];
                                      time2.push(time);
                                      time2.reduce((ac, cv) => ac + cv, 0);
                                      const effective = wx.workerId.effective
                                        ? wx.workerId.effective
                                        : 0;
                                      const payment = wx.workerId.payment
                                        ? wx.workerId.payment
                                        : 0;
                                      return (
                                        <React.Fragment key={i}>
                                          <tr>
                                            <td>
                                              <Row>
                                                <Col>
                                                  Worker:{' '}
                                                  <b>{wx.workerId.name}</b>
                                                </Col>
                                                <Col>
                                                  Hour:{' '}
                                                  <b>
                                                    {isNaN(time) ? 0 : time}
                                                  </b>
                                                  <br />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  Payroll Expense:{' '}
                                                  <b>
                                                    ${' '}
                                                    {isNaN(
                                                      parseFloat(
                                                        Math.round(
                                                          payment * time * 100
                                                        ) / 100
                                                      ).toFixed(2)
                                                    )
                                                      ? 0
                                                      : parseFloat(
                                                        Math.round(
                                                          payment * time * 100
                                                        ) / 100
                                                      ).toFixed(2)}
                                                  </b>
                                                </Col>
                                                <Col>
                                                  Labor Expense:{' '}
                                                  <b>
                                                    ${' '}
                                                    {isNaN(
                                                      parseFloat(
                                                        Math.round(
                                                          effective * time * 100
                                                        ) / 100
                                                      ).toFixed(2)
                                                    )
                                                      ? 0
                                                      : parseFloat(
                                                        Math.round(
                                                          effective *
                                                          time *
                                                          100
                                                        ) / 100
                                                      ).toFixed(2)}
                                                  </b>
                                                </Col>
                                              </Row>
                                              <Button
                                                id={`toggle${wx._id}`}
                                                color="primary"
                                              >
                                                {' '}
                                                <i className="ni ni-bold-down"></i>
                                              </Button>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={7}>
                                              <DropDownExpense
                                                key={wx._id}
                                                id={wx._id}
                                                time={wx.time}
                                                isMobileVersion={
                                                  this.props.isMobileVersion
                                                }
                                              ></DropDownExpense>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    })
                                )}
                              </tbody>
                            </Table>
                            <Table className="align-items-center table-striped table-flush col-xs-12">
                              <thead className="thead-light">
                                <tr>
                                  <th align="center">
                                    <b>Expenses</b>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {e.expenses.length === 0 ? (
                                  <tr>
                                    <td>No expenses.</td>
                                  </tr>
                                ) : (
                                  e.expenses.map((ex, ix) => (
                                    <tr key={ix}>
                                      <td>
                                        <Moment
                                          add={{ days: 1 }}
                                          format={'MMM D, YY'}
                                        >
                                          {ex.date}
                                        </Moment>
                                        &nbsp;
                                        <b>({ex.category})</b>
                                        <br />${' '}
                                        {parseFloat(
                                          Math.round(ex.total * 100) / 100
                                        ).toFixed(2)}
                                        <br />
                                        {ex.vendor}
                                        <br />
                                        {ex.description}
                                        <br />
                                        <div className="buttonfloat-right buttonfloat-right-times">
                                          <Button
                                            onClick={this.handleModal(ex._id)}
                                          >
                                            <i className="fas fa-receipt"></i>{' '}
                                            View
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </UncontrolledCollapse>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </Table>
    );
  }
}

export default ReportJobs;
