import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import Moment from 'react-moment';


import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Input,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Popover,
    PopoverHeader,
    PopoverBody,
    CardTitle,
    Table,

    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from 'reactstrap';


// import {
//     Card,
//     CardHeader,
//     Container,
//     Row,
//     Col,
//     Table,
//     DropdownToggle,
//     DropdownMenu,
//     DropdownItem,
//     UncontrolledDropdown,
//     Form,
//     FormGroup,
//     Button,
//   } from 'reactstrap';
// core components

import Header from '../../components/Headers/Header';
import { quickDateFilters } from '../../constants';

// REDUX
import { getProperties, deleteProperty, getPropertyStages, getLenders } from '../../redux/actions/propertyAction';

import { prepareReportDateFilters } from '../../helpers/reports';
import { permissions } from '../../helpers/utils';



const ActionButton = props => (
    <UncontrolledDropdown>
        <DropdownToggle>...</DropdownToggle>
        <DropdownMenu
            modifiers={{
                setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: data => ({
                        ...data,
                        styles: {
                            ...data.styles,
                            overflow: 'auto',
                            maxHeight: 130,
                        },
                    }),
                },
            }}
        >
            {
                permissions(props.props.userLogged, 'properties', 'update', 0) ?
                    <DropdownItem to={`/admin/updateproperty/${props.item._id}`} tag={Link}>
                        Update
                    </DropdownItem>
                    :
                    <DropdownItem disabled to={`/admin/updateproperty/${props.item._id}`} tag={Link}>
                        Update
                    </DropdownItem>
            }
            {
                permissions(props.props.userLogged, 'properties', 'delete', 0) ?
                    <DropdownItem
                        onClick={() => {
                            if (window.confirm("Are you sure, you want to delete this item?")) {
                                props.deleteProperty(props.item._id);
                            }
                        }}
                    >
                        <span className="text-danger">Delete</span>
                    </DropdownItem>
                    :
                    <DropdownItem disabled to={`/admin/updateproperty/${props.item._id}`} tag={Link}>
                        Delete
                    </DropdownItem>
            }
        </DropdownMenu>
    </UncontrolledDropdown>
);

const defaultStartDate = moment().startOf('week').add('days', -7).format('YYYY-MM-DD')
const defaultEndDate = moment().startOf('week').add('days', 1).format('YYYY-MM-DD');
const defaultRangeDateLabel = `Last Week (${moment()
    .day(-7)
    .day('Monday')
    .format('YYYY-MMM-DD')} to ${moment()
        .day('Sunday')
        .format('YYYY-MMM-DD')})`;

const Properties = props => {

    const dispatch = useDispatch();
    const { property } = useSelector(state => {
        return {
            property: state.property
        }
    })

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [groupedProperties, setGroupProperties] = useState({});
    // const [defaultGroupedProperties, setDefaultGroupedroperties] = useState({});
    const [activeTabDate, setActivateDateTab] = useState('1');
    const [tabToggle, setTabToggle] = useState('1');

    const [purchaseContractCount, setPurchaseContractCount] = useState(0);
    const [purchasedCount, setPurchasedCount] = useState(0);
    const [soldCount, setSoldCount] = useState(0);



    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [rangeDateLabel, setRangeDateLabel] = useState(defaultRangeDateLabel);


    const archivedLeadStage = property && property.propertyStages ? property.propertyStages.find(item => item.stage === 'Archived Leads') : null;
    const leadStage = property && property.propertyStages ? property.propertyStages.find(item => item.stage === 'Leads') : null;
    const underContractStage = property && property.propertyStages ? property.propertyStages.find(item => item.stage === 'Under Contract') : null;
    const soldStage = property && property.propertyStages ? property.propertyStages.find(item => item.stage === 'Sold') : null;


    useEffect(() => {
        dispatch(getProperties());
        dispatch(getPropertyStages());
        dispatch(getLenders());
    }, []);

    useEffect(() => {

        const formattedProperties = groupPropertiesByStage(property.properties);

        if (underContractStage) {
            const purchaseCount = getPurchasedCount(formattedProperties[underContractStage._id] || []);
            setPurchasedCount(purchaseCount);
        }

        if (soldStage) {
            const saleCount = getSoldCount(formattedProperties[soldStage._id] || []);
            setSoldCount(saleCount);
        }

        if ((archivedLeadStage || leadStage) && (formattedProperties[archivedLeadStage._id] || formattedProperties[leadStage._id])) {
            let purchaseCount = 0;
            if (archivedLeadStage) {
                purchaseCount = getSignedPurchaseContracts(formattedProperties[archivedLeadStage._id] || []);
            }
            else if (leadStage) {
                purchaseCount = getSignedPurchaseContracts(formattedProperties[leadStage._id] || []);
            }

            setPurchaseContractCount(purchaseCount);
        }

        setGroupProperties({ ...formattedProperties });
        // setDefaultGroupedroperties({ ...formattedProperties });


    }, [property.properties]);


    const groupPropertiesByStage = data => {
        return data.reduce((accum, item) => {
            return item ? {
                ...accum,
                [item.stage]: [...(accum[item.stage] || []), item]
            } : accum
        }, {})
    };


    const getPurchasedCount = (data = []) => {
        return data.filter(item => item.dealInfo.closingDate).length;
    }

    const getSoldCount = (data = []) => {
        return data.filter(item => item.saleInfo && item.saleInfo.saleDate).length;
    };


    const getSignedPurchaseContracts = (data = []) => {
        return data.filter(item => item.dealInfo && item.dealInfo.projectedSaleDate).length;
    };


    const handleTabToggle = tab => {
        setTabToggle(tab);
    }

    const toggleTabDate = tab => {
        if (activeTabDate !== tab) {
            setActivateDateTab(tab);
        }
    }

    const handleDeleteProperty = id => {
        props.deleteProperty(id);
    }

    const popoverToggle = () => {
        setPopoverOpen(!popoverOpen);
    };

    const handleFilterDate = () => {

        if (popoverOpen) {
            setPopoverOpen(!popoverOpen);
        }

        if (startDate && endDate) {

            const filteredDefaultGroupedProperties = property.properties.filter(item => {
                return (item.dealInfo && (item.dealInfo.purchaseContractDate >= startDate)) &&
                    (item.dealInfo && (item.dealInfo.purchaseContractDate <= endDate))
            });

            const updatedGroupedProperties = groupPropertiesByStage(filteredDefaultGroupedProperties);

            if (underContractStage) {
                const purchaseCount = getPurchasedCount(updatedGroupedProperties[underContractStage._id] || []);
                setPurchasedCount(purchaseCount);
            }


            if (soldStage) {
                const saleCount = getSoldCount(updatedGroupedProperties[soldStage._id] || []);
                setSoldCount(saleCount);
            }

            if ((archivedLeadStage || leadStage)) {
                let purchaseCount = 0;
                if (archivedLeadStage) {
                    purchaseCount = getSignedPurchaseContracts(updatedGroupedProperties[archivedLeadStage._id] || []);
                }
                else if (leadStage) {
                    purchaseCount = getSignedPurchaseContracts(updatedGroupedProperties[leadStage._id] || []);
                }

                setPurchaseContractCount(purchaseCount);
            }

            // setGroupProperties({
            //     ...updatedGroupedProperties
            // })
        }

    }

    const handleQuickFilterChange = filter => {


        
        const { startDate, endDate, rangeDateLbl } = prepareReportDateFilters(filter);
        if (startDate && endDate && rangeDateLbl) {
            setRangeDateLabel(rangeDateLbl);
            setStartDate(startDate);
            setEndDate(endDate);
            setPopoverOpen(false);
        }

    }

    const handleCustomDateFilterChange = e => {
        
        const rangeDateLbl  = `From (${moment(startDate)
                .format('YYYY-MMM-DD')} to ${moment(e.target.value)
                .format('YYYY-MMM-DD')})`;

            setRangeDateLabel(rangeDateLbl);
            setEndDate(e.target.value);
            // setPopoverOpen(false);
    }


    return <>
        <Header />
        <Container className="mt--7" fluid>
            <Row className="mt-5">
                <Col className="mb-5 mb-xl-0" xl="12">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Properties Dashboard</h3>
                                </div>
                                <div className="col text-right">
                                    {
                                        permissions(props.userLogged, 'properties', 'create', 0) ?
                                        <Button color="primary" type="button" onClick={() => window.location.href = 'addproperty'}>
                                            Add Property
                                        </Button>
                                            : null
                                    }
                                </div>
                            </Row>
                            <Row>
                                <Col lg="3">

                                    <h5 >Signed Purchase Contracts: {purchaseContractCount}</h5>
                                </Col>
                                <Col lg="3">
                                    <h5 >Purchased: {purchasedCount}</h5>
                                </Col>
                                <Col lg="3">
                                    <h5 >Sold: {soldCount}</h5>
                                </Col>

                            </Row>

                            <Row>

                                <Form className="card-header">
                                    <Row form>
                                        <Col md={20} lg={20}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-dateStart"
                                                >
                                                    Date Picker
                                                </label>
                                                <Input
                                                    disabled={true}
                                                    value={rangeDateLabel}
                                                    name="rangeDateLbl"
                                                    id="rangeDateLbl"
                                                    className="form-control-alternative inputRangeDate"
                                                    style={{ width: 400 }}

                                                />
                                                <Popover
                                                    placement="bottom"
                                                    isOpen={popoverOpen}
                                                    popperClassName="popoverDate"
                                                    target="rangeDateLbl"
                                                    toggle={popoverToggle}
                                                >
                                                    <PopoverHeader>Select Date</PopoverHeader>
                                                    <PopoverBody>
                                                        <Nav tabs>
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeTabDate === '1',
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleTabDate('1');
                                                                    }}
                                                                >
                                                                    Quick Date/Time
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeTabDate === '2',
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleTabDate('2');
                                                                    }}
                                                                >
                                                                    Custom Range
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                        <TabContent activeTab={activeTabDate}>
                                                            <TabPane tabId="1">
                                                                <Row>
                                                                    <Col className="pt-2">
                                                                        {_.isArray(quickDateFilters) &&
                                                                            quickDateFilters.map((filter, index) => (
                                                                                <FormGroup key={index}>
                                                                                    <label>{_.startCase(filter)}</label>
                                                                                    <Input
                                                                                        type="select"
                                                                                        onChange={e => {
                                                                                            handleQuickFilterChange(
                                                                                                e.target.value
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <option>Select</option>
                                                                                        <option value={`last_${filter}`}>
                                                                                            Last {_.startCase(filter)}
                                                                                        </option>
                                                                                        <option value={`past_${filter}`}>
                                                                                            Past {_.startCase(filter)}
                                                                                        </option>
                                                                                        <option value={`this_${filter}`}>
                                                                                            This {_.startCase(filter)}
                                                                                        </option>
                                                                                    </Input>
                                                                                </FormGroup>
                                                                            ))}
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId="2">
                                                                <Row>
                                                                    <Col>
                                                                        <Card body>
                                                                            <CardTitle>Select Range</CardTitle>
                                                                            <Row>
                                                                                <Col>
                                                                                    <FormGroup>
                                                                                        <label
                                                                                            className="form-control-label"
                                                                                            htmlFor="input-dateStart"
                                                                                        >
                                                                                            From Date
                                                                                        </label>
                                                                                        <Input
                                                                                            name="startDate"
                                                                                            className="form-control-alternative"
                                                                                            type="date"
                                                                                            value={startDate}
                                                                                            onChange={e => {
                                                                                                setStartDate(e.target.value)
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col>
                                                                                    <FormGroup>
                                                                                        <label
                                                                                            className="form-control-label"
                                                                                            htmlFor="input-dateStart"
                                                                                        >
                                                                                            Up to Date
                                                                                        </label>
                                                                                        <Input
                                                                                            name="endDate"
                                                                                            className="form-control-alternative"
                                                                                            type="date"
                                                                                            value={endDate}
                                                                                            onChange={handleCustomDateFilterChange}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </PopoverBody>
                                                </Popover>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} lg={2} xl={2} xs={3} sm={2}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-dateStart"
                                                >
                                                    &nbsp;
                                                </label>
                                                <br />
                                                <Button
                                                    className="form-control-alternative"
                                                    color="info"
                                                    onClick={handleFilterDate}
                                                >
                                                    <i className="fa fa-search"></i>{' '}
                                                    Search
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>

                        </CardHeader>
                        <Form className="card-header">
                            <Row form>
                                <Nav tabs>
                                    {property && property.propertyStages.map(item => {
                                        return <NavItem key={item._id}>
                                            <NavLink
                                                className={classnames({ active: tabToggle === `${item._id}` })}
                                                onClick={() => { handleTabToggle(item._id); }}
                                            >
                                                {item.stage} ({groupedProperties[item._id] ? groupedProperties[item._id].length : 0})
                                            </NavLink>
                                        </NavItem>
                                    })}

                                </Nav>
                                <TabContent activeTab={tabToggle} style={{ width: '100%' }}>

                                    {property && property.propertyStages && property.propertyStages.map((item, i) => {

                                        return (<TabPane key={'tabPane-' + i} tabId={item._id} style={{ width: '100%', padding: 12 }}>
                                            <Row>
                                                <Table style={{ width: '100%' }} className="align-items-center table-flush" responsive>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Address</th>
                                                            <th>Date Created</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {groupedProperties[item._id] && groupedProperties[item._id].map((propertyItem, index) => {
                                                            return (
                                                            <tr key={'groupedTR-' + index}>

                                                                <td style={{ width: 80 }}>
                                                                    <ActionButton
                                                                        item={propertyItem}
                                                                        deleteProperty={handleDeleteProperty}
                                                                        props={props}
                                                                    ></ActionButton>

                                                                </td>
                                                                <td>{propertyItem && propertyItem.propertyInfo && propertyItem.propertyInfo.address1}</td>
                                                                <td>{<Moment format={'MMM D, YY'}>
                                                                    {propertyItem.createdAt}
                                                                </Moment>
                                                                }</td>
                                                            </tr>

                                                            )})}

                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </TabPane>
                                    )})}

                                </TabContent>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
}


const mapStateToProps = state => ({

    properties: state.properties,
    users: state.user.users,
    userLogged: state.auth.userLogged,

});

export default connect(mapStateToProps, {
    getProperties,
    deleteProperty
})(Properties);
