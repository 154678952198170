import {
    FETCH_REVENUE_CENTER_SENT,
    FETCH_REVENUE_CENTER_SUCCESS,
    FETCH_REVENUE_CENTER_FAILURE,
    FETCH_REVENUE_CENTER_REMOVE_SENT,
    FETCH_REVENUE_CENTER_REMOVE_SUCCESS,
    FETCH_REVENUE_CENTER_REMOVE_FAILURE,
    FETCH_REVENUE_CENTER_ADD_FAILURE,
    FETCH_REVENUE_CENTER_ADD_SUCCESS,
    FETCH_REVENUE_CENTER_UPDATE_SUCCESS,
    FETCH_REVENUE_CENTER_UPDATE_FAILURE,
  } from '../actions/revenueCenterAction';
  import { LOG_LOGOUT_USER } from '../actions/authAction';
  import { merger, adder, updater, remover } from './actionReducers';
  
  const initialState = {
    list: [],
    didInvalidate: true
  };
  
  export const revenueCenterReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REVENUE_CENTER_REMOVE_SENT:
      case FETCH_REVENUE_CENTER_SENT:
        return state;
      case LOG_LOGOUT_USER:
        state = initialState;
        return state;
      case FETCH_REVENUE_CENTER_SUCCESS:
        return merger(state, { 
          list: action.payload, 
          itemsErr: undefined ,
          didInvalidate: false,
          lastUpdated: action.receivedAt
        });
      case FETCH_REVENUE_CENTER_FAILURE:
        return merger(state, { itemsEr: action.payload });
  
      case FETCH_REVENUE_CENTER_ADD_SUCCESS:
        return { ...state, list:adder(state.list, action.payload) };
      case FETCH_REVENUE_CENTER_ADD_FAILURE:
        return merger(state, { itemsEr: action.payload });
  
      case FETCH_REVENUE_CENTER_UPDATE_SUCCESS:
        const index = state.list.findIndex(u => u._id === action.payload.id);
        return {
          ...state,
          list:updater(state.list, action.payload.data, index),
        };
      case FETCH_REVENUE_CENTER_UPDATE_FAILURE:
        return merger(state, { itemsEr: action.payload });
  
      case FETCH_REVENUE_CENTER_REMOVE_SUCCESS:
        return { ...state, list: remover(state.list, action.payload.id) };
      case FETCH_REVENUE_CENTER_REMOVE_FAILURE:
        return merger(state, { itemsEr: action.payload });
  
      default:
        return state;
    }
  };
  