import React from 'react';
import PDFViewer from './PDFViewer';
import PDFThumbViewer from './PDFThumbViewer';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`;


class PreviewFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: 0
            , pageNumber: 1
            , currentIndex: 0
        }
        this.onDocumentLoadSuccess.bind(this);
        this.setCurrentIndex.bind(this);
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    setCurrentIndex = (targetIndex) => {
        const previewImages = this.props.previewImages;
        targetIndex = targetIndex < 0 ? previewImages.length-1 : targetIndex >= previewImages.length ? 0 : targetIndex;
        this.setState({ currentIndex: targetIndex });
    }

    render() {
        const {
            previewImages
        } = this.props;

        const {
            currentIndex
        } = this.state;

        const previewSlider = [];
        const previewThumb = [];


         // if we are at the end, go to the first photo
        const photos = previewImages.map((item, index) => {
            const newItem = { id: index };
            const file = item.file;
            const viewingFrom = item.viewingFrom;
            if (viewingFrom === "input-file" && file && file.type) {
                const fileUrl = URL.createObjectURL(file);
                const type = file.type.toLowerCase();
                newItem.url = fileUrl;
                if (type.includes('pdf')) {
                    newItem.type = 'pdf';
                } else if (type.includes("image") || ['.jpg', '.png', '.jpeg'].includes(type)) {
                    newItem.type = 'image';
                }
            } else if (viewingFrom === "url" && file) {
                const fileUrl = file.toLowerCase();
                newItem.url = file.replace('http:', 'https:');
                if (fileUrl.indexOf('.pdf') > -1) {
                    newItem.type = 'pdf';
                } else if (fileUrl.indexOf('.jpg') > -1 || fileUrl.indexOf('.png') > -1 || fileUrl.indexOf('.jpeg') > -1) {
                    newItem.type = 'image';
                }
            }
            return newItem;
        });

        photos.map(photo => {
            previewSlider.push(
                <div
                    key={photo.id}
                    className={photos[currentIndex].id === photo.id ? 'slide -active' : 'slide'}
                >
                    {
                        photo.type === "pdf" ?
                        <PDFViewer fileUrl={photo.url} />
                        :
                        <img src={photo.url} alt={photo.id} className='photo' />
                    }
                </div>
            );

            if (photos && photos.length > 1) {
                previewThumb.push(
                    <li className={photos[currentIndex].id === photo.id ? 'thumb selected' : 'thumb'} onClick={this.setCurrentIndex.bind(this, photo.id)}>
                        {
                            photo.type === "pdf" ?
                            <PDFThumbViewer fileUrl={photo.url} />
                            :
                            <img src={photo.url} alt={photo.id} className='photo' />
                        }
                    </li>
                )
            }
            return photo;
        });
       

        return (
            <div className='file-preview-carousel'>
                <label>Document Preview</label>
                {/* Render the carousel */}
                <div className='slider-container carousel carousel-slider'>
                    {previewSlider}
                    {
                        photos && photos.length > 1 ? 
                        <div>
                            <button onClick={this.setCurrentIndex.bind(this, currentIndex-1)} className='control-arrow control-prev'></button>
                            <button onClick={this.setCurrentIndex.bind(this, currentIndex+1)} className='control-arrow control-next'></button>
                        </div>
                        : null
                    }
                </div>

                {/* Render dots indicator */}
                <div className='dots'>
                    {photos.map((photo) => (
                    <span
                        key={photo.id}
                        // highlight the dot that corresponds to the current photo
                        className={
                        photos[currentIndex].id === photo.id ? 'dot active' : 'dot'
                        }
                        // when the user clicks on a dot, go to the corresponding photo
                        onClick={this.setCurrentIndex.bind(this, photo.id)}
                    ></span>
                    ))}
                </div>

                {
                    photos && photos.length > 1 ?
                    <div className="carousel">
                        <div className="thumbs-wrapper axis-vertical">
                            <ul className='thumbs animated'>
                                {previewThumb}
                            </ul>
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }
}

export default PreviewFile;
