import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`;

class PDFThumbViewer extends React.Component {
    
    render() {
        const {
            fileUrl
        } = this.props;

        return (
            <Document 
                file={fileUrl} 
                onLoadError={(err) => console.error("err", err)}
            >
                <Page key={0} pageNumber={1} />
            </Document>
        );
    }
}

export default PDFThumbViewer;