import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { updateJob } from '../../redux/actions/jobAction';
import { GooglePlacesAutocomplete } from '../../components/Form/GooglePlacesAutocomplete';

class UpdateJob extends React.Component {
  state = {
    name: '',
    email: '',
    revenueCenter: '',
    address: '',
    items: [],
    itemName: '',
    description: '',
    comments: '',
    quantity: 0,
    rate: 0,
    subtotal: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    total: 0,
    dateCreate: '',
    jobName: '',
    submitting: false
  };

  componentDidMount() {
    const job = this.props.jobs.filter(
      item => item._id === this.props.match.params.id
    )[0];
    const client = this.props.clients.filter(
      item => item._id === job.clientId
    )[0];


    this.setState(prevState => ({
      ...prevState,
      ...job,
      clientId: job.clientId,
      name: client ? `${client.firstName} ${client.lastName}` : '',
      email: client ? client.email : '',
      address: job.jobAddress ? job.jobAddress : '',
      revenueCenter: job.revenueCenter ? job.revenueCenter : '',
      tax: job.tax,
      discount: job.discount,
      paid: job.paid,
      comments: job.comments,
    }));
  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true });

    const client = this.props.clients.filter(
      item => item._id === this.state.clientId
    )[0];
    let payload = {
      ...this.state,
      jobName: `${client.firstName} ${client.lastName} - ${this.state.address}`
    };

   
    await this.props.updateJob(this.props.match.params.id, payload);
    this.props.history.push(`/admin/jobs`);
    this.setState({ submitting: false });
  };

  setPhoneNumber = e => {
    this.setState(prevState => ({
      ...prevState,
      estimatePhone: e,
    }));
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = async (address) => {
    this.setState({ address });
  };

  render() {
    const { submitting } = this.state;
    const { clients, revenueCenter } = this.props;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Estimate Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Name
                            </label>
                            {/* <Input
                              readOnly
                              defaultValue={`${this.state.name}`}
                              className="form-control-alternative"
                              placeholder="Enter the name client"
                              name="clientName"
                              type="text"
                              onChange={this.handleInput}
                            /> */}

                            <Input
                              className="form-control-alternative"
                              name="clientId"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Select"
                              value={this.state.clientId}
                            >
                              <option value="Select" disabled>
                                Select a client
                              </option>
                              {clients.map(client => (
                                <option key={client._id} value={client._id}>
                                  {`${client.firstName} ${client.lastName}${client.email ? ` - ${client.email}` : ''
                                    }`}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          {/* <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-nameClient"
                            >
                              Client Email
                            </label>
                            <Input
                              readOnly
                              defaultValue={`${this.state.email}`}
                              className="form-control-alternative"
                              placeholder="Enter a billing name or search your clients list"
                              name="email"
                              type="email"
                              onChange={this.handleInput}
                            />
                          </FormGroup> */}

                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-nameClient"
                            >
                              Revenue Center
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="revenueCenter"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Select"
                              value={this.state.revenueCenter}
                            >
                              <option value="Select" disabled>
                                Select a Revenue Center
                              </option>
                              {revenueCenter.map(item => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-nameClient"
                            >
                              Address
                            </label>
                            <GooglePlacesAutocomplete onSelect={this.handleSelect} value={this.state.address} />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Phone number *
                            </label>
                            <PhoneInput
                              international={false}
                              defaultCountry="US"
                              placeholder="Enter phone number"
                              value={this.state.estimatePhone}
                              onChange={this.setPhoneNumber}
                            />
                          </FormGroup>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  className="form-control-alternative"
                                  color="info"
                                  disabled={submitting}
                                >
                                  Save
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  clients: state.client.clients,
  revenueCenter: state.revenueCenter.list
});

export default connect(mapStateToProps, { updateJob })(withRouter(UpdateJob));
