import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Badge,
    Button
} from 'reactstrap';

import Header from '../../components/Headers/Header';
import { useEffect } from 'react';

// REDUXse
import { getPaymentAccounts, removePaymentAccount } from '../../redux/actions/paymentAccountAction';
import { getPermissions, removePermission } from '../../redux/actions/permissionAction';
import { permissions } from '../../helpers/utils';


// /admin/updateproperty/${props.item._id}
const ActionButton = props => {
    return (
        <UncontrolledDropdown>
            <DropdownToggle>...</DropdownToggle>
            <DropdownMenu
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => ({
                            ...data,
                            styles: {
                                ...data.styles,
                                overflow: 'auto',
                                maxHeight: 130,
                            },
                        }),
                    },
                }}
            >
            {/* {
                permissions(props.props.userLogged, 'workers', 'update', 3) ?
                <DropdownItem to={`/admin/create-default-permission/assign/${props.data && props.data._id}`} tag={Link}>
                    Assign To Users
                </DropdownItem>
            :
                <DropdownItem disabled to={`/admin/create-default-permission/assign/${props.data && props.data._id}`} tag={Link}>
                    Assign To Users
                </DropdownItem>
            } */}
            {
                permissions(props.props.userLogged, 'workers', 'update', 3) ?
                <DropdownItem to={`/admin/create-default-permission/${props.data && props.data._id}`} tag={Link}>
                    Update
                </DropdownItem>
            :
                <DropdownItem disabled to={`/admin/create-default-permission/${props.data && props.data._id}`} tag={Link}>
                    Update
                </DropdownItem>
            }
            {
                permissions(props.props.userLogged, 'workers', 'delete', 4) ?
                <DropdownItem
                    onClick={() => {
                        if (window.confirm("Are you sure, you want to delete this item?") && props.data) {
                            props.deletePermission(props.data._id);
                        }
                    }}
                >
                    <span className="text-danger">Delete</span>
                </DropdownItem>
            :
                <DropdownItem disabled to={`/admin/create-default-permission/${props.data && props.data._id}`} tag={Link}>
                    Delete
                </DropdownItem>
            }

            </DropdownMenu>
        </UncontrolledDropdown>
    );
}


const DefaultPermissions = props => {

    const dispatch = useDispatch();

    const { paymentAccount, users, defPermission } = useSelector(state => {
        return {
            paymentAccount: state.paymentAccount,
            users: state.user.users,
            defPermission: state.defPermission
        }
    });


    useEffect(() => {
        dispatch(getPaymentAccounts());
        dispatch(getPermissions())
        // dispatch(getUsers());
    }, [])

    const handleDeletePermission = id => {
        dispatch(removePermission(id));
    }
    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">

                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Default Permissions</h3>
                                    </div>
                                    <div className="col text-right">
                                        {
                                            permissions(props.userLogged, 'workers', 'create', 3) ?
                                                <Button color="primary" type="button" onClick={() => window.location.href = '/admin/create-default-permission'}>
                                                    Add Default Permission
                                                </Button>
                                                : null
                                        }
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Table style={{ width: '100%' }} className="align-items-center table-flush" responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Permission Name</th>
                                                {/* <th>Users</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {defPermission && defPermission.list !== undefined && defPermission.list.length > 0 ? defPermission.list.map((item, index) => {
                                                // const usersWithPermission = users.filter(user => user.permissionId === item._id);
                                            
                                                
                                               return <tr key={'permission-' + index} >

                                                    <td style={{ width: 80 }}>
                                                        <ActionButton
                                                            deletePermission={handleDeletePermission}
                                                            data={item}
                                                            props={props}
                                                        ></ActionButton>

                                                    </td>
                                                    <td>{item && item.name}</td>
                                                    {/* <td>{usersWithPermission.map(user => {return <><Badge style={{ fontSize: '12px' }} color="info">{user.name }</Badge>{' '} </>})}</td> */}

                                                </tr>
                                            }) : <tr><td>No results.</td></tr>
                                        }

                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


};

const mapStateToProps = state => ({
    userLogged: state.auth.userLogged
});

export default connect(mapStateToProps)(
    withRouter(DefaultPermissions)
);