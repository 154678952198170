import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { isEqual, set, find} from 'lodash';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import Global, { compareValues } from '../../global';
import { getExpenses, getExpenseCount, removeExpense, sendReconciled } from '../../redux/actions/expenseAction';
import {
  getJobs
} from '../../redux/actions/jobAction';
import { reset } from '../../redux/actions/searchAction';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';

import PreviewFile from '../../helpers/PreviewFile';
import { permissions } from '../../helpers/utils';
import LoadBar from '../../components/shared/loadBar';
import Select from '../../../node_modules/react-select';

const ActionButton = props => (
  <span className="dropdownButtons">
    <UncontrolledDropdown>
      <DropdownToggle>...</DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: data => ({
              ...data,
              styles: {
                ...data.styles,
                overflow: 'auto',
                maxHeight: 130,
              },
            }),
          },
        }}
      >
        {
          permissions(props.props.userLogged, 'expenses', 'update', 1, props.item) ?
            <DropdownItem to={`/admin/expenses/${props.item._id}/update`} tag={Link}>
              Update
            </DropdownItem>
            :
            <DropdownItem disabled to={`/admin/expenses/${props.item._id}/update`} tag={Link}>
              Update
            </DropdownItem>
        }
        {
          permissions(props.props.userLogged, 'expenses', 'delete', 4, props.item) ?
            <DropdownItem onClick={() => {
              props.props.removeExpense(props.item._id);
              alert('Expense Delete');
            }}
            >
              <span className="text-danger">Delete</span>
            </DropdownItem>
            :
            <DropdownItem disabled to="/" tag={Link}>
              Delete
            </DropdownItem>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  </span>
);

// const TestRow = ({ index, style }) => {
//   return <div style={style}>Row {index}</div>
// }

const ITEM_PER_PAGE = [
  50, 75, 100, 500, 1000
]
class Expenses extends React.Component {
  state = {
    buttonActive: '3',
    modal: false,
    expense: {
      user: {},
      job: {},
    },
    allExpenses: [],
    expenses: [],
    isMobileVersion: false,
    currentPage: 0,
    pagesCount: 0,
    itemPerPage: 50,
    trReconcileBg: 'tr-reconcile-bg',
    filterExpensesByPayment: [],
    selectedPaymentMethod: [],
    paymentAccountOptions: [],
    expenseInterval: '',
  };

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < Global.mobileWidth,
    }));
  };

  async componentDidMount() {

    const { itemPerPage } = this.state;
    if (!this.props.userLogged) return this.props.history.push('/auth/login');
    await this.props.reset();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (this.props.userLogged._id !== 'undefined') {
      if (!this.props.jobs.length) {
        await this.props.getJobs(this.props.userLogged._id);
      }

      if (!this.props.paymentAccount.list.length) {
        await this.props.getPaymentAccounts(this.props.userLogged._id);
      }

      await this.props.getExpenses(this.props.userLogged._id, {
          page: 1,
          pagination: itemPerPage
      });

      const initialExpense = this.props.expenses.slice(0, itemPerPage);

      this.setState({
        allExpenses: [...(initialExpense || [])]
      })

      if (this.props.paymentAccount && this.props.paymentAccount.list) {
        this.setState({paymentAccountOptions: this.props.paymentAccount.list.map(item => { return {value: item._id, label: item.name}})});
      }

    }
  }


  async componentDidUpdate(prevProps, prevState) {
    const { itemPerPage } = this.state;
    const { expenses, userLogged } = this.props;

    if (userLogged && (userLogged._id !== null || userLogged._id !== undefined)) {
      if (!isEqual(expenses, prevProps.expenses)) {
        await this.props.getExpenses(userLogged._id, {
          page: 1,
          pagination: itemPerPage
        });
        const initialExpense = expenses.slice(0, itemPerPage);

        this.setState({
          allExpenses: [...(initialExpense || [])]
        });
      }
    }

  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    // clearInterval(this.state.expenseInterval);
    // await this.props.reset();

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  openModal = expense => {

    // const expense = this.props.expenses.filter(
    //   expense => expense._id === id
    // )[0];

    const job = this.props.jobs.filter(job => job._id === expense.jobId)[0];
    let user = this.props.users.filter(user => user._id === expense.userId)[0];
    if (user) {
      user.name = user.name ? user.name : '';
    } else {
      user = { name: '' };
    }
    expense.user = user;
    expense.job = job;
    this.setState(prevState => ({
      modal: true,
      expense,
    }));
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !this.state.modal,
    }));
  };

  getOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '1',
      currentPage: 0
    }));
  };

  getClose = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '2',
      currentPage: 0
    }));
  };

  getAll = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '3',
      currentPage: 0
    }));
  };

  getReconciled = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '5',
      currentPage: 0
    }));
  };

  getUnReconciled = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '6',
      currentPage: 0
    }));
  };

  getExpenses = async (currentPage) => {
    const { itemPerPage } = this.state;
    const { expenses } = this.props;
    const offset = currentPage * itemPerPage;
    const updatedExpense = expenses.slice(offset, offset + itemPerPage)
    this.setState({
      allExpenses: [...(updatedExpense || [])]
    });
    // await this.props.getExpenses(this.props.userLogged._id, {
    //   page: currentPage,
    //   pagination: 10
    // });
  }

  nextpage = (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    })
  }

  tenChange = (pageNumber, isposOrneg) => {
    let finalPage;
    if (isposOrneg > 0) {
      finalPage = pageNumber + 10;
    }
    else {
      finalPage = pageNumber - 10;
    }
    this.setState({
      currentPage: finalPage
    })
  }

  hundredChange = (pageNumber, isposOrneg) => {
    let finalPage;
    if (isposOrneg > 0) {
      finalPage = pageNumber + 100
    }
    else {
      finalPage = pageNumber - 100
    }
    this.setState({
      currentPage: finalPage
    })
  }

  getFilteredExpenses = (data = [], status) => {
    let filteredData = [...(data)];
    switch (status) {
      case '1':
        filteredData = filteredData.filter(
          expense => expense.job && expense.job.status === 'Approve'
        );
        break;
      case '2':
        filteredData = filteredData.filter(
          expense => expense.job && expense.job.status === 'Closed'
        );
        break;
      case '4':
        filteredData = this.state.filterExpensesByPayment;
        break;
      case '5':
        filteredData = filteredData.filter(item => { return item.status === 'reconciled' });
        break;
      case '6':
        filteredData = filteredData.filter(item => { return item.status !== 'reconciled' });
        break;
      default:
        break;
    }


    return filteredData;
  }

  handlePerItemChange = e => {

    const value = parseInt(e.target.value);

    const initialExpense = this.props.expenses.slice(0, value);

    this.setState({
      allExpenses: [...(initialExpense || [])],
      itemPerPage: value,
      currentPage: 0
    });

  }

  filterByPaymentMethod = e => {
    const { itemPerPage } = this.state;
    const initialExpense = this.props.expenses.slice(0, itemPerPage);
    if (e && e.length > 0 ) {
      const filterExpenses = initialExpense.filter(item => {
        if (e && e.length > 0) {
          return e.some(data => {
            return item.paymentAccountId === data.value;
          })
        }
  
        return item;
      });
  
      this.setState(prevState => ({
        ...prevState,
        filterExpensesByPayment: filterExpenses,
        buttonActive: '4',
        currentPage: 0,
        selectedPaymentMethod: e
      }));
      
    } else {

      this.setState(prevState => ({
        ...prevState,
        filterExpensesByPayment: initialExpense,
        buttonActive: '3',
        currentPage: 0,
        selectedPaymentMethod: e
      }));
    }

  };

  changeCheckBox = async (expenseId, checked) => {
    const { expenses } = this.props;
    const { selectedPaymentMethod, page, itemPerPage } = this.state;
    if(checked){
      await this.props.sendReconciled({items: [expenseId], status: 'reconciled'});
      const expense = find(expenses, {'_id': expenseId});
      set(expense, 'status', 'reconciled');

    } else {
      await this.props.sendReconciled({items: [expenseId], status: 'unreconciled'});
      const expense = find(expenses, {'_id': expenseId});
      set(expense, 'status', 'unreconciled');
    }
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  searchFunction = () => {
    var input, filter, table, tr, i;
    input = document.getElementById("expenseSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("expenseTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }
      
      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }
  }

  render() {
    let { expenses, paymentAccount } = this.props;
    const { allExpenses, currentPage, itemPerPage, trReconcileBg, paymentAccountOptions, buttonActive, selectedPaymentMethod } = this.state;
    let { pagesCount } = this.state;

  
    if (!this.state) return <LoadBar />;

    let expensesFilter = [...(allExpenses || [])];
    let userInEstimate;
    expenses =
      expenses.length > 0
        ? expenses.sort(compareValues('date', 'desc'))
        : expenses;
    if (expenses.length > 0) {

      expensesFilter = this.getFilteredExpenses(expenses, this.state.buttonActive)

      expensesFilter.forEach(item => {
        item.job = this.props.jobs.filter(job => job._id === item.jobId)[0];
        item.user = this.props.users.filter(
          user => user._id === item.userId
        )[0];
      });

    }

  pagesCount = Math.ceil(expensesFilter.length / itemPerPage);
  let displayedExpense = [...new Set(expensesFilter.slice(this.state.currentPage * itemPerPage,(this.state.currentPage + 1) * itemPerPage))];

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                  <div className="col">
                      {/* <FormGroup className="mb-0"> */}
                        <InputGroup className="input-group-alternative search-margin">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='search-style'
                            placeholder="Search"
                            type="text"
                            id="expenseSearchInput"
                            onChange={this.searchFunction}
                          />
                        </InputGroup>
                      {/* </FormGroup> */}
                    </div>
                    <div className="col text-right">
                      {
                        permissions(this.props.userLogged, 'expenses', 'create', 0) ?
                        <Button color="primary" type="button" onClick={() => window.location.href = 'addexpense'}>
                          Create Expense
                        </Button>
                          : null
                      }
                    </div>
                    
                  </Row>
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Expenses</h3>
                    </div>

                  </Row>
                </CardHeader>
                <Form className="card-header">
                  <Row form>
                    <Col md={{ size: 8 }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-dateStart"
                        >
                          Filter
                        </label>
                        <br />
                        <span>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '1'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getOpen}
                          >
                            {!this.state.isMobileVersion ? (
                              'Open'
                            ) : (
                              <small>Open</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '2'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getClose}
                          >
                            {!this.state.isMobileVersion ? (
                              'Close'
                            ) : (
                              <small>Close</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '5'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getReconciled}
                          >
                            {!this.state.isMobileVersion ? (
                              'Reconciled'
                            ) : (
                              <small>Reconciled</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '6'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getUnReconciled}
                          >
                            {!this.state.isMobileVersion ? (
                              'Unreconciled'
                            ) : (
                              <small>Unreconciled</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '3'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getAll}
                          >
                            {!this.state.isMobileVersion ? (
                              'All'
                            ) : (
                              <small>All</small>
                            )}
                          </Button>
                          <br />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                      <Input
                        name="items"
                        className="form-control-alternative"
                        type="select"
                        onChange={this.handlePerItemChange}
                        value={itemPerPage}
                      >
                        <option value="Select" disabled>
                          Item Per Page
                        </option>
                        {ITEM_PER_PAGE.map((value) => (
                          <option key={value} value={value}>
                            {value} Item per page
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                    <div style={{width: '350px'}}>
                          <Select
                              isMulti
                              name="paymentAccountOptions"
                              options={paymentAccountOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder='Select Payment Accounts'
                              onChange={this.filterByPaymentMethod}
                              value={buttonActive !== '4' ? [] : selectedPaymentMethod}
                            />
                          </div>
                    </Col>
                  </Row>

                </Form>
{/* 
                <Pagination
                  pages={numberOfPages}
                  nextPage={this.nextpage}
                  currentPage={this.state.currentPage}
                  hundredChange={this.hundredChange}
                  tenChange={this.tenChange}
                /> */}

                <Pagination className="pagination-center">
                  <PaginationItem disabled={this.state.currentPage <= 0}>
                    <PaginationLink
                      onClick={e =>
                        this.handleClick(e, this.state.currentPage - 1)
                      }
                      previous
                      href="#"
                    />
                  </PaginationItem>
                  {[...Array(pagesCount)].map((page, i) => (
                    <PaginationItem
                      active={i === this.state.currentPage}
                      key={i}
                    >
                      <PaginationLink
                        onClick={e => this.handleClick(e, i)}
                        href="#"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={this.state.currentPage >= pagesCount - 1}
                  >
                    <PaginationLink
                      onClick={e =>
                        this.handleClick(e, this.state.currentPage + 1)
                      }
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>


                <Table className="align-items-center table-flush" id="expenseTable" responsive>
                  <thead className="thead-light">
                    <tr>
                      {!this.state.isMobileVersion ? (
                        <>
                          <th scope="col" width="10"></th>
                          <th scope="col" width="10"></th>
                          <th scope="col" width="10">Date</th>
                          <th scope="col" width="10">Employee</th>
                          <th scope="col" width="10">Description</th>
                          <th scope="col" width="10">Category</th>
                          <th scope="col" width="10">Job Name</th>
                          <th scope="col" width="10">Vendor</th>
                          <th scope="col" width="10">Payment Method</th>
                          <th scope="col" width="10">Total</th>
                        </>
                      ) : (
                        <th>Details</th>
                      )}
                    </tr>
                  </thead>

                  {allExpenses.length === 0 ? (
                    <tbody>
                      <tr>
                        <td>No expenses.</td>
                      </tr>
                    </tbody>
                  ) :

                    <tbody>
                      {displayedExpense.map((e, i) => {
                        let { user } = e;
                        if (user) {
                          user.name = user.name ? user.name : '';
                        } else {
                          user = { name: '' };
                        }

                        const currentPayment = paymentAccount.list && paymentAccount.list.find(item => e.paymentAccountId && e.paymentAccountId ===  item._id);
                  

                        return (
                          <tr key={i} className={e.status && e.status === 'reconciled' ? "details-row " + trReconcileBg : "details-row"}>
                            {!this.state.isMobileVersion ? (
                              <>
                                <td>
                                  <ActionButton
                                    item={e}
                                    userInEstimate={userInEstimate}
                                    props={this.props}
                                  ></ActionButton>
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <Input
                                      style={{width: '25px', height: '25px'}}
                                      type="checkbox"
                                      value={e._id}
                                      className="btn"
                                      checked={e.status === 'reconciled' ? true : false}
                                      onChange={event => {
                                        this.changeCheckBox(
                                          e._id,
                                          event.target.checked
                                        );

                                        e.status === 'reconciled' ? e.status = 'unreconciled' : e.status = 'reconciled';
                                      }

                                      }
                                    />
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  <Moment
                                    add={{ days: 1 }}
                                    date={new Date(e.date)}
                                    format={'MMM D, YY'}
                                  />
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  {user.name}
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  <div className="divTable">
                                    {e.description}
                                  </div>
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  {e.category}
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  <div className="divTable">
                                    {e.job ? e.job.jobName : ''}
                                    {e.job ? `(${e.job.status})` : ''}
                                  </div>
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  {e.vendor}
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  {currentPayment && currentPayment.name}
                                </td>
                                <td onClick={() => this.openModal(e)}>
                                  ${' '}
                                  {parseFloat(
                                    Math.round(e.total * 100) / 100
                                  ).toFixed(2)}
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <Moment
                                    add={{ days: 1 }}
                                    date={new Date(e.date)}
                                    format={'MMM D, YY'}
                                  />
                                  <br />
                                  {user.name}
                                  <br />
                                  {e.description}
                                  <br />
                                  {e.category}
                                  <br />
                                  <div className="divTable">
                                    {e.job ? e.job.jobName : ''} (
                                    {e.job ? e.job.status : ''})
                                  </div>
                                  <br />${' '}
                                  {parseFloat(
                                    Math.round(e.total * 100) / 100
                                  ).toFixed(2)}
                                  <div className="buttonfloat-right buttonfloat-right-estimates">
                                    <ActionButton
                                      item={e}
                                      userInEstimate={userInEstimate}
                                      props={this.props}
                                    ></ActionButton>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  }

                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size={'lg'}>
          <ModalHeader toggle={this.toggleModal}> Expense details</ModalHeader>
          <ModalBody>
            Employee: <b>{this.state.expense && this.state.expense.user && this.state.expense.user.name}</b>
            <br />
            Expense date:{' '}
            <b>
              <Moment
                add={{ days: 1 }}
                date={new Date(this.state.expense.date)}
                format={'MMM D, YY'}
              />
            </b>
            <br />
            Vendor: <b>{this.state.expense.vendor}</b>
            <br />
            Job: <b>{this.state.expense.job && this.state.expense.job.jobName}</b>
            <br />
            Category: <b>{this.state.expense.category}</b>
            <br />
            Description: <b>{this.state.expense.description}</b>
            <br />
            Total: <b>${this.state.expense.total}</b>
            <br />
            {this.state.expense.image ? (
              <PreviewFile previewImages={[{ viewingFrom: this.state.expense.viewingFrom || "url", file: this.state.expense.image }]} />
            ) : <p><br />Empty documents.</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  expenses: state.expense.expenses,
  expenseCount: state.expense.count,
  users: state.user.users,
  jobs: state.job.jobs,
  paymentAccount: state.paymentAccount,
  userLogged: state.auth.userLogged
});

export default connect(mapStateToProps, { getExpenses, getExpenseCount, removeExpense, reset, getJobs, getPaymentAccounts, sendReconciled })(
  Expenses
);
