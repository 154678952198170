import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import routes from 'routes.js';
import routesWorker from 'routesWorker';
import { connect } from 'react-redux';
import LoadBar from '../components/shared/loadBar';

class Admin extends React.Component {
  componentDidMount() {
    if (!this.props.userLogged) return this.props.history.push('/auth/login');
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = routes =>
    routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/estimates') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/reports') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/jobs') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/time') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/invoices') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/workers') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/expenses') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/clients') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/items') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      return null;
    });

  getRoutesW = routesWorker =>
    routesWorker.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/estimates') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/jobs') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/time') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/invoices') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/workers') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/expenses') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/clients') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.layout === '/admin/items') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      return null;
    });

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  getBrandTextW = path => {
    for (let i = 0; i < routesWorker.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routesWorker[i].layout + routesWorker[i].path
        ) !== -1
      ) {
        return routesWorker[i].name;
      }
    }
    return 'Brand';
  };

  render() {
    return (
      <>
        <LoadBar />
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/admin/index',
            imgSrc: require('assets/img/brand/transparent.png'),
            imgAlt: '...',
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={
             
                this.getBrandText(this.props.location.pathname)
                
            }
          />
          <Switch>
            {
              this.getRoutes(routes)
            }
          </Switch>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, null)(Admin);
