import React from 'react';

import './estimatePreviewStyles.css';

const EstimatePreview = props => {
    const {
        estimateNo,
        name,
        currentAddress,
        currentCityZipCountry,
        phone,
        comments,
        total,
        items = [],
    } = props;

    const dataTableList = items.map((est, index) => {
       ///// const isOdd = (index % 2) === 1;

       let rate = est.rate ? est.rate : 0;
       let estAmount = est.rate * est.quantity;

        return <tr key={index} style={{
            textAlign: 'left',
            border: '1px solid #e9ecef',
            color: '#8898aa'

        }} >
            <td style={{ padding: 5 }} >
                <div style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    paddingLeft: 5
                }}>{est.itemName}</div>
                <div
                    style={{
                        fontSize: 13,
                        paddingLeft: 5
                    }}
                >{est.description}</div>
            </td>
            <td style={{ paddingLeft: 5, fontSize: 15, textAlign: 'center' }}>{est.quantity}</td>
            <td style={{ paddingLeft: 5, fontSize: 15, textAlign: 'center' }}>{rate}</td>
            <td style={{ paddingLeft: 5, fontSize: 15, textAlign: 'center' }}>---</td>
            <td style={{ paddingLeft: 5, fontSize: 15, textAlign: 'center' }}>${estAmount}</td>
        </tr>
        // return <div className={`table-row ${isOdd ? 'table-row-odd' : ''}`} style={{ width: '100%' }}>
        //     <div classname="table-col-1">
        //         <p style={{ marginBottom: 2, color: "#000000 !important" }}>
        //             <span style={{ fontWeight: 700 }}>
        //                 {
        //                     est.itemName
        //                 }
        //             </span>
        //         </p>
        //         <p
        //             style={{
        //                 margin: "0 0 0 2px",
        //                 // lineHeight: 15,
        //                 color: "#000000 !important",
        //                 width: 150,
        //                 wordWrap: "break-word"
        //             }}
        //         >
        //             <span>
        //                 {
        //                     est.itemDescription
        //                 }
        //             </span>
        //         </p>
        //     </div>
        //     <div className="table-col-2">
        //         <p style={{ marginBottom: 2, color: "#000000 !important" }}>
        //             Qty:{" "}
        //             <span>
        //                 {
        //                     est.quantity
        //                 }
        //             </span>
        //         </p>
        //         <p style={{ margin: "0 0 2px 0", color: "#000000 !important" }}>
        //             Rate:{" "}
        //             <span>
        //                 {est.rate}
        //             </span>
        //         </p>
        //         <p style={{ margin: "0 0 2px 0", color: "#000000 !important" }}>
        //             Tax: <span>---</span>
        //         </p>
        //     </div>
        //     <div className="table-col-3">
        //         <p style={{ color: "#00a863 !important" }}>
        //             ${est.subtotal}
        //         </p>
        //     </div>
        // </div>

    })
    return <center>
        <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            height="100%"
            width="100%"
            id="bodyTable"
        >
            <tbody>
                <tr>
                    <td align="center" valign="top" id="bodyCell">
                        <div className="main">
                            <div
                                className="header"
                                style={{
                                    width: "100%",
                                    // borderBottom: "1px solid black",
                                    paddingBottom: 5
                                }}
                            >
                                <div style={{ paddingBottom: 12, borderBottom: '1px solid rgba(100,121,143,0.2)' }}>
                                    <img
                                        alt='headerImage'
                                        className="-header-img"
                                        src="https://res.cloudinary.com/du4edhdwn/image/upload/v1665040405/ImagesGA/wgvrktdomu1cxhy0dvom.png"
                                    />
                                    <span
                                        style={{ textAlign: "right", float: 'right' }}
                                    >
                                        {
                                            estimateNo ?
                                                <span className="-header-name" style={{ fontSize: "1.1em" }}>
                                                    Estimate # {estimateNo}
                                                </span>
                                                : ''
                                        }

                                    </span>
                                </div>
                                <div style={{ borderBottom: '1px solid rgba(100,121,143,0.2)', minHeight: 100 }} className="-top-header">
                                    {/* <br /> */}
                                    <div style={{ width: '40%', float: 'left' }}>
                                        <div style={{ color: "black" }}>
                                            CLIENT NAME
                                        </div>
                                        <div className="-header-name" style={{ color: "black" }}>
                                            {name}
                                        </div>
                                    </div>

                                    <div style={{ width: '40%', float: 'right' }}>
                                        <div style={{ color: "black" }}>
                                            JOB ADDRESS
                                        </div>
                                        <div
                                            className="-header-name"
                                            style={{ color: "black" }}
                                        >
                                            {currentAddress}
                                        </div>
                                    </div>
                                    <div style={{ width: '40%', float: 'left', marginTop: 24 }}>
                                        <div style={{ color: "black" }}>
                                            JOB CITY, STATE, ZIP
                                        </div>
                                        <div
                                            className="-header-name"
                                            style={{ color: "black" }}
                                        >
                                            {currentCityZipCountry}
                                        </div>
                                    </div>

                                    <div style={{ width: '40%', float: 'right', marginTop: 24 }}>
                                        <div style={{ color: "black" }}>
                                            CLIENT PHONE
                                        </div>
                                        <div
                                            className="-header-name"
                                            style={{ color: "black" }}
                                        >
                                            {phone}
                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="secondary">
                                <div>
                                    <p
                                        className="title1"
                                        style={{ textAlign: "center", color: '#00a863' }}
                                    >
                                        {comments}
                                    </p>
                                </div>
                                <div>
                                    <p style={{ textAlign: "left", color: '#00a863' }} className="title1">
                                        Item Description
                                    </p>

                                    <table
                                        style={{
                                            width: '100%',
                                            border: '1px solid #e9ecef'
                                        }}
                                    >
                                        <thead style={{
                                            backgroundColor: '#f6f9fc',
                                            padding: 12
                                        }}>
                                            <tr style={{
                                                textAlign: 'left',
                                                color: '#8898aa'
                                            }} >
                                                <th style={{
                                                    padding: 12,
                                                    width: '40%'
                                                }}>ITEM</th>
                                                <th style={{
                                                    padding: 12,
                                                    width: '15%'
                                                }}>QTY</th>
                                                <th style={{
                                                    padding: 12,
                                                    width: '15%'
                                                }}>RATE</th>
                                                <th style={{
                                                    padding: 12,
                                                    width: '15%'
                                                }}>TAX</th>
                                                <th style={{
                                                    padding: 12,
                                                    width: '15%'
                                                }}>PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr style={{
                                                textAlign: 'left',
                                                border: '1px solid #e9ecef',
                                                color: '#8898aa'

                                            }} >
                                                <td style={{ padding: 5 }} >
                                                    <div style="font-weight:bold; font-size:15px;padding-left: 5px;">${est.itemName}</div>
                                                    <div style="font-size:13px;padding-left: 5px;">${est.description}</div>
                                                </td>
                                                <td style={{ paddingLeft: 5, fontSize: 15 }}>${est.quantity}</td>
                                                <td style={{ paddingLeft: 5, fontSize: 15 }}>${rate}</td>
                                                <td style={{ paddingLeft: 5, fontSize: 15 }}>---</td>
                                                <td style={{ paddingLeft: 5, fontSize: 15 }}>${amount}</td>
                                            </tr> */}
                                            {dataTableList}
                                        </tbody>
                                    </table>
                                    {/* <div className="order-list">
                                        {dataTableList}
                                    </div> */}
                                </div>
                                <div className="-total" style={{ textAlign: 'right' }}>
                                    {/* <span>TOTAL ITEM{items.length > 1 ? 'S' : ''}: {items.length}</span> */}
                                    <span>
                                        TOTAL:{" "}
                                        <span className="-total-amount">
                                            ${total}
                                        </span>
                                    </span>
                                </div>
                                <br />
                                <br />
                                {/* <div>
                                    <a
                                        className="-approved-estimate"
                                        href="${
                process.env.FRONTENDPOINT
              }/convertjobfromemail/${estimateId}"
                                    >
                                        Approve Estimate
                                    </a>
                                    <div className="-approve-msg">
                                        <p>Please click the button above to approve the estimate</p>
                                        <p>and we will be in touch with you shortly.</p>
                                    </div>
                                </div> */}
                                <br />
                                <br />
                                <div>
                                    <img
                                        style={{ marginLeft: 20, width: 40 }}
                                        src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_2_snkfws.png"
                                        alt="elite"
                                    />
                                    <img
                                        style={{ marginLeft: 20, height: 30 }}
                                        src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_3_gveepq.png"
                                        alt="houzz"
                                    />
                                    <img
                                        style={{ marginLeft: 20, height: 30 }}
                                        src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_4_zkdowq.png"
                                        alt="accredited"
                                    />
                                    <img
                                        style={{ marginLeft: 20, width: 40 }}
                                        src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_5_uapttm.png"
                                        alt="accredited"
                                    />
                                </div>
                                <br />
                                <br />
                                <hr />
                                <br />
                                <div style={{ width: "100%", height: 120 }}>
                                    <div className="-company-details">
                                        <p style={{ color: "#00A863" }}>Green Acorn</p>
                                        <p>11504 Black Horse Run,</p>
                                        <p>Raleigh, NC 27613 (919) 600-0199</p>
                                    </div>
                                    <div className="footer-details">
                                        <a href="https://www.facebook.com/GreenAcornContracting/">
                                            <img
                                                style={{ marginLeft: 20 }}
                                                src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_6_tdwknw.png"
                                                alt="elite"
                                            />
                                        </a>
                                        <a href="https://www.instagram.com/greenacorncontracting/">
                                            <img
                                                style={{ marginLeft: 20 }}
                                                src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_7_oozhoi.png"
                                                alt="houzz"
                                            />
                                        </a>
                                        <a href="https://twitter.com/GreenAcornC/">
                                            <img
                                                style={{ marginLeft: 20 }}
                                                src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_8_t6zdai.png"
                                                alt="accredited"
                                            />
                                        </a>
                                        <a href="https://www.pinterest.com.mx/greenacorncontracting/">
                                            <img
                                                style={{ marginLeft: 20 }}
                                                src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297018/ImagesGA/assets/Imagen_9_o7ixka.png"
                                                alt="accredited"
                                            />
                                        </a>
                                        <div
                                            style={{
                                                textAlign: "right",
                                                color: "#000000",
                                                lineHeight: "0.8em"
                                            }}
                                        >
                                            <p>
                                                <a
                                                    style={{ color: "#3200f9" }}
                                                    href="https://www.greenacorn.com/privacy-policy"
                                                >
                                                    Privacy Policy
                                                </a>{" "}
                                                |{" "}
                                                <a
                                                    style={{ color: "#3200f9" }}
                                                    href="https://www.greenacorn.com/terms"
                                                >
                                                    Terms of Use
                                                </a>{" "}
                                            </p>
                                            <p>©2020 Green Acorn</p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </center>

};




export {
    // estimatePreviewStrings,
    EstimatePreview
};