export const FETCH_SEARCH_CHANGE = 'FETCH_SEARCH_CHANGE';
export const FETCH_SEARCH_RESET = 'FETCH_SEARCH_REST';

export const change = data => async dispatch => {
  dispatch({ type: FETCH_SEARCH_CHANGE, payload: data });
};

export const reset = () => async dispatch => {
  dispatch({ type: FETCH_SEARCH_RESET, payload: '' });
};
