/* eslint-disable prettier/prettier */
import moment from 'moment';

export const prepareReportDateFilters = filter => {
  let startDate;
  let endDate;
  let rangeDateLbl;

  switch (filter) {
    case 'last_week':
      startDate = moment()
        .day(-7)
        .day('Monday')
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      endDate = moment()
        .day('Sunday')
        .add(+1, 'days')
        .format('YYYY-MM-DD');
      rangeDateLbl = `Last Week (${moment()
        .day(-7)
        .day('Monday')
        .format('YYYY-MMM-DD')} to ${moment()
        .day('Sunday')
        .format('YYYY-MMM-DD')})`;
      break;
    case 'past_week':
      startDate = moment().add(-7, 'days').add(-1, 'days').format('YYYY-MM-DD');
      endDate = moment()
        .add(+1, 'days')
        .format('YYYY-MM-DD');
      rangeDateLbl = `Past Week (${moment()
        .add(-7, 'days')
        .format('YYYY-MMM-DD')} to ${moment().format('YYYY-MMM-DD')})`;
      break;
    case 'this_week':
      startDate = moment().startOf('week').format('YYYY-MM-DD');
      endDate = moment().endOf('week').format('YYYY-MM-DD');
      rangeDateLbl = `This Week (${moment()
        .startOf('week')
        .format('YYYY-MMM-DD')} to ${moment()
        .endOf('week')
        .format('YYYY-MMM-DD')})`;
      break;
    case 'last_month':
      startDate = moment()
        .startOf('month')
        .day(-1)
        .startOf('month')
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      endDate = moment()
        .startOf('month')
        .day(-1)
        .endOf('month')
        .add(+1, 'days')
        .format('YYYY-MM-DD');
      rangeDateLbl = `Last Month (${moment()
        .startOf('month')
        .day(-1)
        .startOf('month')
        .format('YYYY-MMM-DD')} to ${moment()
        .startOf('month')
        .day(-1)
        .endOf('month')
        .format('YYYY-MMM-DD')})`;
      break;
    case 'past_month':
      startDate = moment()
        .add(-30, 'days')
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      endDate = moment()
        .add(+1, 'days')
        .format('YYYY-MM-DD');
      rangeDateLbl = `Past Month (${moment()
        .add(-30, 'days')
        .format('YYYY-MMM-DD')} to ${moment().format('YYYY-MMM-DD')})`;
      break;
    case 'this_month':
      startDate = moment().startOf('month').format('YYYY-MM-DD');
      endDate = moment().endOf('month').format('YYYY-MM-DD');
      rangeDateLbl = `This Month (${moment()
        .startOf('month')
        .format('YYYY-MMM-DD')} to ${moment()
        .endOf('month')
        .format('YYYY-MMM-DD')})`;
      break;
    case 'last_year':
      startDate = moment()
        .startOf('year')
        .add(-1, 'days')
        .startOf('year')
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      endDate = moment()
        .startOf('year')
        .add(+1, 'days')
        .endOf('year')
        .add(+1, 'days')
        .format('YYYY-MM-DD');
      rangeDateLbl = `Last Year (${moment()
        .startOf('year')
        .day(-1)
        .startOf('year')
        .format('YYYY-MMM-DD')} to ${moment()
        .startOf('year')
        .day(-1)
        .endOf('year')
        .format('YYYY-MMM-DD')})`;
      break;
    case 'past_year':
      startDate = moment()
        .add(-365, 'days')
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      endDate = moment()
        .add(+1, 'days')
        .format('YYYY-MM-DD');
      rangeDateLbl = `Past Year (${moment()
        .add(-365, 'days')
        .format('YYYY-MMM-DD')} to ${moment().format('YYYY-MMM-DD')})`;
      break;
    case 'this_year':
      startDate = moment().startOf('year').format('YYYY-MM-DD');
      endDate = moment().endOf('year').format('YYYY-MM-DD');
      rangeDateLbl = `This Year (${moment()
        .startOf('year')
        .format('YYYY-MMM-DD')} to ${moment()
        .endOf('year')
        .format('YYYY-MMM-DD')})`;
      break;
    default:
      break;
  }
  return { startDate, endDate, rangeDateLbl };
};
