import Global from '../global';
import toastr from 'toastr';

export const uploadPhoto = async e => {
  if (e.target && e.target.files && e.target.files.length > 0) {
    const form = new FormData();
    form.append('photo', e.target.files[0]);
    return fetch(`${Global.url}v2/upload`, {
      method: 'POST',
      body: form
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.err) {
          toastr.error(data.err);
          return 'notNet.png';
        }
        toastr.success('Upload complete.');
        return data.img;
      })
  }
};


export async function urltoFile(files, mimeType = 'image/png') {
  const currentFile = await fetch(files)
  const blob = await currentFile.blob();
  const updatedFile = new File([blob], 'file', { type: mimeType });
  return updatedFile;
};

export function getMimeType(base64) {
  return base64.substring(base64.indexOf(":")+1, base64.indexOf(";"))
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
