import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { updateTime } from '../../redux/actions/timeAction';
import { getUsers } from '../../redux/actions/userAction';
import { getJobs } from '../../redux/actions/jobAction';
import { compareValues } from '../../global';

const fecha = new Date();
let mes = fecha.getMonth() + 1;
let dia = fecha.getDate();
const ano = fecha.getFullYear();
if (dia < 10) dia = `0${dia}`; // agrega cero si es menor de 10
if (mes < 10) mes = `0${mes}`; // agrega cero si es menor de 10

class UpdateTime extends React.Component {
  state = {
    jobsFilter: [],
    workers: [],
    jobName: '',
    nameWorker: '',
    date: `${ano}-${mes}-${dia}`,
    spinner: false,
    submitting: false
  };


  async componentDidMount() {

    const loggedUser = this.props.userLogged;
    if(!this.props.users.length) {
      await this.props.getUsers();
    }
   
    if(!this.props.jobs.length) {
      await this.props.getJobs();
    }

    const time = this.props.times.filter(
      item => item._id === this.props.match.params.id
    )[0];
    const job = this.props.jobs.filter(item => item._id === time.jobId)[0];
    let user = this.props.users.filter(item => item._id === time.userId)[0];


    const users = [];


    if (job) {
      job.workers.forEach(worker => {
        if (
          worker.workerId &&
          worker.workerId === loggedUser && loggedUser._id
        ) {
          user = this.props.users.filter(
            item => item._id === worker.workerId
          )[0];
          users.push(user);
        } else {
          users.push(
            this.props.users.filter(item => item._id === worker.workerId)[0]
          );
        }
      });

      if (user !== null) {
        this.setState(prevState => ({
          ...prevState,
          workers: users,
          userId: user._id,
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          workers: users,
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        workers: [],
        userId: null,
      }));
    }


    this.setState(prevState => ({
      ...prevState,
      date: time.date.substring(0, 10),
      hours: time.hours,
      jobName: job.jobName,
      jobId: job._id,
      workerId: user._id,
      nameWorker: user.name,
      loggedUser: this.props.userLogged,
      jobsFilter: this.props.jobs
        .filter(item => item.status === 'Approve' && item.workers.length > 0)
        .sort(compareValues('jobName', 'asc')),
    }));


  }

  async componentWillUnmount() {
    await this.props.getJobs();
    
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };

  }

  handleInput = e => {
    e.persist();
    
    if(e.target.name === 'workerId') {
      this.setState(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
        userId: e.target.value,
      }));
    }
    else if (e.target.name === 'jobId') {
      const item = this.props.jobs.filter(
        item => item._id === e.target.value
      )[0];
      let user = null;
      const users = [];

      if (item) {
        item.workers.forEach(worker => {
          if (
            worker.workerId &&
            worker.workerId === this.state.loggedUser._id
          ) {
            user = this.props.users.filter(
              item => item._id === worker.workerId
            )[0];
            users.push(user);
          } else {
            users.push(
              this.props.users.filter(item => item._id === worker.workerId)[0]
            );
          }
        });

        if (user !== null) {
          this.setState(prevState => ({
            ...prevState,
            workers: users,
            userId: user._id,
          }));
        } else {
          this.setState(prevState => ({
            ...prevState,
            workers: users,
          }));
        }
      } else {
        this.setState(prevState => ({
          ...prevState,
          workers: [],
          userId: null,
        }));
      }

      this.setState(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value
      }));
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
  
    }
  };









  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true })
    this.setState(prevState => ({
      spinner: true,
    }));

    await this.props.updateTime(this.props.match.params.id, this.state);
    this.props.history.push(`/admin/time`);
    this.setState({ submitting: false });
  };

  render() {

    const { submitting } = this.state;
    // jobName
    // const workerName = this.state.nameWorker;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="8">
                          {/* <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-hours"
                            >
                              Job Name
                            </label>
                            <Input
                              value={jobName}
                              name="jobName"
                              className="form-control-alternative lg"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-hours"
                            >
                              Worker Name
                            </label>
                            <Input
                              value={workerName}
                              name="workerName"
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                            />
                          </FormGroup> */}
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                            >
                              Job *
                            </label>
                            <Input
                              required
                              name="jobId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              value={this.state.jobId}
                            >
                              <option>Select Job to Add Time</option>
                              {this.state.jobsFilter.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.jobName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"

                            >
                              Worker *
                            </label>
                            <Input
                              required
                              name="workerId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              value={this.state.workerId}
                            >
                              <option>Select worker</option>
                              {this.state.workers.map((e, i) => {
                                if (!e || !e._id)
                                  return <option>Worker Delete</option>;
                                return this.state.loggedUser && this.state.loggedUser._id === e._id ? (
                                  <option selected key={i} value={`${e._id}`}>
                                    {e.name}
                                  </option>
                                ) : (
                                  <option key={i} value={`${e._id}`}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Date
                            </label>
                            <Input
                              required
                              id="date"
                              className="form-control-alternative"
                              placeholder="Select a date"
                              name="date"
                              value={this.state.date}
                              type="date"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-hours"
                            >
                              Hours
                            </label>
                            <Input
                              name="hours"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              value={this.state.hours}
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Update Hours
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  users: state.user.users,
  times: state.time.times,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, { updateTime, getUsers, getJobs })(withRouter(UpdateTime));
