import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Button,
} from 'reactstrap';


import Header from '../../../components/Headers/Header';


const PropertySettings = props => {
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Actions</h3>
                                    </div>

                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg="6" className="mb-5">
                                        <Button
                                            style={{ width: '50%' }}
                                            className="form-control-alternative"
                                            color="info"
                                            onClick={() => {
                                                props.history.push(`/admin/propertysettings/stages`)
                                            }}
                                        >
                                            Stage
                                        </Button>
                                        <br />
                                        <br />
                                        <br />
                                        <Button
                                            style={{ width: '50%' }}
                                            className="form-control-alternative"
                                            color="info"
                                            onClick={() => {
                                                props.history.push(`/admin/propertysettings/types`)
                                            }}
                                        >
                                            Property Info
                                        </Button>
                                    </Col>
                                    <br />
                                    <br />
                                    <Col lg="6">
                                        <Button
                                            style={{ width: '50%' }}
                                            className="form-control-alternative"
                                            color="info"
                                            onClick={() => {
                                                props.history.push(`/admin/propertysettings/lenders`)
                                            }}
                                        >
                                            Lenders
                                        </Button>
                                        <br />
                                        <br />
                                        <br />

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


};

export default PropertySettings;