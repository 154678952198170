import React from 'react';
import {
  Button,
  Card,
  Table,
  UncontrolledCollapse,
  Badge,
  Row,
  Col,
  Input,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import Moment from 'react-moment';
import moment from 'moment';
import { compareValues } from '../../global';
import { find, set } from 'lodash';
import LoadBar from '../../components/shared/loadBar';

const DropDownExpense = props => {
  let totalPayroll = 0;
  let totalEffective = 0;
  let totalHours = 0;
  props.e.jobs.forEach(item => {
    const workerDetails = props.workersFilter.find(itm => {
      return itm.jobs.find(job => job._id === item._id)
    });

    const currentTimeDate = moment(item.date).format('YYYY-MM-DD');

    const currentRate = workerDetails && workerDetails.rateHistory && workerDetails.rateHistory !== undefined && workerDetails.rateHistory.find(itm => {
      return moment(itm.date).diff(currentTimeDate, 'days') < 1;
    });

    let totalRate = {};

    if (currentRate) {
      totalRate = {
        payRate: currentRate.payRate,
        effectiveRate: currentRate.effectiveRate,
      }
    }
    else {
      
      totalRate = {
        payRate: item.payroll,
        effectiveRate: item.effective,
      }

    }


    totalPayroll += totalRate.payRate;
    totalEffective += totalRate.effectiveRate;
    totalHours += item.hours;
  });

  return (
    <UncontrolledCollapse key={props.id} toggler={`#toggle${props.i}`} defaultOpen={parseInt(localStorage.getItem('reportWorkerCollapse')) === props.i ? true : false}>
      {!props.isMobileVersion ? (
        <Card>
          <CardBody style={{ width: '88%' }}>
            <div className="div_reports">
              <h3>- Labor</h3>
              <Table
                className="align-items-center table-flush col-md-6 col-xs-12"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th>Job Date</th>
                    <th>Job</th>
                    <th>Payroll Expense</th>
                    <th>Labor Expense (Effective Rate)</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {props.e.jobs
                    .sort(compareValues('date', 'desc'))
                    .map((wx, i) => {

                      const workerDetails = props.workersFilter.find(itm => {
                        return itm.jobs.find(job => job._id === wx._id)

                      });
           
                      const currentTimeDate = moment(wx.date).format('YYYY-MM-DD');
                      const currentRate = workerDetails &&  workerDetails.rateHistory &&  workerDetails.rateHistory !== undefined && workerDetails.rateHistory.find(itm => {
                        return moment(itm.date).diff(currentTimeDate, 'days') < 1;
                      });
                      let totalRate = {};

                      if (currentRate) {
                        totalRate = {
                          payRate:  (currentRate.payRate),
                          effectiveRate:  (currentRate.effectiveRate),
                        }
                      }
                      else {
                        totalRate = {
                          payRate:  (wx.payroll),
                          effectiveRate: (wx.effective ),
                        }
                      }

                     

                      // let totalRate = workerDetails && workerDetails.rateHistory && currentTimes ? currentTimes.reduce(
                      //   (acc, current, i) => {
                      //     const currentTimeDate = moment(current.date).format('YYYY-MM-DD');
                      //     const currentRate = workerDetails.rateHistory.find(itm => {
                      //       return moment(itm.date).diff(currentTimeDate, 'days') < 1;
                      //     })

                      //     if (currentRate) {
                      //       return {
                      //         ...acc,
                      //         payRate: acc.payRate + (currentRate.payRate * current.hours),
                      //         effectiveRate: acc.effectiveRate + (currentRate.effectiveRate * current.hours),
                      //       }
                      //     }


                      //     return {
                      //       ...acc,
                      //       payRate: acc.payRate + (workerDetails.payRate * current.hours),
                      //       effectiveRate: acc.effectiveRate + (workerDetails.effectiveRate * current.hours),
                      //     }
                      //   },
                      //   {
                      //     payRate: 0,
                      //     effectiveRate: 0
                      //   }
                      // ) : null;

  
                      return <React.Fragment key={i}>
                        <tr>
                          <td>
                            <Moment add={{ days: 1 }} format={'MMM D, YY'}>
                              {wx.date}
                            </Moment>
                          </td>
                          <td>{wx.jobName}</td>
                          <td align="right">
                            ${' '}
                            {totalRate.payRate.toFixed(2)}
                            {/* {isNaN(
                              parseFloat(
                                Math.round(wx.payroll * 100) / 100
                              ).toFixed(2)
                            )
                              ? 0
                              : parseFloat(
                                Math.round(wx.payroll * 100) / 100
                              ).toFixed(2)}{' '} */}
                          </td>
                          <td align="right">
                            ${' '}
                            {totalRate.effectiveRate.toFixed(2)}
                            {/* {isNaN(
                              parseFloat(
                                Math.round(wx.effective * 100) / 100
                              ).toFixed(2)
                            )
                              ? 0
                              : parseFloat(
                                Math.round(wx.effective * 100) / 100
                              ).toFixed(2)}{' '} */}
                          </td>
                          <td align="right">
                            {isNaN(
                              parseFloat(
                                Math.round(wx.hours * 100) / 100
                              ).toFixed(2)
                            )
                              ? 0
                              : parseFloat(
                                Math.round(wx.hours * 100) / 100
                              ).toFixed(2)}{' '}
                          </td>
                        </tr>
                      </React.Fragment>
                    })}
                  <tr>
                    <td></td>
                    <td align="right">Total:</td>
                    <td align="right">
                      ${' '}
                      {isNaN(
                        parseFloat(
                          Math.round(totalPayroll * 100) / 100
                        ).toFixed(2)
                      )
                        ? 0
                        : parseFloat(
                          Math.round(totalPayroll * 100) / 100
                        ).toFixed(2)}
                    </td>
                    <td align="right">
                      ${' '}
                      {isNaN(
                        parseFloat(
                          Math.round(totalEffective * 100) / 100
                        ).toFixed(2)
                      )
                        ? 0
                        : parseFloat(
                          Math.round(totalEffective * 100) / 100
                        ).toFixed(2)}
                    </td>
                    <td align="right">
                      {isNaN(totalHours) ? 0.0 : totalHours.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div className="d-flex col-md-8 col-xs-12 justify-content-between align-items-end">
                <h3>- Expenses</h3>
              </div>
              <Table
                className="align-items-center table-flush col-md-8 col-xs-12"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col" colSpan="2"></th>
                    <th scope="col">Date</th>
                    <th scope="col">Expense Type</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Account</th>
                    <th scope="col">Vendor</th>
                    <th scope="col">Job</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {!props.e.expenses ? (
                    <tr>
                      <td colSpan={7}></td>
                    </tr>
                  ) : (
                    props.e.expenses
                      .sort(compareValues('date', 'desc'))
                      .map((ex, i) => {
                        const currentPaymentAccount = props.paymentAccount.find(item => ex.paymentAccountId && (ex.paymentAccountId === item._id))
                        return (
                          <tr
                            key={i}
                            className={
                              ex.status !== 'reconciled' ? '' : 'tr-reconcile-bg'
                            }
                          >
                            <td>
                              <Button onClick={props.handleModal(ex.image)}>
                                <i className="fas fa-receipt"></i> View
                              </Button>
                              <Button
                                color="primary"
                                onClick={props.handleModalEdit(ex._id)}
                              >
                                <i className="fas fa-edit"></i> Update
                              </Button>
                            </td>
                            <td>
                              <Input
                                style={{width: '25px', height: '25px'}}
                                type="checkbox"
                                value={ex._id}
                                className="btn"
                                checked={ex.status === 'reconciled' ? true : false}
                                onChange={event => {
                                    props.changeCheckBox(
                                      props.e._id,
                                      ex._id,
                                      event.target.checked
                                    );
                                    ex.status === 'reconciled' ? ex.status = 'unreconciled' : ex.status = 'reconciled';
                                  }
                                }
                              />
                            </td>
                            <td>
                              <Moment add={{ days: 1 }} format={'MMM D, YY'}>
                                {ex.date}
                              </Moment>
                            </td>
                            <td>{ex.category}</td>
                            <td align="right">$ {ex.total}</td>
                            <td>{currentPaymentAccount && currentPaymentAccount.name}</td>
                            <td>{ex.vendor}</td>
                            <td>{ex.jobName}</td>
                            <td>{ex.description}</td>
                          </tr>
                        )
                      })
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      ) : (
        <>
          <Table className="align-items-center table-flush table-striped col-xs-12">
            <thead className="thead-light">
              <tr>
                <th scope="col" align="center">
                  <b>Labors</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.e.jobs.sort(compareValues('date', 'desc')).map((wx, i) => (
                <tr key={i}>
                  <td className="tdMobile">
                    <Moment add={{ days: 1 }} format={'MMM D, YY'}>
                      {wx.date}
                    </Moment>
                    <br />
                    {wx.jobName}
                    <hr />
                    <Row>
                      <Col>
                        <small>Payroll Expense: </small>
                        <br />
                        <b>
                          ${' '}
                          {isNaN(
                            parseFloat(
                              Math.round(wx.payroll * 100) / 100
                            ).toFixed(2)
                          )
                            ? 0
                            : parseFloat(
                              Math.round(wx.payroll * 100) / 100
                            ).toFixed(2)}{' '}
                        </b>
                      </Col>
                      <Col>
                        <small>Effective Rate: </small>
                        <br />
                        <b>
                          ${' '}
                          {isNaN(
                            parseFloat(
                              Math.round(wx.effective * 100) / 100
                            ).toFixed(2)
                          )
                            ? 0
                            : parseFloat(
                              Math.round(wx.effective * 100) / 100
                            ).toFixed(2)}{' '}
                        </b>
                      </Col>
                      <Col>
                        <small>Hours: </small>
                        <br />
                        <b>
                          {isNaN(
                            parseFloat(
                              Math.round(wx.hours * 100) / 100
                            ).toFixed(2)
                          )
                            ? 0
                            : parseFloat(
                              Math.round(wx.hours * 100) / 100
                            ).toFixed(2)}
                        </b>
                      </Col>
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Table className="align-items-center table-flush table-striped col-xs-12">
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  <b>Expense</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {!props.e.expenses ? (
                <tr>
                  <td></td>
                </tr>
              ) : (
                props.e.expenses
                  .sort(compareValues('date', 'desc'))
                  .map((ex, i) => (
                    <tr key={i}>
                      <td>
                        <Moment add={{ days: 1 }} format={'MMM D, YY'}>
                          {ex.date}
                        </Moment>
                        &nbsp;
                        <b>({ex.category})</b>
                        <br />${' '}
                        {parseFloat(Math.round(ex.total * 100) / 100).toFixed(
                          2
                        )}
                        <br />
                        {ex.vendor}
                        <br />
                        {ex.jobName}
                        <br />
                        {ex.description}
                        <br />
                        <div className="buttonfloat-right buttonfloat-right-times">
                          <Button onClick={props.handleModal(ex.image)}>
                            <i className="fas fa-receipt"></i> View
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </Table>
        </>
      )}
    </UncontrolledCollapse>
  );
};

class ReportWorkers extends React.Component {
  handleModal = url => e => {
    this.props.openModal(url);
  };

  handleModalEdit = expenseId => e => {
    this.props.openModalEdit(expenseId);
  };

  handleModalReconciled = e => {
    this.props.sendReconciled();
  };

  componentWillUnmount() {
    localStorage.removeItem('reportWorkerCollapse');
  }

  /**
   * Gets called when user clicks checkbox on expense row.
   * 
   * @param {string} expenseId 
   * @param {boolean} checked 
   */
  changeCheckBox = (workerId, expenseId, checked) => {
    const { workersFilter, updateReportDataExpense } = this.props;
    if (checked) {
      // TODO: move spinner to own component
      document.getElementById('spinner').style.visibility = 'visible';
      // We reconcile this row.
      this.props.sendReconciled([expenseId], 'reconciled')
        .then((response) => {
          const worker = find(workersFilter, { '_id': workerId });
          if (worker) {
            const expense = find(worker.expenses, { '_id': expenseId });
            set(expense, 'status', 'reconciled');
            updateReportDataExpense(expense);
          }
          document.getElementById('spinner').style.visibility = 'hidden';
        })
        .catch(() => {
          document.getElementById('spinner').style.visibility = 'hidden';
        });
    } else {
      document.getElementById('spinner').style.visibility = 'visible';
      // We reconcile this row.
      this.props.sendReconciled([expenseId], 'unreconciled')
        .then((response) => {
          const worker = find(workersFilter, { '_id': workerId });
          if (worker) {
            const expense = find(worker.expenses, { '_id': expenseId });
            set(expense, 'status', 'unreconciled');
            updateReportDataExpense(expense);
          }
          document.getElementById('spinner').style.visibility = 'hidden';
        })
        .catch(() => {
          document.getElementById('spinner').style.visibility = 'hidden';
        });
    }
  };

  render() {
    const { workersFilter, paymentAccount, search } = this.props;

    let arrayWorkers = [...(workersFilter || [])];
    let reportWorkers = [];
    
    arrayWorkers.filter(function(item){
      let i = reportWorkers.findIndex(x => (x.name == item.name));
      if(i <= -1){
        reportWorkers.push(item);
      }
      return null;
    });

    if (search) {
      reportWorkers = reportWorkers.filter(
        worker =>
          JSON.stringify(worker).toLowerCase().indexOf(search.toLowerCase()) > 0
      );
    }

    if (!reportWorkers) return <LoadBar />;
    return (
      <div className="div_workers">
        <Table
          className="align-items-center table-flush table-striped"
          responsive
        >
          <thead className="thead-light">
            <tr>
              {!this.props.isMobileVersion ? (
                <>
                  <th style={{ width: '100px' }}></th>
                  <th>Worker</th>
                </>
              ) : (
                <>
                  <th>Worker</th>
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {reportWorkers.length === 0 ? (
              <tr>
                <td>No results.</td>
              </tr>
            ) : (
              reportWorkers.map((e, i) => (
                <React.Fragment key={i}>
                  {!this.props.isMobileVersion ? (
                    <React.Fragment key={i}>
                      <tr>
                        <td>
                          <Button id={`toggle${i}`} color="primary" onClick={(e) => {localStorage.setItem('reportWorkerCollapse', i)}}>
                            <i className="ni ni-bold-down"></i>
                          </Button>
                        </td>
                        <td>
                          {e.name} &nbsp; &nbsp;{' '}
                          <Badge style={{ fontSize: '12px' }} color="info">
                            {e.role}
                          </Badge>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={7}>
                          <DropDownExpense
                            i={i}
                            handleModalEdit={this.handleModalEdit}
                            changeCheckBox={this.changeCheckBox}
                            handleModalReconciled={this.handleModalReconciled}
                            handleModal={this.handleModal}
                            isMobileVersion={this.props.isMobileVersion}
                            e={e}
                            paymentAccount={paymentAccount}
                            workersFilter={workersFilter}
                          ></DropDownExpense>
                        </td>
                      </tr>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={i}>
                      <tr>
                        <td className="tdMobile">
                          <Button id={`toggle${i}`} color="primary" onClick={(e) => {localStorage.setItem('reportWorkerCollapse', i)}}>
                            <i className="ni ni-bold-down"></i>
                          </Button>
                          {e.name} &nbsp; &nbsp;{' '}
                          <Badge style={{ fontSize: '12px' }} color="info">
                            {e.role}
                          </Badge>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <DropDownExpense
                          i={i}
                          handleModalEdit={this.handleModalEdit}
                          changeCheckBox={this.changeCheckBox}
                          handleModalReconciled={this.handleModalReconciled}
                          handleModal={this.handleModal}
                          isMobileVersion={this.props.isMobileVersion}
                          e={e}
                          paymentAccount={paymentAccount}
                          workersFilter={workersFilter}
                        ></DropDownExpense>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default ReportWorkers;
