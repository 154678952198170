import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../helpers/file';
import { addExpense, uploadAndAddExpense } from '../../redux/actions/expenseAction';
import { getUsers } from '../../redux/actions/userAction';
import { getJobs } from '../../redux/actions/jobAction';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';
import { toggleSpinner } from '../../helpers/display';
import notNet from '../../assets/img/notNet.png'
import PreviewFile from '../../helpers/PreviewFile';
import LoadBar from '../../components/shared/loadBar';

const toastr = require("toastr");

const date = new Date();
const year = date.getFullYear();
let month = date.getMonth() + 1;
let day = date.getDate();

if (day < 10) day = `0${day}`;
if (month < 10) month = `0${month}`;

class AddExpense extends React.Component {
  state = {
    jobId: '',
    userId: '',
    date: `${year}-${month}-${day}`,
    colorError: false,
    purchaseReturn: 'Purchase',
    paymentAccountId: '',
    submitting: false
  };

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  async componentDidMount() {
    toggleSpinner();
    await this.props.getPaymentAccounts();
    await this.props.getUsers();
    const loggedUser = this.props.userLogged;

    await this.props.getJobs(loggedUser._id);
    let filteredJobs = this.props.jobs.filter(job => job.status.toLowerCase() === 'approve');
    filteredJobs = filteredJobs.sort((a, b) => a.jobName.localeCompare(b.jobName));
    this.setState({
      userId: loggedUser._id,
      jobs:  filteredJobs,
      category: '',
      paymentAccountId: loggedUser.defaultPaymentAccount !== null && loggedUser.defaultPaymentAccount !== undefined ? loggedUser.defaultPaymentAccount : ''
    }, () => {
      toggleSpinner(false);
    });
  }

  async componentWillUnmount() {
    await this.props.getPaymentAccounts();

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };

  }

  fmtTotal = val => {
    const cleanVal = val.replace(/^(-)?/g, '');

    if (this.state.purchaseReturn === 'Return') {
      return `-${cleanVal}`;
    }

    return cleanVal;
  };

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if(image) {
        this.setState(prevState => ({ ...prevState, image: image }));
      }
    } catch (e) {
      console.error(e);
      toastr.error('Server error.');
    }
  };

  onFileChange = event => {
    const previewImages = [];
    previewImages.push({ file: event.target.files[0], viewingFrom: "input-file" });
    this.setState({ image: event.target.files[0], previewImages: previewImages });
  }

  handleSubmit = async (e, props) => {
    e.preventDefault();

    const { total } = this.state;
    this.setState({ submitting: true });

    if (this.state.jobId === '') {
      alert('Select a Job to continue');
    } else if (this.state.category === '') {
      alert('Select a category to continue');
    } else if (this.state.paymentAccountId === null || this.state.paymentAccountId === '') {
      alert('Select a payment account to continue');
    } else if (!total.match(/^0|-?[0-9]\d*(\.\d+)?$/gm)) {
      alert('Total quantity not valid');
    } else {
      const data = { ...this.state, total: this.fmtTotal(total) };
      this.setState({ colorError: false });
      this.props.history.push('/admin/expenses');

      await this.props.uploadAndAddExpense(data);
    }
    this.setState({ submitting: false });
  };


  render() {
    const { submitting, previewImages, paymentAccountId } = this.state;

    if (!this.state.userId || this.state.userId === '') return <LoadBar />;


    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Expense Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Job Name *
                            </label>
                            <Input
                              ref={this.selectRef}
                              name="jobId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Select"
                            >
                              <option value="Select" disabled>
                                Select Job to Add Expense
                              </option>
                              {this.state.jobs.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.jobName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Expense Date *
                            </label>
                            <Input
                              required
                              className="form-control-alternative"
                              placeholder="Select a date"
                              id="date"
                              name="date"
                              type="date"
                              value={this.state.date}
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                             Payment Account *
                            </label>
                            <Input
                              name="paymentAccountId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue={paymentAccountId ? paymentAccountId : 'Select'}
                            >
                              <option value="Select" disabled>
                                Select Payment Account
                              </option>
                              {this.props.paymentAccount.list.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Vendor
                            </label>
                            <Input
                              name="vendor"
                              className="form-control-alternative"
                              placeholder="Enter name of vendor"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category"
                            >
                              Category *
                            </label>
                            <Input
                              required
                              name="category"
                              className="form-control-alternative"
                              placeholder="Enter a category"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Choose One"
                            >
                              <option value="Choose One" disabled>
                                Choose One
                              </option>
                              <option value="Job Materials">
                                Job Materials
                              </option>
                              <option value="Gas">Gas</option>
                              <option value="Supplies">Supplies</option>
                              <option value="Sub Contractors">
                                Sub Contractors
                              </option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Description
                            </label>
                            <Input
                              name="description"
                              className="form-control-alternative"
                              placeholder="Enter a description (optional)"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              File Upload *
                            </label>
                            <Input
                              name="photo"
                              id="photo"
                              className="form-control-alternative"
                              placeholder="Select a photo"
                              type="file"
                              onChange={this.onFileChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Purchase/Return *
                            </label>
                            <Input
                              ref={this.selectRef}
                              name="purchaseReturn"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Purchase"
                            >
                              <option value="Purchase">Purchase</option>
                              <option value="Return">Return</option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Total *
                            </label>
                            <Input
                              required
                              name="total"
                              className="form-control-alternative"
                              placeholder="Enter the total"
                              type="text"
                              inputMode="decimal"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          {previewImages && (
                            <PreviewFile previewImages={previewImages} />
                          )}
                          {this.state.image === 'notNet.png' && (
                            <img
                              width="100%"
                              height="100%"
                              src={notNet}
                              alt="photo_url"
                            />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              disabled={!this.state.image || submitting}
                              className="form-control-alternative"
                              color="info"
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  paymentAccount: state.paymentAccount,
  users: state.user.users,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, { getUsers, getJobs, getPaymentAccounts, addExpense, uploadAndAddExpense })(
  withRouter(AddExpense)
);
