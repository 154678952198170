import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import CardBody from 'reactstrap/es/CardBody';
import Global from '../../global';
import { permissions } from '../../helpers/utils';
import {
  getJobs,
  closeJob,
  removeJob,
  convertJob,
} from '../../redux/actions/jobAction';
import { getUsers } from '../../redux/actions/userAction';
import { reset } from '../../redux/actions/searchAction';
import { getExpenses } from '../../redux/actions/expenseAction';
import PreviewFile from '../../helpers/PreviewFile';
import LoadBar from '../../components/shared/loadBar';

const toastr = require("toastr");

const ActionButton = props => (
  <UncontrolledDropdown>
    <DropdownToggle>...</DropdownToggle>
    <DropdownMenu
      modifiers={{
        setMaxHeight: {
          enabled: true,
          order: 890,
          fn: data => ({
            ...data,
            styles: {
              ...data.styles,
              overflow: 'auto',
              maxHeight: '280px',
            },
          }),
        },
      }}
    >
      <DropdownItem to={`/admin/jobs/${props.item._id}/invoice`} tag={Link}>
        Convert to Invoice
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          props.props.convertJob(props.item._id, props.item);
          toastr.success("Job successfully opened.", "Open Job");
        }}
      >
        <span>Open Job</span>
      </DropdownItem>
        <DropdownItem
          onClick={() => {
            props.props.removeJob(props.item._id);
            toastr.success("Job successfully deleted.", "Delete Job");
          }}
        >
          <span className="text-danger">Delete</span>
        </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const ActionButton2 = props => (
  <UncontrolledDropdown>
    <DropdownToggle>...</DropdownToggle>
    <DropdownMenu
      modifiers={{
        setMaxHeight: {
          enabled: true,
          order: 890,
          fn: data => ({
            ...data,
            styles: {
              ...data.styles,
              overflow: 'auto',
              maxHeight: '280px',
            },
          }),
        },
      }}
    >
      <DropdownItem to={`/admin/jobs/${props.item._id}/invoice`} tag={Link}>
        Convert to Invoice
      </DropdownItem>
      {
        permissions(props.userLogged, 'jobs', 'update', 2, props.item.workers) ?
          <DropdownItem to={`/admin/jobs/${props.item._id}`} tag={Link}>
            Update
          </DropdownItem>
          : 
          <DropdownItem disabled to={`/admin/jobs/${props.item._id}`} tag={Link}>
            Update
          </DropdownItem>
      }
      <DropdownItem to={`/admin/jobs/${props.item._id}/addexpense`} tag={Link}>
        Add Expense
      </DropdownItem>
      <DropdownItem to={`/admin/jobs/addworker/${props.item._id}`} tag={Link}>
        Edit Workers
      </DropdownItem>
      <DropdownItem to={`/admin/jobs/addpm/${props.item._id}`} tag={Link}>
        Add Project Manager
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          props.props.closeJob(props.item._id, props.item)
          .then(() => {
            toastr.success("Job closed.", "Close Job Success");
          })
          .catch(e => {
            toastr.error(e.message, "Close Job Error");
          })
        }}
      >
        <span>{props.item.isClosed} Close Job</span>
      </DropdownItem>
      {
        permissions(props.userLogged, 'jobs', 'delete', 4, props.item.workers) ?
          <DropdownItem onClick={() => {
              props.props.removeJob(props.item._id);
              toastr.success("Job successfully deleted.", "Delete Job");
            }}
          >
            <span className="text-danger">Delete</span>
          </DropdownItem>
          : 
          <DropdownItem disabled to="/" tag={Link}>
            Delete
          </DropdownItem>
      }
    </DropdownMenu>
  </UncontrolledDropdown>
);

class Jobs extends React.Component {
  state = {
    currentPage: 0,
    pageSize: 50,
    pagesCount: 0,
    filter: '',
    buttonActive: '1',
    modal: false,
    job: {
      workers: [],
      items: [],
      expenses: [],
    },
    previewImages: [],
  };

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < Global.mobileWidth,
    }));
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    // await this.props.reset();
    
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    await this.props.getUsers();
    await this.props.getExpenses(this.props.userLogged._id);
    await this.props.getJobs(this.props.userLogged._id);
    await this.props.reset();
  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  getOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '1',
      currentPage: 0,
    }));
  };

  getClose = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '2',
      currentPage: 0,
    }));
  };

  getAll = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '3',
      currentPage: 0,
    }));
  };

  openJobsModal = id => {
    const job = this.props.jobs.filter(job => job._id === id)[0];
    const client = this.props.clients.filter(
      item => item._id === job.clientId
    )[0];
    const previewImages = [];
    if (!job.imgs) job.imgs = [];
    job.imgs.forEach(item => {
      item = item.replace('http', 'https');
      previewImages.push({ file: item, viewingFrom: "url" });
    });

    this.setState(prevState => ({
      ...prevState,
      job: {
        name: `${client.firstName} ${client.lastName}`,
        email: client.email,
        address: client.address,
        tax: job.tax,
        discount: job.discount,
        paid: job.paid,
        comments: job.comments,
        ...job,
      },
      previewImages,
      modal: true,
    }));
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  searchFunction = () => {
    var input, filter, table, tr, i;
    input = document.getElementById("jobSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("jobTable");
    console.log(table)
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }
      
      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }
  }

  render() {
    const { jobs, users } = this.props;
    let { pagesCount, job } = this.state;
    let jobsFilter = [];
    if (!this.state) return <LoadBar />;
    switch (this.state.buttonActive) {
      case '1':
        jobsFilter = jobs.filter(job => job.status === 'Approve' && job.isJob);
        break;
      case '2':
        jobsFilter = jobs.filter(job => job.status === 'Closed' && job.isJob);
        break;
      default:
        jobsFilter = jobs.filter(job => job.isJob);
        break;
    }

    pagesCount = Math.ceil(jobsFilter.length / this.state.pageSize);

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                  <div className="col">
                      {/* <FormGroup className="mb-0"> */}
                        <InputGroup className="input-group-alternative search-margin">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='search-style'
                            placeholder="Search"
                            id="jobSearchInput"
                            type="text"
                            onChange={this.searchFunction}
                          />
                        </InputGroup>
                      {/* </FormGroup> */}
                    </div>
                    <div className="col text-right">
                      {
                        permissions(this.props.userLogged, 'jobs', 'create', 0) ?
                        <Button color="primary" type="button" onClick={() => window.location.href = 'addjob'}>
                          Create Job
                        </Button>
                          : null
                      }
                    </div>
                  </Row>
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Jobs</h3>
                    </div>

                  </Row>
                </CardHeader>
                <Form className="card-header">
                  <Row form>
                    <Col md={{ size: 12 }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-dateStart"
                        >
                          Filter
                        </label>
                        <br />
                        <span>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '1'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getOpen}
                          >
                            {!this.state.isMobileVersion ? (
                              'Open'
                            ) : (
                              <small>Open</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '2'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getClose}
                          >
                            {!this.state.isMobileVersion ? (
                              'Close'
                            ) : (
                              <small>Close</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '3'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getAll}
                          >
                            {!this.state.isMobileVersion ? (
                              'All'
                            ) : (
                              <small>All</small>
                            )}
                          </Button>
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>

                <Pagination className="pagination-center">
                  <PaginationItem disabled={this.state.currentPage <= 0}>
                    <PaginationLink
                      onClick={e =>
                        this.handleClick(e, this.state.currentPage - 1)
                      }
                      previous
                      href="#"
                    />
                  </PaginationItem>
                  {[...Array(pagesCount)].map((page, i) => (
                    <PaginationItem
                      active={i === this.state.currentPage}
                      key={i}
                    >
                      <PaginationLink
                        onClick={e => this.handleClick(e, i)}
                        href="#"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={this.state.currentPage >= pagesCount - 1}
                  >
                    <PaginationLink
                      onClick={e =>
                        this.handleClick(e, this.state.currentPage + 1)
                      }
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>

                <Table className="align-items-center table-flush" id="jobTable" responsive>
                  <thead className="thead-light">
                    <tr>
                      {!this.state.isMobileVersion ? (
                        <>
                          <th scope="col"></th>
                          <th scope="col">Job Name</th>
                          <th scope="col">Worker(s)</th>
                          <th scope="col">Total</th>
                        </>
                      ) : (
                        <>
                          <th scope="col" colSpan="2">
                            Details
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {jobsFilter.length === 0 ? (
                      <tr>
                        <td>No Jobs register</td>
                      </tr>
                    ) : (
                      jobsFilter
                        .slice(
                          this.state.currentPage * this.state.pageSize,
                          (this.state.currentPage + 1) * this.state.pageSize
                        )
                        .map((e, i) => {
                          const subtotal = e.items
                            ? e.items.reduce(
                                (acc, current, i) => acc + current.subtotal,
                                0
                              )
                            : 0;
                          const tax =
                            (parseInt(e.estimateTax) * subtotal) / 100;
                          const discount = e.estimateDiscount;
                          const paid = e.estimatePaid;
                          const total = subtotal + tax - paid - discount;
                          const jobName = `${e.jobName} ${
                            e.estimatePhone ? `(${e.estimatePhone})` : ''
                          }`;
                          if (e.status === 'Closed') {
                            return (
                              <tr key={e._id} className="details-row">
                                {!this.state.isMobileVersion ? (
                                  <>
                                    <td>
                                      <ActionButton
                                        item={e}
                                        props={this.props}
                                        userLogged={this.props.userLogged}
                                      ></ActionButton>
                                    </td>
                                    <td
                                      onClick={() => {
                                        this.openJobsModal(e._id);
                                      }}
                                    >
                                      {jobName}
                                    </td>
                                    <td
                                      onClick={() => {
                                        this.openJobsModal(e._id);
                                      }}
                                    >
                                      Closed
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <div className="buttonfloat-right buttonfloat-right-estimates">
                                        <ActionButton
                                          item={e}
                                          props={this.props}
                                          userLogged={this.props.userLogged}
                                        ></ActionButton>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() => {
                                        this.openJobsModal(e._id);
                                      }}
                                    >
                                      {jobName}
                                      <br />
                                      Closed
                                      <br />
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          }
                          return (
                            <tr key={e._id} className="details-row">
                              {!this.state.isMobileVersion ? (
                                <>
                                  <td>
                                    <ActionButton2
                                      item={e}
                                      props={this.props}
                                      userLogged={this.props.userLogged}
                                    ></ActionButton2>
                                  </td>
                                  <td
                                    onClick={() => {
                                      this.openJobsModal(e._id);
                                    }}
                                  >
                                    {jobName}
                                  </td>
                                  <td
                                    onClick={() => {
                                      this.openJobsModal(e._id);
                                    }}
                                  >
                                    {e.workers.map((e, i) => {
                                      const user = users.filter(
                                        user => user._id === e.workerId
                                      );
                                      return !user[0] ? (
                                        <p style={{ fontSize: '10px' }} key={i}>
                                          Worker Delete
                                        </p>
                                      ) : (
                                        <p style={{ fontSize: '10px' }} key={i}>
                                          {user[0].name}
                                        </p>
                                      );
                                    })}
                                  </td>
                                  <td
                                    onClick={() => {
                                      this.openJobsModal(e._id);
                                    }}
                                  >
                                    
                                    {isNaN(
                                      parseFloat(
                                        Math.round(total * 100) / 100
                                      ).toFixed(2)
                                    )
                                      ? 'Check your quantities and figures in your estimate please'
                                      : '$'+parseFloat(
                                          Math.round(total * 100) / 100
                                        ).toFixed(2)}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <div className="buttonfloat-right buttonfloat-right-jobs">
                                      <ActionButton2
                                        item={e}
                                        props={this.props}
                                        userLogged={this.props.userLogged}
                                      ></ActionButton2>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => {
                                      this.openJobsModal(e._id);
                                    }}
                                  >
                                    {e.dateStart === 'Update this field' ? (
                                      'Update '
                                    ) : (
                                      <Moment format={'MMM D, YY'}>
                                        {e.dateStart}
                                      </Moment>
                                    )}{' '}
                                    -{' '}
                                    {e.dateEnd === 'Update this field' ? (
                                      'Update '
                                    ) : (
                                      <Moment format={'MMM D, YY'}>
                                        {e.dateEnd}
                                      </Moment>
                                    )}
                                    <br />
                                    <small>{jobName}</small>
                                    <br />
                                    {e.workers.map((e, i) => {
                                      const user = users.filter(
                                        user => user._id === e.workerId
                                      );
                                      return !user[0] ? (
                                        <p style={{ fontSize: '10px' }} key={i}>
                                          Worker Delete
                                        </p>
                                      ) : (
                                        <p style={{ fontSize: '10px' }} key={i}>
                                          {user[0].name}
                                        </p>
                                      );
                                    })}
                                    <br />
                                    <b>
                                      $
                                      {isNaN(
                                        parseFloat(
                                          Math.round(total * 100) / 100
                                        ).toFixed(2)
                                      )
                                        ? 'Check your quantities and figures in your estimate please'
                                        : parseFloat(
                                            Math.round(total * 100) / 100
                                          ).toFixed(2)}
                                    </b>
                                    <br />
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size={'lg'}>
          <ModalHeader toggle={this.toggleModal} tag="h1">
            Job details ({job.jobName})
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <div>
                Client Name: <b>{job.name}</b>
                </div>
                Client Email: <b>{job.email}</b>
                <br />
                Phone number: <b>{job.estimatePhone}</b>
                <br />
                Address: <b>{job.jobAddress}</b>
                <br />
                Internal Comments: <b>{job.comments_internal}</b>
                <br />
                External Comments: <b>{job.comments}</b>
                <br />
                Status: <b>{job.status}</b>
                <br />
                Date create: <b>{job.dateCreate}</b>
                <br />
                Subtotal: <b>${job.estimateSubtotal}</b>
                <br />
                Tax: <b>${job.estimateTax}</b>
                <br />
                Discount: <b>${job.estimateDiscount}</b>
                <br />
                Paid: <b>${job.estimatePaid}</b>
                <br />
                Total: <b>${job.estimateTotal}</b>
                <br />
                <div>
                  Workers:{' '}
                  <b>
                    {job.workers.map((worker, i) => {
                      const user = users.filter(
                        user => user._id === worker.workerId
                      );
                      return !user[0] ? (
                        <span key={i}>Worker Delete</span>
                      ) : (
                        <span key={i}>
                          {user[0].name}
                          {i === job.workers.length - 1 ? '' : ', '}
                        </span>
                      );
                    })}
                    <br />
                  </b>
                </div>
                <br />
                <h3>- Items</h3>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"> Item Name </th>
                      <th scope="col"> Description </th>
                      <th scope="col"> Quantity </th>
                      <th scope="col"> Rate </th>
                      <th scope="col"> Total </th>
                    </tr>
                  </thead>
                  <tbody>
                    {job.items &&
                      job.items.map((el, i) => (
                        <tr key={i}>
                          <td> {el.itemName} </td>
                          <td> {el.itemDescription} </td>
                          <td> {el.quantity} </td>
                          <td>$ {el.rate} </td>
                          <td>$ {el.subtotal} </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {this.state.previewImages &&
                  this.state.previewImages.length > 0 && (
                    <>
                      <br />
                      <PreviewFile previewImages={this.state.previewImages} />
                    </>
                  )}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  users: state.user.users,
  clients: state.client.clients,
  userLogged: state.auth.userLogged,
  expenses: state.expense.expenses,
});

export default connect(mapStateToProps, {
  getUsers,
  getExpenses,
  getJobs,
  closeJob,
  removeJob,
  convertJob,
  reset,
})(Jobs);
