import React from 'react';


import './invoicePreviewStyles.css';

const InvoicePreview = props => {
    const {
        description,
        total,
        invoiceNumber,
        jobName,
        items = []
    } = props;

    return <div className="main">
        {/* <img style={{ height: '124px', width: '380px', marginLeft: '20px' }} src="https://res.cloudinary.com/du4edhdwn/image/upload/v1665041602/ImagesGA/ioxi3xiwazhl2s8vll1n.png" /> */}
        <div style={{ paddingBottom: 12, borderBottom: '1px solid rgba(100,121,143,0.2)' }}>
            <img
                className="-header-img"
                src="https://res.cloudinary.com/du4edhdwn/image/upload/v1665041602/ImagesGA/ioxi3xiwazhl2s8vll1n.png"
                alt=''
            />
            <span
                style={{ textAlign: "left", float: 'right', paddingTop: 8 }}
            >
                <span className="-header-name" style={{ fontSize: "1.1em", paddingRight: 5 }}>
                    INV-{invoiceNumber ? invoiceNumber.toString().padStart(7, 0) : '0000000'}
                </span>
                <div style={{ fontSize: "1.1em" }}>
                    {jobName}
                </div>

            </span>
        </div>

        <div style={{ width: '100%' }}>
            <p style={{ color: '#707070', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '20px' }}>
                This e-mail contains the details of the invoice for the work done.
            </p>
            <p style={{ fontWeight: 'bold', color: '#707070', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 22 }}>Description:</p>
            <p style={{ textAlign: 'justify', color: '#707070', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18 }}>
                {description}
            </p>
            {/* <p style={{ fontWeight: 'bold', color: '#707070', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '22px' }}>Total Invoice:</p>
            <p style={{ color: '#00A863', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '20px' }}>
                ${total}
            </p> */}
            <div style={{ paddingTop: 20 }}>
                <table style={{ width: '100%', border: '1px solid #e9ecef' }}>
                    <thead style={{ backgroundColor: '#f6f9fc', padding: 12 }}>
                        <tr style={{ color: '#8898aa' }}>
                            <th style={{ padding: 12 }}>ITEM</th>
                            <th style={{ padding: 12 }}>QTY</th>
                            <th style={{ padding: 12 }}>RATE</th>
                            <th style={{ padding: 12 }}>SUBTOTAL</th>
                        </tr>
                    </thead>
                    <tbody>

                        {items.map(item => {
                            return <tr style={{ border: '1px solid #e9ecef', color: '#8898aa' }}>
                                <td style={{ padding: 5 }}>
                                    <div style={{
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        paddingLeft: 5
                                    }}>{item.itemName}</div>
                                    <div style={{
                                        fontSize: 13,
                                        paddingLeft: 5
                                    }}>{item.description} dest</div>
                                </td>
                                <td style={{ padding: 5 }}>{item.quantity}</td>
                                <td style={{ padding: 5 }}>{item.rate}</td>
                                <td style={{ padding: 5 }}>{item.subtotal}</td>
                            </tr>
                        })}

                        <tr style={{ border: '1px solid gray', backgroundColor: '#4E3524' }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <p style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '18px' }}>TOTAL: ${total}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />
        <div style={{ width: '100%' }}>
            {/* <a href="${urlPay}" style={{ marginLeft: '150px', padding: '20px', textDecoration: 'none', border: 'none', fontFamily: 'Arial, Helvetica, sans-serif', borderRadius: '4px', backgroundColor: '#00A863', color: 'white', fontSize: '18px' }}>Pay Invoice</a> */}
            <br />
            <p style={{ marginTop: '25px', color: '#707070', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '13px' }}>
                You may pay your invoice here. If you have any questions, please reply to this e-mail or call us at: (919) 600-0199
            </p>
        </div>
        <br />
        <br />
        <br />
        <div style={{ textAlign: 'center' }}>
            <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_2_snkfws.png" alt="elite" />
            <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_3_gveepq.png" alt="houzz" />
            <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_4_zkdowq.png" alt="accredited" />
            <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_5_uapttm.png" alt="accredited" />
        </div>
        <br />
        <br />
        <br />

        <div className="content1">
            <a href="https://www.facebook.com/GreenAcornContracting/">
                <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_6_tdwknw.png" alt="elite" />
            </a>
            <a href="https://www.instagram.com/greenacorncontracting/">
                <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_7_oozhoi.png" alt="houzz" />
            </a>
            <a href="https://twitter.com/GreenAcornC/">
                <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297019/ImagesGA/assets/Imagen_8_t6zdai.png" alt="accredited" />
            </a>
            <a href="https://www.pinterest.com.mx/greenacorncontracting/">
                <img style={{ marginLeft: '20px' }} src="https://res.cloudinary.com/ironhackjorge/image/upload/v1585297018/ImagesGA/assets/Imagen_9_o7ixka.png" alt="accredited" />
            </a>
        </div>
        <br />
        <br />
        <div className="content2">
            <p style={{ fontSize: '12px', color: '#707070' }}>Green Acorn</p>
            <p style={{ fontSize: '12px', color: '#707070' }}>11504 Black Horse Run,</p>
            <p style={{ fontSize: '12px', color: '#707070' }}>Raleigh, NC 27613 (919) 600-0199</p>
            <p style={{ fontSize: '12px', color: '#707070' }}><a href="https://www.greenacorn.com/privacy-policy">Privacy Policy</a> | <a href="https://www.greenacorn.com/terms">Terms of Use</a> </p>
            <p style={{ fontSize: '12px', color: '#707070' }}>©2020 Green Acorn</p>
        </div>
    </div>

};

export default InvoicePreview;