import React from 'react';
import { withRouter } from 'react-router-dom';
// import { Carousel } from 'react-responsive-carousel';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';

// core components
import PhoneInput from 'react-phone-number-input';
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { addEstimate } from '../../redux/actions/jobAction';
import { addItem, getItems } from '../../redux/actions/itemAction';
import { getRevenueCenter } from '../../redux/actions/revenueCenterAction';
import { getClients } from '../../redux/actions/clientAction';
import AuthService from '../../services/services';
import { GooglePlacesAutocomplete } from '../../components/Form/GooglePlacesAutocomplete';
import { FileUploadPreview } from '../../components/FileUpload';

import PreviewFile from '../../helpers/PreviewFile';
import { getDraftById, removeDraftById, saveAsDraft } from '../../helpers/draft';
import { fileToBase64 } from '../../helpers/file';

const authService = new AuthService();

const fecha = new Date();
let mes = fecha.getMonth() + 1;
let dia = fecha.getDate();
const ano = fecha.getFullYear();
if (dia < 10) dia = `0${dia}`; // agrega cero si es menor de 10
if (mes < 10) mes = `0${mes}`; // agrega cero si es menor de 10



const EmptyFields = <span style={{ color: 'red' }}>This field should not be empty.</span>;


// function byteConverter(bytes, decimals, only) {
//   const K_UNIT = 1024;
//   const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

//   if (bytes == 0) return "0 Byte";

//   if (only === "MB") return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

//   let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
//   let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];

//   return resp;
// }


class AddEstimate extends React.Component {
  state = {
    name: '',
    address: '',
    email: '',
    items: [],
    itemName: '',
    itemDescription: '',
    itemInternalNotes: '',
    description: '',
    photo: [],
    quantity: '',
    cost: 0,
    rate: '',
    estimateSubtotal: 0,
    estimateTax: 0,
    estimateDiscount: 0,
    estimatePaid: 0,
    estimateCost: 0,
    estimateTotal: 0,
    dateCreate: `${ano}-${mes}-${dia}`,
    jobName: '',
    estimatePhone: '',
    value: '',
    suggestions: [],
    itemSuggestions: [],
    modal: false,
    previewModal: false,
    selectedImage: 0,
    imgs: [],
    selectedFiles: null,
    uploadedFiles: null,
    previewImages: [],
    progressInfos: [],
    message: [],
    submitting: false,
    revenueCenter: null,
    showRequired: false,
    isUploading: false,
    newClients: [],
    clientSuggestions: []
  };

  constructor(props) {
    super(props);
    this.selectFiles = this.selectFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadImages = this.uploadImages.bind(this);
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  async componentDidMount() {
    await this.props.getItems();
    await this.props.getRevenueCenter();
    await this.props.getClients();

    let clientsArray = this.props.clients && this.props.clients.length > 0 ? this.props.clients : [];
    if (clientsArray.length > 0) {
      for (let i = 0; i < clientsArray.length; i++) {
        clientsArray[i].name = clientsArray[i].firstName + ' ' + clientsArray[i].lastName;
      }
      this.setState({newClients: clientsArray});
    }

    const urlParams = new URLSearchParams(window.location.search);
    const draftId = urlParams.get('draftId');

    if (draftId) {
      let draft = await getDraftById('estimates', draftId);

      if (draft) {
        delete draft.submitting;
        let previewImages = [];
        if (draft.selectedFiles) {

          if (draft.imgs.length === 0) {
            const url = await fileToBase64(draft.selectedFiles[0]);
            previewImages.push({ file: url, viewingFrom: "url" });
          }
          else {
            previewImages.push({ file: draft.imgs[0], viewingFrom: "url" });
          }


        }
        else {
          if (draft.imgs.length > 0) {
            previewImages.push({ file: draft.imgs[0], viewingFrom: "url" });
          }
        }

        this.setState({
          ...draft,
          previewImages
        });
      }

    }

    this.setState({
      userId: this.props.userLogged._id,
    });
    authService.getFiles().then(response => {
      this.setState({
        imageInfos: response.data,
      });
    }).catch(err => {
      console.log(err)
    })
  }


  async componentDidUpdate(prevProps, prevState) {
    const { connection } = this.props;

    if (connection && prevProps.connection && (prevProps.connection.status && !connection.status)) {
      const urlParams = new URLSearchParams(window.location.search);
      const draftId = urlParams.get('draftId') || null;

      if (!draftId) {
        const payload = {
          ...this.state,
          draft_id: draftId
        };
        if (payload.name) {
          const currentDraft = await saveAsDraft('estimates', payload);
          if (currentDraft) {
            this.setState({
              draft_id: currentDraft.draft_id
            });
            this.props.history.push(`addestimate?draftId=${currentDraft.draft_id}`);
          }
        }
      }
      else {
        const payload = {
          ...this.state,
          draft_id: draftId
        };
        if (payload.name) {
          const currentDraft = await saveAsDraft('estimates', payload);
          if (currentDraft) {
            this.setState({
              draft_id: currentDraft.draft_id
            });

          }
        }
      }

    }
    else if (connection && prevProps.connection && (!prevProps.connection.status && connection.status)) {
      if (this.state.selectedFiles) {
        this.reUploadImages(this.state.selectedFiles);
      }
    }
  }

  async componentWillUnmount() {
    await this.props.getItems();
    await this.props.getRevenueCenter();
    await this.props.getClients();

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  selectFiles(event) {
    const { connection } = this.props;
    const previewImages = [];


    if (event.target.files) {
      Object.keys(event.target.files).forEach(key => {
        previewImages.push({ file: event.target.files[key], viewingFrom: "input-file" });
      })

      const files = [...(this.state.selectedFiles || []), ...(event.target.files || [])];
      this.setState({
        progressInfos: [],
        message: [],
        selectedFiles: [...(files || [])],
        previewImages,
      });

      if (connection.status) {
        this.reUploadImages(files);
      }

    }



  }

  async upload(idx, file) {
    const _progressInfos = [...this.state.progressInfos];

    const imgs = [];
    const previewImages = [];
    this.state.imgs.forEach(item => {
      imgs.push(item);
      previewImages.push({ file: item, viewingFrom: "url" });
    });

    const response = await authService.upload(file, event => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      this.setState({
        progressInfos: _progressInfos,
      });
    });
    imgs.push(response.data.img);
    previewImages.unshift({ file: response.data.img, viewingFrom: "url" });
    this.setState(prev => {
      const nextMessage = [
        ...prev.message,
        `Uploaded the image successfully: ${file.name}`,
      ];
      return {
        imgs,
        previewImages,
        message: nextMessage
        // selectedFiles: undefined
      };
    });
  }

  uploadImages = async () => {

    const { selectedFiles } = this.state;

    const _progressInfos = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }
    this.setState(
      {
        isUploading: true,
        progressInfos: _progressInfos,
        message: [],
      },
      async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(i, selectedFiles[i]);
        }
      }
    );

  };


  reUploadImages = async selectedFiles => {

    const { progressInfos } = this.state;
    const _progressInfos = [...(progressInfos || [])];
    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }
    this.setState(
      {
        isUploading: true,
        progressInfos: _progressInfos,
        message: [],
      },
      async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          if (_progressInfos[i].percentage === 0) {
            await this.upload(i, selectedFiles[i]);
          }
        }
        this.setState({
          isUploading: false
        })
      }
    );

  };
  addToCart = async product => {
    if (this.state.itemName === '') {
      alert('The field Item Name is required to add item');
    } else if (this.state.quantity === '') {
      alert('The field quantity is required to add item');
    } else if (this.state.rate === '') {
      alert('The field rate is required to add item');
    } else {
      if (this.state.reuseItem === 'on') {
        const data = this.props.items.filter(
          text => text.item.toLowerCase() === product.itemName.toLowerCase()
        );
        if (data.length === 0) {
          product.item = product.itemName;
          product.description = product.itemDescription;
          await this.props.addItem(product);
        }
      }
      const joined = this.state.items.concat(product);
      this.setState({
        items: joined,
        itemName: '',
        itemDescription: '',
        itemInternalNotes: '',
        cost: 0,
        quantity: 0,
        rate: 0,
        reuseItem: 'off',
      });
    }
  };

  handleInput = async e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.name === 'cost' || e.target.name === 'rate' || e.target.name === 'quantity' ? parseFloat(e.target.value) : e.target.value,
    }));
  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    const { name, email, estimatePhone, address, imgs, revenueCenter, jobName, items } = this.state;


    const isOffline = !navigator.onLine;// connection && !connection.status;

    if (jobName === '') {
      this.setState({jobName: name + ' - ' + address});
    }

    if (name === '') {
      alert('Select a Client to continue');
    } else if (revenueCenter === null) {
      alert('Select a Revenue Center to continue');
    } else if (email === '') {
      alert('Enter an email to continue');
    } else if (address === undefined || address === '') {
      alert('Enter an address to continue');
    } else if (estimatePhone === undefined || estimatePhone === 0 || estimatePhone === '') {
      alert('Enter a phone number to continue');
    } else if (items.length === 0) {
      alert('Add an item to continue');
    } else {

      if (!isOffline) {
        this.setState({ submitting: true });
        let payload = {
          ...this.state
        }
        //if (this.state.draft_id) {
        if (this.state.selectedFiles) {
          // UPLOAD DRAFT IMAGES
          // const mimeType = getMimeType(files);
          // const currentFile = await urltoFile(files);
          if (imgs.length === 0 && this.state.selectedFiles) {
            const imageResponse = await authService.upload(this.state.selectedFiles[0], this.state.selectedFiles[0].type);

            payload = {
              ...payload,
              imgs: [imageResponse.data.img]
            }

          }
          else {
            payload = {
              ...payload,
              imgs
            }

          }

        }

        //}

        await this.props.addEstimate(payload, 'estimates');
        if (payload.draft_id) {
          removeDraftById('estimates', payload.draft_id);
        }
        this.props.history.push(`estimates`);
        this.setState({ submitting: false });
      }
      else {

        if (this.state.selectedFiles && this.state.selectedFiles.length > 0) {
          this.setState({ submitting: true });


          const payload = {
            ...this.state,
            files: this.state.selectedFiles[0],
            syncFiles: true
          };

          if (payload.jobName === '') {
            payload.jobName = payload.name + ' - ' + payload.address;
          }

          await saveAsDraft('estimates', payload);
          this.props.history.push(`estimates`);
          this.setState({ submitting: false });

        }
        else {
          this.setState({ submitting: true });

          this.props.history.push(`estimates`);
          saveAsDraft('estimates', this.state);
          this.setState({ submitting: false });
        }


      }



    }
  };

  setPhoneNumber = e => {
    this.setState(prevState => ({
      ...prevState,
      estimatePhone: e,
    }));
  };

  onChangeItem = async (event, { newValue }) => {
    this.setState(prevState => ({
      ...prevState,
      itemName: newValue,
    }));
  };

  getItemSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : this.props.items.filter(
        text => text.item.toLowerCase().includes(inputValue) > 0
      );
  };

  getSuggestionValueItem = suggestion => {
    this.setState(prevState => ({
      ...prevState,
      itemName: suggestion.item,
      itemDescription: suggestion.description,
      quantity: suggestion.quantity,
      rate: suggestion.rate,
    }));
    return suggestion.item;
  };

  renderSuggestionItem = suggestion => <div>{suggestion.item}</div>;

  onSuggestionsFetchRequestedItem = ({ value }) => {
    this.setState({
      itemSuggestions: this.getItemSuggestions(value),
    });
  };

  onSuggestionsClearRequestedItem = () => {
    this.setState({
      itemSuggestions: [],
    });
  };

  onChangeImage = value => {
    this.setState({
      selectedImage: value,
    });
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });
  };


  handleOpenPreviewModal = () => {
    this.setState(prevState => ({
      ...prevState,
      previewModal: !this.state.previewModal,
    }));
  };


  togglePreviewModal = () => {
    this.setState({
      previewModal: !this.state.previewModal,
    });
  };


  handleInputItems = index => e => {
    const { items } = this.state;
    let { name, value } = e.target;

    let updatedItems = items.map((item, itemIndex) => {
      if (index === itemIndex) {
        let subtotal = 0;

        if (name === 'quantity') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        else if (name === 'rate') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.quantity;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }

        else {
          subtotal = item.subtotal; // item.rate * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }

        return {
          ...item,
          [name]: value,
          subtotal
        }
      }

      return item
    });

    this.setState({
      items: [...(updatedItems || [])]
    })

  }

  handleClearFiles = key => () => {
    // this.fileInput.value = '';

    let previewImages = [...this.state.previewImages];
    const updatedFiles = [...this.state.selectedFiles];
    updatedFiles.splice(key, 1);
    previewImages.splice(key, 1);

    this.setState({
      previewImages,
      selectedFiles: updatedFiles
    });


  }


  getClientSuggestions = value => {
    const {newClients} = this.state;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : newClients.filter(client => {
          if (client.firstName !== undefined && client.lastName !== undefined && client.email !== undefined) {
            return client.name.toLowerCase().includes(inputValue);
          }

          return client;
        }
      );
  };

  getClientSuggestionValue = suggestion => {
    this.setState({
      clientId: suggestion._id,
      name: suggestion.name,
      email: suggestion.email,
      address: suggestion.address,
      estimatePhone: suggestion.mobile,
    });
    
    return suggestion.name;
  };

  renderClientSuggestion = suggestion => <div>{suggestion.name} {suggestion.email ? '- ' + suggestion.email : ''} </div>;

  onSuggestionsFetchRequestedClient = ({ value }) => {
    this.setState({
      clientSuggestions: this.getClientSuggestions(value),
    });
  };

  onClientSuggestionsClearRequested = () => {
    this.setState({
      clientSuggestions: [],
    });
  };

  onChangeClient = async (event, { newValue }) => {
    this.setState(prevState => ({
      ...prevState,
      name: newValue,
    }));
  };

  render() {
    const { selectedFiles, previewImages, progressInfos, message, submitting, showRequired, itemName, itemSuggestions, name, clientSuggestions, address } = this.state;
    const { connection, revenueCenter } = this.props;

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const product = {
      itemName: this.state.itemName,
      itemDescription: this.state.itemDescription,
      itemInternalNotes: this.state.itemInternalNotes,
      quantity: parseFloat(this.state.quantity),
      rate: parseFloat(this.state.rate),
      cost: parseFloat(this.state.cost),
      subtotal: parseFloat(this.state.quantity * this.state.rate),
    };



    const subtotal = this.state.items.reduce(
      (acc, current, i) => acc + current.subtotal,
      0
    );
    const totalCost = this.state.items.reduce(
      (acc, current) => acc + (parseFloat(current.cost) * current.quantity),
      0
    );
    const tax = (parseInt(this.state.estimateTax) * subtotal) / 100;
    const discount = parseInt(this.state.estimateDiscount);
    const paid = parseInt(this.state.estimatePaid);
    const estimateTotal = subtotal + tax - discount - paid;
    const jobName = name + ' - ' + address;
    const totalProfit = estimateTotal - totalCost;
    const inputPropsItem = {
      placeholder: 'Enter a name item',
      value: itemName,
      onChange: this.onChangeItem,
    };

    const inputPropsClient = {
      placeholder: 'Enter a client name',
      value: name,
      onChange: this.onChangeClient,
    };

    const isOffline = connection && !connection.status;


    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Estimate Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Name *
                            </label>
                            <Autosuggest
                                  className='form-control-alternative'
                                  alwaysRenderSuggestions={false}
                                  suggestions={clientSuggestions}
                                  onSuggestionsFetchRequested={
                                    this.onSuggestionsFetchRequestedClient
                                  }
                                  onSuggestionsClearRequested={
                                    this.onClientSuggestionsClearRequested
                                  }
                                  getSuggestionValue={this.getClientSuggestionValue}
                                  renderSuggestion={this.renderClientSuggestion}
                                  inputProps={inputPropsClient}
                                />
                            {/* <Input
                              className="form-control-alternative"
                              placeholder="Enter the name client"
                              name="name"
                              type="text"
                              onChange={this.handleInput}
                              value={this.state.name}
                            /> */}
                            {(this.state.name === '' && showRequired) && EmptyFields}
                            <br />
                            <Button
                              className="form-control-alternative"
                              color="light"
                              onClick={() =>
                                this.props.history.push('/admin/addclient')
                              }
                            >
                              Add New Client
                            </Button>
                            <br />
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Email *
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Enter the email client"
                              name="email"
                              type="email"

                              onChange={this.handleInput}
                              value={this.state.email}
                            />
                            {(this.state.email === '' && showRequired) && EmptyFields}
                            <br />

                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-nameClient"
                            >
                              Revenue Center
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="revenueCenter"
                              type="select"
                              onChange={this.handleInput}
                              value={this.state.revenueCenter || 'Select'}
                            >
                              <option value="Select" disabled>
                                Select a Revenue Center
                              </option>
                              {revenueCenter.map(item => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Phone number *
                            </label>
                            <PhoneInput
                              className={isOffline ? "phone-number-disconnect" : ""}
                              international={false}
                              defaultCountry="US"
                              placeholder="Enter phone number"
                              value={this.state.estimatePhone}
                              onChange={this.setPhoneNumber}
                            />
                            {(this.state.estimatePhone === '' && showRequired) && EmptyFields}
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Address *
                            </label>
                            <GooglePlacesAutocomplete onSelect={this.handleSelect} value={this.state.address} />
                            {(this.state.address === '' && showRequired) && EmptyFields}
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Items
                            </label>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Item Name</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Internal Notes</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Cost</th>
                                  <th scope="col">Rate</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.items.map((el, i) => (
                                  <tr key={i}>
                                    <td> {/* el.itemName*/}

                                      <Input
                                        defaultValue={el.itemName}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemName"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemDescription}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemDescription"
                                        type="text"
                                        // rows={5}
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemInternalNotes}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemInternalNotes"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>

                                    <td >
                                      <Input
                                        value={el.quantity}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="quantity"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        value={el.cost}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="cost"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        value={el.rate}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="rate"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>
                                    <td> $ {el.subtotal ? el.subtotal.toFixed(2) : 0}</td>
                                    <td>
                                      <Button
                                        onClick={e =>
                                          this.setState(prevState => {
                                            const filter =
                                              this.state.items.filter(
                                                e => e !== this.state.items[i]
                                              );
                                            this.setState({ items: filter });
                                          })
                                        }
                                        style={{
                                          width: '100px',
                                          height: '20px',
                                          fontSize: '10px',
                                          paddingTop: '0',
                                        }}
                                        className="btn icon-btn btn-danger"
                                      >
                                        <i className="nc-icon nc-simple-remove" />
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Item Name *
                            </label>
                            <Autosuggest
                              alwaysRenderSuggestions={true}
                              suggestions={itemSuggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedItem
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedItem
                              }
                              getSuggestionValue={this.getSuggestionValueItem}
                              renderSuggestion={this.renderSuggestionItem}
                              inputProps={inputPropsItem}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Description
                            </label>
                            <Input
                              value={this.state.itemDescription}
                              name="itemDescription"
                              className="form-control-alternative"
                              placeholder="Enter a description of item"
                              type="textarea"
                              rows={5}
                              id="itemDescription"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Internal Notes
                            </label>
                            <Input
                              value={this.state.itemInternalNotes}
                              name="itemInternalNotes"
                              className="form-control-alternative"
                              placeholder="Enter a internal notes of an item"
                              type="textarea"
                              rows={5}
                              id="itemInternalNotes"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Quantity *
                            </label>
                            <Input
                              value={this.state.quantity}
                              name="quantity"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="quantity"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Cost *
                            </label>
                            <Input
                              value={this.state.cost}
                              name="cost"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="cost"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Rate *
                            </label>
                            <Input
                              value={this.state.rate}
                              name="rate"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="rate"
                              onChange={this.handleInput}
                            />
                            <br />
                            <Row>
                              <Col md={6}>
                                <button
                                  type="button"
                                  onClick={e => this.addToCart(product)}
                                  className="btn btn-primary"
                                >
                                  Add Item
                                </button>
                              </Col>
                              <Col md={6}>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    id="cbReuseItem"
                                    onClick={this.handleInput}
                                    name="reuseItem"
                                  />
                                  <Label check for="cbReuseItem">
                                    Save as reusable item
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          {/* {this.state.imgs && this.state.imgs.length > 0 ? (
                            <FormGroup className="text-center">
                              <label className="form-control-label">
                                <Button
                                  color="info"
                                  onClick={this.handleOpenPreviewModal}
                                >
                                  <i className="fas fa-image"></i> View Images
                                  loaded
                                </Button>
                              </label>
                            </FormGroup>
                          ) : null} */}

                          <FormGroup>
                            {/* <Button
                              type="button"
                              color="info"
                              // disabled={isOffline}
                              onClick={e => this.toggleModal()}
                            >
                              Upload Documents
                            </Button> */}
                          </FormGroup>

                          <Row>

                            <div className="col-8">
                              <label className="btn btn-default p-0">
                                <input
                                  type="file"
                                  multiple
                                  accept="image/*,application/pdf"
                                  onChange={this.selectFiles}
                                  ref={ref => this.fileInput = ref}
                                />
                              </label>
                            </div>

                          </Row>

                          <Row style={{ paddingLeft: 15 }}>

                            {selectedFiles && Object.keys(selectedFiles).map((key, index) => {

                              return <FileUploadPreview
                                index={key}
                                file={selectedFiles[key]}
                                handleClearFiles={this.handleClearFiles}
                                handleOpenPreviewModal={this.handleOpenPreviewModal}
                                progress={progressInfos[[index]]}
                              />
                              // return <div >
                              //   <div
                              //     onClick={this.handleOpenPreviewModal}
                              //     style={{
                              //       display: 'inline-block',
                              //       width: 400,
                              //       height: 'auto',
                              //       backgroundColor: progressInfos && progressInfos[index] && progressInfos[index].percentage === 100 ? '#5cb85c' : 'gray',
                              //       padding: 15,
                              //       borderRadius: 12,
                              //       cursor: 'pointer',
                              //       marginTop: 5
                              //     }}>

                              //     <div>

                              //       {progressInfos && progressInfos[index] && <ProgressBar value={progressInfos[index].percentage} />}


                              //       <span style={{ paddingLeft: 5, paddingBottom: 5 }}>
                              //         <i class="ni ni-image "></i>  {selectedFiles && selectedFiles[key].name}
                              //       </span>
                              //       <i class="ni ni-check-bold " style={{ float: 'right', marginTop: 5 }}></i>
                              //     </div>
                              //     <div style={{ paddingLeft: 5 }}>
                              //       {byteConverter(selectedFiles[key].size, 2, 'MB')}
                              //     </div>
                              //   </div>

                              //   {progressInfos && progressInfos[index] && progressInfos[index].percentage === 100 && <div style={{
                              //     display: 'inline-block',
                              //     color: 'red',
                              //     paddingLeft: 12,
                              //     cursor: 'pointer'
                              //   }}
                              //     onClick={this.handleClearFiles(key)}>
                              //     <span>Remove</span>
                              //   </div>}
                              // </div>
                            })}

                          </Row>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              External Comments
                            </label>
                            <Input
                              name="comments"
                              className="form-control-alternative"
                              placeholder="Enter comments (optional)"
                              type="textarea"
                              rows="6"
                              onChange={this.handleInput}
                              value={this.state.comments}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Internal Comments (not visible to clients){' '}
                            </label>
                            <Input
                              name="comments_internal"
                              className="form-control-alternative"
                              placeholder="Enter comments (optional)"
                              type="textarea"
                              rows="6"
                              onChange={this.handleInput}
                              value={this.state.comments_internal}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <h4>Estimate</h4>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Date
                            </label>
                            <Input
                              name="dateCreate"
                              value={
                                month < 10
                                  ? `${day}-0${month}-${year}`
                                  : `${day}-${month}-${year}`
                              }
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                              width="50%"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Subtotal
                            </label>
                            <Input
                              name="estimateSubtotal"
                              value={parseInt(subtotal)}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              disabled
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-tax"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Tax
                            </label>
                            <Input
                              name="estimateTax"
                              defaultValue="0"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          {/* <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Discount
                            </label>
                            <Input
                              name="estimateDiscount"
                              defaultValue="0"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Paid
                            </label>
                            <Input
                              name="estimatePaid"
                              defaultValue="0"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup> */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-total"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total
                            </label>
                            <Input
                              disabled
                              name="estimateTotal"
                              value={estimateTotal ? estimateTotal.toFixed(2) : 0}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-total"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total Cost
                            </label>
                            <Input
                              disabled
                              name="estimateCost"
                              value={totalCost ? totalCost.toFixed(2) : 0}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-total"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total Profit
                            </label>
                            <Input
                              disabled
                              name="totalProfit"
                              value={totalProfit ? totalProfit.toFixed(2) : 0}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              name="jobName"
                              value={jobName}
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                              width="50%"
                              hidden
                            />
                          </FormGroup>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  className="form-control-alternative"
                                  color="info"
                                  disabled={submitting}
                                >
                                  {isOffline ? 'Save as Draft' : submitting ? 'Saving...' : 'Save'}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={this.state.previewModal} toggle={this.togglePreviewModal} size="lg">
          <ModalHeader toggle={this.togglePreviewModal}> Images loaded</ModalHeader>
          <ModalBody>
            {previewImages && (
              <PreviewFile previewImages={previewImages} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.togglePreviewModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          fullscreen={true}
          size="lg"
        >
          <ModalHeader toggle={this.toggleModal}> Load Documents</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-8">
                <label className="btn btn-default p-0">
                  <input
                    type="file"
                    multiple
                    accept="image/*,application/pdf"
                    onChange={this.selectFiles}
                  />
                </label>
              </div>

              {/* <div className="col-4 -justify-end">
                <button
                  className="btn btn-success btn-sm"
                  disabled={!selectedFiles}
                  onClick={this.uploadImages}
                >
                  Upload
                </button>
              </div> */}
            </div>

            {progressInfos &&
              progressInfos.map((progressInfo, index) => (
                <div className="mb-2" key={index}>
                  <span>{progressInfo.fileName}</span>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      aria-valuenow={progressInfo.percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progressInfo.percentage}%` }}
                    >
                      {progressInfo.percentage}%
                    </div>
                  </div>
                </div>
              ))}

            {previewImages && (
              <PreviewFile previewImages={previewImages} />
            )}

            {message.length > 0 && (
              <div className="alert alert-secondary mt-2" role="alert">
                <ul>
                  {message.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  connection: state.connection,
  items: state.item.items,
  revenueCenter: state.revenueCenter.list,
  userLogged: state.auth.userLogged,
  clients: state.client.clients
});

export default connect(mapStateToProps, { 
  addEstimate, 
  addItem, 
  getItems, 
  getRevenueCenter,
  getClients
})(
  withRouter(AddEstimate)
);
