import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_EXPENSE_SENT = 'FETCH_EXPENSE_SENT';
export const FETCH_EXPENSE_SUCCESS = 'FETCH_EXPENSE_SUCCESS';
export const FETCH_EXPENSE_FAILURE = 'FETCH_EXPENSE_FAILURE';

export const FETCH_EXPENSE_ADD_SUCCESS = 'FETCH_EXPENSE_ADD_SUCCESS';
export const FETCH_EXPENSE_ADD_FAILURE = 'FETCH_EXPENSE_ADD_FAILURE';

export const FETCH_EXPENSE_UPDATE_SUCCESS = 'FETCH_EXPENSE_UPDATE_SUCCESS';
export const FETCH_EXPENSE_UPDATE_FAILURE = 'FETCH_EXPENSE_UPDATE_FAILURE';

export const FETCH_EXPENSE_REMOVE_SUCCESS = 'FETCH_EXPENSE_REMOVE_SUCCESS';
export const FETCH_EXPENSE_REMOVE_FAILURE = 'FETCH_EXPENSE_REMOVE_FAILURE';

export const FETCH_EXPENSE_COUNT_SENT = 'FETCH_EXPENSE_COUNT_SENT';
export const FETCH_EXPENSE_COUNT_SUCCESS = 'FETCH_EXPENSE_COUNT_SUCCESS';
export const FETCH_EXPENSE_COUNT_FAILURE = 'FETCH_EXPENSE_COUNT_FAILURE';


// const shouldGetList = (state, listArg) => {
//   const listInfo = state[listArg];
//   if (!listInfo || !listInfo.expenses) {
//     return true;
//   } else if ((new Date().getTime() - listInfo.lastUpdated) > 500) {
//     return true;
//   } else {
//     return listInfo.didInvalidate;
//   }
// }

export const addExpense = data => async dispatch => {
  try {
    const response = await authService.addExpense(data);
    dispatch({
      type: FETCH_EXPENSE_ADD_SUCCESS,
      payload: response.data.expense,
    });
  } catch (err) {
    dispatch({ type: FETCH_EXPENSE_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updateExpense = (id, data) => async dispatch => {
  try {

    const response = await authService.updateExpense(id, data);

    dispatch({
      type: FETCH_EXPENSE_UPDATE_SUCCESS,
      payload: { id, data: response.data.expense },
    });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_EXPENSE_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removeExpense = id => async dispatch => {
  try {
    await authService.removeExpense(id);
    dispatch({ type: FETCH_EXPENSE_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_EXPENSE_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};

export const getExpenses = (id, options) => async (dispatch, getState) => {
  dispatch({ type: FETCH_EXPENSE_SENT }); // get old
  try {
    const response = await authService.getExpenses(id);
    dispatch({
      type: FETCH_EXPENSE_SUCCESS
      , payload: response && response.data && response.data.expenses
      , receivedAt: new Date().getTime()
    });
  } catch (err) {
    dispatch({ type: FETCH_EXPENSE_FAILURE, payload: err && err.message });
    console.error(err);
  }
};

export const getExpenseCount = (id) => async (dispatch, getState) => {
  dispatch({ type: FETCH_EXPENSE_COUNT_SENT }); // get old
  try {
    const response = await authService.getExpenseCount();
    dispatch({
      type: FETCH_EXPENSE_COUNT_SUCCESS
      , count: response.data.count
      , receivedAt: new Date().getTime()
    });
  } catch (err) {
    dispatch({ type: FETCH_EXPENSE_COUNT_FAILURE, payload: err.message });
    console.error(err);
  }
};


export const sendReconciled = data => async dispatch => {
  try {
    const response = await authService.sendEstimatesReconciled(data);
    dispatch({ type: FETCH_EXPENSE_SUCCESS, payload: response.data.expenses });
    return response;
  } catch (err) {
    dispatch({ type: FETCH_EXPENSE_FAILURE, payload: err.message });
    console.error(err);
    return err;
  }
};

export const uploadAndAddExpense = data => async dispatch => {
  try {
    const response = await authService.uploadAndAddExpense(data);
    response.data.expense.image = data.image;
    response.data.expense.viewingFrom = "input-file";
    dispatch({
      type: FETCH_EXPENSE_ADD_SUCCESS,
      payload: response.data.expense,
    });
  } catch (err) {
    dispatch({ type: FETCH_EXPENSE_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};