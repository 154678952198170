import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Form
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { useSelector, useDispatch } from 'react-redux';


import { addPropertyStage, updatePropertyStage } from '../../../../redux/actions/propertyAction';

// import { getJobs } from '../../redux/actions/jobAction';
// import { getUsers } from '../../redux/actions/userAction';
// import { addProperty } from '../../redux/actions/propertyAction';

// import { STAGE_OPTIONS, PROPERTY_TYPE_OPTIONS } from './constants';



const AddUpdatePropertyStage = props => {


    const { history, match } = props;

    const currentId = match.params.id || null;

    const dispatch = useDispatch();

    const { property } = useSelector(state => {
        return {
            auth: state.auth,
            property: state.property
        }
    });

    const [isSaving, setIsSaving] = useState(false);

    const [propertyDetails, setPropertyDetails] = useState(null);

    useEffect(() => {
        if (currentId) {
            const currentPropertyStage = property.propertyStages.find(item => item._id === currentId);
            setPropertyDetails(currentPropertyStage);
        }
    }, [currentId])

    //   state = {
    //     jobName: '',
    //     nameWorker: '',
    //     date: `${ano}-${mes}-${dia}`,
    //   };

    const handleInput = e => {

        const { name, value } = e.target;
        setPropertyDetails({
            ...(propertyDetails || {}),
            [name]: value
        })
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        const payload = {
            ...(propertyDetails || {}),
            stage: propertyDetails.stage,
            order: parseInt(propertyDetails.order)
        };

        if (currentId) {
            dispatch(updatePropertyStage(payload));
            setIsSaving(false);
        }
        else {
            dispatch(addPropertyStage(payload));
            setIsSaving(false);

            setTimeout(() => {
                history.push(`/admin/propertysettings/stages`);
            },1500);
        }

    };


    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">{currentId ? 'Update' : 'Add'} Property Stage</h3>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <div className="pl-lg-4">

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Name
                                                    </label>

                                                    <Input
                                                        name="stage"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.stage}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Order
                                                    </label>

                                                    <Input
                                                        name="order"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.order}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Button
                                                        disabled={isSaving}
                                                        className="form-control-alternative"
                                                        color="info"
                                                    >
                                                        {isSaving ? 'Please Wait...' : 'Save'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


}


export default withRouter(AddUpdatePropertyStage);