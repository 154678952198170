import {
    SET_CONNECTION_STATUS, SET_SYNCING
} from '../actions/connectionAction';
// import { merger, adder, updater, remover } from './actionReducers';

const initialState = {
    status: true,
    isSyncing: false,
};

export const connectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONNECTION_STATUS:
            return {
                ...state,
                status: action.status
            }
        case SET_SYNCING:
            return {
                ...state,
                isSyncing: action.status
            }
        default:
            return state;
    }
};
