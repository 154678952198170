import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_REVENUE_CENTER_SENT = 'FETCH_REVENUE_CENTER_SENT';
export const FETCH_REVENUE_CENTER_SUCCESS = 'FETCH_REVENUE_CENTER_SUCCESS';
export const FETCH_REVENUE_CENTER_FAILURE = 'FETCH_REVENUE_CENTER_FAILURE';

export const FETCH_REVENUE_CENTER_ADD_SENT = 'FETCH_REVENUE_CENTER_ADD_SENT';
export const FETCH_REVENUE_CENTER_ADD_SUCCESS = 'FETCH_REVENUE_CENTER_ADD_SUCCESS';
export const FETCH_REVENUE_CENTER_ADD_FAILURE = 'FETCH_REVENUE_CENTER_ADD_FAILURE';

export const FETCH_REVENUE_CENTER_UPDATE_SENT = 'FETCH_REVENUE_CENTER_UPDATE_SENT';
export const FETCH_REVENUE_CENTER_UPDATE_SUCCESS = 'FETCH_REVENUE_CENTER_UPDATE_SUCCESS';
export const FETCH_REVENUE_CENTER_UPDATE_FAILURE = 'FETCH_REVENUE_CENTER_UPDATE_FAILURE';

export const FETCH_REVENUE_CENTER_REMOVE_SENT = 'FETCH_REVENUE_CENTER_REMOVE_SENT';
export const FETCH_REVENUE_CENTER_REMOVE_SUCCESS = 'FETCH_REVENUE_CENTER_REMOVE_SUCCESS';
export const FETCH_REVENUE_CENTER_REMOVE_FAILURE = 'FETCH_REVENUE_CENTER_REMOVE_FAILURE';



export const getRevenueCenter = () => async (dispatch, getState) => {
    dispatch({ type: FETCH_REVENUE_CENTER_SENT }); // get old
    try {
      const response = await authService.getRevenueCenter();
      dispatch({ 
        type: FETCH_REVENUE_CENTER_SUCCESS
        , payload: response.data.revenue_center
        , receivedAt: new Date().getTime()
      });
    } catch (err) {
      dispatch({ type: FETCH_REVENUE_CENTER_FAILURE, payload: err && err.message });
      console.error(err);
    }
};

export const addRevenueCenter = data => async dispatch => {
  dispatch({ type: FETCH_REVENUE_CENTER_ADD_SENT });
  try {
 
    const response = await authService.addRevenueCenter(data);
    dispatch({ type: FETCH_REVENUE_CENTER_ADD_SUCCESS, payload: response.data.revenue_center });
    dispatch(getRevenueCenter());
  } catch (err) {
    dispatch({ type: FETCH_REVENUE_CENTER_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updateRevenueCenter = (data) => async dispatch => {
  dispatch({ type: FETCH_REVENUE_CENTER_UPDATE_SENT });
  try {
    const response = await authService.updateRevenueCenter(data);
    dispatch({
      type: FETCH_REVENUE_CENTER_UPDATE_SUCCESS,
      payload: { data: response.data.revenue_center },
    });
    dispatch(getRevenueCenter());
  } catch (err) {
    dispatch({ type: FETCH_REVENUE_CENTER_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removeRevenueCenter = id => async dispatch => {
  dispatch({ type: FETCH_REVENUE_CENTER_REMOVE_SENT });
  try {
    await authService.deleteRevenueCenter(id);
    dispatch({ type: FETCH_REVENUE_CENTER_REMOVE_SUCCESS, payload: { id } });
    dispatch(getRevenueCenter());
  } catch (err) {
    dispatch({ type: FETCH_REVENUE_CENTER_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};
