import localforage from 'localforage';
import { nanoid } from 'nanoid'


const draftInstance = localforage.createInstance({
    driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: 'drafts',
});

export const saveAsDraft = async (type, data) => {
    try {
        let currentDraft = await draftInstance.getItem(type) || [];
        // currentDraft = currentDraft ? JSON.parse(currentDraft) : [];

        let currentData = {
            ...data
        };

        if (currentData.draft_id) {

            const currentIndex = currentDraft.findIndex(item => item.draft_id === currentData.draft_id);
            if (currentIndex > -1) {
                currentDraft[currentIndex] = {
                    ...currentData
                }
            }
            else {

                currentData = {
                    ...currentData,
                    draft_id: `${nanoid()}`
                };
                currentDraft.push({
                    ...currentData
                });
            }


        }
        else {
            currentData = {
                ...currentData,
                draft_id: `${nanoid()}`
            }
            currentDraft.push({
                ...currentData
            });
        }

        await draftInstance.setItem(type, currentDraft);
        return currentData;
    } catch (err) {
        console.error('error', err)
    }
};


export const getDrafts = async type => {
    // let currentDraft = localStorage.getItem(type);
    // currentDraft = currentDraft ? JSON.parse(currentDraft) : [];

    let currentDraft = await draftInstance.getItem(type) || [];


    return currentDraft || [];
};

export const getDraftById = async (type, draftId) => {
    let currentDraft = await draftInstance.getItem(type) || [];
    // currentDraft = currentDraft ? JSON.parse(currentDraft) : [];
    const selectedDraft = currentDraft.find(item => item.draft_id === draftId);
    return selectedDraft;
};


export const removeDraftById = async (type, draftId) => {
    let currentDraft = await getDrafts(type);

    let updatedDrafts = currentDraft.filter(item => item.draft_id !== draftId);
    await draftInstance.setItem(type, updatedDrafts);
    return updatedDrafts;
};


