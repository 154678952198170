import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_PAYMENT_ACCOUNT_SENT = 'FETCH_PAYMENT_ACCOUNT_SENT';
export const FETCH_PAYMENT_ACCOUNT_SUCCESS = 'FETCH_PAYMENT_ACCOUNT_SUCCESS';
export const FETCH_PAYMENT_ACCOUNT_FAILURE = 'FETCH_PAYMENT_ACCOUNT_FAILURE';

export const FETCH_PAYMENT_ACCOUNT_ADD_SENT = 'FETCH_PAYMENT_ACCOUNT_ADD_SENT';
export const FETCH_PAYMENT_ACCOUNT_ADD_SUCCESS = 'FETCH_PAYMENT_ACCOUNT_ADD_SUCCESS';
export const FETCH_PAYMENT_ACCOUNT_ADD_FAILURE = 'FETCH_PAYMENT_ACCOUNT_ADD_FAILURE';

export const FETCH_PAYMENT_ACCOUNT_UPDATE_SENT = 'FETCH_PAYMENT_ACCOUNT_UPDATE_SENT';
export const FETCH_PAYMENT_ACCOUNT_UPDATE_SUCCESS = 'FETCH_PAYMENT_ACCOUNT_UPDATE_SUCCESS';
export const FETCH_PAYMENT_ACCOUNT_UPDATE_FAILURE = 'FETCH_PAYMENT_ACCOUNT_UPDATE_FAILURE';

export const FETCH_PAYMENT_ACCOUNT_REMOVE_SENT = 'FETCH_PAYMENT_ACCOUNT_REMOVE_SENT';
export const FETCH_PAYMENT_ACCOUNT_REMOVE_SUCCESS = 'FETCH_PAYMENT_ACCOUNT_REMOVE_SUCCESS';
export const FETCH_PAYMENT_ACCOUNT_REMOVE_FAILURE = 'FETCH_PAYMENT_ACCOUNT_REMOVE_FAILURE';



export const getPaymentAccounts = () => async (dispatch, getState) => {
    dispatch({ type: FETCH_PAYMENT_ACCOUNT_SENT }); // get old
    try {
      const response = await authService.getPaymentAccounts();
      dispatch({ 
        type: FETCH_PAYMENT_ACCOUNT_SUCCESS
        , payload: response.data.paymentAccounts
        , receivedAt: new Date().getTime()
      });
      
    } catch (err) {
      dispatch({ type: FETCH_PAYMENT_ACCOUNT_FAILURE, payload: err && err.message });
      console.error(err);
    }
};

export const addPaymentAccount = data => async dispatch => {
  dispatch({ type: FETCH_PAYMENT_ACCOUNT_ADD_SENT });
  try {
 
    const response = await authService.addPaymentAccount(data);
    dispatch({ type: FETCH_PAYMENT_ACCOUNT_ADD_SUCCESS, payload: response.data.revenue_center });
    dispatch(getPaymentAccounts());
  } catch (err) {
    dispatch({ type: FETCH_PAYMENT_ACCOUNT_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updatePaymentAccount = (data) => async dispatch => {
  dispatch({ type: FETCH_PAYMENT_ACCOUNT_UPDATE_SENT });
  try {
    const response = await authService.updatePaymentAccount(data);
    dispatch({
      type: FETCH_PAYMENT_ACCOUNT_UPDATE_SUCCESS,
      payload: { data: response.data.revenue_center },
    });
    dispatch(getPaymentAccounts());
  } catch (err) {
    dispatch({ type: FETCH_PAYMENT_ACCOUNT_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removePaymentAccount = id => async dispatch => {
  dispatch({ type: FETCH_PAYMENT_ACCOUNT_REMOVE_SENT });
  try {
    await authService.deletePaymentAccount(id);
    dispatch({ type: FETCH_PAYMENT_ACCOUNT_REMOVE_SUCCESS, payload: { id } });
    dispatch(getPaymentAccounts());
  } catch (err) {
    dispatch({ type: FETCH_PAYMENT_ACCOUNT_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};
