import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    Card,
    CardBody,
    Col,
  } from 'reactstrap';
import { getInvoiceById } from '../../../redux/actions/invoiceAction';


const CheckoutSuccess = (props) => {
    const paramsId = props.match.params.id;

    useEffect(() => {
    
        setTimeout(function(){
            window.location.href = process.env.REACT_APP_SITE_URL;
    
        }, 3000);
    
      }, []);

return (
    <>
    <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
            <h1>Nothing to invoice!</h1>
            <h5>redirecting to the main page...</h5>
            </div>
        </CardBody>
        </Card>
    </Col>
    </>

);

};

const mapStateToProps = state => ({
    invoice: state.invoice.invoice
  });


  export default connect(mapStateToProps, { getInvoiceById })(
    withRouter(CheckoutSuccess)
  );
