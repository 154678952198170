import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import {
//     Button,
//     Input,
//     ModalHeader,
//     ModalBody,
//     ModalFooter,
//     Modal,
// } from 'reactstrap'

import { setConnectionStatus, setSyncing } from '../redux/actions/connectionAction';
import { addEstimate, updateEstimate } from '../redux/actions/jobAction';


import { getDrafts, removeDraftById } from './draft';



import AuthService from '../services/services';


const authService = new AuthService();

const InternetConnectionStatus = props => {
    const { setConnectionStatus } = props;
    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [, setLoading] = useState(false);
    const [hideOnlineBar, setHideOnlineBar] = useState(false);



    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            if (navigator.onLine) {
                checkEstimatesDraft();
            }

            setIsOnline(navigator.onLine);
            setConnectionStatus(navigator.onLine)
            setHideOnlineBar(false);


            setTimeout(() => {
                setHideOnlineBar(true);
            }, 5000)
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    useEffect(() => {

        setTimeout(() => {
            setHideOnlineBar(true);
        }, 5000)
    }, []);

    const checkEstimatesDraft = async () => {
        let estimates = await getDrafts('estimates');
        // estimates = estimates;// .filter(item => item.syncFiles);
        // setEstimateDrafts(estimates);

        handleSyncEstimateDrafts(estimates)
    };

    const handleSyncEstimateDrafts = async estimateDrafts => {
   
        if (estimateDrafts.length > 0) {
            setLoading(true);
            props.setSyncing(true);
            // setIsModalVisible(false);
            for (let item of estimateDrafts) {

                try {
                    const currentEstimate = item;

                    let payload = {
                        ...currentEstimate,

                    };

                    if (currentEstimate.selectedFiles) {
                        const imageResponse = await authService.upload(currentEstimate.selectedFiles[0], currentEstimate.selectedFiles[0].type);
                        payload = {
                            ...payload,
                            imgs: [...(payload.imgs || []), imageResponse.data.img]
                        };
                        delete payload.files;
                        await removeDraftById('estimates', payload.draft_id);

                        if (payload._id) {
                            await props.updateEstimate(payload._id, payload, 'estimates');
                        }
                        else {
                            await props.addEstimate(payload, 'estimates');
                        }


                    }
                    else {
                        await removeDraftById('estimates', payload.draft_id);
                        if (payload._id) {

                            await props.updateEstimate(payload._id, payload, 'estimates');
                        }
                        else {
                            if(payload) {
                                await props.addEstimate(payload, 'estimates');      
                            }
                          
                        }
                    }

                } catch (err) {
                    console.error('Error', err)
                }
                finally {
                    props.setSyncing(false);
                }

            }
            setLoading(false);

        }

    }



    // const handleSyncEstimateDrafts = async e => {


    //     if (selectedEstimateDrafts.length > 0) {
    //         setLoading(true);
    //         setIsModalVisible(false);
    //         for (let item of selectedEstimateDrafts) {

    //             try {
    //                 const currentEstimate = item;

    //                 let payload = {
    //                     ...currentEstimate,

    //                 };

    //                 if (currentEstimate.selectedFiles) {
    //                     const imageResponse = await authService.upload(currentEstimate.selectedFiles[0], currentEstimate.selectedFiles[0].type);
    //                     payload = {
    //                         ...payload,
    //                         imgs: [...(payload.imgs || []), imageResponse.data.img]
    //                     };
    //                     delete payload.files;
    //                     await removeDraftById('estimates', payload.draft_id);

    //                     if (payload._id) {
    //                         await props.updateEstimate(payload._id, payload, 'estimates');
    //                     }
    //                     else {
    //                         await props.addEstimate(payload, 'estimates');
    //                     }


    //                 }
    //                 else {
    //                     await removeDraftById('estimates', payload.draft_id);
    //                     if (payload._id) {

    //                         await props.updateEstimate(payload._id, payload, 'estimates');
    //                     }
    //                     else {
    //                         if(payload) {
    //                             await props.addEstimate(payload, 'estimates');      
    //                         }
                          
    //                     }
    //                 }

    //             } catch (err) {
    //                 console.log('Error', err)
    //             }

    //         }
    //         setLoading(false);

    //     }

    // }



    // const handleModalChange = () => {
    //     setIsModalVisible(!isModalVisible);
    // };


    // const handleSelectEstimateDraftId = data => e => {

    //     if (e.target.checked) {
    //         setSelectedEstimateDrafts([...(selectedEstimateDrafts || []), data]);
    //     }
    //     else {
    //         const updatedSelectedEstimateDrafts = selectedEstimateDrafts.filter(item => item.draft_id !== data.draft_id);
    //         setSelectedEstimateDrafts([...(updatedSelectedEstimateDrafts || [])]);
    //     }
    // }


    return <>

        {/* <Modal
            isOpen={isModalVisible}
            toggle={setIsModalVisible}
            size={'lg'}
        >
            <ModalHeader toggle={handleModalChange}>
                {' '}
                Estimate Drafts
            </ModalHeader>
            <ModalBody>
                {estimateDrafts.map((item) => {

                    return <div style={{ paddingLeft: 12 }}>
                        <Input
                            style={{ marginBottom: 5 }}
                            type="checkbox"
                            //   value={ex._id}
                            className="btn"
                            onChange={handleSelectEstimateDraftId(item)}

                        />
                        <a href={`/admin/addestimate?draftId=${item.draft_id}`}>
                            <span style={{ marginTop: 5, marginLeft: 8 }}>{item.name} - {item.address}</span>
                        </a>

                    </div>
                })}

            </ModalBody>
            <ModalFooter>

                <Button onClick={handleModalChange}>
                    Close
                </Button>
                <Button
                    type="button"
                    disabled={!selectedEstimateDrafts.length}
                    color="primary"
                    onClick={handleSyncEstimateDrafts}>
                    {isLoading ? 'Syncing...' : 'Sync With Server'}
                </Button>
            </ModalFooter>
        </Modal> */}
        {isOnline ?
            (!hideOnlineBar && <div className={`-dark-mode -connection-normal`}>
                <span>
                    {/* <img src={onlineIcon} /> */}
                    <i className="fa fa-wifi " style={{ color: '#75f900', marginRight: 8 }} />
                    You are now online
                </span>
            </div>)
            :
            (!hideOnlineBar && <div className={`-dark-mode -connection-warning`}>
                <span>
                    <i className="fa fa-wifi " style={{ color: 'red', marginRight: 8 }} />
                    You are currently working offline
                </span>
            </div>)}

    </>

}


const mapStateToProps = state => ({
    connection: state.connection
});

export default connect(mapStateToProps, { addEstimate, updateEstimate, setConnectionStatus, setSyncing })(
    InternetConnectionStatus
);

