import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import axios from 'axios';
import { authReducer } from './reducers/authReducer';
import { userReducer } from './reducers/userReducer';
import { clientReducer } from './reducers/clientReducer';
import { jobReducer } from './reducers/jobReducer';
import { timeReducer } from './reducers/timeReducer';
import { invoiceReducer } from './reducers/invoiceReducer';
import { expenseReducer } from './reducers/expenseReducer';
import { itemReducer } from './reducers/itemReducer';
// import { searchReducer } from './reducers/searchReducer';
import { propertiesReducer } from './reducers/propertyReducer';
import { revenueCenterReducer } from './reducers/revenueCenterReducer';
import { connectionReducer } from './reducers/connectionReducer';
import { paymentAccountReducer } from './reducers/paymentAccountReducer';
import { permissionReducer } from './reducers/permissionReducer';
import { paymentReducer } from './reducers/paymentReducers';
import Global from '../global';

const env = Global.urlEnvironment;
const { version } = Global;

const effect = (effect, _action) => axios(effect);
const discard = async (error, _action, _retries) => {
  const { request, response } = error;
  if (!request) throw error; // There was an error creating the request
  if (!response) return false; // There was no response
  return response.status >= 400 && response.status < 500;
};

localforage.config({
  name: `GA:${env}`,
  version,
  storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
  description: 'GA database',
});

const authPersistConfig = {
  key: 'ga:auth',
  storage: localforage,
  whitelist: ['auth'],
};

const clientPersistConfig = {
  key: 'ga:client',
  storage: localforage,
};

const userPersistConfig = {
  key: 'ga:user',
  storage: localforage,
};

const jobsPersistConfig = {
  key: 'ga:job',
  storage: localforage,
};

const timesPersistConfig = {
  key: 'ga:time',
  storage: localforage,
};

const invoicesPersistConfig = {
  key: 'ga:invoice',
  storage: localforage,
};

const expensesPersistConfig = {
  key: 'ga:expense',
  storage: localforage,
};

const itemsPersistConfig = {
  key: 'ga:item',
  storage: localforage,
};

const revenueCenterPersistConfig = {
  key: 'ga:revenueCenter',
  storage: localforage,
};

// const searchPersistConfig = {
//   key: 'ga:search',
//   storage: localforage,
// };


const propertiesPersistConfig = {
  key: 'ga:property',
  storage: localforage,
};

const connectionPersistConfig = {
  key: 'ga:connection',
  storage: localforage,
};

const paymentAccountPersistConfig = {
  key: 'ga:paymentAccount',
  storage: localforage,
};

const permissionPersistConfig = {
  key: 'ga:permission',
  storage: localforage,
};

const paymentPersistConfig = {
  key: 'ga:payment',
  storage: localforage,
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
  effect,
  discard,
});

const reducer = combineReducers({
  auth: authReducer,
  user: persistReducer(userPersistConfig, userReducer),
  client: persistReducer(clientPersistConfig, clientReducer),
  job: persistReducer(jobsPersistConfig, jobReducer),
  time: persistReducer(timesPersistConfig, timeReducer),
  invoice: persistReducer(invoicesPersistConfig, invoiceReducer),
  expense: persistReducer(expensesPersistConfig, expenseReducer),
  item: persistReducer(itemsPersistConfig, itemReducer),
  revenueCenter: persistReducer(revenueCenterPersistConfig, revenueCenterReducer),
  // search: persistReducer(searchPersistConfig, searchReducer),
  property: persistReducer(propertiesPersistConfig, propertiesReducer),
  connection: persistReducer(connectionPersistConfig, connectionReducer),
  paymentAccount: persistReducer(paymentAccountPersistConfig, paymentAccountReducer),
  defPermission: persistReducer(permissionPersistConfig, permissionReducer),
  stripePayment: persistReducer(paymentPersistConfig, paymentReducer),
});

const persistedReducer = persistReducer(
  authPersistConfig,
  offlineEnhanceReducer(reducer)
);

export default function configureStore() {


  const config = {
    // Only 'TOGGLE_TODO' will be triggered in other tabs
    // whitelist: ['expense'],
    // enforce a type, oneOf['native', 'idb', 'localstorage', 'node']
    broadcastChannelOption: { type: 'localstorage' },
};

  const middlewares = [createStateSyncMiddleware(config)];
  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      offlineEnhanceStore,
      applyMiddleware(thunk, offlineMiddleware,...middlewares)
    )
  );

  initMessageListener(store);
  const persistor = persistStore(store);

  return { persistor, store };
}
