import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Badge,
    Button
} from 'reactstrap';

import Header from '../../components/Headers/Header';
import { useEffect } from 'react';

// REDUX
import { getPaymentAccounts, removePaymentAccount } from '../../redux/actions/paymentAccountAction';
import { permissions } from '../../helpers/utils';


// /admin/updateproperty/${props.item._id}
const ActionButton = props => {
    return (
        <UncontrolledDropdown>
            <DropdownToggle>...</DropdownToggle>
            <DropdownMenu
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => ({
                            ...data,
                            styles: {
                                ...data.styles,
                                overflow: 'auto',
                                maxHeight: 130,
                            },
                        }),
                    },
                }}
            >
            {
                permissions(props.props.userLogged, 'paymentAccounts', 'update', 0) ?
                <DropdownItem to={`/admin/paymentaccounts/update/${props.data && props.data._id}`} tag={Link}>
                    Update
                </DropdownItem>
            :
                <DropdownItem disabled to={`/admin/paymentaccounts/update/${props.data && props.data._id}`} tag={Link}>
                    Update
                </DropdownItem>
            }
            {
                permissions(props.props.userLogged, 'paymentAccounts', 'delete', 0) ?
                <DropdownItem
                    onClick={() => {
                        if (window.confirm("Are you sure, you want to delete this item?") && props.data) {
                            props.deletePaymentAccount(props.data._id);
                        }
                    }}
                >
                    <span className="text-danger">Delete</span>
                </DropdownItem>
            :
                <DropdownItem disabled to={`/admin/paymentaccounts/update/${props.data && props.data._id}`} tag={Link}>
                    Delete
                </DropdownItem>
            }

            </DropdownMenu>
        </UncontrolledDropdown>
    );
}


const PaymentAccounts = props => {

    const dispatch = useDispatch();

    const { paymentAccount, users } = useSelector(state => {
        return {
            paymentAccount: state.paymentAccount,
            users: state.user.users
        }
    });


    useEffect(() => {
        dispatch(getPaymentAccounts());
        // dispatch(getUsers());
    }, [])

    const handleDeletePaymentAccount = id => {
        dispatch(removePaymentAccount(id));
    }
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">

                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Payment Accounts</h3>
                                    </div>
                                    <div className="col text-right">
                                        {
                                            permissions(props.userLogged, 'paymentAccounts', 'create', 0) ?
                                            <Button color="primary" type="button" onClick={() => window.location.href = '/admin/paymentaccounts/create'}>
                                                Add Payment Account
                                            </Button>
                                                : null
                                        }
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Table style={{ width: '100%' }} className="align-items-center table-flush" responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>Users</th>

                                                {/* <th>Date Created</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentAccount.list.map((item) => {
                                                const defUsers = users.filter(user => user.defaultPaymentAccount ===  item._id).map(userData => { return {value: userData._id, label: userData.name}});



                                                return <tr>

                                                    <td style={{ width: 80 }}>
                                                        <ActionButton
                                                            deletePaymentAccount={handleDeletePaymentAccount}
                                                            data={item}
                                                            props={props}
                                                        ></ActionButton>

                                                    </td>
                                                    <td>{item && item.name}</td>
                                                    <td>{item && item.users !== undefined && item.users !== null ? item.users.concat(defUsers).map(user => {return <><Badge style={{ fontSize: '12px' }} color="info">{user.label }</Badge>{' '} </>}) : defUsers ? defUsers.map(user => {return <><Badge style={{ fontSize: '12px' }} color="info">{user.label }</Badge>{' '} </>}) : ''}</td>


                                                </tr>
                                            })}

                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


};

const mapStateToProps = state => ({
    userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps)(
    withRouter(PaymentAccounts)
);