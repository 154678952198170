import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-places-autocomplete';
import { useSelector } from 'react-redux';

import {
  Alert,
} from 'reactstrap';
import { Suggestion } from "./Suggestion";
import {
  Input
} from 'reactstrap';
/**
 * Component for Google PlacesAutocomplete
 * 
 */


const getCityAndState = places => {
  const city = places.find(place => place.types.includes('locality'));
  const state = places.find(place => place.types.includes('administrative_area_level_1'));
  const zip = places.find(place => place.types.includes('postal_code'));
  
  return {
    city: city && city.long_name,
    state: state && state.long_name,
    zip: zip && zip.long_name,
  }
}
export const GooglePlacesAutocomplete = ({required = false,value, onSelect}) => {
  const { connection } = useSelector(state => {
    return {
      connection: state.connection
    }
});

  const [ error, setError ] = useState();
  const [ address, setAddress ] = useState(value);
 

  useEffect(() => { 
    setAddress(value)
  }, [value]);

  const handleSelect = async (address, placeId) => {
    const [place] = await geocodeByPlaceId(placeId);
    const { formatted_address } = place;
    const selectedAddress = formatted_address ? formatted_address : address;
    let places = null;
    if(place) {
      places = getCityAndState(place.address_components);
    }


    setAddress(selectedAddress);
    onSelect(selectedAddress, places);
    setError(null);
  }

  const handleInput = async e => {
    e.persist();
    setAddress(e.target.value);
    onSelect(e.target.value);
  };

  const handleAutoCompleteChange = value => {
    setAddress(value)
    onSelect(value);
  };

  if (connection && !connection.status) {
    return (
      <Input
        value={address}
        className="form-control-alternative"
        placeholder="Address"
        name="address"
        type="address"
        required={required}
        onChange={handleInput}
      />
    )
  } else {
    return (
      <>
        <PlacesAutocomplete
          value={address}
          onChange={handleAutoCompleteChange}
          onSelect={handleSelect}
          debounce={500}
          onError={(err) => setError(err)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {

            return (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Address',
                    className:
                      'form-control-alternative form-control',
                  })}
                />
                <div className="form-control-alternative">
                  {loading && <div>Loading...</div>}
                  <div>
                    {suggestions.map(suggestion => {
                      return <Suggestion key={suggestion.index} suggestion={suggestion} getSuggestionItemProps={getSuggestionItemProps} />
                    })}
                  </div>
                </div>
              </div>
            )
          }}
        </PlacesAutocomplete>
        {error && <Alert className="mt-2" color="warning">{error} - please check the console</Alert>}
      </>
    )
  }
}
