import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_WORKER_SENT = 'FETCH_WORKER_SENT';
export const FETCH_WORKER_SUCCESS = 'FETCH_WORKER_SUCCESS';
export const FETCH_WORKER_FAILURE = 'FETCH_WORKER_FAILURE';

export const FETCH_WORKER_ADD_SENT = 'FETCH_WORKER_ADD_SENT';
export const FETCH_WORKER_ADD_SUCCESS = 'FETCH_WORKER_ADD_SUCCESS';
export const FETCH_WORKER_ADD_FAILURE = 'FETCH_WORKER_ADD_FAILURE';

export const FETCH_WORKER_UPDATE_SENT = 'FETCH_WORKER_UPDATE_SENT';
export const FETCH_WORKER_UPDATE_SUCCESS = 'FETCH_WORKER_UPDATE_SUCCESS';
export const FETCH_WORKER_UPDATE_FAILURE = 'FETCH_WORKER_UPDATE_FAILURE';

export const FETCH_WORKER_REMOVE_SENT = 'FETCH_WORKER_REMOVE_SENT';
export const FETCH_WORKER_REMOVE_SUCCESS = 'FETCH_WORKER_REMOVE_SUCCESS';
export const FETCH_WORKER_REMOVE_FAILURE = 'FETCH_WORKER_REMOVE_FAILURE';

const shouldGetList = (state, listArg) => {
  const listInfo = state[listArg];
  if (!listInfo || !listInfo.users) {
    return true;
  } else if ((new Date().getTime() - listInfo.lastUpdated) > 50) {
    return true;
  } else {
    return listInfo.didInvalidate;
  }
}

export const getUsers = () => async (dispatch, getState) => {
  if (shouldGetList(getState(), 'user')) {
    dispatch({ type: FETCH_WORKER_SENT }); // get old
    try {
      const response = await authService.getUsers();
      dispatch({ 
        type: FETCH_WORKER_SUCCESS
        , payload: response.data.users
        , receivedAt: new Date().getTime()
      });
    } catch (err) {
      dispatch({ type: FETCH_WORKER_FAILURE, payload: err && err.message });
      console.error(err);
    }
  } else {
    return new Promise((resolve) => {
      resolve({
        type: FETCH_WORKER_SUCCESS
      })
    });
  }
  dispatch({ type: FETCH_WORKER_SENT }); // get old
  try {
    const response = await authService.getUsers();
    dispatch({
      type: FETCH_WORKER_SUCCESS
      , payload: response.data.users
      , receivedAt: new Date().getTime()
    });
  } catch (err) {
    dispatch({ type: FETCH_WORKER_FAILURE, payload: err && err.message });
    console.error(err);
  }
};

export const addUser = data => async dispatch => {
  dispatch({ type: FETCH_WORKER_ADD_SENT });
  try {
    const response = await authService.addWorker(data);
    dispatch({ type: FETCH_WORKER_ADD_SUCCESS, payload: response.data.user });
  } catch (err) {
    dispatch({ type: FETCH_WORKER_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updateUser = (id, data) => async dispatch => {
  dispatch({ type: FETCH_WORKER_UPDATE_SENT });
  try {
    const response = await authService.updateWorker(id, data);
    dispatch({
      type: FETCH_WORKER_UPDATE_SUCCESS,
      payload: { id, data: response.data.user },
    });
  } catch (err) {
    dispatch({ type: FETCH_WORKER_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removeUser = id => async dispatch => {
  dispatch({ type: FETCH_WORKER_REMOVE_SENT });
  try {
    await authService.deleteWorker(id);
    dispatch({ type: FETCH_WORKER_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_WORKER_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};
