
import React from "react";

import CheckoutForm from "./CheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const PaymentForm = props => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);


  return (
    <>
      <Elements stripe={stripePromise} >
      <CheckoutForm
        onSuccessfulCheckout={() => console.log('successful payment')}
      />
        </Elements>

      </>
  );
};

export default PaymentForm;
