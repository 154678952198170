import React from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import Global from '../../global';
import { getItems, removeItem } from '../../redux/actions/itemAction';
import { reset } from '../../redux/actions/searchAction';
import { permissions } from '../../helpers/utils';
import LoadBar from '../../components/shared/loadBar';

const ActionButton = props => (
  <UncontrolledDropdown>
    <DropdownToggle>...</DropdownToggle>
    <DropdownMenu>
      {
        permissions(props.userLogged, 'items', 'update', 3) ?
          <DropdownItem to={`/admin/items/update/${props._id}`} tag={Link}>
            Update
          </DropdownItem>
          : 
          <DropdownItem disabled to={`/admin/items/update/${props._id}`} tag={Link}>
            Update
          </DropdownItem>
      }
      {
        permissions(props.userLogged, 'items', 'delete', 4) ?
          <DropdownItem
            onClick={() => {
              props.removeItem(props._id);
              alert('Item Delete');
            }}
          >
            <span className="text-danger">Delete</span>
          </DropdownItem>
          : 
          <DropdownItem disabled to={`/admin/items/update/${props._id}`} tag={Link}>
            Delete
          </DropdownItem>
      }
    </DropdownMenu>
  </UncontrolledDropdown>
);

class Items extends React.Component {
  state = {
    item: '',
    modal: false,
  };

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < Global.mobileWidth,
    }));
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    await this.props.getItems();
    // await this.props.reset();
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  async componentDidMount() {
    if (!this.props.userLogged) return this.props.history.push('/auth/login');

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    await this.props.getItems();
    await this.props.reset();
  }

  openItemModal = id => {
    const item = this.props.items.filter(item => item._id === id)[0];
    this.setState(prevState => ({
      ...prevState,
      item,
      modal: true,
    }));
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { items } = this.props;
    if (!this.state) return <LoadBar />;
    let itemsFilter = items;
    // if (search !== '') {
    //   itemsFilter = itemsFilter.filter(
    //     item =>
    //       JSON.stringify(item).toLowerCase().indexOf(search.toLowerCase()) > 0
    //   );
    // }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Information</h3>
                    </div>
                    {
                      permissions(this.props.userLogged, 'items', 'create', 3) ?
                        <Button color="primary" type="button" onClick={() => window.location.href = 'additem'}>
                          Create Item
                        </Button>
                        : null
                    }
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {!this.state.isMobileVersion ? (
                        <>
                          <th scope="col"></th>
                          <th scope="col">Item</th>
                          <th scope="col">Description</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Rate</th>
                        </>
                      ) : (
                        <>
                          <th>Item</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {itemsFilter.length === 0 ? (
                      <tr>
                        <td>No items register</td>
                      </tr>
                    ) : (
                      itemsFilter.map((e, i) => (
                        <tr key={e._id} className="details-row">
                          {!this.state.isMobileVersion ? (
                            <>
                                <td>
                                  <ActionButton
                                    {...e}
                                    removeItem={this.props.removeItem}
                                    userLogged={this.props.userLogged}
                                  />
                                </td>
                              <td
                                onClick={() => {
                                  this.openItemModal(e._id);
                                }}
                              >
                                {e.item}
                              </td>
                              <td
                                onClick={() => {
                                  this.openItemModal(e._id);
                                }}
                              >
                                {e.description}
                              </td>
                              <td
                                onClick={() => {
                                  this.openItemModal(e._id);
                                }}
                              >
                                {e.quantity}
                              </td>
                              <td
                                onClick={() => {
                                  this.openItemModal(e._id);
                                }}
                              >
                                {e.rate}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                Item: {e.item}
                                <br />
                                Description: {e.description}
                                <br />
                                Quantity: <b>{e.payRate}</b>
                                <br />
                                Rate: <b>{e.effectiveRate}</b>
                                <br />
                                <div className="buttonfloat-right buttonfloat-right-jobs">
                                  <ActionButton
                                    {...e}
                                    removeItem={this.props.removeItem}
                                    userLogged={this.props.userLogged}
                                  />
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size={'lg'}>
          <ModalHeader toggle={this.toggleModal}>
            {' '}
            <h1>Item details ({this.state.item.item})</h1>
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                Item: {this.state.item.item}
                <br />
                Description: {this.state.item.description}
                <br />
                Quantity: <b>{this.state.item.quantity}</b>
                <br />
                Rate: <b>{this.state.item.rate}</b>
                <br />
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  items: state.item.items,
  userLogged: state.auth.userLogged
});

export default connect(mapStateToProps, { getItems, removeItem, reset })(Items);
