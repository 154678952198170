import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Form
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../redux/actions/userAction';
import Select from '../../../node_modules/react-select';


import { addPaymentAccount, updatePaymentAccount } from '../../redux/actions/paymentAccountAction';



const AddUpdatePaymentAccounts = props => {

    const { history, match } = props;
    const currentId = match.params.id || null;
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const dispatch = useDispatch();
    const { paymentAccount } = useSelector(state => {
        return {
            paymentAccount: state.paymentAccount
        }
    });

    const [isSaving, setIsSaving] = useState(false);
    const [paymentAccountDetails, setPaymentAccountDetails] = useState(null);

    useEffect(() => {
        props.getUsers();


        if (props.users && props.users.length > 0) {
            const usersArray = props.users.map(user => { return {value: user._id, label: user.name, defaultPaymentAccount: user.defaultPaymentAccount ? user.defaultPaymentAccount : null} });
            setUserOptions(usersArray);
            if (currentId) {
                const currentPaymentAccount = paymentAccount.list.find(item => item._id === currentId);
                if (currentPaymentAccount) {
                    const defUsers = usersArray.filter(item => item.defaultPaymentAccount ===  currentPaymentAccount._id);
                    setPaymentAccountDetails(currentPaymentAccount);
                    setSelectedUsers(currentPaymentAccount && currentPaymentAccount.users !== undefined && currentPaymentAccount.users !== null ? currentPaymentAccount.users.concat(defUsers) : defUsers );
                
                }

            }
        }


    }, [currentId])


    const handleInput = e => {

        const { name, value } = e.target;
        setPaymentAccountDetails({
            ...(paymentAccountDetails || {}),
            [name]: value
        })
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        const payload = {
            ...(paymentAccountDetails || {}),
            name: paymentAccountDetails.name,
            users: selectedUsers
        };

        if (currentId) {
            dispatch(updatePaymentAccount(payload));

            setTimeout(() => {
                setIsSaving(false);
            },1500);
        }
        else {
            dispatch(addPaymentAccount(payload));

            setTimeout(() => {
                history.push(`/admin/paymentaccounts`);
            },1500);
        }

    };

    return (
        <>
        <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">{currentId ? 'Update' : 'Add'} Payment Account</h3>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <div className="pl-lg-4">

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Name
                                                    </label>

                                                    <Input
                                                        name="name"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        onChange={handleInput}
                                                        value={paymentAccountDetails && paymentAccountDetails.name ? paymentAccountDetails.name : ''}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Users
                                                    </label>
                                                <div style={{width: '500px'}}>
                                                    <Select
                                                        isMulti
                                                        name="userOptions"
                                                        options={userOptions}
                                                        className="basic-multi-select form-control-alternative"
                                                        classNamePrefix="select"
                                                        placeholder='Select Users'
                                                        onChange={(e) => setSelectedUsers(e)}
                                                        value={selectedUsers ? selectedUsers : []}
                                                        />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row>
  
                                        </Row> */}


                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Button
                                                        disabled={isSaving}
                                                        className="form-control-alternative"
                                                        color="info"
                                                    >
                                                        {isSaving ? 'Please Wait...' : 'Save'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


}

const mapStateToProps = state => ({
    users: state.user.users,
  });


export default connect(mapStateToProps, { getUsers })(withRouter(AddUpdatePaymentAccounts));