import React from 'react';
import { withRouter } from 'react-router-dom';
import { get, isArray } from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { WithContext as ReactTags } from 'react-tag-input';
import { connect } from 'react-redux';

import AuthService from '../../services/services';
import { toggleSpinner } from '../../helpers/display';
import { find } from 'lodash';
import LoadBar from '../../components/shared/loadBar';

const toastr = require("toastr");

const authService = new AuthService();

const fecha = new Date();
let mes = fecha.getMonth() + 1;
let dia = fecha.getDate();
const ano = fecha.getFullYear();
if (dia < 10) dia = `0${dia}`; // agrega cero si es menor de 10
if (mes < 10) mes = `0${mes}`; // agrega cero si es menor de 10

class SendInvoice extends React.Component {
  state = {
    workerId: '',
    date: `${ano}-${mes}-${dia}`,
    name: '',
    email: '',
    address: '',
    phone: '',
    jobName: '',
    tags: [],
    invoiceDescription: '',
    invoiceTotal: 0,
    urlPay: '',
    submitting: false,
    items: [],
    invoiceNumber: '0'
  };

  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidMount() {
    if (this.props.invoices.length === 0)
      this.props.history.push(`/admin/invoices`);
    const invoice = this.props.invoices.filter(
      item => item._id === this.props.match.params.invoiceId
    )[0];

    this.setState(prevState => {
      const job = this.props.jobs.filter(job => job._id === invoice.jobId)[0];
      const client = this.props.clients.filter(
        client => client._id === job.clientId
      )[0];
      const nameJobArray = job.jobName.split('-');
      if (nameJobArray.length > 1) {
        if (nameJobArray[1] === '' && nameJobArray[1] === null && nameJobArray[1] === undefined) {
          job.jobName = `${job.jobName} - ${job.jobAddress}`
        }
      }
      return {
        ...prevState,
        workerId: this.props.userLogged._id,
        invoiceId: this.props.match.params.invoiceId,
        jobId: job._id,
        name: `${client && client.firstName} ${client && client.lastName}`,
        email: client && client.email,
        address: job.jobAddress,
        phone: job.estimatePhone,
        jobName: job.jobName,
        invoiceDescription: invoice.invoiceDescription,
        invoiceTotal: invoice.invoiceTotal,
        tags: client && client.email ? [
          {
            id: client.email,
            text: client.email,
          },
        ] : [],
        items: invoice.estimatedItems,
        invoiceNumber: invoice && invoice.invoiceNumber ? invoice.invoiceNumber : this.state.invoiceNumber
      };
    });
  }

  handleInput = e => {
    e.persist();

    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = async (e, props) => {
    e.preventDefault();

    this.setState({ submitting: true });

    const { tags } = this.state;
    // Require an email address
    if(tags.length === 0){
      return toastr.error("Please provide an email address.");
    }
    toggleSpinner();
    const payUrl = process.env.REACT_APP_SITE_URL + "/auth/payment/invoice/" + this.props.match.params.invoiceId;
    // send it to the first email with the first name nothing more
    authService
      .sendInvoice(
      this.state,
      payUrl
    ) 
    .then(response => {
      this.setState({ submitting: false });
      toggleSpinner(false);
      this.props.history.push(`/admin/invoices`);
    })
    .catch(err => {
      this.setState({ submitting: false });
      toggleSpinner(false);
      if(err && err.response){
        console.error(err.response);
        const errors = get(err, 'response.body.errors', [])
        if(isArray(errors) && errors.length > 0){
          toastr.error(`Mailer Error: ${get(errors[0], 'message', 'Unspecified error. Please check the console.')}`)
        }
      }
    });
  };

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    if(tag && tag.id){
      this.setState(state => ({ tags: [...state.tags, tag] }));
    }
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }


  render() {
    const { submitting } = this.state;

    if (!this.state.workerId || this.state.workerId === '')
      return <LoadBar />;
    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Invoice Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Name
                            </label>
                            <Input
                              defaultValue={`${this.state.name}`}
                              className="form-control-alternative"
                              placeholder="Enter the name client"
                              name="name"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Emails
                            </label>
                            <div>
                              <ReactTags
                                placeholder="Add email"
                                tags={this.state.tags}
                                handleDelete={this.handleDelete}
                                handleAddition={this.handleAddition}
                                handleDrag={this.handleDrag}
                                delimiters={this.state.delimiters}
                                handleInputBlur={email => {
                                  const exists = find(this.state.tags, {id: email})
                                  if(!exists){
                                    this.handleAddition({id: email, text: email})
                                  }
                                }}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-jobName"
                            >
                              Job Name
                            </label>
                            <Input
                              name="_id"
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              value={this.state.jobName}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Invoice Date
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Select a date"
                              id="date"
                              value={this.state.date}
                              name="date"
                              type="date"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Total Invoice
                            </label>
                            <Input
                              name="total"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              value={this.state.invoiceTotal}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Description
                            </label>
                            <Input
                              name="invoiceDescription"
                              className="form-control-alternative"
                              placeholder="This is an invoice generated with the items of an estimate"
                              type="text"
                              onChange={this.handleInput}
                              value={this.state.description}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Send
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  invoices: state.invoice.invoices,
  clients: state.client.clients,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, {})(
  withRouter(SendInvoice)
);
