import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_ITEM_SENT = 'FETCH_ITEM_SENT';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAILURE = 'FETCH_ITEM_FAILURE';

export const FETCH_ITEM_ADD_SENT = 'FETCH_ITEM_ADD_SENT';
export const FETCH_ITEM_ADD_SUCCESS = 'FETCH_ITEM_ADD_SUCCESS';
export const FETCH_ITEM_ADD_FAILURE = 'FETCH_ITEM_ADD_FAILURE';

export const FETCH_ITEM_UPDATE_SENT = 'FETCH_ITEM_UPDATE_SENT';
export const FETCH_ITEM_UPDATE_SUCCESS = 'FETCH_ITEM_UPDATE_SUCCESS';
export const FETCH_ITEM_UPDATE_FAILURE = 'FETCH_ITEM_UPDATE_FAILURE';

export const FETCH_ITEM_REMOVE_SENT = 'FETCH_ITEM_REMOVE_SENT';
export const FETCH_ITEM_REMOVE_SUCCESS = 'FETCH_ITEM_REMOVE_SUCCESS';
export const FETCH_ITEM_REMOVE_FAILURE = 'FETCH_ITEM_REMOVE_FAILURE';

const shouldGetList = (state, listArg) => {
  const listInfo = state[listArg];
  if (!listInfo || !listInfo.items) {
    return true;
  } else if ((new Date().getTime() - listInfo.lastUpdated) > 200) {
    return true;
  } else {
    return listInfo.didInvalidate;
  }
}

export const getItems = () => async (dispatch, getState) => {
  if (shouldGetList(getState(), 'item')) {
    dispatch({ type: FETCH_ITEM_SENT }); // get old
    try {
      const response = await authService.getItems();
      dispatch({
        type: FETCH_ITEM_SUCCESS
        , payload: response.data.items
        , receivedAt: new Date().getTime()
      });
    } catch (err) {
      dispatch({ type: FETCH_ITEM_FAILURE, payload: err && err.message });
      console.error(err);
    }
  } else {
    return new Promise((resolve) => {
      resolve({
        type: FETCH_ITEM_SUCCESS
      })
    });
  }
};

export const addItem = data => async dispatch => {
  dispatch({ type: FETCH_ITEM_ADD_SENT });
  try {
    const response = await authService.addItem(data);
    dispatch({ type: FETCH_ITEM_ADD_SUCCESS, payload: response.data.item });
  } catch (err) {
    dispatch({ type: FETCH_ITEM_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updateItem = (id, data) => async dispatch => {
  dispatch({ type: FETCH_ITEM_UPDATE_SENT });
  try {
    const response = await authService.updateItem(id, data);
    dispatch({
      type: FETCH_ITEM_UPDATE_SUCCESS,
      payload: { id, data: response.data.item },
    });
  } catch (err) {
    dispatch({ type: FETCH_ITEM_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removeItem = id => async dispatch => {
  dispatch({ type: FETCH_ITEM_REMOVE_SENT });
  try {
    await authService.deleteItem(id);
    dispatch({ type: FETCH_ITEM_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_ITEM_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};
