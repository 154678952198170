import {
  FETCH_ITEM_SENT,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_REMOVE_SENT,
  FETCH_ITEM_REMOVE_SUCCESS,
  FETCH_ITEM_REMOVE_FAILURE,
  FETCH_ITEM_ADD_FAILURE,
  FETCH_ITEM_ADD_SUCCESS,
  FETCH_ITEM_UPDATE_SUCCESS,
  FETCH_ITEM_UPDATE_FAILURE,
} from '../actions/itemAction';
import { LOG_LOGOUT_USER } from '../actions/authAction';
import { merger, adder, updater, remover } from './actionReducers';

const initialState = {
  items: [],
  didInvalidate: true
};

export const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ITEM_REMOVE_SENT:
    case FETCH_ITEM_SENT:
      return state;
    case LOG_LOGOUT_USER:
      state = initialState;
      return state;
    case FETCH_ITEM_SUCCESS:
      return merger(state, { 
        items: action.payload, 
        itemsErr: undefined ,
        didInvalidate: false,
        lastUpdated: action.receivedAt
      });
    case FETCH_ITEM_FAILURE:
      return merger(state, { itemsEr: action.payload });

    case FETCH_ITEM_ADD_SUCCESS:
      return { ...state, items: adder(state.items, action.payload) };
    case FETCH_ITEM_ADD_FAILURE:
      return merger(state, { itemsEr: action.payload });

    case FETCH_ITEM_UPDATE_SUCCESS:
      const index = state.items.findIndex(u => u._id === action.payload.id);
      return {
        ...state,
        items: updater(state.items, action.payload.data, index),
      };
    case FETCH_ITEM_UPDATE_FAILURE:
      return merger(state, { itemsEr: action.payload });

    case FETCH_ITEM_REMOVE_SUCCESS:
      return { ...state, items: remover(state.items, action.payload.id) };
    case FETCH_ITEM_REMOVE_FAILURE:
      return merger(state, { itemsEr: action.payload });

    default:
      return state;
  }
};
