import Index from 'views/Index.jsx';
import Home from 'views/Home';
import Login from 'views/Login.jsx';

import Estimates from 'views/estimates/Estimates.jsx';
import UpdateEstimate from 'views/estimates/UpdateEstimate.jsx';
import SendEstimate from 'views/estimates/SendEstimate';
import AddEstimate from 'views/estimates/AddEstimate.jsx';

import Jobs from 'views/jobs/Jobs.jsx';
import AddJob from 'views/jobs/AddJob.jsx';
import UpdateJob from 'views/jobs/UpdateJob.jsx';
import AddWorkerJob from 'views/jobs/AddWorkerJob.jsx';
import AddPM from 'views/jobs/AddProjectManager.jsx';

import Invoices from 'views/invoices/Invoices.jsx';
import PayInvoice from 'views/invoices/PayInvoice';
import AddInvoiceByJob from 'views/invoices/AddInvoiceByJob';
import UpdateInvoice from 'views/invoices/UpdateInvoice';
import SendInvoice from 'views/invoices/SendInvoice';

import Expenses from 'views/expenses/Expenses.jsx';
import AddExpense from 'views/expenses/AddExpense.jsx';
import UpdateExpense from 'views/expenses/UpdateExpense';
import AddExpenseByWorker from 'views/expenses/AddExpenseByWorker.jsx';

import Time from 'views/times/Time.jsx';
import AddTime from 'views/times/AddTime.jsx';
import UpdateTime from 'views/times/UpdateTime.jsx';
import AddTimeAllUsers from 'views/times/AddTimeAllUsers';

import Clients from 'views/clients/Clients.jsx';
import AddClient from 'views/clients/AddClient.jsx';

import Workers from 'views/workers/Workers.jsx';
import AddWorker from 'views/workers/AddWorker.jsx';
import UpdateClient from './views/clients/UpdateClient.jsx';
import AddInvoiceByEstimate from './views/invoices/AddInvoiceByEstimate.jsx';
import AddInvoice from './views/invoices/AddInvoice.jsx';
import AddEstimateByUser from './views/estimates/AddEstimateByUser.jsx';
import UpdateWorker from './views/workers/UpdateWorker.jsx';

import Reports from './views/reports/Reports';
import AddReport from './views/reports/AddReport';

import IndexSetting from './views/settings/IndexSetting';
import Items from './views/items/Items';
import AddItem from './views/items/AddItem';
import UpdateItem from './views/items/UpdateItem';


import Properties from './views/properties/Properties';
import AddUpdateProperty from './views/properties/AddUpdateProperty';
// import UpdateProperty from './views/properties/UpdateProperty.jsx';

import PropertySettings from './views/properties/settings/index.js';
import PropertyStages from './views/properties/settings/stages/index.js';
import PropertyTypes from './views/properties/settings/propertytypes/index.js';
import AddUpdatePropertyStage from './views/properties/settings/stages/AddUpdatePropertyStage';
import AddUpdatePropertyTypes from './views/properties/settings/propertytypes/AddUpdatePropertyTypes';

import Lenders from './views/properties/settings/lenders/index.js';
import AddUpdateLenders from './views/properties/settings/lenders/AddUpdateLenders.js';
import RevenueCenter from './views/revenuecenter/index';
import AddUpdateRevenueCenter from './views/revenuecenter/AddUpdateRevenueCenter';

import PaymentAccounts from './views/paymentaccounts/index.js';
import AddUpdatePaymentAccount from './views/paymentaccounts/AddUpdatePaymentAccount.js';

import DefaultPermissions from './views/userpermissions/index.js';
import AddUpdateDefaultPermission from './views/userpermissions/AddUpdateDefaultPermission.js';

import PaymentPage from './views/payment/PaymentForm.jsx';
import CheckoutSuccess from './views/payment/paymentsuccess/CheckoutSuccess.jsx';
import NoBillablePage from './views/payment/paymentsuccess/NoBillablePage.jsx';


const routes = [
  {
    path: '/sign',
    name: 'Sign',
    icon: 'ni ni-tv-2 text-primary',
    component: Home,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/index',
    name: 'Home',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
  },
  {
    path: '/estimates',
    name: 'Estimates',
    icon: 'ni ni-ruler-pencil text-blue',
    component: Estimates,
    layout: '/admin',
  },
  {
    path: '/:id/invoice',
    name: 'Estimate Invoice',
    icon: 'ni ni-ruler-pencil text-blue',
    component: AddInvoiceByEstimate,
    layout: '/admin/estimates',
    invisible: true,
  },
  {
    path: '/:id/invoice',
    name: 'Job Invoice',
    icon: 'ni ni-ruler-pencil text-blue',
    component: AddInvoiceByJob,
    layout: '/admin/jobs',
    invisible: true,
  },
  {
    path: '/:id',
    name: 'Edit Estimate',
    icon: 'ni ni-ruler-pencil text-blue',
    component: UpdateEstimate,
    layout: '/admin/estimates',
    invisible: true,
  },
  {
    path: '/:id/email',
    name: 'Send Estimate',
    icon: 'ni ni-ruler-pencil text-blue',
    component: SendEstimate,
    layout: '/admin/estimates',
    invisible: true,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    icon: 'ni ni-settings text-gray',
    component: Jobs,
    layout: '/admin',
  },
  {
    path: '/:id',
    name: 'Edit Job',
    icon: 'ni ni-ruler-pencil text-blue',
    component: UpdateJob,
    layout: '/admin/jobs',
    invisible: true,
  },
  {
    path: '/addworker/:id',
    name: 'Add Worker Job',
    icon: 'ni ni-ruler-pencil text-blue',
    component: AddWorkerJob,
    layout: '/admin/jobs',
    invisible: true,
  },
  {
    path: '/addpm/:id',
    name: 'Add PM',
    icon: 'ni ni-ruler-pencil text-blue',
    component: AddPM,
    layout: '/admin/jobs',
    invisible: true,
  },
  {
    path: '/invoices',
    name: 'Invoices',
    icon: 'ni ni-credit-card text-orange',
    component: Invoices,
    layout: '/admin',
  },
  {
    path: '/:invoiceId/email',
    name: 'Send Invoice',
    icon: 'ni ni-ruler-pencil text-blue',
    component: SendInvoice,
    layout: '/admin/invoices',
    invisible: true,
  },
  {
    path: '/:invoiceId',
    name: 'Pay Invoice',
    icon: 'ni ni-credit-card text-orange',
    component: PayInvoice,
    layout: '/admin/invoices',
    invisible: true,
  },
  {
    path: '/:invoiceId/update',
    name: 'Update Invoice',
    icon: 'ni ni-credit-card text-orange',
    component: UpdateInvoice,
    layout: '/admin/invoices',
    invisible: true,
  },
  {
    path: '/:expenseId/update',
    name: 'Update Expense',
    icon: 'ni ni-credit-card text-orange',
    component: UpdateExpense,
    layout: '/admin/expenses',
    invisible: true,
  },
  {
    path: '/expenses',
    name: 'Expenses',
    icon: 'ni ni-money-coins text-info',
    component: Expenses,
    layout: '/admin',
  },
  {
    path: '/time',
    name: 'Time',
    icon: 'ni ni-watch-time text-blue',
    component: Time,
    layout: '/admin',
  },
  {
    path: '/addtime',
    name: 'Add Time General',
    icon: 'ni ni-ruler-pencil text-blue',
    component: AddTimeAllUsers,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/addtime/:estimateId/:id/:workerId',
    name: 'Add Time',
    icon: 'ni ni-ruler-pencil text-blue',
    component: AddTime,
    layout: '/admin/time',
    invisible: true,
  },
  {
    path: '/updatetime/:id',
    name: 'Update Time',
    icon: 'ni ni-ruler-pencil text-blue',
    component: UpdateTime,
    layout: '/admin/time',
    invisible: true,
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: 'ni ni-ruler-pencil text-red',
    component: Reports,
    layout: '/admin',
  },
  {
    path: '/clients',
    name: 'Clients',
    icon: 'ni ni-single-02 text-yellow',
    component: Clients,
    layout: '/admin',
  },
  {
    path: '/update/:id',
    name: 'Update Client',
    icon: 'ni ni-circle-08 text-pink',
    component: UpdateClient,
    layout: '/admin/clients',
    invisible: true,
  },
  {
    path: '/estimatecreate/:id',
    name: 'Create Estimate by User',
    icon: 'ni ni-circle-08 text-pink',
    component: AddEstimateByUser,
    layout: '/admin/clients',
    invisible: true,
  },
  {
    path: '/workers',
    name: 'Users',
    icon: 'ni ni-circle-08 text-pink',
    component: Workers,
    layout: '/admin',
  },
  {
    path: '/properties',
    name: 'Properties',
    icon: 'ni ni-single-copy-04 text-green',
    component: Properties,
    layout: '/admin',
  },

  {
    path: '/addproperty',
    name: 'AddProperty',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdateProperty,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/updateproperty/:id',
    name: 'UpdateProperty',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdateProperty,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/propertysettings',
    name: 'PropertySettings',
    icon: 'ni ni-single-copy-04 text-green',
    component: PropertySettings,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/propertysettings/stages',
    name: 'PropertyStages',
    icon: 'ni ni-single-copy-04 text-green',
    component: PropertyStages,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/propertysettings/stages/create',
    name: 'PropertyStages',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdatePropertyStage,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/propertysettings/stages/update/:id',
    name: 'PropertyStages',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdatePropertyStage,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/propertysettings/types',
    name: 'PropertyTypes',
    icon: 'ni ni-single-copy-04 text-green',
    component: PropertyTypes,
    layout: '/admin',
    invisible: true,
  },
  
  {
    path: '/propertysettings/types/create',
    name: 'PropertyTypes',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdatePropertyTypes,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/propertysettings/types/update/:id',
    name: 'PropertyTypes',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdatePropertyTypes,
    layout: '/admin',
    invisible: true,
  },

  
  {
    path: '/propertysettings/lenders',
    name: 'PropertyTypes',
    icon: 'ni ni-single-copy-04 text-green',
    component: Lenders,
    layout: '/admin',
    invisible: true,
  },
  
  {
    path: '/propertysettings/lenders/create',
    name: 'PropertyTypes',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdateLenders,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/propertysettings/lenders/update/:id',
    name: 'PropertyTypes',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdateLenders,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/paymentaccounts',
    name: 'PaymentAccounts',
    icon: 'ni ni-single-copy-04 text-green',
    component: PaymentAccounts,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/paymentaccounts/create',
    name: 'AddUpdatePaymentAccount',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdatePaymentAccount,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/paymentaccounts/update/:id',
    name: 'AddUpdatePaymentAccount',
    icon: 'ni ni-single-copy-04 text-green',
    component: AddUpdatePaymentAccount,
    layout: '/admin',
    invisible: true,
  },



  
  
  {
    path: '/settings',
    name: 'Settings',
    icon: 'ni ni-settings text-black',
    component: IndexSetting,
    layout: '/admin',
  },
  {
    path: '/items',
    name: 'Items',
    component: Items,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/additem',
    name: 'Add Item',
    component: AddItem,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/update/:id',
    name: 'Update Item',
    component: UpdateItem,
    layout: '/admin/items',
    invisible: true,
  },
  

  {
    path: '/revenuecenter',
    name: 'Items',
    component: RevenueCenter,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/revenuecenter/update/:id',
    name: 'Update Revenue Center',
    component: AddUpdateRevenueCenter,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/revenuecenter/create',
    name: 'Add Revenue Center',
    component: AddUpdateRevenueCenter,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/update/:id',
    name: 'Update Worker',
    icon: 'ni ni-circle-08 text-pink',
    component: UpdateWorker,
    layout: '/admin/workers',
    invisible: true,
  },
  {
    path: '/addclient',
    component: AddClient,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/addestimate',
    component: AddEstimate,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/:id/addexpense',
    component: AddExpense,
    layout: '/admin/jobs',
    invisible: true,
  },
  {
    path: '/addexpense',
    component: AddExpenseByWorker,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/createinvoice',
    component: AddInvoice,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/addjob',
    component: AddJob,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/addworker',
    component: AddWorker,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/addreport',
    component: AddReport,
    layout: '/admin',
    invisible: true,
  },

  {
    path: '/defaultpermission',
    component: DefaultPermissions,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/create-default-permission',
    component: AddUpdateDefaultPermission,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/create-default-permission/:id',
    component: AddUpdateDefaultPermission,
    layout: '/admin',
    invisible: true,
  },
  // {
  //   path: '/create-default-permission/assign/:id',
  //   component: AddUpdateDefaultPermission,
  //   layout: '/admin',
  //   invisible: true,
  // },
  



  {
    path: '/login',
    component: Login,
    layout: '/auth',
    invisible: true,
  },

  {
    path: '/payment/invoice/:id',
    component: PaymentPage,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/payment/success/:id',
    component: CheckoutSuccess,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/payment/no-bill/:id',
    component: NoBillablePage,
    layout: '/auth',
    invisible: true,
  }
  


];
export default routes;
