import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { isEqual } from 'lodash';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Button,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from 'reactstrap';
// core components

import { connect } from 'react-redux';
import CardBody from 'reactstrap/es/CardBody';
// import { Carousel } from 'react-responsive-carousel';
import { Document, Page } from 'react-pdf';
import {
  getJobs,
  getEstimates,
  convertJob,
  decline,
  removeEstimate,
  duplicateEstimate,
} from '../../redux/actions/jobAction';
import Header from '../../components/Headers/Header';
import { getClients } from '../../redux/actions/clientAction';
import { reset } from '../../redux/actions/searchAction';
import { compareValues } from '../../global';
import AuthService from '../../services/services';
import PreviewFile from '../../helpers/PreviewFile';
import { permissions } from '../../helpers/utils';
import { fileToBase64 } from '../../helpers/file';

import { getDrafts, removeDraftById } from '../../helpers/draft';
import LoadBar from '../../components/shared/loadBar';


const authService = new AuthService();


const ActionButton = ({ jobsFilter = [], item = {}, props, handleRemoveDraft, existingDraft }) => {
  const isDraft = item && item.draft_id;
  return (
    <span className="dropdownButtons">
      <UncontrolledDropdown>
        <DropdownToggle>...</DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: data => ({
                ...data,
                styles: {
                  ...data.styles,
                  overflow: 'auto',
                  maxHeight: '280px',
                },
              }),
            },
          }}
        >
          <DropdownItem
            to={`/admin/estimates/${item._id}/invoice`}
            tag={Link}
          >
            Convert to Invoice
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              props.convertJob(item._id, item);
              props.history.push(`jobs`);
            }}
          >
            Approve
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              props.decline(item._id, item);
            }}
          >
            Decline
          </DropdownItem>
          <DropdownItem
            to={`/admin/estimates/${item._id}/email`}
            tag={Link}
          >
            Send by email
          </DropdownItem>
          {
            permissions(props.userLogged, 'estimates', 'update', 2, item.workers) ?
              <DropdownItem to={isDraft ? `/admin/addestimate?draftId=${item.draft_id}` : `/admin/estimates/${item._id}?${existingDraft ? `draftId=${existingDraft.draft_id}` : ''}`} tag={Link}>
                Update
              </DropdownItem>
              :
              <DropdownItem disabled to={`/admin/estimates/${item._id}`} tag={Link}>
                Update
              </DropdownItem>
          }
          <DropdownItem
            onClick={() => {
              const estimateCopies = jobsFilter.filter(est => est.jobName.includes(item.jobName));
              const payload = {
                ...item,
                jobName: `${item.jobName} (Copy ${estimateCopies.length})`

              }
              props.duplicateEstimate(payload);
              alert('Estimate Duplicated');
            }}
          >
            Duplicate
          </DropdownItem>
          {
            permissions(props.userLogged, 'estimates', 'delete', 4, item.workers) ?
              <DropdownItem onClick={() => {

                if (isDraft) {
                  handleRemoveDraft(item.draft_id);
                }
                else {
                  props.removeEstimate(item._id);
                  alert('Estimate Deleted');
                }
              }}
              >
                <span className="text-danger">Delete</span>
              </DropdownItem>
              :
              <DropdownItem disabled to="/" tag={Link}>
                Delete
              </DropdownItem>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </span>
  );
}

class Estimates extends React.Component {
  state = {
    drafts: [],
    existingDrafts: [],
    pageNumber: 0,
    numPages: 0,
    currentPage: 0,
    pageSize: 50,
    pagesCount: 0,
    id: '',
    btnDropup: false,
    userId: false,
    isMobileVersion: false,
    estimates: [],
    buttonActive: '5',
    modal: false,
    modalExpense: false,
    estimate: {
      workers: [],
      expenses: [],
      totalExpenses: 0,
      invoices: [],
      totalInvoices: 0,
      items: [],
      client: {},
    },
    totalTime: 0,
    totalEffective: 0,
    time: [],
    trNewRecord: 'tr-new-record',
  };

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < 1024,
    }));
  };

  async componentWillUnmount() {
    const {getClients} = this.props;
    window.removeEventListener('resize', this.updateWindowDimensions);
    await getClients();
    await getDrafts('estimates');

  }

  async componentDidMount() {
    const {
      userLogged
      , history
      , reset
      , getClients
      , getEstimates
    } = this.props;

    setTimeout(
      function() {
          this.setState({ trNewRecord: '' });
      }
      .bind(this),
      1000
    );
    

    if (!userLogged) return history.push('/auth/login');
    await reset();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    await getClients();


    let drafts = await getDrafts('estimates');
    let newDrafts = drafts.filter(item => !item._id);
    let existingDrafts = drafts.filter(item => item._id);
    this.setState({
      existingDrafts,
      drafts: newDrafts
    });

    // if (localStorage.getItem('internetStatus') != "disconnect") {

    //   // if (!(estimates && estimates.length && !estimates.some(item => !item._id))) {
    //   //   await getEstimates(userLogged._id);
    //   // }
    //   console.log('userLogged',userLogged)
    //   if (userLogged) {
    //     await getEstimates(userLogged._id);
    //   }

    // }
    if (userLogged) {
      await getEstimates(userLogged._id);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { estimates } = this.props;


    if (!isEqual(estimates, prevProps.estimates)) {
      const drafts = await getDrafts('estimates');
      let newDrafts = drafts.filter(item => !item._id);
      let existingDrafts = drafts.filter(item => item._id);
      this.setState({
        existingDrafts,
        drafts: newDrafts
      });
      this.setState({
        drafts: newDrafts,
        existingDrafts
      });
    }


  }

  convertInvoice = _id => {
    authService
      .convertInvoice(_id)
      .then(response => {
        this.props.history.push(`estimates`);
      })
      .catch(err => {
        console.error(err.response);
      });
  };

  openEstimatesModal = async id => {
    let estimate = id;

    // const estimate = this.props.estimates.filter(job => job._id === id)[0];
    // if (id && id.draft_id) {
    //   console.log('yes')
    //   estimate = this.props.estimates.filter(job => job._id === id)[0]; 
    // }

    estimate.client = this.props.clients.filter(
      item => item._id === estimate.clientId
    )[0];
    estimate.expenses = [];
    estimate.invoices = [];
    const workers_ = [];
    if (estimate && estimate.workers && estimate.workers.length > 0) {
      estimate.workers.forEach(worker => {
        worker.time = [];
        this.props.times.forEach(time => {
          if (
            time.userId === worker.workerId &&
            time.jobId === estimate._id
          ) {
            worker.time.push(time);
            worker.user = this.props.users.filter(
              user => user._id === worker.workerId
            )[0];
            workers_[worker.user._id] = worker.user;
            if (!workers_[worker.user._id].times) {
              workers_[worker.user._id].times = [];
            }
            workers_[worker.user._id].times.push(time);

            if (!workers_[worker.user._id].ajobs) {
              workers_[worker.user._id].ajobs = [];
            }
            workers_[worker.user._id].ajobs[estimate._id] = estimate;
          }
        });
      });
    }
    const expenses = this.props.expenses.filter(
      expense => expense.jobId === id
    );
    if (expenses.length > 0) {
      expenses.forEach(expense => {
        estimate.expenses.push(expense);
      });
      estimate.expenses = estimate.expenses.sort(
        compareValues('date', 'desc')
      );
    }
    estimate.totalExpenses = estimate.expenses
      ? estimate.expenses.reduce((acc, cv) => acc + cv.total, 0)
      : 0;

    estimate.estimateTotal = estimate.items
      ? estimate.items.reduce((acc, cv) => acc + cv.subtotal, 0)
      : 0;


    this.props.invoices.forEach(invoice => {
      if (invoice.jobId === estimate._id) {
        estimate.invoices.push(invoice);
      }
    });
    estimate.invoices = estimate.invoices.sort(
      compareValues('invoiceDate', 'desc')
    );
    estimate.totalInvoices = estimate.invoices
      ? estimate.invoices.reduce((acc, cv) => acc + cv.invoiceTotal, 0)
      : 0;

    const previewImages = [];
    if (estimate.imgs.length > 0) {
      estimate.imgs.forEach(item => {
        item = item.replace('http', 'https');
        previewImages.push({ file: item, viewingFrom: "url" });
      });
    }
    // DRAFTS
    else if (estimate && estimate.previewImages) {
      for (let item of estimate.previewImages) {
        let imgUrl = await fileToBase64(item.file);
        previewImages.push({ file: imgUrl, viewingFrom: "url" });
      }

    }
    this.setState(prevState => ({
      ...prevState,
      estimate,
      previewImages,
      modal: true,
    }));
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleExpenseOpenModal = expenseId => {
    const expense = this.props.expenses.filter(
      item => item._id === expenseId
    )[0];
    this.setState(prevState => {
      let img = expense.image;
      if (img !== '') {
        img = img.replace('http', 'https');

      }

      return {
        ...prevState,
        img,
        modalExpense: true,
        extension:
          img === ''
            ? ''
            : img.substring(img.length - 3, img.length).toLowerCase(),
      };
    });
  };

  toggleModalExpense = () => {
    this.setState({
      modalExpense: !this.state.modalExpense,
    });
  };

  getOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '1',
      currentPage: 0,
    }));
  };

  getClose = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '2',
      currentPage: 0,
    }));
  };

  getSent = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '3',
      currentPage: 0,
    }));
  };

  getUnsent = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '4',
      currentPage: 0,
    }));
  };

  getAll = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '5',
      currentPage: 0,
    }));
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  handleRemoveDraft = async id => {

    const drafts = await removeDraftById('estimates', id);
    this.setState({
      drafts
    });

  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState(prevState => ({
      ...prevState,
      numPages,
    }));
  };

  searchFunction = () => {
    var input, filter, table, tr, i;
    input = document.getElementById("estimateSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("estimateTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }
      
      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }
  }

  render() {
    let jobsFilter = [];
    let draftsFilter = [];
    let { drafts, existingDrafts, pagesCount, estimate, trNewRecord } = this.state;
    const { estimates, clients, connection, userLogged } = this.props;

    draftsFilter = drafts;

    if (!this.state || !userLogged) return <LoadBar />;
    switch (this.state.buttonActive) {
      case '1':
        jobsFilter = estimates.filter(job => job.status === 'Approve');
        break;
      case '2':
        jobsFilter = estimates.filter(job => job.status === 'Closed');
        break;
      case '3':
        jobsFilter = estimates.filter(job => job.status === 'Sent');
        break;
      case '4':
        jobsFilter = estimates.filter(job => job.status === 'Unsent');
        break;
      default:
        jobsFilter = estimates;
        break;
    }

    jobsFilter.sort((a, b) => b && a && new Date(b.createdAt) - new Date(a.createdAt))

    pagesCount = Math.ceil(jobsFilter.length / this.state.pageSize);

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                  <div className="col">
                      {/* <FormGroup className="mb-0"> */}
                        <InputGroup className="input-group-alternative search-margin">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='search-style'
                            placeholder="Search"
                            type="text"
                            id="estimateSearchInput"
                            onChange={this.searchFunction}
                          />
                        </InputGroup>
                      {/* </FormGroup> */}
                    </div>
                    <div className="col text-right">
                    {
                      permissions(userLogged, 'estimates', 'create', 2) ?
                      <Button color="primary" type="button" onClick={() => window.location.href = 'addestimate'}>
                        Create Estimate
                      </Button>
                        : null
                    }
                    </div>
                    
                  </Row>
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Estimates</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Form className="card-header">
                  <Row form>
                    <Col md={{ size: 12 }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-dateStart"
                        >
                          Filter
                        </label>
                        <br />
                        <span>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '1'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getOpen}
                          >
                            {!this.state.isMobileVersion ? (
                              'Open'
                            ) : (
                              <small>Open</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '2'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getClose}
                          >
                            {!this.state.isMobileVersion ? (
                              'Close'
                            ) : (
                              <small>Close</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '3'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getSent}
                          >
                            {!this.state.isMobileVersion ? (
                              'Sent'
                            ) : (
                              <small>Sent</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '4'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getUnsent}
                          >
                            {!this.state.isMobileVersion ? (
                              'Unsent'
                            ) : (
                              <small>Unsent</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '5'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getAll}
                          >
                            {!this.state.isMobileVersion ? (
                              'All'
                            ) : (
                              <small>All</small>
                            )}
                          </Button>
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>

                <Pagination className="pagination-center">
                  <PaginationItem disabled={this.state.currentPage <= 0}>
                    <PaginationLink
                      onClick={e =>
                        this.handleClick(e, this.state.currentPage - 1)
                      }
                      previous
                      href="#"
                    />
                  </PaginationItem>
                  {[...Array(pagesCount)].map((page, i) => (
                    <PaginationItem
                      active={i === this.state.currentPage}
                      key={'pagination-item-'+i}
                    >
                      <PaginationLink
                        onClick={e => this.handleClick(e, i)}
                        href="#"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={this.state.currentPage >= pagesCount - 1}
                  >
                    <PaginationLink
                      onClick={e =>
                        this.handleClick(e, this.state.currentPage + 1)
                      }
                      next
                      href="#"
                    />
                  </PaginationItem>
                </Pagination>

                <Table className="align-items-center table-flush" id="estimateTable" responsive>
                  <thead className="thead-light">
                    <tr>
                      {!this.state.isMobileVersion ? (
                        <>
                          <th scope="col"></th>
                          <th scope="col">Client</th>
                          <th scope="col">Attachment</th>
                          <th scope="col">Date created</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Total</th>
                        </>
                      ) : (
                        <th colSpan="2">Details</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {jobsFilter.length === 0 ? (
                      <tr>
                        <td>No estimates register</td>
                      </tr>
                    ) : (
                      [...(draftsFilter || []), ...(jobsFilter || [])]
                        .slice(
                          this.state.currentPage * this.state.pageSize,
                          (this.state.currentPage + 1) * this.state.pageSize
                        )
                        .map((e, i) => {
                          const client = clients && clients.filter(
                            client => e && (client._id === e.clientId)
                          );
                          const currentDraft = existingDrafts.find(item => item._id === e._id);
    
                          const nameEstimate = e && (e.jobName || (e.name + ' - ' + e.address));
                          const subtotal = e && e.items && e.items.reduce(
                            (acc, current, i) => acc + current.subtotal,
                            0
                          );
                          const tax =
                            (parseInt(e.estimateTax) * subtotal) / 100;
                          const discount = e.estimateDiscount;
                          const paid = e.estimatePaid;
                          const total = !subtotal
                            ? 0
                            : subtotal + tax - discount - paid;
                          return (
                          <>
                          {i === 0 ? 
                            <tr key={"details-row" + i} className={"details-row " + trNewRecord}>
                              {!this.state.isMobileVersion ? (
                                <>
                                  <td>
                                    <ActionButton
                                      existingDraft={currentDraft}
                                      jobsFilter={jobsFilter}
                                      item={e}
                                      props={this.props}
                                      handleRemoveDraft={this.handleRemoveDraft}
                                    ></ActionButton>
                                  </td>
                                  <td
                                    onClick={() => {
                                        this.openEstimatesModal(e);
                                    }}
                                  >
                                    {nameEstimate ||
                                      `${client[0].firstName} ${client[0].lastName}`} {e.draft_id && <span className="badge-pill">Draft</span>}
                                  </td>
                                  <td>
                                    {e.imgs && e.imgs.length >= 1 && <i className="fas fa-image fa-5x"></i> }
                                  </td>
                                  <td
                                    onClick={() => {
                                        this.openEstimatesModal(e);
                                    }}
                                  >
                                    <Moment format={'MMM D, YY'}>
                                      {e.createdAt}
                                    </Moment>
                                  </td>
                                  <td
                                    onClick={() => {
                                        this.openEstimatesModal(e);
                                    }}
                                  >
                                    <Moment format={'MMM D, YY'}>
                                      {e.dateCreate}
                                    </Moment>
                                  </td>
                                  <td
                                    onClick={() => {
                                        this.openEstimatesModal(e);
                                    }}
                                  >
                                    {e.status === 'Approve' && !currentDraft
                                      ? 'Approved'
                                      : currentDraft || e.draft_id ? connection.isSyncing ? 'Syncing...' : 'Draft' : e.status}
                                  </td>
                                  <td
                                    onClick={() => {
                                        this.openEstimatesModal(e);
                                    }}
                                  >
                                    $
                                    {parseFloat(
                                      Math.round(total * 100) / 100
                                    ).toFixed(2)}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <div className="buttonfloat-right">
                                      <ActionButton
                                        item={e}
                                        props={this.props}
                                      ></ActionButton>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() => {
                                        this.openEstimatesModal(e);
                                    }}
                                  >
                                    {nameEstimate ||
                                      `${client[0].firstName} ${client[0].lastName}`}
                                    <br />
                                    <Moment format={'MMM D, YY'}>
                                      {e.dateCreate}
                                    </Moment>
                                    <br />
                                    {e.status === 'Approve'
                                      ? 'Approved'
                                      : e.status}
                                    <br />$
                                    {parseFloat(
                                      Math.round(total * 100) / 100
                                    ).toFixed(2)}
                                    <br />
                                  </td>
                                </>
                              )}
                            </tr>
                          :
                          <tr key={i} className="details-row">
                          {!this.state.isMobileVersion ? (
                            <>
                              <td>
                                <ActionButton
                                  existingDraft={currentDraft}
                                  jobsFilter={jobsFilter}
                                  item={e}
                                  props={this.props}
                                  handleRemoveDraft={this.handleRemoveDraft}
                                ></ActionButton>
                              </td>
                              <td
                                onClick={() => {
                                    this.openEstimatesModal(e);
                                }}
                              >
                                {nameEstimate ||
                                  `${client[0].firstName} ${client[0].lastName}`} {e.draft_id && <span className="badge-pill">Draft</span>}
                              </td>
                              <td>
                                {e && e.imgs && e.imgs.length >= 1 && <i className="fas fa-image fa-5x"></i> }
                              </td>
                              <td
                                onClick={() => {
                                    this.openEstimatesModal(e);
                                }}
                              >
                                <Moment format={'MMM D, YY'}>
                                  {e.createdAt}
                                </Moment>
                              </td>
                              <td
                                onClick={() => {
                                    this.openEstimatesModal(e);
                                }}
                              >
                                <Moment format={'MMM D, YY'}>
                                  {e.dateCreate}
                                </Moment>
                              </td>
                              <td
                                onClick={() => {
                                    this.openEstimatesModal(e);
                                }}
                              >
                                {e.status === 'Approve' && !currentDraft
                                  ? 'Approved'
                                  : currentDraft || e.draft_id ? 'Draft' : e.status}
                              </td>
                              <td
                                onClick={() => {
                                    this.openEstimatesModal(e);
                                }}
                              >
                                $
                                {parseFloat(
                                  Math.round(total * 100) / 100
                                ).toFixed(2)}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <div className="buttonfloat-right">
                                  <ActionButton
                                    item={e}
                                    props={this.props}
                                  ></ActionButton>
                                </div>
                              </td>
                              <td
                                onClick={() => {
                                    this.openEstimatesModal(e);
                                }}
                              >
                                {nameEstimate ||
                                  `${client[0].firstName} ${client[0].lastName}`}
                                <br />
                                <Moment format={'MMM D, YY'}>
                                  {e.dateCreate}
                                </Moment>
                                <br />
                                {e.status === 'Approve'
                                  ? 'Approved'
                                  : e.status}
                                <br />$
                                {parseFloat(
                                  Math.round(total * 100) / 100
                                ).toFixed(2)}
                                <br />
                              </td>
                            </>
                          )}
                        </tr>
                          }

                          </>

                          );
                        })
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size={'lg'}>
          <ModalHeader toggle={this.toggleModal}>
            {' '}
            Estimate details ({estimate.jobName})
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                Client Name:{' '}
                <b>
                  {estimate && estimate.client && estimate.client.firstName}{' '}
                  {estimate && estimate.client && estimate.client.lastName}
                </b>
                <br />
                Client Email: <b>{estimate.client && estimate.client.email}</b>
                <br />
                Phone number: <b>{estimate.estimatePhone}</b>
                <br />
                Address: <b>{estimate.jobAddress}</b>
                <br />
                Internal Comments: <b>{estimate.comments_internal}</b>
                <br />
                External Comments: <b>{estimate.comments}</b>
                <br />
                Status: <b>{estimate.status}</b>
                <br />
                Date create: <b>{estimate.dateCreate}</b>
                <br />
                Subtotal: <b>${estimate.estimateSubtotal}</b>
                <br />
                Tax: <b>${estimate.estimateTax}</b>
                <br />
                Discount: <b>${estimate.estimateDiscount}</b>
                <br />
                Paid: <b>${estimate.estimatePaid}</b>
                <br />
                Total: <b>${estimate.estimateTotal}</b>
                <br />
                {/* Total Cost: <b>${estimate.estimateTotal}</b>
                <br />
                Total Profit: <b>${estimate.estimateTotal}</b> */}
                <br />
                <br />
                <h3>- Items</h3>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"> Item Name </th>
                      <th scope="col"> Description </th>
                      <th scope="col"> Internal Notes </th>
                      <th scope="col"> Quantity </th>
                      <th scope="col"> Cost </th>
                      <th scope="col"> Rate </th>
                      <th scope="col"> Total </th>
                    </tr>
                  </thead>
                  <tbody>
                    {estimate.items &&
                      estimate.items.map((el, i) => (
                        <tr key={'est-items' + i}>
                          <td> {el.itemName} </td>
                          <td> {el.itemDescription} </td>
                          <td> {el.itemInternalNotes} </td>
                          <td> {el.quantity} </td>
                          <td>$ {el.cost} </td>
                          <td>$ {el.rate} </td>
                          <td>$ {el.subtotal} </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {this.state.previewImages && this.state.previewImages.length > 0 ? (
                  <>
                    <br />
                    {<PreviewFile previewImages={this.state.previewImages} />}
                  </>
                ) : <p><br />Empty documents.</p>}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalExpense}
          toggle={this.toggleModalExpense}
          size={'lg'}
        >
          <ModalHeader toggle={this.toggleModalExpense}>
            {' '}
            Expense details{' '}
          </ModalHeader>
          <ModalBody>
            {this.state.img && this.state.extension !== 'pdf' ? (
              <img
                width="100%"
                height="100%"
                src={this.state.img}
                alt="photo_url"
              />
            ) : null}

            {this.state.img && this.state.extension === 'pdf' ? (
              <>
                <Document
                  file={this.state.pdfFile}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={this.state.pageNumber} />
                </Document>
                <p>
                  Page {this.state.pageNumber} of {this.state.numPages}
                </p>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModalExpense}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  estimates: state.job.estimates,
  clients: state.client.clients,
  connection: state.connection,
  userLogged: state.auth.userLogged,
  users: state.user.users,
  expenses: state.expense.expenses,
  invoices: state.invoice.invoices,
  times: state.time.times
});

export default connect(mapStateToProps, {
  getClients,
  getJobs,
  getEstimates,
  convertJob,
  decline,
  removeEstimate,
  duplicateEstimate,
  reset,
})(Estimates);
