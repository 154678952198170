import React from 'react';
import { withRouter } from 'react-router-dom';
import { startCase, toLower } from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Label,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { addUser, getUsers } from '../../redux/actions/userAction';
import { addWorkers } from '../../redux/actions/jobAction';
import { ROLE_ADMIN, ROLE_PROJECT_MANAGER, ROLE_WORKER } from '../../constants';
import LoadBar from '../../components/shared/loadBar';

class AddWorkerJob extends React.Component {
  state = {
    workerIds: [],
    estimate: null,
    submitting: false
  };

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  /**
   * Manages array of worker ids for this job in local state
   * @param {*} e
   */
  handleMultiSelect = e => {
    e.persist();
    let { workerIds } = this.state;
    if (e.target.checked) {
      workerIds.push(e.target.value);
    } else {
      workerIds = workerIds.filter(id => id !== e.target.value);
    }
    this.setState(prevState => ({
      ...prevState,
      workerIds,
    }));
  };

  async componentDidMount() {
    if (this.props.users.length === 0) await this.props.getUsers();
    const estimate = this.props.jobs.filter(
      item => item._id === this.props.match.params.id
    )[0];
    this.setState(prevState => ({
      estimate,
      workerIds:
        estimate && estimate.workers
          ? estimate.workers.map(_w => _w.workerId)
          : [],
    }));
  }

  async componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true });
    await this.props.addWorkers(this.props.match.params.id, {
      ids: this.state.workerIds,
    });
    this.props.history.push(`/admin/jobs`);
    this.setState({ submitting: false });
  };

  render() {
    const { users } = this.props;
    const { estimate, workerIds, submitting } = this.state;
    if (!estimate || users.length === 0) return <LoadBar />;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Worker Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            {users.map((e, i) => {
                              const selected = workerIds.indexOf(e._id) >= 0;
                              const label = `${e.name} (${startCase(
                                toLower(e.role)
                              )})`;
                              return (
                                [
                                  ROLE_ADMIN,
                                  ROLE_PROJECT_MANAGER,
                                  ROLE_WORKER,
                                ].indexOf(e.role) >= 0 && (
                                  <FormGroup check key={e._id}>
                                    <Input
                                      className="form-control-alternative"
                                      type="checkbox"
                                      checked={selected}
                                      name="_ids[]"
                                      value={e._id}
                                      onChange={this.handleMultiSelect}
                                    />
                                    <Label check>{label}</Label>
                                  </FormGroup>
                                )
                              );
                            })}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Update Workers
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user.users,
  jobs: state.job.jobs,
});

export default connect(mapStateToProps, { addUser, getUsers, addWorkers })(
  withRouter(AddWorkerJob)
);
