import React from 'react';


function byteConverter(bytes, decimals, only) {
    const K_UNIT = 1024;
    const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

    if (bytes === 0) return "0 Byte";

    if (only === "MB") return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

    let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];

    return resp;
};

const ProgressBar = props => {
    const { value } = props;
    return <div className="progress" style={{ height: 18 }}> <div
        className="progress-bar progress-bar-info"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${value}%`, paddingTop: 5, paddingBottom: 5 }}
    >
        {value}%
    </div>
    </div>
}


const FileUploadPreview = props => {
    const { handleOpenPreviewModal, handleClearFiles, index, progress, file, hideRemove = false } = props;
    return <div > <div
        onClick={handleOpenPreviewModal}
        style={{
            display: 'inline-block',
            width: 400,
            height: 'auto',
            backgroundColor: progress && progress.percentage === 100 ? '#5cb85c' : 'gray',
            padding: 15,
            borderRadius: 12,
            cursor: 'pointer',
            marginTop: 5
        }}>

        <div>
            {progress && <ProgressBar value={progress.percentage} />}
            <span style={{ paddingLeft: 5, paddingBottom: 5 }}>
                <i class="ni ni-image "></i>  {file && file.name}
            </span>
            <i class="ni ni-check-bold " style={{ float: 'right', marginTop: 5 }}></i>
        </div>
        <div style={{ paddingLeft: 5 }}>
            {byteConverter(file.size, 2, 'MB')}
        </div>
    </div>


        {(progress && progress.percentage === 100  && !hideRemove) && <div style={{
            display: 'inline-block',
            color: 'red',
            paddingLeft: 12,
            cursor: 'pointer'
        }}
            onClick={handleClearFiles(index)}>
            <span>Remove</span>
        </div>}
    </div>
};

export {
    FileUploadPreview
};