import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
  Button,
  UncontrolledCollapse,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import ReactToPdf from 'react-to-pdf';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { getJobs } from '../../redux/actions/jobAction';
import { getInvoices, removeInvoice } from '../../redux/actions/invoiceAction';
import { getUsers } from '../../redux/actions/userAction';
import { reset } from '../../redux/actions/searchAction';
import { permissions } from '../../helpers/utils';
import InvoicePreview from './InvoicePreview';
import LoadBar from '../../components/shared/loadBar';

const ActionButton = props => (
  <div className="dropdownButtons">
    <UncontrolledDropdown>
      <DropdownToggle>...</DropdownToggle>
      <DropdownMenu>
        {props.item.total - props.paid === 0 ? (
          <DropdownItem
            disabled
            to={`/admin/invoices/${props.item._id}`}
            tag={Link}
          >
            Accept Payment
          </DropdownItem>
        ) : (
          <DropdownItem to={`/admin/invoices/${props.item._id}`} tag={Link}>
            Accept Payment
          </DropdownItem>
        )}
        {
          permissions(props.props.userLogged, 'invoices', 'update', 2, props.item) ?
            <DropdownItem to={`/admin/invoices/${props.item._id}/update`} tag={Link}>
              Update
            </DropdownItem>
            :
            <DropdownItem disabled to={`/admin/invoices/${props.item._id}/update`} tag={Link}>
              Update
            </DropdownItem>
        }
        <DropdownItem to={`/admin/invoices/${props.item._id}/email`} tag={Link}>
          Send by email
        </DropdownItem>
        {
          permissions(props.props.userLogged, 'invoices', 'delete', 4, props.item) ?
            <DropdownItem onClick={() => {
              props.props.removeInvoice(props.item._id);
              alert('Invoice Delete ');
            }}
            >
              <span className="text-danger">Delete</span>
            </DropdownItem>
            :
            <DropdownItem disabled to="/" tag={Link}>
              Delete
            </DropdownItem>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  </div>
);

const ActionDropDown = props => (
  <UncontrolledCollapse toggler={`#toggle${props.item._id}`}>
    {!props.isMobileVersion ? (
      <Card>
        <CardBody>
          <h3>
            INV-{props.invoiceIndex ? props.invoiceIndex.toString().padStart(7, 0) : '0000000'} | {props.item.jobName}
          </h3>
          <br />
          <h3>- Payments</h3>
          <div onClick={() => {
            props.togglePdfPreview(props.item)
          }}
            style={{
              paddingBottom: 5,
              paddingTop: 5,
              cursor: 'pointer',
              color: 'blue'
            }}
          >Download Invoice Preview</div>
          <Table
            className="align-items-center table-flush col-md-6 col-xs-12"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Payment</th>
                <th scope="col">Date</th>
                <th scope="col">Total</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            <tbody>
              {!props.item.payments || (props.item.payments && props.item.payments.length === 0) ? (
                <tr>
                  <td>No payments register</td>
                </tr>
              ) : (
                props.item.payments.map((e, i) => {
                  const paymentIndex = i + 1;
                  return (
                    <tr key={i}>
                      <td>Payment # {paymentIndex}</td>
                      <td>
                        <Moment format={'MMM D, YY'}>{ new Date(e.paidDate)}</Moment>
                      </td>
                      <td align="right">
                      ${' '}
                        {isNaN(
                          parseFloat(
                            Math.round(e.paidAmount * 100) / 100
                          ).toFixed(2)
                        )
                          ? 0
                          : parseFloat(
                            Math.round(e.paidAmount * 100) / 100
                          ).toFixed(2)}
                      </td>
                      <td align="right">
                        ${' '}
                        {parseFloat(
                          Math.round((e.invoiceBalance !== undefined ? e.invoiceBalance : e.invoiceTotal) * 100) /
                          100
                        ).toFixed(2)}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
          <br />
          <br />
          <h3>- Invoice Items</h3>
          <Table
            className="align-items-center table-flush col-md-6 col-xs-12"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Item Name</th>
                <th scope="col">Item Description</th>
                <th scope="col">Quantity</th>
                <th scope="col">Rate</th>
                <th scope="col">Cost</th>
                <th scope="col">Sub Total</th>
              </tr>
            </thead>
            <tbody>
              {props.item.estimatedItems === null || props.item.estimatedItems === undefined || props.item.estimatedItems.length === undefined || props.item.estimatedItems.length === 0 ? (
                <tr>
                  <td>No Line Items Registered</td>
                </tr>
              ) : (
                props.item.estimatedItems.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td align="left">
                        {e.itemName}
                      </td>
                      <td align="left">
                        {e.itemDescription}
                      </td>
                      <td align="left">
                        {e.quantity}
                      </td>
                      <td align="left">
                        {e.rate}
                      </td>
                      <td align="left">
                        {e.cost}
                      </td>
                      <td align="left">
                        {e.subtotal}
                      </td>
                    </tr>
                  );
                })
              )}

              <tr><th></th><th></th><th></th><th></th><th></th><th> Total: {props.item.invoiceTotal} </th></tr>


            </tbody>
          </Table>
        </CardBody>
      </Card>
    ) : (
      <>
        <div className="col-md-12">
          <b>
            INV-{props.invoiceIndex ? props.invoiceIndex.toString().padStart(7, 0) : 'No Invoice Number'} | {props.item.jobName}
          </b>
        </div>
        <br />
        <Table className="align-items-center table-flush col-xs-12">
          <thead className="thead-light">
            <tr>
              <th scope="col">Payments</th>
            </tr>
          </thead>
          <tbody>
            {props.item.payments === undefined || props.item.payments.length === undefined || props.item.payments.length === 0 ? (
              <tr>
                <td>No payments register</td>
              </tr>
            ) : (
              props.item.payments.map((e, i) => {
                const paymentIndex = i + 1;
                return (
                  <tr key={i}>
                    <td>
                      Payment # {paymentIndex}
                      <br />
                      <b>
                        <Moment format={'MMM D, YY'}>{new Date(e.paidDate)}</Moment>
                      </b>{' '}
                      <br />
                      Total:{' '}
                      <b>
                        ${' '}
                        {isNaN(
                          parseFloat(
                            Math.round(e.paidAmount * 100) / 100
                          ).toFixed(2)
                        )
                          ? 0
                          : parseFloat(
                            Math.round(e.paidAmount * 100) / 100
                          ).toFixed(2)}
                      </b>
                      , Balance:{' '}
                      <b>
                        ${' '}
                        {parseFloat(
                          Math.round((e.invoiceBalance !== undefined ? e.invoiceBalance : e.invoiceTotal) * 100) /
                          100
                        ).toFixed(2)}
                      </b>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </>
    )}
  </UncontrolledCollapse>
);

const RowInvoice = props => (
  <>
    {!props.isMobileVersion ? (
      <>
        <tr key={props.item._id} className="details-row">
          <td style={{ width: '100px' }}>
            <ActionButton {...props}></ActionButton>
          </td>
          <td>
            <Button id={`toggle${props.item._id}`} color="primary">
              <i className="ni ni-bold-down"></i>
            </Button>
          </td>
          <td onClick={() => props.openModal(props.item._id)}>
            {props.item.jobName}
            <br />
            <small>
              {!props.nameClient ? 'Client Delete' : props.nameClient}
            </small>
          </td>
          <td onClick={() => props.openModal(props.item._id)}>
            <Moment
              date={new Date(props.item.invoiceDate)}
              format={'MMM D, YYYY'}
            />
          </td>
          <td onClick={() => props.openModal(props.item._id)}>
            {props.total2 === 0 ? 'Paid' : props.item.invoiceStatus}
          </td>
          <td onClick={() => props.openModal(props.item._id)}>
            $
            {parseFloat(
              Math.round(props.item.invoiceTotal * 100) / 100
            ).toFixed(2)}
          </td>
          <td onClick={() => props.openModal(props.items._id)}>
            ${parseFloat(Math.round(props.total2 * 100) / 100).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td colSpan={7}>
            <ActionDropDown {...props}></ActionDropDown>
          </td>
        </tr>
      </>
    ) : (
      <>
        <tr>
          <td>
            <Moment
              date={new Date(props.item.invoiceDate)}
              format={'MMM D, YYYY'}
            />
            <br />
            Job: <b>{props.item.jobName}</b>
            <br />
            Client:{' '}
            <b>{!props.nameClient ? 'Client Delete' : props.nameClient}</b>
            <br />
            Status:{' '}
            <b>
              {props.total2 === 0
                ? 'Paid'
                : props.item.invoiceStatus}
            </b>
            <br />
            Total:{' '}
            <b>
              $
              {parseFloat(
                Math.round(props.item.invoiceTotal * 100) / 100
              ).toFixed(2)}
            </b>
            <br />
            Balance:{' '}

            <b>${parseFloat(Math.round(props.total2 * 100) / 100).toFixed(2)}</b>
            <br />
            <div className="buttonfloat-right buttonfloat-right-estimates">
              <ActionButton {...props}></ActionButton>
            </div>
            <Button id={`toggle${props.item._id}`} color="primary">
              <i className="ni ni-bold-down"></i>
            </Button>
          </td>
        </tr>
        <tr>
          <td className="tdMobile">
            <ActionDropDown {...props}></ActionDropDown>
          </td>
        </tr>
      </>
    )}
  </>
);

class Invoices extends React.Component {

  pdfRef = React.createRef();

  state = {
    id: '',
    btnDropup: false,
    modal: false,
    pdfModal: false,
    invoicePreview: null,
    invoice: {
      job: {},
      user: {},
    },
  };

  openModal = id => {
    const invoice = this.props.invoices.filter(
      invoice => invoice._id === id
    )[0];
    const job = this.props.jobs.filter(job => job._id === invoice.jobId)[0];
    let user = this.props.users.filter(user => user._id === invoice.userId)[0];
    if (user) {
      user.name = user.name ? user.name : '';
    } else {
      user = { name: '' };
    }
    invoice.user = user;
    invoice.job = job;
    this.setState(prevState => ({
      modal: true,
      invoice,
    }));
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !this.state.modal,
    }));
  };

  togglePdfPreview = (data = null) => {
    this.setState({
      pdfModal: !this.state.pdfModal,
      invoicePreview: data
    });
  }

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < 1024,
    }));
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };

  }

  async componentDidMount() {
    if (!this.props.userLogged) return this.props.history.push('/auth/login');
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (this.props.userLogged && this.props.userLogged._id !== null ) {
      await this.props.getJobs(this.props.userLogged._id);
      await this.props.getInvoices(this.props.userLogged._id);
    }
    await this.props.getUsers();
    await this.props.reset();

  }

  sum(array) {
    let aux = array[0];
    const arr2 = [];
    arr2.push(array[0]);
    array.forEach((e, i, a) => {
      if (e !== a[0]) {
        arr2.push(e + aux);
        aux = e + aux;
      }
    });
    return arr2;
  }

  searchFunction = () => {
    var input, filter, table, tr, i;
    input = document.getElementById("invoiceSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("invoiceTable");
    console.log(table)
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }
      
      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }

  }

  render() {
    const { invoices, users, jobs } = this.props;

    if (!this.state || users.length === 0 || jobs.length === 0)
      return <LoadBar />;
    let invoicesFilter = invoices.filter(item => {
      if (item !== null) {
        return item;
      }

      return item;
    })

    invoices.forEach(item => {
      if (item !== null && item !== undefined ) {
        item.job = jobs.filter(job => job._id === item.jobId)[0];
        item.user = users.filter(user => user._id === item.userId)[0];
      }

    });

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                  <div className="col">
                      {/* <FormGroup className="mb-0"> */}
                        <InputGroup className="input-group-alternative search-margin">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='search-style'
                            placeholder="Search"
                            type="text"
                            id="invoiceSearchInput"
                            onChange={this.searchFunction}
                          />
                        </InputGroup>
                      {/* </FormGroup> */}
                    </div>
                    <div className="col text-right">
                      {
                        permissions(this.props.userLogged, 'invoices', 'create', 0) ?
                        <Button color="primary" type="button" onClick={() => window.location.href = 'createinvoice'}>
                          Create invoice
                        </Button>
                          : null
                      }
                    </div>
                    
                  </Row>
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Information</h3>
                    </div>

                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" id="invoiceTable" responsive>
                  <thead className="thead-light">
                    <tr>
                      {!this.state.isMobileVersion ? (
                        <>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">Job/User</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Total</th>
                          <th scope="col">Balance</th>
                        </>
                      ) : (
                        <>
                          <th>Details</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {invoicesFilter.length === 0 ? (
                      <tr>
                        <td>No invoices register</td>
                      </tr>
                    ) : (
                      invoicesFilter.map((e, i) => {
                        const job_ = e.job;
                        const client = e.user;
                        const nameClient = client ? client.name : '';
                        // const invoiceIndex = i + 1;

                        let paid = 0;
                        if (e.payments && e.payments.length > 0) {
                          paid = e.payments.reduce(
                            (acc, current, i) =>
                              acc + isNaN(current.paidAmount)
                                ? 0
                                : current.paidAmount,
                            0
                          );
                        }
                        const total = e.invoiceBalance !== undefined ? e.invoiceBalance : e.invoiceTotal - paid;
                        const total2 = e.invoiceBalance !== undefined ? e.invoiceBalance : e.invoiceTotal;

                        let paidAcc = [];
                        if (e.payments && e.payments.length > 0) {
                          paidAcc = e.payments.map((e, i) => {
                            let sum = 0;
                            sum += e.paidAmount || 0;
                            return sum;
                          });
                        }
                        const paidOk = this.sum(paidAcc);
                        e.jobName = job_ ? job_.jobName : '';
                        return (
                          <RowInvoice
                            key={i}
                            item={e}
                            isMobileVersion={this.state.isMobileVersion}
                            paid={paid}
                            invoiceIndex={e && e.invoiceNumber ? e.invoiceNumber : '0000000' }
                            total={total}
                            total2={total2}
                            paidOk={paidOk}
                            nameClient={nameClient}
                            props={this.props}
                            openModal={this.openModal}
                            togglePdfPreview={this.togglePdfPreview}
                          >
                            {' '}
                          </RowInvoice>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size={'lg'}>
          <ModalHeader toggle={this.toggleModal}> Invoice details </ModalHeader>
          <ModalBody>
            Job: <b>{this.state.invoice.job.jobName}</b>
            <br />
            User: <b>{this.state.invoice.user.name}</b>
            <br />
            Invoice date:{' '}
            <b>
              <Moment
                date={new Date(this.state.invoice.invoiceDate)}
                format={'MMM D, YYYY'}
              />
            </b>
            <br />
            Description: <b>{this.state.invoice.invoiceDescription}</b>
            <br />
            Status: <b>{this.state.invoice.invoiceStatus}</b>
            <br />
            Total: <b>${this.state.invoice.invoiceTotal}</b>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={this.state.pdfModal} toggle={this.togglePdfPreview} size="lg">
          <ModalHeader toggle={this.togglePdfPreview}> Preview</ModalHeader>
          <ModalBody>
            <div>
              <ReactToPdf targetRef={this.pdfRef} filename="invoice.pdf">
                {({ toPdf }) => (
                  <div style={{ paddingBottom: 12, paddingTop: 12 }}>

                    <Button
                      type="button"
                      onClick={toPdf}
                      className="form-control-alternative"
                      color="info"
                    >
                      Download PDF
                    </Button>
                  </div>
                )}
              </ReactToPdf>

              <div ref={this.pdfRef}>
                <InvoicePreview
                  total={this.state.invoicePreview && this.state.invoicePreview.invoiceTotal}
                  description={this.state.invoicePreview && this.state.invoicePreview.invoiceDescription}
                  invoiceNumber={this.state.invoicePreview && this.state.invoicePreview.invoiceNumber}
                  jobName={this.state.invoicePreview && this.state.invoicePreview.jobName}
                  items={this.state.invoicePreview && this.state.invoicePreview.estimatedItems}

                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.togglePdfPreview}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  users: state.user.users,
  invoices: state.invoice.invoices,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, {
  getInvoices,
  getUsers,
  getJobs,
  removeInvoice,
  reset
})(Invoices);
