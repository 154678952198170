import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../helpers/file';
import { getExpenses, updateExpense } from '../../redux/actions/expenseAction';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';
import PreviewFile from '../../helpers/PreviewFile';
import LoadBar from '../../components/shared/loadBar';

const toastr = require("toastr");

class UpdateExpense extends React.Component {
  state = {
    workerId: '',
    expenses: [],
    image: '',
    date: '',
    vendor: '',
    category: '',
    description: '',
    total: 0,
    loggedUser: '',
    submitting: false
  };

async componentDidMount() {
    await this.props.getPaymentAccounts();
    if (this.props.expenses.length === 0)
      this.props.history.push(`/admin/expenses`);
    const expense = this.props.expenses.filter(
      item => item._id === this.props.match.params.expenseId
    )[0];


    if(!this.props.jobs.length) {
      await this.props.jobs();
    }
    const job = this.props.jobs.filter(item => item._id === expense.jobId)[0];
    this.setState(prevState => {
      const img = expense.image;
      const date = moment.utc(expense.date).format('YYYY-MM-DD');
      const { vendor } = expense;
      const { category } = expense;
      const { description } = expense;
      const { total } = expense;

      if (img && img.startsWith('http:')) {
        img.replace('http:', 'https:');
      }

      return {
        ...prevState,
        loggedUser: this.props.userLogged,
        workerId: this.props.userLogged._id,
        expenses: job && job.expenses,
        purchaseReturn: total < 0 ? 'Return' : 'Purchase',
        image: img,
        date,
        vendor,
        jobId: expense.jobId,
        paymentAccountId: expense.paymentAccountId,
        category,
        description,
        total,
        jobs: this.props.jobs.filter(job => job.status === 'Approve').sort((a, b) => a.jobName.localeCompare(b.jobName)),
      };
    });
  }

async componentWillUnmount() {
  await this.props.getPaymentAccounts();
  // fix Warning: Can't perform a React state update on an unmounted component
  this.setState = (state,callback)=>{
    return;
  };
}

  /**
   * Format the total: + for Purchase; - for Return;
   * @param {*} val 
   * @returns String
   */
  fmtTotal = val => {
    const cleanVal = val.toString().replace(/^(-)?/g, '');

    if (this.state.purchaseReturn === 'Return') {
      return `-${cleanVal}`;
    }

    return cleanVal;
  };

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if(image && image !== 'notNet.png'){
        this.setState(prevState => ({ ...prevState, image: image }));
      }
    } catch (e) {
      console.error(e);
      toastr.error('Server error.');
    }

  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { total } = this.state;
    await this.props.updateExpense(
      this.props.match.params.expenseId,
      { ...this.state, total: this.fmtTotal(total) }
    );
    this.props.history.push(`/admin/expenses`);
    this.setState({ submitting: false });
  };

  render() {
    const { submitting } = this.state;
    const categories = [
      { category: 'Job Materials' },
      { category: 'Gas' },
      { category: 'Supplies' },
      { category: 'Sub Contractors' },
    ];

    if (!this.state.workerId || this.state.workerId === '')
      return <LoadBar />;
    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Expense Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">

                        <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Job Name *
                            </label>
                            <Input
                   
                              name="jobId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              value={this.state.jobId}
                            >
                              <option value="Select" disabled>
                                Select Job to Add Expense
                              </option>
                              {this.state.jobs.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.jobName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Expense Date
                            </label>
                            <Input
                              required
                              id="date"
                              className="form-control-alternative"
                              placeholder="Select a date"
                              name="date"
                              defaultValue={this.state.date}
                              type="date"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                             Payment Account *
                            </label>
                            <Input
                              name="paymentAccountId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              value={this.state.paymentAccountId !== null && this.state.paymentAccountId !== '' ? this.state.paymentAccountId : 'Select'}
                            >
                              <option value="Select" disabled>
                                Select Payment Account
                              </option>
                              {this.props.paymentAccount.list.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Vendor
                            </label>
                            <Input
                              name="vendor"
                              className="form-control-alternative"
                              placeholder="Enter name of vendor"
                              type="text"
                              defaultValue={this.state.vendor}
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category"
                            >
                              Category
                            </label>
                            <Input
                              required
                              name="category"
                              className="form-control-alternative"
                              placeholder="Enter a category"
                              type="select"
                              defaultValue={this.state.category}
                              onChange={this.handleInput}
                            >
                              {categories.map((e, i) => <option key={i}>{e.category}</option>)}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Description
                            </label>
                            <Input
                              name="description"
                              className="form-control-alternative"
                              placeholder="Enter a description (optional)"
                              type="text"
                              defaultValue={this.state.description}
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              File Upload 
                            </label>
                            <Input
                              name="photo"
                              id="photo"
                              className="form-control-alternative"
                              placeholder="Select a photo"
                              type="file"
                              onChange={this.uploadPhoto}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Purchase/Return
                            </label>
                            <Input
                              ref={this.selectRef}
                              name="purchaseReturn"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue={parseFloat(this.state.total) < 0 ? "Return" : "Purchase"}
                            >
                              <option value="Purchase">Purchase</option>
                              <option value="Return">Return</option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Total
                            </label>
                            <Input
                              name="total"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              value={this.state.total}
                              step="any"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          {this.state.image && this.state.image.includes('.pdf') && (
                            <div>
                               <a target="_blank" rel="noopener noreferrer" href={this.state.image} download>Download PDF</a>
                            </div>
                          )}
                          {this.state.image && (
                            <PreviewFile previewImages={[{ viewingFrom: 'url', file: this.state.image }]} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  expenses: state.expense.expenses,
  jobs: state.job.jobs,
  paymentAccount: state.paymentAccount,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, { getExpenses, getPaymentAccounts, updateExpense })(
  withRouter(UpdateExpense)
);
