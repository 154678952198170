import React from 'react';
import { withRouter } from 'react-router-dom';
// import { startCase, toLower } from 'lodash';

import _ from 'lodash';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Label,
} from 'reactstrap';
import Select from '../../../node_modules/react-select';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../helpers/file';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';
import { addPermission, updatePermission, getPermissionById } from '../../redux/actions/permissionAction';
import { updateUser, getUsers } from '../../redux/actions/userAction';

const toastr = require("toastr");
class AddUpdateDefaultPermission extends React.Component {
  state = {
    name: '',
    submitting: false
    , permissions: {
      estimates: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , jobs: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , invoices: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , expenses: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , time: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , reports: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , clients: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , workers: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , items: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , properties: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , propertyTypes: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , revenueCenter: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , propertyStages: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , lenders: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , paymentAccounts: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
    },
    defaultPaymentAccount: '',
    currentId: this.props.match.params.id || null,
    userOptions: [],
    selectedUsers: [],
    assignToUsers: window.location.href.includes('assign')
  };

  async componentDidMount() {
    await this.props.getUsers();
    await this.props.getPaymentAccounts();
    if (this.state.currentId !== null) {
      await this.props.getPermissionById(this.state.currentId);
    }

    if (this.state.currentId !== null && this.props.permissionById !== null && this.props.permissionById !== undefined) {
      this.setState({name: this.props.permissionById.name, permissions: this.props.permissionById.permissions});
    }

    if (this.props.users !== undefined && this.props.users.length > 0) {
      this.setState({
        userOptions: this.props.users.map(user => { return {value: user._id, label: user.name }})        
      });

        const usersWithPermission = this.props.users.filter(user => user.permissionId === this.state.currentId);
        if (usersWithPermission.length > 0) {
          this.setState({selectedUsers: usersWithPermission.map(data => { return {value: data._id, label: data.name} })});
        }
      
    }

  }

  async componentWillUnmount() {
    await this.props.getPaymentAccounts();
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if(image){
        this.setState(prevState => ({ ...prevState, img: image }));
      }
    } catch (e) {
      toastr.error('Server error.');
    }

  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true });
    if (this.state.currentId !== null) {
      this.state._id = this.state.currentId;
      this.props.updatePermission(this.state);
      this.props.history.push(`../defaultpermission`);

    } else {
      this.props.addPermission(this.state);
      this.props.history.push(`defaultpermission`);

    }
    this.setState({ submitting: false });
  };

  /**
   * After handleSubmit, if no error, go back to workers list
   * 
   */
  redirectToWorkers = () => {
    const { usersEr } = this.props;
    if(usersEr){
      toastr.error(usersEr)
    } else {
      this.props.history.push(`defaultpermission`);
    }
  }

  handleChange = (e) => {
    let newState = _.update(_.cloneDeep(this.state), e.target.name, () => {
      return e.target.value;
    });
    this.setState(newState);
  }

  handleOptionChange = async (value) => {
    const { currentId, selectedUsers } = this.state;
    let difference = null;

    if (value !== null) {
      difference = this.state.selectedUsers.find(x => !value.includes(x)); 
      if (difference !== undefined ) {
        await this.props.updateUser(difference.value, {permissionId: ''});
      } else {
        this.setState({submitting: true});
        value.forEach(async item => {
          await this.props.updateUser(item.value, {permissionId: currentId})
        });
        this.setState({submitting: false});
      }
    } 

    if (value === null) {
        selectedUsers.forEach(async item => {
          await this.props.updateUser(item.value, {permissionId: ''})
        });
    }
    this.setState({selectedUsers: value});

  }

  render() {
    const { submitting, permissions, userOptions, selectedUsers, assignToUsers } = this.state;
    const { permissionById } = this.props;
    const renderElements = [];
    const permissionsOrderDisplay = ['estimates', 'jobs', 'invoices', 'expenses', 'time', 'reports', 'clients', 'workers', 'items', 'properties', 'propertyTypes', 'revenueCenter', 'propertyStages', 'lenders', 'paymentAccounts'];
    const associatedInUserLogged = ['estimates', 'jobs', 'invoices', 'expenses', 'time'];
    permissionsOrderDisplay.map(moduleName => {
      const moduleActionsOrder = ['view', 'create', 'update', 'delete'];
      const renderChildElements = [];
      if (permissions[moduleName]) {
        moduleActionsOrder.map(actionName => {
          const childElmt = (
            <FormGroup key={'actionName-' + actionName} className={`-child-FG -${actionName}-fg`}>
              <Label
                className="form-control-label d-inline-block"
                htmlFor="input-name"
              >
                {_.startCase(actionName)}:
              </Label>
              <FormGroup>
                <div>
                  <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="all" 
                    checked={permissions[moduleName][actionName] === "all"} onChange={this.handleChange}
                  /><span>All</span>
                </div>
                {
                  associatedInUserLogged.indexOf(moduleName) === -1 ? null :
                  <div>
                    <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="assigned" 
                      checked={permissions[moduleName][actionName] === "assigned"} onChange={this.handleChange} 
                    /><span>Assigned</span></div>
                }
                <div>
                  <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="none" 
                    checked={permissions[moduleName][actionName] === "none"} onChange={this.handleChange} />
                  <span>None</span></div>
              </FormGroup>
            </FormGroup>
          )
          renderChildElements.push(childElmt);
          
          return renderChildElements;
        });
      }
      const elmt = (
        <FormGroup key={moduleName}>
          <label
            className="form-control-label d-inline-block"
            htmlFor="input-name"
          >
            {_.startCase(moduleName)}
          </label>
          {renderChildElements}
        </FormGroup>
      )
      renderElements.push(elmt);

      return renderElements;

    });

    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{!assignToUsers ? 'Default Pemission Information' : 'Assign Permission To Users'} </h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>

                  {!assignToUsers ?

                        (

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Display Name *
                            </label>
                            <Input
                              required
                              className="form-control-alternative"
                              placeholder="Enter Permission Name"
                              name="name"
                              type="text"
                              value={this.state.name}
                              onChange={this.handleInput}
                            />
                          </FormGroup>                          
                        {/* </Col>
                        <Col lg="6"> */}
                          {renderElements}
                        </Col>
                        ) : (
                        <Col lg="4">
                          <label
                              className="form-control-label"
                              htmlFor="input-hours"
                          >
                              Users
                          </label>
                          <div style={{width: '500px'}}>
                              <Select
                                  isMulti
                                  isClearable={false}
                                  name="userOptions"
                                  options={userOptions}
                                  className="basic-multi-select form-control-alternative"
                                  classNamePrefix="select"
                                  placeholder='Select Users'
                                  onChange={e => {
                                    this.handleOptionChange(e);
                                  }}
                                  value={selectedUsers ? selectedUsers : []}
                                  />
                          </div>
                        </Col>
                        )}
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                          {!assignToUsers ?
                          (
                            <Button
                              className="form-control-alternative button-margin"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>) 
                            : (
                              <Button
                              className="form-control-alternative button-margin"
                              color="info"
                              onClick={() => {
                                this.props.history.push(`/admin/defaultpermission`)
                              }}
                            >
                              Done
                            </Button>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  paymentAccount: state.paymentAccount,
  usersEr: state.user.usersEr,
  userLogged: state.auth.userLogged,
  permissionById: state.defPermission.permissionById,
  users: state.user.users,


});

export default connect(mapStateToProps, { getPaymentAccounts, addPermission, getPermissionById, updatePermission, getUsers, updateUser })(withRouter(AddUpdateDefaultPermission));
