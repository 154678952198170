import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_INVOICE_SENT = 'FETCH_INVOICE_SENT';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_BY_ID_SUCCESS = 'FETCH_INVOICE_BY_ID_SUCCESS';
export const FETCH_INVOICE_ADD_SUCCESS = 'FETCH_INVOICE_ADD_SUCCESS';
export const FETCH_INVOICE_UPDATE_SUCCESS = 'FETCH_INVOICE_UPDATE_SUCCESS';
export const FETCH_INVOICE_REMOVE_SUCCESS = 'FETCH_INVOICE_REMOVE_SUCCESS';
export const FETCH_INVOICE_NUMBER_SUCCESS = 'FETCH_INVOICE_NUMBER_SUCCESS';

export const FETCH_INVOICE_FAILURE = 'FETCH_INVOICE_DECLINE_FAILURE';

// const shouldGetList = (state, listArg) => {
//   const listInfo = state[listArg];
//   if (!listInfo || !listInfo.invoices) {
//     return true;
//   } else if ((new Date().getTime() - listInfo.lastUpdated) > 500) {
//     return true;
//   } else {
//     return listInfo.didInvalidate;
//   }
// }

export const getInvoices = (id) => async (dispatch, getState) => {
  // if (shouldGetList(getState(), 'invoice')) {
  //   dispatch({ type: FETCH_INVOICE_SENT }); // get old
  //   try {
  //     const response = await authService.getInvoices(id);
  //     dispatch({ 
  //       type: FETCH_INVOICE_SUCCESS
  //       , payload: response.data.invoices
  //       , receivedAt: new Date().getTime()
  //     });
  //   } catch (err) {
  //     dispatch({ type: FETCH_INVOICE_FAILURE, payload: err.message });
  //     console.error(err);
  //   }
  // } else {
  //   return new Promise((resolve) => {
  //     resolve({
  //       type: FETCH_INVOICE_SUCCESS
  //     })
  //   });
  // }
  dispatch({ type: FETCH_INVOICE_SENT }); // get old
  try {
    const response = await authService.getInvoices(id);
    dispatch({
      type: FETCH_INVOICE_SUCCESS
      , payload: response.data.invoices.reverse()
      , receivedAt: new Date().getTime()
    });
  } catch (err) {
    dispatch({ type: FETCH_INVOICE_FAILURE, payload: err && err.message });
    console.error(err);
  }
};

export const getInvoiceById = (id) => async () => {
  try {
    const response = await authService.getInvoiceById(id);

   return response.data.invoice;
  } catch (err) {
    console.error(err);
  }
};

export const convertInvoice = (id, data) => async dispatch => {
  try {
    const response = await authService.convertInvoice(id, data);

    dispatch({
      type: FETCH_INVOICE_ADD_SUCCESS,
      payload: { id, data: response.data.estimate },
    });
  } catch (err) {
    dispatch({ type: FETCH_INVOICE_FAILURE, payload: err });
    console.error(err);
  }
};

export const addInvoice = invoice => async dispatch => {
  try {
    const response = await authService.addInvoices(invoice);
    dispatch({
      type: FETCH_INVOICE_ADD_SUCCESS,
      payload: { id: response.data.invoice._id, data: response.data.invoice },
    });
  } catch (err) {
    dispatch({ type: FETCH_INVOICE_FAILURE, payload: err });
    console.error(err);
  }
};

export const removeInvoice = id => async dispatch => {
  try {
    await authService.invoiceDelete(id);
    dispatch({ type: FETCH_INVOICE_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_INVOICE_FAILURE, payload: err });
    console.error(err);
  }
};

export const updateInvoice = (id, item) => async dispatch => {
  try {
    const response = await authService.updateInvoice(id, item);
    dispatch({
      type: FETCH_INVOICE_UPDATE_SUCCESS,
      payload: { id, data: response.data.invoice },
    });
  } catch (err) {
    dispatch({ type: FETCH_INVOICE_FAILURE, payload: err });
    console.error(err);
  }
};

export const payInvoice = (id, item) => async dispatch => {
  try {
    const response = await authService.payInvoice(id, item);
    dispatch({
      type: FETCH_INVOICE_UPDATE_SUCCESS,
      payload: { id, data: response.data.invoice },
    });
  } catch (err) {
    dispatch({ type: FETCH_INVOICE_FAILURE, payload: err });
    console.error(err);
  }
};

export const getInvoiceNumber = () => async dispatch => {
  try {
    const response = await authService.getInvoiceNumber();
    dispatch({
      type: FETCH_INVOICE_NUMBER_SUCCESS, 
      payload: response.data.number
    });

  } catch (err) {
    console.error(err);
  }
};

export const sendInvoice = (invoice, urlPay) => async dispatch => {
  try {
    await authService.sendInvoice(invoice, urlPay);
  } catch (err) {
    dispatch({ type: FETCH_INVOICE_FAILURE, payload: err });
    console.error(err);
  }
};
