import React from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import Global from '../../global';
import { getUsers, removeUser } from '../../redux/actions/userAction';
import { reset } from '../../redux/actions/searchAction';
import { permissions } from '../../helpers/utils';
import LoadBar from '../../components/shared/loadBar';

const ActionButton = props => (
  <UncontrolledDropdown>
    <DropdownToggle>...</DropdownToggle>
    <DropdownMenu>
      {
        permissions(props.userLogged, 'workers', 'update', 3) ?
          <DropdownItem to={`/admin/workers/update/${props._id}`} tag={Link}>
            Update
          </DropdownItem>
          : 
          <DropdownItem disabled to={`/admin/workers/update/${props._id}`} tag={Link}>
            Update
          </DropdownItem>
      }
      {
        permissions(props.userLogged, 'workers', 'delete', 4) ?
          <DropdownItem
            onClick={() => {
              props.removeUser(props._id);
              alert('Worker Delete');
            }}
          >
            <span className="text-danger">Delete</span>
          </DropdownItem>
          : 
          <DropdownItem disabled to={`/admin/workers/update/${props._id}`} tag={Link}>
            Delete
          </DropdownItem>
      }
    </DropdownMenu>
  </UncontrolledDropdown>
);

class Workers extends React.Component {
  state = {
    modal: false,
    worker: {},
  };

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < Global.mobileWidth,
    }));
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  async componentDidMount() {
    if (!this.props.userLogged) return this.props.history.push('/auth/login');

    await this.props.reset();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    await this.props.getUsers();
  }

  openModal = id => {
    const worker = this.props.users.filter(worker => worker._id === id)[0];
    this.setState(prevState => ({
      modal: true,
      worker,
    }));
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !this.state.modal,
    }));
  };

  searchFunction = () => {
    var input, filter, table, tr, i;
    input = document.getElementById("userSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("userTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }
      
      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }
  }

  render() {
    const { users } = this.props;
    if (!this.state) return <LoadBar />;

    let usersFilter = users;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                  <div className="col">
                      {/* <FormGroup className="mb-0"> */}
                        <InputGroup className="input-group-alternative search-margin">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='search-style'
                            placeholder="Search"
                            type="text"
                            id="userSearchInput"
                            onChange={this.searchFunction}
                          />
                        </InputGroup>
                      {/* </FormGroup> */}
                    </div>
                    {
                      permissions(this.props.userLogged, 'workers', 'create', 3) ?
                        <div className="col text-right">
                          <Button color="primary" type="button" onClick={() => window.location.href = 'addworker'}>
                              Create User
                          </Button>
                        </div>
                        : null
                    }
                  </Row>
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Users</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" id="userTable" responsive>
                  <thead className="thead-light">
                    <tr>
                      {!this.state.isMobileVersion ? (
                        <>
                          <th scope="col"></th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Pay Rate</th>
                          <th scope="col">Effective Rate</th>
                        </>
                      ) : (
                        <>
                          <th>Worker</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {usersFilter.length === 0 ? (
                      <tr>
                        <td>No workers register</td>
                      </tr>
                    ) : (
                      usersFilter.map((e, i) => (
                        <tr key={i} className="details-row">
                          {!this.state.isMobileVersion ? (
                            <>
                                <td>
                                  <ActionButton
                                    {...e}
                                    removeUser={this.props.removeUser}
                                    userLogged={this.props.userLogged}
                                  />
                                </td>
                              <td
                                onClick={() => {
                                  this.openModal(e._id);
                                }}
                              >
                                {e.name}
                              </td>
                              <td
                                onClick={() => {
                                  this.openModal(e._id);
                                }}
                              >
                                {e.email}
                              </td>
                              <td
                                onClick={() => {
                                  this.openModal(e._id);
                                }}
                              >
                                {e.payRate}
                              </td>
                              <td
                                onClick={() => {
                                  this.openModal(e._id);
                                }}
                              >
                                {e.effectiveRate}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                {e.name}
                                <br />
                                <small>({e.email})</small>
                                <br />
                                Payment: <b>{e.payRate}</b>
                                <br />
                                Effective: <b>{e.effectiveRate}</b>
                                <br />
                                <div className="buttonfloat-right buttonfloat-right-jobs">
                                  <ActionButton
                                    {...e}
                                    removeUser={this.props.removeUser}
                                    userLogged={this.props.userLogged}
                                  />
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size={'lg'}>
          <ModalHeader toggle={this.toggleModal}> Worker details </ModalHeader>
          <ModalBody>
            Name:{' '}
            <b>
              {this.state.worker.name}{' '}
              <small>({this.state.worker.email})</small>
            </b>
            <br />
            {/* Role: <b>{this.state.worker.role}</b> <br /> */}
            Level: <b>{this.state.worker.level}</b> <br />
            Address: <b>{this.state.worker.address}</b> <br />
            Phone: <b>{this.state.worker.phone}</b> <br />
            Mobile: <b>{this.state.worker.mobile}</b> <br />
            Activity: <b>{this.state.worker.activity}</b> <br />
            Contact Name: <b>{this.state.worker.contactName}</b> <br />
            Payment: <b>{this.state.worker.payRate}</b>
            <br />
            Effective: <b>{this.state.worker.effectiveRate}</b>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user.users,
  userLogged: state.auth.userLogged
});

export default connect(mapStateToProps, { getUsers, removeUser, reset })(
  Workers
);
