import axios from 'axios';
import AxiosOffline from './axiosOffline';
import LocalForage from 'localforage';
import Global from '../global';

const baseURL = Global.url;
const env = Global.urlEnvironment;

class AuthService {
  constructor() {
    const AxiosOfflineAdapter = AxiosOffline({
      defaultAdapter: axios.defaults.adapter, // require, basic adapter
      storageName: `axiosreply${env}`, // optional, default: "axios-stack"
      storageDriver: LocalForage.WEBSQL, // optional, default: LocalForage.LOCALSTORAGE
    });

    this.service = axios.create({
      timeout: 60000,
      adapter: AxiosOfflineAdapter,
      baseURL,
      withCredentials: true,
    });

    this.service.interceptors.response.use(
      async response => {
        if (response.headers.version !== Global.version) {
          await LocalForage.clear();
        }
        
        return response;
      },
      error => {
        return Promise.reject(error && error.response && error.response.data && error.response.data.err)
      }
    );
  }

  signup(data) {
    return this.service.post('/v2/user/signup', data);
  }

  login(data) {
    return this.service.post('/v2/user/login', data);
  }

  logout() {
    return this.service.get('/v2/user/logout');
  }

  // clients
  getClients() {
    return this.service.get('/v2/client/checkclients');
  }

  getClientById(id) {
    return this.service.get(`/v2/client/oneclient/${id}`);
  }

  addClient(data) {
    return this.service.post('/v2/client/createclient', data);
  }

  updateClient(id, data) {
    return this.service.patch(`/v2/client/updateclient/${id}`, data);
  }

  deleteClient(data) {
    return this.service.delete(`/v2/client/deleteclient/${data}`);
  }

  // users
  getUsers(idUser) {
    return this.service.get('/v2/user/getusers');
  }

  addWorker(data) {
    return this.service.post('/v2/user/addworker', data);
  }

  updateWorker(id, data) {
    return this.service.patch(`/v2/user/updateworker/${id}`, data);
  }

  deleteWorker(data) {
    return this.service.delete(`/v2/user/deleteworker/${data}`);
  }

  // estimates
  // getEstimates(id = '') {
  //   return this.service.get(`/v2/job/checkestimates/${id}`);
  // }

  addEstimate(data) {
    return this.service.post('/v2/job/addestimate', data);
  }

  duplicateEstimate(data) {
    return this.service.post('/v2/job/duplicateestimate', data);
  }

  decline(data) {
    return this.service.patch(`/v2/job/estimatedecline/${data}`);
  }

  deleteEstimate(id) {
    return this.service.delete(`/v2/job/estimatedelete/${id}`);
  }

  sendEstimates(data) {
    return this.service.post('/v2/job/sendestimate', data);
  }

  updateEstimate(id, data) {
    return this.service.patch(`/v2/job/estimateupdate/${id}`, data);
  }

  addWorkers(id, data) {
    return this.service.patch(`/v2/job/addworkers/${id}`, data);
  }

  addProjectManager(id, data) {
    return this.service.patch(`/v2/job/addpm/${id}`, data);
  }

  sendAcceptedEmail(id) {
    return this.service.get(`/v2/job/sendAcceptedEmail/${id}`);
  }

  // invoices
  addInvoices(data) {
    return this.service.post('/v2/invoice/addinvoice/', data);
  }

  getInvoices(id) {
    return this.service.get(`/v2/invoice/getInvoices/${id}`);
  }

  getInvoiceById(id) {
    return this.service.get(`/v2/invoice/getInvoice/${id}`);
  }

  invoiceDelete(id) {
    return this.service.patch(`/v2/invoice/invoicedelete/${id}`);
  }

  paidInvoice(data) {
    return this.service.patch(`/v2/invoice/paidinvoice/${data}`);
  }

  convertInvoice(id, data) {
    return this.service.post(`/v2/invoice/convertinvoice/${id}`, data);
  }

  updateInvoice(id, data) {
    return this.service.patch(`/v2/invoice/invoiceupdate/${id}`, data);
  }

  payInvoice(id, data) {
    return this.service.patch(`/v2/invoice/pay-invoice/${id}`, data);
  }

  getInvoiceNumber() {
    return this.service.get(`/v2/invoice/getInvoicesNumbers`);
  }

  sendInvoice(data, urlPay) {
    data.urlPay = urlPay;
    return this.service.post('/v2/invoice/sendinvoice/', data);
  }

  // jobs
  addJob(data) {
    return this.service.post('/v2/job/createJob/', data);
  }

  addJobAndClient(data) {
    return this.service.post('/v2/job/createjobandclient/', data);
  }

  convertJob(data) {
    return this.service.get(`/v2/job/convertjob/${data}`);
  }

  closeJob(data) {
    return this.service.patch(`/v2/job/closejob/${data}`);
  }

  // time
  getTimes(id) {
    if (id) {
      return this.service.get(`/v2/time/gettimes/${id}`);
    }
    return this.service.get('/v2/time/gettimes/');
  }

  addTime(data) {
    return this.service.patch('/v2/time/addtime/', data);
  }

  updateTime(timeId, data) {
    return this.service.patch(`/v2/time/updatetime/${timeId}`, data);
  }

  removeTime(timeId) {
    return this.service.patch(`/v2/time/deletetime/${timeId}`);
  }

  // expense
  getExpenses(id) {
    return this.service.get(`/v2/expense/getexpenses/${id}`);
  }
  getExpenseCount() {
    return this.service.get(`/v2/expense/count`);
  }

  addExpense(data) {
    return this.service.patch('/v2/expense/addexpense/', data);
  }

  addExpenseWithFile(data) {
    return this.service.patch('/v2/expense/addexpensewithfile', data);
  }

  updateExpense(id, data) {
    return this.service.patch(`/v2/expense/expenseupdate/${id}`, data);
  }

  removeExpense(id) {
    return this.service.patch(`/v2/expense/expensedelete/${id}`);
  }

  sendEstimatesReconciled(data) {
    return this.service.patch('/v2/expense/reconciled/', data);
  }

  // item
  getItems() {
    return this.service.get('/v2/item/getItems');
  }

  addItem(data) {
    return this.service.post('/v2/item/additem', data);
  }

  updateItem(id, data) {
    return this.service.patch(`/v2/item/updateitem/${id}`, data);
  }

  deleteItem(data) {
    return this.service.delete(`/v2/item/deleteitem/${data}`);
  }

  getEstimates(userId) {
    return this.service.get(`/v2/job/getestimates/${userId}`);
  }

  getEstimateById(id) {
    return this.service.get(`/v2/job/estimatedetail/${id}`);
  }

  getJobs(userId) {
    return this.service.get(`/v2/job/getjobs/${userId}`);
  }

    // item
    getProperties() {
      return this.service.get('/v2/properties');
    }

    getProperty(id) {
      return this.service.get(`/v2/properties${id}`);
    }
    
    addProperty(data) {
      return this.service.post('/v2/properties', data);
    }
  
    updateProperty(id, data) {
      return this.service.put(`/v2/properties`, data);
    }
  
    deleteProperty(id) {
      return this.service.delete(`/v2/properties/${id}`);
    }
    

    getPropertyStages() {
      return this.service.get('/v2/stages');
    }

    getPropertyStage(id) {
      return this.service.get(`/v2/stages/${id}`);
    }
    
    addPropertyStage(data) {
      return this.service.post('/v2/stages', data);
    }
  
    updatePropertyStage(data) {
      return this.service.put(`/v2/stages`, data);
    }
  
    deletePropertyStage(id) {
      return this.service.delete(`/v2/stages/${id}`);
    }


    
    getPropertyTypes() {
      return this.service.get('/v2/propertyInfo');
    }

    getPropertyType(id) {
      return this.service.get(`/v2/propertyInfo/${id}`);
    }
    
    addPropertyType(data) {
      return this.service.post('/v2/propertyInfo', data);
    }
  
    updatePropertyType(data) {
      return this.service.put(`/v2/propertyInfo`, data);
    }
  
    deletePropertyType(id) {
      return this.service.delete(`/v2/propertyInfo/${id}`);
    }
    
    getLenders() {
      return this.service.get('/v2/lenders');
    }

    getLender(id) {
      return this.service.get(`/v2/lenders/${id}`);
    }
    
    addLender(data) {
      return this.service.post('/v2/lenders', data);
    }
  
    updateLender(data) {
      return this.service.put(`/v2/lenders`, data);
    }
  
    deleteLender(id) {
      return this.service.delete(`/v2/lenders/${id}`);
    }

    

  // revenue center
  getRevenueCenter() {
    return this.service.get('/v2/revenue-center');
  }

  addRevenueCenter(data) {
    return this.service.post('/v2/revenue-center', data);
  }

  updateRevenueCenter(data) {
    return this.service.put('/v2/revenue-center', data);
  }

  deleteRevenueCenter(id) {
    return this.service.delete(`/v2/revenue-center/${id}`);
  }

  // payment accounts
  getPaymentAccounts() {
    return this.service.get('/v2/paymentAccount');
  }

  addPaymentAccount(data) {
    return this.service.post('/v2/paymentAccount', data);
  }

  updatePaymentAccount(data) {
    return this.service.put('/v2/paymentAccount', data);
  }

  deletePaymentAccount(id) {
    return this.service.delete(`/v2/paymentAccount/${id}`);
  }


  // Default Permissions
  getPermissions() {
    return this.service.get('/v2/permission');
  }

  getPermissionById(id) {
    return this.service.get(`/v2/permission/${id}`);
  }

  addPermission(data) {
    return this.service.post('/v2/permission', data);
  }

  updatePermission(data) {
    return this.service.put('/v2/permission', data);
  }

  deletePermission(id) {
    return this.service.delete(`/v2/permission/${id}`);
  }

  addPayment(data) {
    return this.service.post('/v2/payment', data);
  }


  // files
  upload(file, onUploadProgress) {
    const formData = new FormData();
    formData.append('photo', file);
    return this.service.post('/v2/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  uploadAndAddExpense(data) {
    const formData = new FormData();
    formData.append('category', data.category);
    formData.append('date', data.date);
    formData.append('description', data.description);
    formData.append('purchaseReturn', data.purchaseReturn);
    formData.append('total', data.total);
    formData.append('userId', data.userId);
    formData.append('vendor', data.vendor);
    formData.append('jobId', data.jobId);
    formData.append('photo', data.image);
    formData.append('paymentAccountId', data.paymentAccountId);
    return this.service.post('/v2/upload-and-addexpense', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  getFiles() {
    return this.service.get('/files');
  }
}

export default AuthService;
