import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_PERMISSION_SENT = 'FETCH_PERMISSION_SENT';
export const FETCH_PERMISSION_SUCCESS = 'FETCH_PERMISSION_SUCCESS';
export const FETCH_PERMISSION_FAILURE = 'FETCH_PERMISSION_FAILURE';

export const FETCH_PERMISSION_BY_ID_SENT = 'FETCH_PERMISSION_BY_ID_SENT';
export const FETCH_PERMISSION_BY_ID_SUCCESS = 'FETCH_PERMISSION_BY_ID_SUCCESS';
export const FETCH_PERMISSION_BY_ID_FAILURE = 'FETCH_PERMISSION_BY_ID_FAILURE';

export const FETCH_PERMISSION_ADD_SENT = 'FETCH_PERMISSION_ADD_SENT';
export const FETCH_PERMISSION_ADD_SUCCESS = 'FETCH_PERMISSION_ADD_SUCCESS';
export const FETCH_PERMISSION_ADD_FAILURE = 'FETCH_PERMISSION_ADD_FAILURE';

export const FETCH_PERMISSION_UPDATE_SENT = 'FETCH_PERMISSION_UPDATE_SENT';
export const FETCH_PERMISSION_UPDATE_SUCCESS = 'FETCH_PERMISSION_UPDATE_SUCCESS';
export const FETCH_PERMISSION_UPDATE_FAILURE = 'FETCH_PERMISSION_UPDATE_FAILURE';

export const FETCH_PERMISSION_REMOVE_SENT = 'FETCH_PERMISSION_REMOVE_SENT';
export const FETCH_PERMISSION_REMOVE_SUCCESS = 'FETCH_PERMISSION_REMOVE_SUCCESS';
export const FETCH_PERMISSION_REMOVE_FAILURE = 'FETCH_PERMISSION_REMOVE_FAILURE';



export const getPermissions = () => async (dispatch, getState) => {
    dispatch({ type: FETCH_PERMISSION_SENT }); // get old
    try {
      const response = await authService.getPermissions();
      dispatch({ 
        type: FETCH_PERMISSION_SUCCESS
        , payload: response.data.permissions
        , receivedAt: new Date().getTime()
      });
      
    } catch (err) {
      dispatch({ type: FETCH_PERMISSION_FAILURE, payload: err && err.message });
      console.error(err);
    }
};

export const getPermissionById = id => async (dispatch, getState) => {
    dispatch({ type: FETCH_PERMISSION_BY_ID_SENT }); // get old
    try {
      const response = await authService.getPermissionById(id);
      dispatch({ 
        type: FETCH_PERMISSION_BY_ID_SUCCESS
        , payload: response.data.permission
        , receivedAt: new Date().getTime()
      });
      
    } catch (err) {
      dispatch({ type: FETCH_PERMISSION_BY_ID_FAILURE, payload: err && err.message });
      console.error(err);
    }
};

export const addPermission = data => async dispatch => {
  dispatch({ type: FETCH_PERMISSION_ADD_SENT });
  try {
 
    const response = await authService.addPermission(data);
    dispatch({ type: FETCH_PERMISSION_ADD_SUCCESS, payload: response.data.permission });
    dispatch(getPermissions());
  } catch (err) {
    dispatch({ type: FETCH_PERMISSION_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updatePermission = (data) => async dispatch => {
  dispatch({ type: FETCH_PERMISSION_UPDATE_SENT });
  try {
    const response = await authService.updatePermission(data);
    dispatch({
      type: FETCH_PERMISSION_UPDATE_SUCCESS,
      payload: { data: response.data.permission },
    });
    dispatch(getPermissions());
  } catch (err) {
    dispatch({ type: FETCH_PERMISSION_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removePermission = id => async dispatch => {
  dispatch({ type: FETCH_PERMISSION_REMOVE_SENT });
  try {
    await authService.deletePermission(id);
    dispatch({ type: FETCH_PERMISSION_REMOVE_SUCCESS, payload: { id } });
    dispatch(getPermissions());
  } catch (err) {
    dispatch({ type: FETCH_PERMISSION_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};
