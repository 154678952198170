import {
    FETCH_PERMISSION_SENT,
    FETCH_PERMISSION_SUCCESS,
    FETCH_PERMISSION_FAILURE,
    FETCH_PERMISSION_BY_ID_SENT,
    FETCH_PERMISSION_BY_ID_SUCCESS,
    FETCH_PERMISSION_BY_ID_FAILURE,
    FETCH_PERMISSION_REMOVE_SENT,
    FETCH_PERMISSION_REMOVE_SUCCESS,
    FETCH_PERMISSION_REMOVE_FAILURE,
    FETCH_PERMISSION_ADD_FAILURE,
    FETCH_PERMISSION_ADD_SUCCESS,
    FETCH_PERMISSION_UPDATE_SUCCESS,
    FETCH_PERMISSION_UPDATE_FAILURE,
  } from '../actions/permissionAction';
  import { LOG_LOGOUT_USER } from '../actions/authAction';
  import { merger, adder, updater, remover } from './actionReducers';
  
  const initialState = {
    list: [],
    didInvalidate: true
  };
  
  export const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PERMISSION_REMOVE_SENT:
      case FETCH_PERMISSION_SENT:
        return state;
      case LOG_LOGOUT_USER:
        state = initialState;
        return state;
      case FETCH_PERMISSION_SUCCESS:
        return merger(state, { 
          list: action.payload, 
          itemsErr: undefined ,
          didInvalidate: false,
          lastUpdated: action.receivedAt
        });
      case FETCH_PERMISSION_FAILURE:
        return merger(state, { itemsEr: action.payload });
      case FETCH_PERMISSION_BY_ID_SENT:
            return state;
      case FETCH_PERMISSION_BY_ID_SUCCESS:
        return merger(state, { 
            permissionById: action.payload, 
        });
      case FETCH_PERMISSION_BY_ID_FAILURE:
        return merger(state, { permissionById: action.payload });
  
      case FETCH_PERMISSION_ADD_SUCCESS:
        return { ...state, list:adder(state.list, action.payload) };
      case FETCH_PERMISSION_ADD_FAILURE:
        return merger(state, { itemsEr: action.payload });
  
      case FETCH_PERMISSION_UPDATE_SUCCESS:
        const index = state.list.findIndex(u => u._id === action.payload.id);
        return {
          ...state,
          list:updater(state.list, action.payload.data, index),
        };
      case FETCH_PERMISSION_UPDATE_FAILURE:
        return merger(state, { itemsEr: action.payload });
  
      case FETCH_PERMISSION_REMOVE_SUCCESS:
        return { ...state, list: remover(state.list, action.payload.id) };
      case FETCH_PERMISSION_REMOVE_FAILURE:
        return merger(state, { itemsEr: action.payload });
  
      default:
        return state;
    }
  };
  