import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Table,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Button
} from 'reactstrap';


import Header from '../../components/Headers/Header';
import { useEffect } from 'react';

// REDUX
import { getRevenueCenter, removeRevenueCenter } from '../../redux/actions/revenueCenterAction';
import { permissions } from '../../helpers/utils';



// /admin/updateproperty/${props.item._id}
const ActionButton = props => (
    <UncontrolledDropdown>
        <DropdownToggle>...</DropdownToggle>
        <DropdownMenu
            modifiers={{
                setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: data => ({
                        ...data,
                        styles: {
                            ...data.styles,
                            overflow: 'auto',
                            maxHeight: 130,
                        },
                    }),
                },
            }}
        >
            {
                permissions(props.props.userLogged, 'revenueCenter', 'update', 0) ?
                <DropdownItem to={`/admin/revenuecenter/update/${props.data && props.data._id}`} tag={Link}>
                    Update
                </DropdownItem>
                : 
                <DropdownItem disabled to={`/admin/revenuecenter/update/${props.data && props.data._id}`} tag={Link}>
                    Update
                </DropdownItem>
            }
            {
                permissions(props.props.userLogged, 'revenueCenter', 'delete', 0) ?
                <DropdownItem
                    onClick={() => {
                        if (window.confirm("Are you sure, you want to delete this item?")) {
                
                            props.deleteRevenueCenter(props.data && props.data._id);
                        }
                    }}
                >
                    <span className="text-danger">Delete</span>
                </DropdownItem>
                : 
                <DropdownItem disabled to={`/admin/revenuecenter/update/${props.data && props.data._id}`} tag={Link}>
                    Delete
                </DropdownItem>
            }
        </DropdownMenu>
    </UncontrolledDropdown>
);


const RevenueCenter = props => {

    const dispatch = useDispatch();

    const { revenueCenter } = useSelector(state => {
        return {
            revenueCenter: state.revenueCenter
        }
    });

    useEffect(() => {
        dispatch(getRevenueCenter());
    }, [])

    const handleDeleteRevenueCenter = id => {
        dispatch(removeRevenueCenter(id));
    }
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Revenue Center</h3>
                                    </div>
                                    <div className="col text-right">
                                        {
                                            permissions(props.userLogged, 'revenueCenter', 'create', 0) ?
                                            <Button color="primary" type="button" onClick={() => window.location.href = '/admin/revenuecenter/create'}>
                                                Add Revenue Center
                                            </Button>
                                                : null
                                        }
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Table style={{ width: '100%' }} className="align-items-center table-flush" responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                {/* <th>Order</th> */}
                                                {/* <th>Date Created</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {revenueCenter &&  revenueCenter.list.map((item) => {

                                                return <tr>

                                                    <td style={{ width: 80 }}>
                                                        <ActionButton
                                                            deleteRevenueCenter={handleDeleteRevenueCenter}
                                                            data={item}
                                                            props={props}
                                                        ></ActionButton>

                                                    </td>
                                                    <td>{item && item.name}</td>
                                                    {/* <td>{propertyStage.order}</td> */}

                                                </tr>
                                            })}

                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


};

const mapStateToProps = state => ({
    userLogged: state.auth.userLogged,
});
  
export default connect(mapStateToProps) (
    withRouter(RevenueCenter)
);