import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_JOB_SENT = 'FETCH_JOB_SENT';
export const FETCH_ESTIMATE_SUCCESS = 'FETCH_ESTIMATE_SUCCESS';
export const FETCH_ESTIMATE_FAILURE = 'FETCH_ESTIMATE_FAILURE';

export const FETCH_ESTIMATE_ADD_SUCCESS = 'FETCH_ESTIMATE_ADD_SUCCESS';
export const FETCH_ESTIMATE_ADD_FAILURE = 'FETCH_ESTIMATE_ADD_FAILURE';

export const FETCH_ESTIMATE_UPDATE_SUCCESS = 'FETCH_ESTIMATE_UPDATE_SUCCESS';
export const FETCH_ESTIMATE_UPDATE_FAILURE = 'FETCH_ESTIMATE_UPDATE_FAILURE';

export const FETCH_ESTIMATE_REMOVE_SUCCESS = 'FETCH_ESTIMATE_REMOVE_SUCCESS';
export const FETCH_ESTIMATE_REMOVE_FAILURE = 'FETCH_ESTIMATE_REMOVE_FAILURE';

export const FETCH_ESTIMATE_CONVERT_JOB_FAILURE =
  'FETCH_ESTIMATE_CONVERT_JOB_FAILURE';

export const FETCH_ESTIMATE_DECLINE_FAILURE = 'FETCH_ESTIMATE_DECLINE_FAILURE';

export const FETCH_JOB_SUCCESS = 'FETCH_JOB_SUCCESS';
export const FETCH_JOB_FAILURE = 'FETCH_JOB_FAILURE';

export const FETCH_JOB_ADD_FAILURE = 'FETCH_JOB_ADD_FAILURE';
export const FETCH_JOB_UPDATE_FAILURE = 'FETCH_JOB_UPDATE_FAILURE';
export const FETCH_JOB_REMOVE_FAILURE = 'FETCH_JOB_REMOVE_FAILURE';

// const shouldGetList = (state, listArg) => {
//   const listInfo = state[listArg];
//   if (!listInfo || !listInfo.jobs) {
//     return true;
//   } else if ((new Date().getTime() - listInfo.lastUpdated) > 500) {
//     return true;
//   } else {
//     return listInfo.didInvalidate;
//   }
// }

export const addEstimate = (data, tableName) => async dispatch => {
  try {
    const tempId = new Date().getTime();
    data.selectedFiles = null;
    data.previewImagesThumbnails = [];
    data.previewImages = [];
    data.imgsThumbs = [];
    data.createdAt = new Date();
    data._id = tempId;
    dispatch({ type: FETCH_ESTIMATE_ADD_SUCCESS, payload: data, table: tableName });
    const response = await authService.addEstimate(data);
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id: tempId, data: response.data.job, table: tableName },
    });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

/**
 * Duplicate an estimate
 *
 * @param {*} data
 * @returns void
 */
export const duplicateEstimate = data => async dispatch => {
  try {
    if (data._id) {
      delete data._id;
    }
    const response = await authService.duplicateEstimate(data);
    dispatch({ type: FETCH_ESTIMATE_ADD_SUCCESS, payload: response.data.job });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updateEstimate = (id, data, tableName) => async dispatch => {
  try {
    data.selectedFiles = null;
    data.previewImagesThumbnails = [];
    data.previewImages = [];
    data.imgsThumbs = [];
    const response = await authService.updateEstimate(id, data);
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id, data: response.data.job, table: tableName },
    });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removeEstimate = id => async dispatch => {
  try {
    await authService.deleteEstimate(id);
    dispatch({ type: FETCH_ESTIMATE_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};

export const convertJob = (id, item) => async dispatch => {
  try {
    await authService.convertJob(id, item);
    item.isJob = true;
    item.status = 'Approve';
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id, data: item },
    });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_CONVERT_JOB_FAILURE, payload: err });
    console.error(err);
  }
};

export const decline = (id, item) => async dispatch => {
  try {
    await authService.decline(id);
    item.status = 'Decline';
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id, data: item },
    });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_DECLINE_FAILURE, payload: err });
    console.error(err);
  }
};

export const getJobs = (id = undefined) => async (dispatch, getState) => {
  if (id) {
    dispatch({ type: FETCH_JOB_SENT }); // get old
    try {
      let response = await authService.getJobs(id);
      dispatch({
        type: FETCH_JOB_SUCCESS
        , payload: response.data.estimates
        , receivedAt: new Date().getTime()
        , table: 'jobs'
      });
    } catch (err) {
      dispatch({ type: FETCH_JOB_FAILURE, payload: err && err.message });
      console.error(err);
    }
  } else {
    return new Promise((resolve) => {
      resolve({
        type: FETCH_JOB_SUCCESS
      })
    });
  }
};

export const addJobs = data => async dispatch => {
  try {
    const response = await authService.addJob(data);
    dispatch({
      type: FETCH_ESTIMATE_ADD_SUCCESS,
      payload: response.data.estimate,
    });
  } catch (err) {
    dispatch({ type: FETCH_JOB_ADD_FAILURE, payload: err && err.message !== undefined ? err.message : 'Fetching job failed' });
    console.error(err);
  }
};

export const addJobAndClient = data => async dispatch => {
  try {
    const response = await authService.addJobAndClient(data);
    dispatch({
      type: FETCH_ESTIMATE_ADD_SUCCESS,
      payload: response.data.estimate,
    });
  } catch (err) {
    dispatch({ type: FETCH_JOB_ADD_FAILURE, payload: err && err.message !== undefined ? err.message : 'Adding job failed' });
    console.error(err);
  }
};

export const updateJob = (id, data) => async dispatch => {
  try {
    const response = await authService.updateEstimate(id, data, 'jobs');
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id, data: response.data.job },
    });
  } catch (err) {
    dispatch({ type: FETCH_JOB_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removeJob = id => async dispatch => {
  try {
    await authService.deleteEstimate(id);
    dispatch({ type: FETCH_ESTIMATE_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_JOB_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};

export const closeJob = (id, item) => async dispatch => {
  try {
    await authService.closeJob(id);
    item.status = 'Closed';
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id, data: item },
    });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_DECLINE_FAILURE, payload: err });
    console.error(err);
    throw new Error(err);
  }
};

export const addWorkers = (id, data) => async dispatch => {
  try {
    const response = await authService.addWorkers(id, data);
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id, data: response.data.estimate },
    });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_DECLINE_FAILURE, payload: err });
    console.error(err);
  }
};

export const addProjectManager = (id, data) => async dispatch => {
  try {
    const response = await authService.addProjectManager(id, data);
    dispatch({
      type: FETCH_ESTIMATE_UPDATE_SUCCESS,
      payload: { id, data: response.data.estimate },
    });
  } catch (err) {
    dispatch({ type: FETCH_ESTIMATE_DECLINE_FAILURE, payload: err });
    console.error(err);
  }
};

export const sendAcceptedEmail = async id => {
  await authService.sendAcceptedEmail(id);
};


export const getEstimates = (id = undefined) => async (dispatch, getState) => {
  dispatch({ type: FETCH_JOB_SENT }); // get old
  try {
    let response = await authService.getEstimates(id);

    dispatch({
      type: FETCH_JOB_SUCCESS
      , payload: response.data.estimates
      , receivedAt: new Date().getTime()
      , table: 'estimates'
    });
  } catch (err) {
    dispatch({ type: FETCH_JOB_FAILURE, payload: err && err.message });
    console.error(err);
  }
};

export const getEstimateById = (id) => async () => {
  try {
    const response = await authService.getEstimateById(id);

   return response.data.job;
  } catch (err) {
    console.error(err);
  }
};