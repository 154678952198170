import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../helpers/file';
import { addExpense } from '../../redux/actions/expenseAction';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';
import PreviewFile from '../../helpers/PreviewFile';
import LoadBar from '../../components/shared/loadBar';

const toastr = require("toastr");

const fecha = new Date();
let mes = fecha.getMonth() + 1;
let dia = fecha.getDate();
const ano = fecha.getFullYear();
if (dia < 10) dia = `0${dia}`; // agrega cero si es menor de 10
if (mes < 10) mes = `0${mes}`; // agrega cero si es menor de 10

class AddExpense extends React.Component {
  state = {
    userId: '',
    date: `${ano}-${mes}-${dia}`,
    jobId: this.props.match.params.id,
    category: '',
    vendor: '',
    description: '',
    total: 0,
    image: '',
    submitting: false
  };

  async componentDidMount() {
    await this.props.getPaymentAccounts();
    this.setState({
      loggedUser: this.props.userLogged,
      userId: this.props.userLogged._id,
    });
  }

  async componentWillUnmount() {
    await this.props.getPaymentAccounts();
    
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if(image){
        this.setState(prevState => ({ ...prevState, image: image }));
      }
    } catch (e) {
      console.error(e);
      toastr.error('Server error.');
    }
  };

  handleSubmit = async (e, props) => {
    const { total } = this.state;

    e.preventDefault();

    this.setState({ submitting: true });
    if (this.state.category === '') {
      alert('Select a category');
    } else if (!total.match(/^0|-?[0-9]\d*(\.\d+)?$/gm)) {
      alert('Total quantity not valid');
    } else {
      await this.props.addExpense(this.state);
      this.props.history.push('/admin/jobs');
    }
    this.setState({ submitting: false });
  };

  render() {
    const { submitting } = this.state;
    if (!this.state.userId || this.state.userId === '') return <LoadBar />;
    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Expense Informat 2ion</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={this.handleSubmit}
                    enctype="multipart/form-data"
                  >
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Expense Date *
                            </label>
                            <Input
                              required
                              id="date"
                              className="form-control-alternative"
                              placeholder="Select a date"
                              name="date"
                              value={this.state.date}
                              type="date"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Vendor
                            </label>
                            <Input
                              name="vendor"
                              className="form-control-alternative"
                              placeholder="Enter name of vendor"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category"
                            >
                              Category *
                            </label>
                            <Input
                              required
                              name="category"
                              className="form-control-alternative"
                              placeholder="Enter a category"
                              type="select"
                              onChange={this.handleInput}
                            >
                              <option disabled selected>
                                Choose One
                              </option>
                              <option>Job Materials</option>
                              <option>Gas</option>
                              <option>Supplies</option>
                              <option>Sub Contractors</option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Description
                            </label>
                            <Input
                              name="description"
                              className="form-control-alternative"
                              placeholder="Enter a description (optional)"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Image *
                            </label>
                            <Input
                              name="photo"
                              id="photo"
                              className="form-control-alternative"
                              placeholder="Select a photo"
                              type="file"
                              onChange={this.uploadPhoto}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Total *
                            </label>
                            <Input
                              required
                              name="total"
                              className="form-control-alternative"
                              placeholder="Enter the total"
                              type="text"
                              inputMode="decimal"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          {this.state.image && (
                            <PreviewFile previewImages={[{ viewingFrom: 'url', file: this.state.image }]} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              disabled={!this.state.image || submitting}
                              className="form-control-alternative"
                              color="info"
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  paymentAccount: state.paymentAccount,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, { addExpense, getPaymentAccounts })(withRouter(AddExpense));
