import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  convertJob,
  getJobs,
  sendAcceptedEmail,
} from '../redux/actions/jobAction';
import AuthService from '../services/services';

const authService = new AuthService();

let timer;
class ConvertJobFromEmail extends React.Component {
  state = {
    seconds: 10,
    sended: false,
  };

  componentDidMount() {
    // this.props.getJobs();
    // timer = setInterval(this.countDown, 1000);
  }

  countDown = () => {
    const seconds = this.state.seconds - 1;
    this.setState({
      seconds,
    });

    if (seconds === 0) {
      clearInterval(timer);
      // window.location.href = "https://greenacorn.com";
    }
  };

  render() {
    if (!this.state.sended) {
      const job = this.props.jobs.filter(
        item => item._id === this.props.match.params.id
      )[0];
      if (job) {
        authService.convertJob(this.props.match.params.id);
        authService.sendAcceptedEmail(job._id);
        this.setState({
          sended: true,
        });
      }
    }

    return (
      <>
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0 text-center">
            <CardBody className="px-lg-5 py-lg-5 text-center">
              Thank you for accepting the estimate
              <br />
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
});

export default connect(mapStateToProps, {
  getJobs,
  convertJob,
  sendAcceptedEmail,
})(ConvertJobFromEmail);
