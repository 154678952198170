import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_TIME_SENT = 'FETCH_TIME_SENT';
export const FETCH_TIME_SUCCESS = 'FETCH_TIME_SUCCESS';
export const FETCH_TIME_FAILURE = 'FETCH_TIME_FAILURE';

export const FETCH_TIME_ADD_SUCCESS = 'FETCH_TIME_ADD_SUCCESS';
export const FETCH_TIME_ADD_FAILURE = 'FETCH_TIME_ADD_FAILURE';

export const FETCH_TIME_UPDATE_SUCCESS = 'FETCH_TIME_UPDATE_SUCCESS';
export const FETCH_TIME_UPDATE_FAILURE = 'FETCH_TIME_UPDATE_FAILURE';

export const FETCH_TIME_REMOVE_SUCCESS = 'FETCH_TIME_REMOVE_SUCCESS';
export const FETCH_TIME_REMOVE_FAILURE = 'FETCH_TIME_REMOVE_FAILURE';

// const shouldGetList = (state, listArg) => {
//   const listInfo = state[listArg];
//   if (!listInfo || !listInfo.times) {
//     return true;
//   } else if ((new Date().getTime() - listInfo.lastUpdated) > 500) {
//     return true;
//   } else {
//     return listInfo.didInvalidate;
//   }
// }

export const addTime = data => async dispatch => {
  try {
    const response = await authService.addTime(data);

    dispatch({ type: FETCH_TIME_ADD_SUCCESS, payload: response.data.time });
  } catch (err) {
    dispatch({ type: FETCH_TIME_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const updateTime = (id, data) => async dispatch => {
  try {
    const response = await authService.updateTime(id, data);
    dispatch({
      type: FETCH_TIME_UPDATE_SUCCESS,
      payload: { id, data: response.data.TIME },
    });
  } catch (err) {
    dispatch({ type: FETCH_TIME_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const removeTime = id => async dispatch => {
  try {
    await authService.removeTime(id);
    dispatch({ type: FETCH_TIME_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_TIME_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};

export const getTimes = (id) => async (dispatch, getState) => {
  dispatch({ type: FETCH_TIME_SENT }); // get old
  try {
    const response = await authService.getTimes(id);
    dispatch({
      type: FETCH_TIME_SUCCESS
      , payload: response.data.times
      , receivedAt: new Date().getTime()
    });
  } catch (err) {
    dispatch({ type: FETCH_TIME_FAILURE, payload: err && err.message });
    console.error(err);
  }
};