import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`;

class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: 0
            , pageNumber: 1
        }
        this.onDocumentLoadSuccess.bind(this);
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }
    
    render() {
        const {
            numPages
        } = this.state;
        const {
            fileUrl
        } = this.props;

        return (
            <Document 
                file={fileUrl} 
                onLoadSuccess={this.onDocumentLoadSuccess}
                onLoadError={(err) => console.error("err", err)}
            >
                {
                    Array(numPages).fill().map((x, pageIndex) => <Page key={pageIndex} pageNumber={pageIndex+1} />)
                }
            </Document>
        );
    }
}

export default PDFViewer;