import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Label,
  Table
} from 'reactstrap';
// core components
import _ from 'lodash';
import moment from 'moment';

import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../helpers/file';
import { updateUser } from '../../redux/actions/userAction';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';
import { getPermissions } from '../../redux/actions/permissionAction';
import LoadBar from '../../components/shared/loadBar';


const toastr = require("toastr");
const permissionsItems = {
  estimates: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , jobs: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , invoices: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , expenses: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , time: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , reports: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , clients: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , workers: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , items: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , properties: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , propertyTypes: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , revenueCenter: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , propertyStages: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , lenders: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , paymentAccounts: {
    view: "all"
    , create: "all"
    , update: "all"
    , delete: "all"
  }
  , expenseTransactions: {
    reconcile: "all"
  , unreconcile: "all"
  }
}

const dateNow = moment().format('YYYY-MM-DD')

const getNearestRate = rateHistory => {
  return rateHistory.reduce((accum, item) => {
    if (!accum) {
      return item
    };
    const currentDayDiff = moment(item.date).diff(dateNow, 'days');
    if (currentDayDiff < 1) {
      const currentRateDayDiff = moment(accum.date).diff(item.date, 'days');
      if (currentDayDiff >= currentRateDayDiff) {
        return item;
      }
    }
    return accum;
  }, null);
}

class UpdateWorker extends React.Component {
  state = {
    name: '',
    role: '',
    email: '',
    address: '',
    contact: '',
    phone: '',
    mobile: '',
    activity: '',
    type: '',
    payRate: '',
    effective: '',
    submitting: false,
    permissions: permissionsItems,
    defaultPaymentAccount: null,
    rateAsOfDate: null, //  moment.utc().format('YYYY-MM-DD')
    permissionName: '',
    permissionId: ''
  };

  workerOptions = () => [
    { value: 'WORKER', label: 'WORKER' },
    { value: 'PROJECT MANAGER', label: 'PROJECT MANAGER' },
  ];

  typeOptions = () => [
    { value: '1099', label: '1099' },
    { value: 'Employee', label: 'Employee' },
  ];

  async componentDidMount() {
    await this.props.getPaymentAccounts();
    await this.props.getPermissions();

    
    const user = this.props.users.filter(
      item => item._id === this.props.match.params.id
    )[0];

    let nearestRate = null;
    if (user && user.rateHistory && user.rateHistory.length > 0) {
      nearestRate = getNearestRate(user.rateHistory);
    } else {
      nearestRate = {
        payRate: user && user.payRate ? user.payRate : 0 ,
        effectiveRate: user && user.effectiveRate ? user.effectiveRate : 0,
      }
    }

    this.setState(prevState => ({
      ...prevState,
      name: user.name,
      role: user.role,
      level: user.level,
      email: user.email,
      address: user.address,
      contact: user.contactName,
      phone: user.phone,
      mobile: user.mobile,
      activity: user.activity,
      type: user.type,
      payRate: nearestRate.payRate,
      effectiveRate: nearestRate.effectiveRate,
      tempEffectiveRate: nearestRate.effectiveRate,
      tempPayRate: nearestRate.payRate,
      permissions: user.permissions || permissionsItems,
      defaultPaymentAccount: user.defaultPaymentAccount,
      rateHistory: user.rateHistory || [],
      permissionName: user.permissionName || '',
      permissionId: user.permissionId || '',
    }));
  }

  async componentWillUnmount() {
    await this.props.getPaymentAccounts();

    this.setState = (state,callback)=>{
      return;
    };
  }

  handleInput = e => {
    e.persist();
    if (e.target.name === 'defaultPermission') {
      let arrayPermissions = this.props.defPermissions.find(item => item._id === e.target.value);
      this.setState(prevState => ({
        ...prevState,
        permissions: arrayPermissions.permissions,
        permissionName: arrayPermissions.name,
        permissionId: e.target.value
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if (image && image !== 'notNet.png') {
        this.setState(prevState => ({ ...prevState, img: image }));
      }
    } catch (e) {
      console.error(e);
      toastr.error('Server error.');
    }

  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true })
    let payload = {
      ...this.state,
      rateHistory: [
        ...this.state.rateHistory || []
      ]
    };

    if (this.state.rateAsOfDate) {
      if (moment().diff(this.state.rateAsOfDate, 'days') === 0) {
        payload = {
          ...payload,
          effectiveRate: this.state.tempEffectiveRate,
          payRate: this.state.tempPayRate,
          rateHistory: [
            ...(payload.rateHistory || []),
            {
              date: this.state.rateAsOfDate,
              effectiveRate: this.state.tempEffectiveRate,
              payRate: this.state.tempPayRate,
            }
          ]
        }
      }
      else {
        payload = {
          ...payload,
          rateHistory: [
            ...(payload.rateHistory || []),
            {
              date: this.state.rateAsOfDate,
              effectiveRate: this.state.tempEffectiveRate,
              payRate: this.state.tempPayRate,
            }
          ]
        }
      }
    }
    else {
      payload = {
        ...payload,
        effectiveRate: this.state.tempEffectiveRate,
        payRate: this.state.tempPayRate,
      }

    }

    await this.props.updateUser(this.props.match.params.id, payload);
    this.props.history.push(`/admin/workers`);
    this.setState({ submitting: false })
  };

  handleChange = (e) => {
    let newState = _.update(_.cloneDeep(this.state), e.target.name, () => {
      return e.target.value;
    });
    this.setState(newState);
  }

  render() {
    const user = this.state;
    const { submitting, permissions, permissionId } = this.state;
    if (!this.state) return <LoadBar />;
    const typeUser = user.type;
    // const roles = [{ role: 'WORKER' }, { role: 'PROJECT MANAGER' }];
    const types = [{ type: '1099' }, { type: 'Employee' }];

    const renderElements = [];
    const permissionsOrderDisplay = ['estimates', 'jobs', 'invoices', 'expenses', 'time', 'reports', 'clients', 'workers', 'items', 'properties', 'propertyTypes', 'revenueCenter', 'propertyStages', 'lenders', 'paymentAccounts'];
    const associatedInUserLogged = ['estimates', 'jobs', 'invoices', 'expenses', 'time'];
    permissionsOrderDisplay.map(moduleName => {
      const moduleActionsOrder = ['view', 'create', 'update', 'delete'];
      const renderChildElements = [];
      const permission = permissions[moduleName] || permissionsItems[moduleName];
      moduleActionsOrder.map(actionName => {
        const childElmt = (
          <FormGroup className={`-child-FG -${actionName}-fg`}>
            <Label
              className="form-control-label d-inline-block"
              htmlFor="input-name"
            >
              {_.startCase(actionName)}:
            </Label>
            <FormGroup>
              <div>
                <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="all"
                  checked={permission[actionName] === "all"} onChange={this.handleChange}
                /><span>All</span>
              </div>
              {
                associatedInUserLogged.indexOf(moduleName) === -1 ? null :
                  <div>
                    <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="assigned"
                      checked={permission[actionName] === "assigned"} onChange={this.handleChange}
                    /><span>Assigned</span></div>
              }
              <div>
                <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="none"
                  checked={permission[actionName] === "none"} onChange={this.handleChange} />
                <span>None</span></div>
            </FormGroup>
          </FormGroup>
        )
        renderChildElements.push(childElmt);

        return renderChildElements;
      });
      const elmt = (
        <FormGroup>
          <label
            className="form-control-label d-inline-block"
            htmlFor="input-name"
          >
            {_.startCase(moduleName)}
          </label>
          {renderChildElements}
        </FormGroup>
      )
      renderElements.push(elmt);

      return renderElements;
    });

    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">User Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Worker Name *
                            </label>
                            <Input
                              defaultValue={user.name}
                              className="form-control-alternative"
                              placeholder="Enter the worker name"
                              name="name"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email *
                            </label>
                            <Input
                              name="email"
                              defaultValue={user.email}
                              className="form-control-alternative"
                              placeholder="Enter a email"
                              type="email"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address *
                            </label>
                            <Input
                              name="address"
                              className="form-control-alternative"
                              placeholder="Enter an address"
                              type="text"
                              defaultValue={user.address}
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-contactName"
                            >
                              Contact Name *
                            </label>
                            <Input
                              name="contactName"
                              defaultValue={user.contact}
                              className="form-control-alternative"
                              placeholder="Enter a contact name"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone *
                            </label>
                            <Input
                              name="phone"
                              defaultValue={user.phone}
                              className="form-control-alternative"
                              placeholder="Enter the phone number"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-mobile"
                            >
                              Mobile *
                            </label>
                            <Input
                              name="mobile"
                              defaultValue={user.mobile}
                              className="form-control-alternative"
                              placeholder="Enter the mobile number"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-activity"
                            >
                              Activity *
                            </label>
                            <Input
                              name="activity"
                              defaultValue={user.activity}
                              className="form-control-alternative"
                              placeholder="Enter an activity (plumber, carpenter)"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-type"
                            >
                              Type *
                            </label>
                            <Input
                              name="type"
                              className="form-control-alternative"
                              type="select"
                              defaultValue={this.typeOptions().find(
                                op => op.value === typeUser
                              )}
                              onChange={this.handleInput}
                            >
                              {types.map((e, i) =>
                                user.type === e.type ? (
                                  <option selected key={i}>
                                    {user.type}
                                  </option>
                                ) : (
                                  <option key={i}>{e.type}</option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Add More Documents *
                            </label>
                            <Input
                              name="photo"
                              id="photo"
                              className="form-control-alternative"
                              type="file"
                              onChange={this.uploadPhoto}
                              multiple
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Payment Rate *
                            </label>
                            <Input
                              name="tempPayRate"
                              defaultValue={user.payRate}
                              className="form-control-alternative"
                              type="number"
                              placeholder="$0.00"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Effective Rate *
                            </label>
                            <Input
                              defaultValue={user.effectiveRate}
                              name="tempEffectiveRate"
                              className="form-control-alternative"
                              type="number"
                              placeholder="$0.00"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                             Default Payment Account *
                            </label>
                            <Input
                              name="defaultPaymentAccount"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              value={user && user.defaultPaymentAccount ? user.defaultPaymentAccount : 'Select'}
                            >
                              <option value="Select" disabled>
                                Select Default Payment Account
                              </option>
                              {this.props.paymentAccount.list.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                             Default Permission *
                            </label>
                            <Input
                              name="defaultPermission"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              value={permissionId}
                            >
                              <option value="Select" disabled>
                                Select Default Permission
                              </option>
                              {this.props.defPermissions.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Rate As Of
                            </label>
                            <Input
                              id="rateAsOfDate"
                              className="form-control-alternative"
                              placeholder="Select a date"
                              name="rateAsOfDate"
                              defaultValue={this.state.rateAsOfDate}
                              type="date"
                              onChange={this.handleInput}
                              min={dateNow}
                            />
                          </FormGroup>

                          <Row style={{ padding: 20 }}>
                            <div style={{ fontWeight: 'bolder' }}>
                              Rate History
                            </div>
                            <Table className="align-items-center table-flush" responsive>
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Pay Rate</th>
                                  <th scope="col">Effective Rate</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.rateHistory && this.state.rateHistory.map(item => {
                                  return <tr>
                                    <td>{item.payRate}</td>
                                    <td>{item.effectiveRate}</td>
                                    <td>{item.date}</td>
                                  </tr>
                                })}
                              </tbody>
                            </Table>
                          </Row>
                        </Col>
                        <Col lg="6">
                          {renderElements}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  paymentAccount: state.paymentAccount,
  users: state.user.users,
  userLogged: state.auth.userLogged,
  defPermissions: state.defPermission && state.defPermission.list !== undefined ? state.defPermission.list : []
});

export default connect(mapStateToProps, { updateUser, getPaymentAccounts, getPermissions })(
  withRouter(UpdateWorker)
);
