import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Table,
} from 'reactstrap';
// core components
import PhoneInput from 'react-phone-number-input';
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import Global from '../../global';
import { addEstimate } from '../../redux/actions/jobAction';
import { uploadPhoto } from '../../helpers/file';

const toastr = require("toastr");

const date = new Date();
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();

class AddEstimateByUser extends React.Component {
  state = {
    name: '',
    email: '',
    address: '',
    items: [],
    itemName: '',
    description: '',
    comments: '',
    comments_internal: '',
    quantity: '',
    rate: '',
    subtotal: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    total: 0,
    dateCreate:
      month < 10 ? `${day}-0${month}-${year}` : `${day}-${month}-${year}`,
    jobName: '',
    estimatePhone: '',
    value: '',
    suggestions: [],
    hereSuggestions: [],
  };

  async componentDidMount() {
    await axios
      .get(`${Global.url}oneclient/${this.props.match.params.id}`)
      .then(({ data }) => {
        this.setState(prevState => ({
          ...prevState,
          name: data.client.name,
          email: data.client.email,
          address: data.client.address,
          ...data.user,
        }));
      })
      .catch(err => {
        console.error(err);
      });
  }

  async componentWillUnmount() {
    await axios
    .get(`${Global.url}oneclient/${this.props.match.params.id}`);

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };

  }

  addToCart = product => {
    const joined = this.state.items.concat(product);
    if (this.state.itemName === '') {
      alert('The field Item Name is required to add item');
    } else if (this.state.quantity === '') {
      alert('The field quantity is required to add item');
    } else if (this.state.rate === '') {
      alert('The field rate is required to add item');
    } else {
      document.getElementById('itemName').value = '';
      document.getElementById('description').value = '';
      document.getElementById('quantity').value = '';
      document.getElementById('rate').value = '';
      this.setState({ items: joined });
    }
  };

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if (image) {
        this.setState(prevState => ({ ...prevState, img: image }));
      }
    } catch (e) {
      console.error(e);
      toastr.error('Server error.');
    }

  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    await this.props.addEstimate(this.state, 'estimates');
    this.props.history.push(`/admin/estimates`);
  };

  setPhoneNumber = e => {
    this.setState(prevState => ({
      ...prevState,
      estimatePhone: e,
    }));
  };

  onChange = async (event, { newValue }) => {
    this.setState(prevState => ({
      ...prevState,
      value: newValue,
      address: newValue,
    }));

    if (newValue.length > 4) {
      const response = await fetch(Global.hereUrl + newValue);
      const data = await response.json();
      if (data.suggestions.length > 0) {
        this.setState(prevState => ({
          ...prevState,
          languages: data.suggestions,
        }));
        document.getElementById('spinner').style.visibility = 'hidden';
      }
    }
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : this.state.languages.filter(
        text => text.label.toLowerCase().includes(inputValue) > 0
      );
  };

  getSuggestionValue = suggestion => suggestion.label;

  renderSuggestion = suggestion => <div>{suggestion.label}</div>;

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleInputItems = index => e => {
    const { items } = this.state;
    let { name, value } = e.target;

    let updatedItems = items.map((item, itemIndex) => {
      if (index === itemIndex) {
        let subtotal = 0;

        if (name === 'quantity') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        else if (name === 'rate') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.quantity;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        else {
          subtotal = item.rate * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        return {
          ...item,
          [name]: value,
          subtotal

        }
      }

      return item
    });

    const invoiceTotal = updatedItems.reduce(
      (acc, current) => acc + (parseFloat(current.rate) * current.quantity),
      0
    );

    this.setState({
      items: [...(updatedItems || [])],
      total: invoiceTotal
    });

  }

  render() {
    const product = {
      itemName: this.state.itemName,
      description: this.state.description,
      quantity: parseFloat(this.state.quantity),
      rate: parseFloat(this.state.rate),
      subtotal: parseFloat(this.state.quantity * this.state.rate),
    };
    const subtotal = this.state.items.reduce(
      (acc, current, i) => acc + current.subtotal,
      0
    );
    const tax = (parseInt(this.state.tax) * subtotal) / 100;
    const discount = parseInt(this.state.discount);
    const paid = parseInt(this.state.paid);
    const { name } = this.state;
    const { address } = this.state;

    const total = subtotal + tax - discount - paid;
    const jobName = name + ' - ' + address;

    const { value, suggestions } = this.state;
    const inputProps = {
      value,
      onChange: this.onChange,
    };

    if (!this.state) return <p> Loading </p>;
    return (
      <>
        <Header /> {/* Page content */}{' '}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0"> Estimate Information </h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Estimate Client Name *
                            </label>
                            <Input
                              disabled
                              value={`${this.state.name}`}
                              className="form-control-alternative"
                              placeholder="Enter the name client"
                              name="name"
                              type="text"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Email*
                            </label>
                            <Input
                              disabled
                              value={`${this.state.email}`}
                              className="form-control-alternative"
                              placeholder="Enter the email client"
                              name="email"
                              type="email"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Phone number *
                            </label>
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={this.state.estimatePhone}
                              onChange={this.setPhoneNumber}
                            />
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Address *
                            </label>
                            <Autosuggest
                              alwaysRenderSuggestions={true}
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                              }
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Items
                            </label>

                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col"> Item Name </th>
                                  <th scope="col"> Description </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Rate </th>
                                  <th scope="col"> Total </th>
                                  <th scope="col"> Options </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.items.map((el, i) => (
                                  <tr key={i}>
                                    <td> {/* el.itemName*/}

                                      <Input
                                        defaultValue={el.itemName}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemName"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemDescription}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemDescription"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        value={el.quantity}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="quantity"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        value={el.rate}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="rate"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                      />
                                    </td>
                                    <td>$ {el.subtotal} </td>
                                    <td>
                                      <Button
                                        onClick={e =>
                                          this.setState(prevState => {
                                            const filter =
                                              this.state.items.filter(
                                                e => {
                                                  this.setState({ total: this.state.total - this.state.items[i].subtotal });
                                                  return e !== this.state.items[i]
                                                }
                                              );
                                            this.setState({ items: filter });
                                          })
                                        }
                                        style={{
                                          width: '100px',
                                          height: '20px',
                                          fontSize: '10px',
                                          paddingTop: '0',
                                        }}
                                        className="btn icon-btn btn-danger"
                                      >
                                        <i className="nc-icon nc-simple-remove" />{' '}
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Item Name *
                            </label>
                            <Input
                              name="itemName"
                              className="form-control-alternative"
                              placeholder="Enter a name of item"
                              type="text"
                              id="itemName"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Description
                            </label>
                            <Input
                              name="description"
                              className="form-control-alternative"
                              placeholder="Enter a description of item"
                              type="textarea"
                              rows={5}
                              id="description"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Quantity *
                            </label>
                            <Input
                              name="quantity"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="quantity"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Rate *
                            </label>
                            <Input
                              name="rate"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="rate"
                              onChange={this.handleInput}
                            />
                            <br />
                            <button
                              type="button"
                              onClick={e => this.addToCart(product)}
                              className="btn btn-primary"
                            >
                              Add Item
                            </button>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Image{' '}
                            </label>
                            <Input
                              name="photo"
                              id="photo"
                              className="form-control-alternative"
                              placeholder="Select a photo"
                              type="file"
                              onChange={this.uploadPhoto}
                              multiple
                            />
                            <br />

                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Image Preview
                            </label>
                            {this.state.img && (
                              <img
                                width="100%"
                                height="400px"
                                src={this.state.img}
                                alt="photo_url"
                              />
                            )}
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              External Comments{' '}
                            </label>
                            <Input
                              defaultValue={`${this.state.comments}`}
                              name="comments"
                              className="form-control-alternative"
                              placeholder="Enter comments (optional)"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Internal Comments (not visible to clients){' '}
                            </label>
                            <Input
                              name="comments_internal"
                              className="form-control-alternative"
                              placeholder="Enter comments (optional)"
                              type="textarea"
                              rows="6"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <h4>Estimate</h4>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Date Create{' '}
                            </label>
                            <Input
                              defaultValue={`${this.state.dateCreate}`}
                              name="dateCreate"
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                              width="50%"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Subtotal{' '}
                            </label>
                            <Input
                              name="subtotal"
                              value={parseInt(subtotal)}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-tax"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Tax{' '}
                            </label>
                            <Input
                              placeholder="0"
                              name="tax"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          {/* <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Discount
                            </label>
                            <Input
                              placeholder="0"
                              name="discount"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Paid{' '}
                            </label>
                            <Input
                              name="paid"
                              placeholder="0"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup> */}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-total"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total{' '}
                            </label>
                            <Input
                              disabled
                              name="total"
                              value={total}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              name="jobName"
                              value={jobName}
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                              width="50%"
                              hidden
                            />
                          </FormGroup>{' '}
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  className="form-control-alternative"
                                  color="info"
                                >
                                  Save{' '}
                                </Button>{' '}
                              </FormGroup>{' '}
                            </Col>{' '}
                          </Row>{' '}
                        </Col>{' '}
                      </Row>
                    </div>{' '}
                  </Form>{' '}
                </CardBody>{' '}
              </Card>{' '}
            </Col>
          </Row>{' '}
        </Container>{' '}
      </>
    );
  }
}

export default connect(null, { addEstimate })(withRouter(AddEstimateByUser));
