import React from 'react';
import GreenAcorn25 from '../../assets/img/acorn-25.png'
import GreenAcorn50 from '../../assets/img/acorn-50.png'
import GreenAcorn75 from '../../assets/img/acorn-75.png'
import GreenAcorn100 from '../../assets/img/acorn-100.png'


class LoadBar extends React.Component {
    render() {
      let images = [GreenAcorn25, GreenAcorn50, GreenAcorn75, GreenAcorn100];
      var lap = 0;
      let timeleft = 0;
      let imgLoaded = GreenAcorn25;
      const loopImage = setInterval(function(){

        if (timeleft === images.length) {
          timeleft = 0;
          lap += 1;
        }

        imgLoaded = images[timeleft];
        document.getElementById('imgLoader').src = imgLoaded;

        if (lap === 7) {
          clearInterval(loopImage);
        }

        timeleft += 1;
      }, 2000);


        return (
          <>
            <div
              id="spinner"
              style={{
                display: 'flex',
                backgroundColor: 'rgba(183,183,183,0.5)',
                alignContent: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                visibility: 'hidden',
                position: 'fixed',
                zIndex: '1',
              }}
            >
              <div>
                <img src={imgLoaded} width={150} height={150} id='imgLoader' alt='Loader' />
                <p style={{ fontSize: '25px', fontWeight: 'bold', color: 'white' }}>
                  L O A D I N G
                </p>
              </div>
            </div>
          </>
        );
      }
}

export default (LoadBar);