import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';

class IndexSetting extends React.Component {
  render() {
    const userLogged = this.props.userLogged;
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Actions</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    {
                      userLogged && userLogged.permissions && userLogged.permissions.items && userLogged.permissions.items.view === "none" ? null
                        :
                        <Col lg="6" className="mb-5">
                          <Button
                            style={{ width: '50%' }}
                            className="form-control-alternative"
                            color="info"
                            onClick={() => {
                              this.props.history.push(`/admin/items`);
                            }}
                          >
                            Items
                          </Button>
                        </Col>
                    }
                    {
                      userLogged && userLogged.permissions && userLogged.permissions.propertyStages && userLogged.permissions.propertyStages.view === "none" ? null
                        :
                        <Col lg="6" className="mb-5">
                          <Button
                            style={{ width: '50%' }}
                            className="form-control-alternative"
                            color="info"
                            onClick={() => {
                              this.props.history.push(`/admin/propertysettings/stages`)
                            }}
                          >
                            Property Stage
                          </Button>
                        </Col>
                    }
                    {
                      userLogged && userLogged.permissions && userLogged.permissions.propertyTypes && userLogged.permissions.propertyTypes.view === "none" ? null
                        :
                        <Col lg="6" className="mb-5">
                          <Button
                            style={{ width: '50%' }}
                            className="form-control-alternative"
                            color="info"
                            onClick={() => {
                              this.props.history.push(`/admin/propertysettings/types`)
                            }}
                          >
                            Property Type
                          </Button>
                        </Col>
                    }
                    {
                      userLogged && userLogged.permissions && userLogged.permissions.lenders && userLogged.permissions.lenders.view === "none" ? null
                        :
                        <Col lg="6" className="mb-5">
                          <Button
                            style={{ width: '50%' }}
                            className="form-control-alternative"
                            color="info"
                            onClick={() => {
                              this.props.history.push(`/admin/propertysettings/lenders`)
                            }}
                          >
                            Lenders
                          </Button>
                        </Col>
                    }
                    {
                      userLogged && userLogged.permissions && userLogged.permissions.revenueCenter && userLogged.permissions.revenueCenter.view === "none" ? null
                        :
                        <Col lg="6" className="mb-5">
                          <Button
                            style={{ width: '50%' }}
                            className="form-control-alternative"
                            color="info"
                            onClick={() => {
                              this.props.history.push(`/admin/revenuecenter`)
                            }}
                          >
                            Revenue Center
                          </Button>
                        </Col>
                    }
                    {
                      userLogged && userLogged.permissions && userLogged.permissions.paymentAccounts && userLogged.permissions.paymentAccounts.view === "none" ? null
                        :

                    <Col lg="6" className="mb-5">
                      <Button
                        style={{ width: '50%' }}
                        className="form-control-alternative"
                        color="info"
                        onClick={() => {
                          this.props.history.push(`/admin/paymentaccounts`)
                        }}
                      >
                        Payment Accounts
                      </Button>
                    </Col>
                    }
                                        {
                      userLogged && userLogged.permissions && userLogged.permissions.paymentAccounts && userLogged.permissions.paymentAccounts.view === "none" ? null
                        :

                    <Col lg="6" className="mb-5">
                      <Button
                        style={{ width: '50%' }}
                        className="form-control-alternative"
                        color="info"
                        onClick={() => {
                          this.props.history.push(`/admin/defaultpermission`)
                        }}
                      >
                        Default permissions
                      </Button>
                    </Col>
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

// export default IndexSetting;

const mapStateToProps = state => ({
  users: state.user.users,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps)(
  withRouter(IndexSetting)
);

