import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Form,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input';

import moment from 'moment';
// core components
import Header from 'components/Headers/Header.jsx';
import { useSelector, useDispatch } from 'react-redux';
// import { connect } from 'react-redux';
// import { addTime } from '../../redux/actions/timeAction';
// import { getJobs } from '../../redux/actions/jobAction';
// import { getUsers } from '../../redux/actions/userAction';
import { addProperty, updateproperty, getPropertyStages, getLenders, getPropertyTypes } from '../../redux/actions/propertyAction';


import { GooglePlacesAutocomplete } from '../../components/Form/GooglePlacesAutocomplete';
import PreviewFile from '../../helpers/PreviewFile';

import AuthService from '../../services/services';

const authService = new AuthService();


const defaultComps = {
    weight: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    bedrooms: '',
    bathrooms: '',
    sqft: '',
    acres: '',
    yearsBuilt: '',
    listPriceSqft: '',
    salePrice: '',
    dom: '',
    link: '',
    notes: ''
};

// const defaultPropertyDetails = {
//     stage: 1,
//     sellerFirstname: 'Dennis',
//     sellerLastname: 'San Jose',
//     sellerPhone: '1231121213',
//     sellerEmail: 'dennis@gmail.com',
//     leadNotes: 'test',
//     propertyInfoAddress1: 'test st',
//     propertyInfoAddress2: 'test2 st',
//     city: 'Cary',
//     state: 'North Carolina',
//     heatedAboveGradeSqft: '1',
//     heatedBelowGradeSqft: '1',
//     bedrooms: '1',
//     fullBaths: '1',
//     halfBaths: '1',
//     parking: '1',
//     acres: '1',
//     yearsBuilt: '2010',

//     propertyType: 'Manufactured',
//     currentTenants: 'test2',
//     leftInLease: 'test2',
//     notesOnProperty: 'sample notes',

//     dealContractAmount: '20000',
//     dealPurchaseContractDate: '2022-01-01',
//     dealClosingDate: '2022-01-20',

//     notesArv: 'test notes',
//     estimatedArv: 'test notes222',

//     repairEstimate: 2,
//     markedUpRepairEstimate: 2,
//     financingRequested: 1,
//     ltv: 2,
//     linkToPictures: 'test',
//     linkToFiles: 'test',
//     scopeOfWorkWithBudget: 'test',
//     scopeOfWorkWithMarkedUpBudget: 'test',
//     projectRehabCompletionDate: '2022-01-01',
//     projectedSaleDate: '2022-01-01',


//     lenderName: 'test',
//     acquisitionFunded: 2000,
//     repairFunded: 2000,
//     totalFunded: 2000,
//     applicationFee: 2000,
//     appraisalFee: 2000,
//     originationFee: 2000,
//     interestRate: 5,
//     interestPaidMonthly: 5,

//     term: '3 months',


//     rehabInvoices: 'test',

//     invoiceImage: 'test',
//     invoiceAmount: 2000,

//     listingDate: '2022-01-01',
//     listingUnderContractDate: '2022-02-02',


//     contractUnderContractDate: '2022-01-02',
//     contractClosingDate: '2022-01-02',
//     contractSalesPrice: 2000,


//     saleDate: '2022-01-02',
//     saleAmount: 2000,

// }


const AddUpdateProperty = props => {


    const { history, match } = props;

    const dispatch = useDispatch();

    const currentId = match && match.params && match.params.id;

    const { connection, property } = useSelector(state => {
        return {
            auth: state.auth,
            connection: state.connection,
            property: state.property
        }
    });

    const [selectedInvoiceFiles, setInvoiceFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [currentLender, setCurrentLender] = useState(null);
    const [currentLenderTemplateId, setCurrentLenderTemplateId] = useState('');
    const [propertyDetails, setPropertyDetails] = useState({});
    const [comps, setComps] = useState([]);
    // const [draws, setDraws] = useState([]);
    const [compsSalesPriceSqft, setCompsSalesPriceSqft] = useState(0);


    useEffect(() => {
        dispatch(getPropertyStages());
        dispatch(getPropertyTypes());
        dispatch(getLenders());
    }, []);

  
    useEffect(() => {
        if (currentId && property.properties.length > 0) {

            const currentProperty = property.properties.find(item => item._id === currentId);
       
            if (currentProperty) {
                // const currentLenderInfo = property.lenders.find(item => item._id === currentProperty.lenderId);

                const compsSalesPriceSqftAvg = getCompsAvg(currentProperty.comps);
                setCompsSalesPriceSqft(compsSalesPriceSqftAvg);

                setPropertyDetails(currentProperty);
                setCurrentLender(currentProperty && currentProperty.lenderDetails);
                setComps(currentProperty.comps || []);

                setInvoiceFiles([
                    {
                        file: currentProperty && currentProperty.rehabInvoices && currentProperty.rehabInvoices.invoiceImage,
                        viewingFrom: "url"
                    }
                ]);
            }

        }
    }, [currentId, property.properties, property.lenders]);



    const getCompsAvg = data => {
        const sqftAvg = data.reduce((accum, item) => {
            return accum + item.salePriceSqft
        }, 0);

        return sqftAvg / data.length
    }

    const handleSelectedFiles = (event) => {
        const previewImages = [];
        previewImages.push({ file: event.target.files[0], viewingFrom: "input-file" });

        setInvoiceFiles(previewImages);
    };


    const handleInput = e => {

        const { name, value } = e.target;
        const splittedField = name.split('.');

        if (splittedField.length > 1) {

            setPropertyDetails({
                ...(propertyDetails || {}),
                [splittedField[0]]: {
                    ...(propertyDetails[splittedField[0]] || {}),
                    [splittedField[1]]: value
                }
            })
        }
        else {
            setPropertyDetails({
                ...(propertyDetails || {}),
                [name]: value
            })
        }


    };

    const handlePhoneNumberInput = value => {
        setPropertyDetails({
            ...(propertyDetails || {}),
            seller: {
                ...(propertyDetails.seller || {}),
                phoneNumber: value
            }
        });
    }

    const handleInputLender = e => {
        const { name, value } = e.target;
        setCurrentLender({
            ...(currentLender || {}),
            [name]: value
        });
    }


    const handleInputComps = (index, type = 'number') => e => {

        let { name, value } = e.target;
        let updatedComps = comps.map((item, itemIndex) => {
            if (index === itemIndex) {

                return {
                    ...item,
                    [name]: type === 'string' ? value : parseInt(value)

                }
            }
            return item
        });

        const salePriceSqftAvg = updatedComps.reduce((accum, item) => {
            return accum + item.salePriceSqft
        }, 0);

        if (salePriceSqftAvg > 0) {
            setCompsSalesPriceSqft(salePriceSqftAvg / updatedComps.length)
        }
        setComps(updatedComps);

    }



    const handleDeleteComps = index => {
        let updatedComps = comps.filter((item, itemIndex) => index !== itemIndex);
        const sqftAvg = updatedComps.reduce((accum, item) => {
            return accum + item.salePriceSqft
        }, 0);

        setCompsSalesPriceSqft(sqftAvg > 0 ? sqftAvg / updatedComps.length : 0)
        setComps(updatedComps);
    }

    // const handleInputDraws = index => e => {

    //     let { name, value } = e.target;
    //     let updatedComps = draws.map((item, itemIndex) => {
    //         if (index === itemIndex) {

    //             return {
    //                 ...item,
    //                 [name]: value

    //             }
    //         }

    //         return item
    //     });
    //     setDraws(updatedComps);

    // }

    const handleSelectAddress = name => (value, place = {}) => {
        const splittedField = name.split('.');
        if (splittedField.length > 1) {

            setPropertyDetails({
                ...(propertyDetails || {}),
                [splittedField[0]]: {
                    ...(propertyDetails[splittedField[0]] || {}),
                    [splittedField[1]]: value,
                    city: place.city || (propertyDetails[splittedField[0]] && propertyDetails[splittedField[0]].city) || '',
                    state: place.state || (propertyDetails[splittedField[0]] && propertyDetails[splittedField[0]].state)  || '',
                    zip: place.zip || (propertyDetails[splittedField[0]] && propertyDetails[splittedField[0]].zip)  || '',
                }
            })
        }
    }

    // const handleSelectAddressItems = name => (value, place = {}) => {
    //     const splittedField = name.split('.');
    //     if (splittedField.length > 1) {

    //         setPropertyDetails({
    //             ...(propertyDetails || {}),
    //             [splittedField[0]]: {
    //                 ...(propertyDetails[splittedField[0]] || {}),
    //                 [splittedField[1]]: value,
    //                 city: place.city || '',
    //                 state: place.state || '',
    //             }
    //         })
    //     }
    // }

    const handleSubmit = async (e, props) => {
        e.preventDefault();
        setIsSaving(true);

        let payload = {
            ...propertyDetails,
            lenderDetails: {
                ...(currentLender || {})
            },
            comps
        };


        if (selectedInvoiceFiles.length > 0) {
            if (selectedInvoiceFiles[0].viewingFrom === 'input-file') {
                const uploadResponse = await authService.upload(selectedInvoiceFiles[0].file);

                if (uploadResponse && uploadResponse.data) {
                    payload = {
                        ...payload,
                        rehabInvoices: {
                            ...(payload.rehabInvoices || {}),
                            invoiceImage: uploadResponse.data.img
                        }
                    }
                }
            }

        }


        if (payload && payload._id) {
            dispatch(updateproperty(payload._id, payload));
            setTimeout(() => {
                setIsSaving(false);
            }, 2000);
        }
        else {
            dispatch(addProperty(payload));

            setTimeout(() => {
                setIsSaving(false);
                history.push(`/admin/properties`)
            }, 2000);
        }

    };

    const handleAddComps = () => {
        setComps([
            ...comps,
            defaultComps
        ])
    };

    // const handleAddDraws = () => {
    //     setDraws([
    //         ...draws,
    //         defaultDraws
    //     ])
    // };

    const toggleUploadModal = () => {
        setIsUploadModalVisible(!isUploadModalVisible);
    }


    const handleSelectLender = e => {
        const { value } = e.target;
        
        const lender = property.lenders.find(item => item._id === value);

        setCurrentLender({
            ...(lender || ((propertyDetails && propertyDetails.lenderDetails )|| {}))
        });
        setCurrentLenderTemplateId(value);
        setPropertyDetails({
            ...propertyDetails
        });
    }
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    {/* <div className="col">
                                        <h3 className="mb-0">Information</h3>
                                    </div> */}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    General Information
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row style={{
                                            position: 'sticky',
                                            top: 10,
                                            right: 70,
                                            zIndex: 999

                                        }}>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <Button
                                                        style={{ float: 'right' }}
                                                        disabled={isSaving}
                                                        className="form-control-alternative"
                                                        color="info"
                                                    >
                                                        {isSaving ? 'Please Wait...' : 'Save'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Stage
                                                    </label>

                                                    <Input
                                                        required={true}
                                                        name="stage"
                                                        className="form-control-alternative"
                                                        type="select"
                                                        onChange={handleInput}
                                                        defaultValue="Select"
                                                        value={propertyDetails && propertyDetails.stage}
                                                    >
                                                        <option value="Select" disabled>
                                                            Select Stage
                                                        </option>
                                                        {property && property.propertyStages.map((item) => (
                                                            <option key={item._id} value={item._id}>
                                                                {item.stage}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Seller Firstname *
                                                    </label>
                                                    <Input
                                                        required={true}
                                                        name="seller.firstName"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.seller && propertyDetails.seller.firstName}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Seller Lastname *
                                                    </label>
                                                    <Input
                                                        required={true}
                                                        name="seller.lastName"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.seller && propertyDetails.seller.lastName}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Seller Phone *
                                                    </label>
                                                    {/* <Input
                                                        required={true}
                                                        name="seller.phoneNumber"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.seller && propertyDetails.seller.phoneNumber}
                                                    /> */}
                                                    <PhoneInput
                                                        className={!connection.status ? "phone-number-disconnect" : ""}
                                                        international={false}
                                                        defaultCountry="US"
                                                        placeholder="Enter phone number"
                                                        value={propertyDetails && propertyDetails.seller && propertyDetails.seller.phoneNumber}
                                                        onChange={handlePhoneNumberInput}
                                                    />

                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Seller Email *
                                                    </label>
                                                    <Input
                                                        required={true}
                                                        name="seller.email"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="email"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.seller && propertyDetails.seller.email}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>



                                        <Row>
                                            <Col lg="8">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Property Address *
                                                    </label>
                                                    {/* <Input
                                                        required={true}
                                                        name="propertyInfo.address1"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.address1}

                                                    /> */}
                                                    <GooglePlacesAutocomplete onSelect={handleSelectAddress('propertyInfo.address1')} value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.address1} />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        {/* 
                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    Property Information
                                                </label>
                                            </Col>
                                        </Row> */}

                                        {/* 
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Address 1
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.address1"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.address1}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Address 2
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.address2"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.address2}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row> */}


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        City *
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.city"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.city}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        State *
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.state"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.state}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>

                                        <Row>
                                        <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Zip *
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.zip"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.zip}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="8">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Lead Notes
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        name="leadNotes"
                                                        type="textarea"
                                                        rows={5}
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.leadNotes}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Heated Above Grade Sqft
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.heatedAboveGradeSqft"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.heatedAboveGradeSqft}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Heated Below Grade Sqft
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.heatedBelowGradeSqft"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.heatedBelowGradeSqft}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Bedrooms
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.bedrooms"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.bedrooms}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Full Baths
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.fullBaths"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.fullBaths}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Half Baths
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.halfBaths"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.halfBaths}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>



                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Parking
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.parking"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.parking}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Acres
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.acres"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.acres}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Years Built
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.yearsBuilt"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.yearsBuilt}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Property Type
                                                    </label>

                                                    <Input
                                                        name="propertyInfo.type"
                                                        className="form-control-alternative"
                                                        type="select"
                                                        onChange={handleInput}
                                                        defaultValue="Select"
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.type}
                                                    >
                                                        <option value="Select" disabled>
                                                            Select Property Type
                                                        </option>

                                                        {
                                                            property && property.propertyTypes.map(item => (
                                                                <option key={item._id} value={item._id}>
                                                                    {item.name}
                                                                </option>))
                                                        }

                                                    </Input>
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Current Tenants
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.currentTenants"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.currentTenants}

                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Left on Current Lease
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.leftInLease"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.leftInLease}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Notes on Property
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        name="propertyInfo.notes"
                                                        type="textarea"
                                                        rows={5}
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.notes}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        style={{ fontSize: 24, fontWeight: 'bold' }}
                                                    >
                                                        ARV Calculations
                                                    </label>
                                                    <Table
                                                        className="align-items-center table-flush"
                                                        responsive
                                                    >
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Weight</th>
                                                                <th scope="col">Address 1</th>
                                                                {/* <th scope="col">Address 2</th> */}
                                                                <th scope="col">City</th>
                                                                <th scope="col">State</th>
                                                                <th scope="col">Zip</th>
                                                                <th scope="col">Bedrooms</th>
                                                                <th scope="col">Bathrooms</th>
                                                                <th scope="col">Sqft</th>
                                                                <th scope="col">Acres</th>

                                                                <th scope="col">Years Built</th>
                                                                <th scope="col">List Price/Sqft</th>
                                                                <th scope="col">Sale Price</th>
                                                                <th scope="col">Sale Price/Sqft</th>
                                                                <th scope="col">DOM</th>
                                                                <th scope="col">Link</th>
                                                                <th scope="col">Notes</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {comps.map((el, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.weight}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="weight"
                                                                            type="number"
                                                                            step="any"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: 200 }}>
                                                                        <Input
                                                                            defaultValue={el.address1}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="address1"
                                                                            type="text"
                                                                            style={{ width: 120 }}
                                                                            onChange={handleInputComps(index, 'string')}
                                                                        />


                                                                    </td>
                                                                    {/* <td>
                                                                        <Input
                                                                            defaultValue={el.address2}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="address2"
                                                                            type="text"
                                                                            style={{ width: 120 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td> */}
                                                                    <td >
                                                                        <Input
                                                                            defaultValue={el.city}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="city"
                                                                            type="text"
                                                                            style={{ width: 120 }}
                                                                            onChange={handleInputComps(index, 'string')}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.state}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="state"
                                                                            type="text"
                                                                            style={{ width: 120 }}
                                                                            onChange={handleInputComps(index, 'string')}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.zip}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="zip"
                                                                            type="text"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.bedrooms}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="bedrooms"
                                                                            type="text"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.bathrooms}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="bathrooms"
                                                                            type="text"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.sqft}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="sqft"
                                                                            type="text"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.acres}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="acres"
                                                                            type="number"
                                                                            step="any"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.yearBuilt}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="yearBuilt"
                                                                            type="text"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.listPriceSqft}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="listPriceSqft"
                                                                            type="text"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.salePrice}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="salePrice"
                                                                            type="number"
                                                                            step="any"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.salePriceSqft}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="salePriceSqft"
                                                                            type="number"
                                                                            step="any"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.dom}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="dom"
                                                                            type="text"
                                                                            style={{ width: 80 }}
                                                                            onChange={handleInputComps(index, 'string')}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.link}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="link"
                                                                            type="text"
                                                                            style={{ width: 120 }}
                                                                            onChange={handleInputComps(index, 'string')}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <Input
                                                                            defaultValue={el.notes}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="notes"
                                                                            type="text"
                                                                            style={{ width: 130 }}
                                                                            onChange={handleInputComps(index, 'string')}
                                                                        />
                                                                    </td>

                                                                    <td>

                                                                        <Button
                                                                            onClick={() => {
                                                                                handleDeleteComps(index);
                                                                            }}
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '20px',
                                                                                fontSize: '10px',
                                                                                paddingTop: '0',
                                                                            }}
                                                                            className="btn icon-btn btn-danger"
                                                                        >
                                                                            <i className="nc-icon nc-simple-remove" />
                                                                            Delete
                                                                        </Button>
                                                                    </td>



                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <br />


                                                    <Row>
                                                        <Col md={6}>
                                                            <button
                                                                onClick={handleAddComps}
                                                                type="button"
                                                                // </Col>onClick={e => this.addToCart(product)}
                                                                className="btn btn-primary"
                                                            >
                                                                Add Comps
                                                            </button>
                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-merchant"
                                                                >
                                                                    Avg Sales Price/Sqft
                                                                </label>
                                                                <Input
                                                                    disabled={true}
                                                                    name="compsSalesPriceSqft"
                                                                    className="form-control-alternative"
                                                                    placeholder=""
                                                                    type="number"
                                                                    step="any"
                                                                    value={compsSalesPriceSqft}
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-merchant"
                                                                >
                                                                    Estimated ARV
                                                                </label>
                                                                <Input
                                                                    name="estimatedArv"
                                                                    className="form-control-alternative"
                                                                    placeholder=""
                                                                    type="textarea"
                                                                    onChange={handleInput}
                                                                    rows={5}
                                                                    value={propertyDetails && propertyDetails.estimatedArv}
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-merchant"
                                                                >
                                                                    Notes ARV
                                                                </label>
                                                                <Input
                                                                    name="notesArv"
                                                                    className="form-control-alternative"
                                                                    placeholder=""
                                                                    type="textarea"
                                                                    onChange={handleInput}
                                                                    rows={5}
                                                                    value={propertyDetails && propertyDetails.notesArv}
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                    </Row>


                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    Deal Information
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Contract Amount
                                                    </label>
                                                    <Input
                                                        name="dealInfo.contractAmount"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.contractAmount}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Purchase Contract Date
                                                    </label>
                                                    <Input
                                                        name="dealInfo.purchaseContractDate"
                                                        className="form-control-alternative"
                                                        placeholder="mm-dd-yyyy"
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && moment(propertyDetails.dealInfo.purchaseContractDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Anticipating Closing Date
                                                    </label>
                                                    <Input
                                                        name="dealInfo.closingDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && moment(propertyDetails.dealInfo.closingDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Closing Date
                                                    </label>
                                                    <Input
                                                        name="dealInfo.closingDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && moment(propertyDetails.dealInfo.closingDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Repair Estimate
                                                    </label>
                                                    <Input
                                                        name="dealInfo.repairEstimateNumber"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.repairEstimateNumber}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Marked Up Repair Estimate
                                                    </label>
                                                    <Input
                                                        name="dealInfo.markedUpRepairEstimate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.markedUpRepairEstimate}

                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Financing Requested
                                                    </label>
                                                    <Input
                                                        name="dealInfo.financingRequested"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.financingRequested}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        LTV
                                                    </label>
                                                    <Input
                                                        name="dealInfo.ltv"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.ltv}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Link to Pictures
                                                    </label>
                                                    <Input
                                                        name="dealInfo.image"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.image}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Link to Files
                                                    </label>
                                                    <Input
                                                        name="dealInfo.file"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.files}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Scope of Work with Budget
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        name="dealInfo.scopeOfWorkWithBudget"
                                                        type="textarea"
                                                        onChange={handleInput}
                                                        rows={5}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.scopeOfWorkWithBudget}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Scope of Work with Marked Up Budget
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        name="dealInfo.scopeOfWorkWithMarkedUpBudget"
                                                        type="textarea"
                                                        onChange={handleInput}
                                                        rows={5}
                                                        value={propertyDetails && propertyDetails.dealInfo && propertyDetails.dealInfo.scopeOfWorkWithMarkedUpBudget}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Projected Rehab Completion Date
                                                    </label>
                                                    <Input
                                                        name="dealInfo.projectRehabCompletionDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && moment(propertyDetails.dealInfo.projectRehabCompletionDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>



                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Projected Sale Date
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        name="dealInfo.projectedSaleDate"
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.dealInfo && moment(propertyDetails.dealInfo.projectedSaleDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>


                                        </Row>

                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    Lender
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="12">
                                                <Input
                                                    name="lenderId"
                                                    className="form-control-alternative"
                                                    type="select"
                                                    onChange={handleSelectLender}
                                                    defaultValue="Select"
                                                    value={currentLenderTemplateId}
                                                >
                                                    <option value="" >
                                                        Select Lender
                                                    </option>
                                                    {property && property.lenders.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Lender Name
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="name"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.name}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Acquisition Funded
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="typicalAcquisition"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.typicalAcquisition}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Repair Funded
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="typicalRepair"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.typicalRepair}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Total Funded
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="totalFunded"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.totalFunded}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Application Fee
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="applicationFee"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.applicationFee}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Appraisal Fee
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="appraisalFee"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.appraisalFee}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Origination Fee
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="originationPoints"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.originationPoints}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Interest Rate
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="interestRate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.interestRate}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Interest Paid Monthly
                                                    </label>
                                                    <Input
                                                       // disabled={true}
                                                        name="interestPaidMonthly"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.interestPaidMonthly}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Term
                                                    </label>
                                                    <Input
                                                        // disabled={true}
                                                        name="term"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.term}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Draw Fee
                                                    </label>
                                                    <Input
                                                       // disabled={true}
                                                        name="drawFee"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInputLender}
                                                        value={currentLender && currentLender.drawFee}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        {/* <Row>

                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        style={{ fontSize: 24, fontWeight: 'bold' }}
                                                    >
                                                        Draws
                                                    </label>

                                                    <Table
                                                        className="align-items-center table-flush"
                                                        responsive
                                                    >
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Draw</th>
                                                                <th scope="col">Date</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {draws.map((el, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Input
                                                                            value={el.draw}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="weight"
                                                                            type="text"
                                                                            style={{ width: '100%' }}
                                                                            onChange={handleInputDraws(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            value={moment(el.date).format('yyyy-MM-DD')}
                                                                            className="form-control-alternative"
                                                                            placeholder=""
                                                                            name="date"
                                                                            type="date"
                                                                            style={{ width: '100%' }}
                                                                            onChange={handleInputDraws(index)}
                                                                        />
                                                                    </td>
                                                                </tr>))}
                                                        </tbody>
                                                    </Table>
                                                    <br />


                                                    <Row>
                                                        <Col md={6}>
                                                            <button
                                                                onClick={handleAddDraws}
                                                                type="button"
                                                           
                                                                className="btn btn-primary"
                                                            >
                                                                Add Draws
                                                            </button>
                                                        </Col>

                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row> */}



                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Total Draws Paid
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.totalDrawsPaid"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.totalDrawsPaid}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Total Draws Remaining
                                                    </label>
                                                    <Input
                                                        name="propertyInfo.totalDrawsRemaining"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.propertyInfo && propertyDetails.propertyInfo.totalDrawsRemaining}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    Rehab Invoices
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    {/* <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Invoice Image
                                                    </label> */}
                                                    {/* <Input
                                                        name="rehabInvoices.invoiceImage"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.rehabInvoices && propertyDetails.rehabInvoices.invoiceImage}
                                                    /> */}

                                                    <Button
                                                        type="button"
                                                        color="info"
                                                        // disabled={!selectedFiles}
                                                        onClick={toggleUploadModal}
                                                    >
                                                        Upload Invoice Image
                                                    </Button>

                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Invoice Amount
                                                    </label>
                                                    <Input
                                                        name="rehabInvoices.invoiceAmount"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.rehabInvoices && propertyDetails.rehabInvoices.invoiceAmount}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    Listing Info
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Listing Date
                                                    </label>
                                                    <Input
                                                        name="listingInfo.listingDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.listingInfo && moment(propertyDetails.listingInfo.listingDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Under Contract Date
                                                    </label>
                                                    <Input
                                                        name="listingInfo.underContractDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.listingInfo && moment(propertyDetails.listingInfo.underContractDate).format('yyyy-MM-DD')}

                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    Contract Info
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Under Contract Date
                                                    </label>
                                                    <Input
                                                        name="contractInfo.underContractDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.contractInfo && moment(propertyDetails.contractInfo.underContractDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Contract Closing Date
                                                    </label>
                                                    <Input
                                                        name="contractInfo.contractClosingDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.contractInfo && moment(propertyDetails.contractInfo.contractClosingDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Contract Sales Price
                                                    </label>
                                                    <Input
                                                        name="contractInfo.contractSalesPrice"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.contractInfo && propertyDetails.contractInfo.contractSalesPrice}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    style={{ fontSize: 24, fontWeight: 'bold' }}
                                                >
                                                    Sale Info
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Sale Date
                                                    </label>
                                                    <Input
                                                        name="saleInfo.saleDate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="date"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.saleInfo && moment(propertyDetails.saleInfo.saleDate).format('yyyy-MM-DD')}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-merchant"
                                                    >
                                                        Sale Amount
                                                    </label>
                                                    <Input
                                                        name="saleInfo.saleAmount"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        step="any"
                                                        onChange={handleInput}
                                                        value={propertyDetails && propertyDetails.saleInfo && propertyDetails.saleInfo.saleAmount}
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>

                                    </div>


                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                <Modal
                    isOpen={isUploadModalVisible}
                    toggle={toggleUploadModal}
                    fullscreen={true}
                    size="lg"
                >
                    <ModalHeader toggle={toggleUploadModal}> Load Documents</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-8">
                                <label className="btn btn-default p-0">
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*,application/pdf"
                                        onChange={handleSelectedFiles}
                                    />
                                </label>
                            </div>

                            {/* <div className="col-4 -justify-end">
                                <Button
                                    type="button"
                                    color="info"

                                    onClick={toggleUploadModal}
                                >
                                    Upload Documents
                                </Button>
                            </div> */}
                        </div>

                        {/* {progressInfos &&
              progressInfos.map((progressInfo, index) => (
                <div className="mb-2" key={index}>
                  <span>{progressInfo.fileName}</span>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      aria-valuenow={progressInfo.percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progressInfo.percentage}%` }}
                    >
                      {progressInfo.percentage}%
                    </div>
                  </div>
                </div>
              ))} */}

                        {selectedInvoiceFiles && (
                            <PreviewFile previewImages={selectedInvoiceFiles} />
                        )}
                        {/* 
            {message.length > 0 && (
              <div className="alert alert-secondary mt-2" role="alert">
                <ul>
                  {message.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            )} */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleUploadModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );


}

// const mapStateToProps = state => ({
//     jobs: state.job.jobs,
//     users: state.user.users,
//     userLogged: state.auth.userLogged,
// });


export default withRouter(AddUpdateProperty);