import {
  FETCH_JOB_SENT,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_FAILURE,
  FETCH_JOB_REMOVE_FAILURE,
  FETCH_JOB_ADD_FAILURE,
  FETCH_JOB_UPDATE_FAILURE,
  FETCH_ESTIMATE_ADD_SUCCESS,
  FETCH_ESTIMATE_REMOVE_SUCCESS,
  FETCH_ESTIMATE_SUCCESS,
  FETCH_ESTIMATE_UPDATE_SUCCESS,
} from '../actions/jobAction';
import { LOG_LOGOUT_USER } from '../actions/authAction';
import { merger, adder, updater, remover } from './actionReducers';

const initialState = {
  jobs: [],
  estimates: [],
  didInvalidate: true
};

export const jobReducer = (state = initialState, action) => {
  const tableName = action.table || (action.payload && action.payload.table) || 'jobs';
  const fetchList = { [tableName]: action.payload }

  switch (action.type) {
    case FETCH_JOB_SENT:
      return state;
    case LOG_LOGOUT_USER:
      state = initialState;
      return state;
    case FETCH_ESTIMATE_SUCCESS:
    case FETCH_JOB_SUCCESS:
      return merger(state, {
        ...fetchList,
        jobsErr: undefined,
        didInvalidate: false,
        lastUpdated: action.receivedAt
      });

    case FETCH_ESTIMATE_ADD_SUCCESS:
      return { ...state, [tableName]: adder(state[tableName], action.payload) };

    case FETCH_ESTIMATE_UPDATE_SUCCESS:
      try {
        const index = state[tableName].findIndex(u => u._id === action.payload.id);
        return { ...state, [tableName]: updater(state[tableName], action.payload.data, index) };
      } catch (e) {
        return state;
      }

    case FETCH_ESTIMATE_REMOVE_SUCCESS:
      try {
        return { ...state, [tableName]: remover(state[tableName], action.payload.id) };
      } catch (e) {
        return state;
      }

    case FETCH_JOB_FAILURE:
    case FETCH_JOB_ADD_FAILURE:
    case FETCH_JOB_REMOVE_FAILURE:
    case FETCH_JOB_UPDATE_FAILURE:
      return merger(state, { jobsEr: action.payload });

    default:
      return state;
  }
};
