import React from 'react';
import { withRouter } from 'react-router-dom';
// import { Carousel } from 'react-responsive-carousel';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Label,
} from 'reactstrap';
// core components
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import ReactToPdf from 'react-to-pdf';

// import { Document, Page } from 'react-pdf';
import PreviewFile from '../../helpers/PreviewFile';
import { FileUploadPreview } from '../../components/FileUpload';

import AuthService from '../../services/services';
import Header from '../../components/Headers/Header';
import { updateEstimate } from '../../redux/actions/jobAction';
import { addItem, getItems } from '../../redux/actions/itemAction';
import { getRevenueCenter } from '../../redux/actions/revenueCenterAction';

import { GooglePlacesAutocomplete } from '../../components/Form/GooglePlacesAutocomplete';


import { getDraftById, removeDraftById, saveAsDraft } from '../../helpers/draft';
import { fileToBase64 } from '../../helpers/file';

import { EstimatePreview } from './EstimatePreview';


const authService = new AuthService();

const EmptyFields = <span style={{ color: 'red' }}>This field should not be empty.</span>;



class UpdateEstimate extends React.Component {
  state = {
    name: '',
    email: '',
    address: '',
    items: [],
    itemName: '',
    itemDescription: '',
    itemInternalNotes: '',
    description: '',
    comments: '',
    comments_internal: '',
    estimateName: '',
    quantity: 0,
    rate: 0,
    cost: 0,
    estimateSubtotal: 0,
    estimateTax: 0,
    estimateDiscount: 0,
    estimatePaid: 0,
    estimateTotal: 0,
    estimateAddress: '',
    dateCreate: '',
    jobName: '',
    modal: false,
    estimatePhone: '',
    value: '',
    suggestions: [],
    itemSuggestions: [],
    selectedImage: 0,
    modalImage: false,
    imgs: [],
    selectedFiles: undefined,
    previewImages: [],
    progressInfos: [],
    message: [],
    pageNumber: 0,
    numPages: 0,
    submitting: false,
    revenueCenter: null,
    showRequired: false,
    isUploading: false,
    pdfModal: false
  };

  constructor(props) {
    super(props);
    this.pdfRef = React.createRef();
    this.selectFiles = this.selectFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadImages = this.uploadImages.bind(this);
  }

  toggleModalImage = () => {
    this.setState({
      modalImage: !this.state.modalImage,
    });
  };

  togglePdfPreview = () => {
    this.setState({
      pdfModal: !this.state.pdfModal,
    });
  }
  selectFiles(event) {
    const { connection } = this.props;
    const previewImages = [];

    if (event.target.files) {
      Object.keys(event.target.files).forEach(key => {
        previewImages.push({ file: event.target.files[key], viewingFrom: "input-file" });
      })
      // console.log('previewImages', previewImages)
      const files = [...(this.state.selectedFiles || []), ...(event.target.files || [])];
      this.setState({
        progressInfos: [],
        message: [],
        selectedFiles: [...(files || [])],
        previewImages,
      });

      if (connection.status) {
        this.reUploadImages(files);
      }

    }
    // previewImages.push({ file: event.target.files[0], viewingFrom: "input-file" });

    // this.setState({
    //   progressInfos: [],
    //   message: [],
    //   selectedFiles: event.target.files,
    //   previewImages,
    // });
  }

  async upload(idx, file) {
    const _progressInfos = [...this.state.progressInfos];
    const previewImages = [];
    const imgs_e = [];
    this.state.imgs.forEach(item => {
      imgs_e.push(item);
      previewImages.push({ file: item, viewingFrom: "url" });
    });

    const response = await authService.upload(file, event => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      this.setState({
        progressInfos: _progressInfos,
      });
    });
    imgs_e.push(response.data.img);
    previewImages.unshift({ file: response.data.img, viewingFrom: "url" });
    this.setState(prev => {
      const nextMessage = [
        ...prev.message,
        `Uploaded the image successfully: ${file.name}`,
      ];
      return {
        imgs: imgs_e,
        previewImages,
        message: nextMessage,
        // selectedFiles: undefined
      };
    });
  }

  uploadImages = () => {
    const { selectedFiles } = this.state;
    const _progressInfos = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }
    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          await this.upload(i, selectedFiles[i]);
        }
      }
    );

  };

  reUploadImages = async selectedFiles => {

    const { progressInfos } = this.state;
    const _progressInfos = [...(progressInfos || [])];
    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        isUploading: true,
        progressInfos: _progressInfos,
        message: [],
      },
      async () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          if (_progressInfos[i].percentage === 0) {
            await this.upload(i, selectedFiles[i]);
          }
        }
        this.setState({
          isUploading: false
        })
      }
    );

  };

  async componentDidMount() {
    await this.props.getItems();
    await this.props.getRevenueCenter();
    const estimate = this.props.estimates.filter(
      item => item._id === this.props.match.params.id
    )[0];

    const urlParams = new URLSearchParams(window.location.search);
    const draftId = urlParams.get('draftId');

    const client = this.props.clients.filter(
      item => item._id === estimate.clientId
    )[0];
    if (!estimate.jobAddress)
      estimate.jobAddress = estimate.jobName.split('-')[1];

    const previewImages = [];
    if (!estimate.imgs) estimate.imgs = [];
    estimate.imgs.forEach(item => {
      item = item.replace('http', 'https');
      previewImages.push({ file: item, viewingFrom: "url" });
    });

    if (draftId) {
      let draft = await getDraftById('estimates', draftId);

      if (draft) {
        delete draft.submitting;
        let previewImages = [];
        if (draft.selectedFiles) {

          if (draft.imgs.length === 0) {
            const url = await fileToBase64(draft.selectedFiles[0]);
            previewImages.push({ file: url, viewingFrom: "url" });
          }
          else {
            previewImages.push({ file: draft.imgs[0], viewingFrom: "url" });
          }


        }
        else {
          if (draft.imgs.length > 0) {
            previewImages.push({ file: draft.imgs[0], viewingFrom: "url" });
          }
        }

        this.setState({
          email: client && client.email ? client.email : '',
          name: estimate && estimate.estimateName ? estimate.estimateName : `${client.firstName} ${client.lastName}`,
          value: estimate && estimate.jobAddress,
          address: estimate && estimate.jobAddress ? estimate.jobAddress : '',
          revenueCenter: estimate && estimate.revenueCenter ? estimate.revenueCenter : '',
          ...draft,
          previewImages
        });
      }

    }
    else {

      this.setState(prevState => ({
        ...prevState,
        email: client && client.email ? client.email : '',
        name: estimate && estimate.estimateName ? estimate.estimateName : `${client.firstName} ${client.lastName}`,
        ...(estimate || {}),
        previewImages,
        value: estimate && estimate.jobAddress,
        address: estimate && estimate.jobAddress ? estimate.jobAddress : '',
        revenueCenter: estimate && estimate.revenueCenter ? estimate.revenueCenter : '',
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { connection } = this.props;

    if (connection && prevProps.connection && (!prevProps.connection.status && connection.status)) {
      if (this.state.selectedFiles) {
        this.reUploadImages(this.state.selectedFiles);
      }
    }
  }

  async componentWillUnmount() {
    await this.props.getItems();
    await this.props.getRevenueCenter();

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleOpenModal = () => {
    this.setState(prevState => ({
      ...prevState,
      modal: true,
    }));
  };

  addToCart = async product => {
    if (this.state.reuseItem === 'on') {
      product.item = product.itemName;
      product.description = product.itemDescription;
      product.internal_notes = product.itemInternalNotes;
      product.cost = parseFloat(product.cost);
      await this.props.addItem(product);
    }
    const joined = this.state.items.concat(product);
    this.setState({
      items: joined,
      itemName: '',
      itemDescription: '',
      itemInternalNotes: '',
      quantity: 0,
      cost: 0,
      rate: 0,
    });
  };

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    // const payload = {
    //   ...this.state,
    //   items: this.state.items.map(item => {
    //     return {
    //       ...item,
    //       cost: parseFloat(item.cost)
    //     }
    //   })
    // };
    const { name, email, estimatePhone, address, imgs } = this.state;

    const isOffline = !navigator.onLine;// connection && !connection.status;

    if (!name || !email || !estimatePhone || !address) {
      this.setState({
        showRequired: true
      })
    }
    else {

      this.setState({ submitting: true });
      let payload = {
        ...this.state,
        jobName: `${this.state.name} - ${this.state.address}`
      };
      if (!isOffline) {

        if (payload && !payload.revenueCenter) {
          delete payload.revenueCenter;
        }

        if (this.state.selectedFiles) {

          // UPLOAD DRAFT IMAGES
          // const mimeType = getMimeType(files);
          // const currentFile = await urltoFile(files);

          // const imageResponse = await authService.upload(this.state.selectedFiles[0], this.state.selectedFiles[0].type);


          // payload = {
          //   ...payload,
          //   imgs: [imageResponse.data.img]
          // }

          if (imgs.length === 0 && this.state.selectedFiles) {
            const imageResponse = await authService.upload(this.state.selectedFiles[0], this.state.selectedFiles[0].type);

            payload = {
              ...payload,
              imgs: [imageResponse.data.img]
            }

          }
          else {
            payload = {
              ...payload,
              imgs
            }

          }

        }
        if (payload.draft_id) {
          removeDraftById('estimates', payload.draft_id);
        }

        await this.props.updateEstimate(this.props.match.params.id, payload, 'estimates');
        this.props.history.push(`/admin/estimates`);
        this.setState({ submitting: false });
      }
      else {

        if (this.state.selectedFiles && this.state.selectedFiles.length > 0) {
          this.setState({ submitting: true });


          payload = {
            ...payload,
            files: this.state.selectedFiles[0],
            syncFiles: true
          };

          await saveAsDraft('estimates', payload);
          this.props.history.push(`/admin/estimates`);
          this.setState({ submitting: false });

        }
        else {
          this.setState({ submitting: true });

          this.props.history.push(`/admin/estimates`);
          saveAsDraft('estimates', payload);
          this.setState({ submitting: false });
        }

        // this.props.history.push(`estimates`);
        // saveAsDraft('estimates', this.state);
        // this.props.history.push(`/admin/estimates`);
        // this.setState({ submitting: false });
      }

    }


  };

  handleClearFiles = key => () => {
    // this.fileInput.value = '';

    let previewImages = [...this.state.previewImages];
    const updatedFiles = [...this.state.selectedFiles];
    updatedFiles.splice(key, 1);
    previewImages.splice(key, 1);

    this.setState({
      previewImages,
      selectedFiles: updatedFiles
    });


  }



  setPhoneNumber = e => {
    this.setState(prevState => ({
      ...prevState,
      estimatePhone: e,
    }));
  };

  onChangeItem = async (event, { newValue }) => {
    this.setState(prevState => ({
      ...prevState,
      itemName: newValue,
    }));
  };

  getItemSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const data = this.props.items.filter(
      text =>
        text.item.toLowerCase().includes(inputValue) > 0 &&
        text.item.toLowerCase() !== inputValue
    );
    return inputLength === 0 ? [] : data;
  };

  getSuggestionValueItem = suggestion => {
    this.setState(prevState => ({
      ...prevState,
      itemName: suggestion.item,
      itemDescription: suggestion.description,
      itemInternalNotes: suggestion.internal_notes,
      quantity: suggestion.quantity,
      cost: suggestion.cost,
      rate: suggestion.rate,
    }));
    return suggestion.item;
  };

  renderSuggestionItem = suggestion => <div>{suggestion.item}</div>;

  onSuggestionsFetchRequestedItem = ({ value }) => {
    this.setState({
      itemSuggestions: this.getItemSuggestions(value),
    });
  };

  onSuggestionsClearRequestedItem = () => {
    this.setState({
      itemSuggestions: [],
    });
  };

  onChangeImage = value => {
    this.setState({
      selectedImage: value,
    });
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });
  };

  handleInputItems = index => e => {
    const { items } = this.state;
    let { name, value } = e.target;

    let updatedItems = items.map((item, itemIndex) => {
      if (index === itemIndex) {
        let subtotal = 0;

        if (name === 'quantity') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        else if (name === 'rate') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.quantity;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        else {
          subtotal = item.subtotal; // item.rate * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        return {
          ...item,
          [name]: value,
          subtotal

        }
      }

      return item
    });

    this.setState({
      items: [...(updatedItems || [])]
    })

  }

  handleRemoveImage = url => () => {
    const { imgs } = this.state;
    const updatedImgs = imgs.filter(imgUrl => imgUrl !== url)
    this.setState({
      imgs: updatedImgs
    });
  }


  render() {
    const { selectedFiles, previewImages, progressInfos, message, submitting, showRequired } = this.state;
    const { revenueCenter } = this.props;
    const product = {
      itemName: this.state.itemName,
      itemDescription: this.state.itemDescription,
      itemInternalNotes: this.state.itemInternalNotes,
      quantity: parseFloat(this.state.quantity),
      rate: parseFloat(this.state.rate),
      cost: parseFloat(this.state.cost),
      subtotal: parseFloat(this.state.quantity * this.state.rate),
    };
    const estimateSubtotal = this.state.items.reduce(
      (acc, current, i) => acc + current.subtotal,
      0
    );
    const totalCost = this.state.items.reduce(
      (acc, current) => acc + (parseFloat(current.cost) * current.quantity),
      0
    );
    const tax =
      (parseInt(this.state.estimateTax ? this.state.estimateTax : 0) *
        estimateSubtotal) /
      100;
    const discount = parseInt(
      this.state.estimateDiscount ? this.state.estimateDiscount : 0
    );
    const paid = parseInt(
      this.state.estimatePaid ? this.state.estimatePaid : 0
    );
    const { clientName } = this.state;
    const { address } = this.state;

    const estimateTotal = estimateSubtotal + tax - discount - paid;
    const jobName = address + clientName;
    const totalProfit = estimateTotal - totalCost;

    if (!this.state) return <p> Loading </p>;
    const { dateCreate } = this.state;

    const { itemName, itemSuggestions } = this.state;
    const inputPropsItem = {
      placeholder: 'Enter a name item',
      value: itemName,
      onChange: this.onChangeItem,
    };

    const isOffline = !navigator.onLine;// connection && !connection.status;


    return (
      <>
        <Header /> {/* Page content */}{' '}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0"> Estimate Information </h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Name *
                            </label>
                            <Input
                              value={this.state.name}
                              className="form-control-alternative"
                              placeholder="Enter the name client"
                              name="name"
                              type="text"
                              onChange={this.handleInput}
                            />
                            {(this.state.name === '' && showRequired) && EmptyFields}
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Email*
                            </label>
                            <Input
                              value={`${this.state.email}`}
                              className="form-control-alternative"
                              placeholder="Enter the email client"
                              name="email"
                              type="email"
                              onChange={this.handleInput}
                            />
                            {(this.state.email === '' && showRequired) && EmptyFields}
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-nameClient"
                            >
                              Revenue Center
                            </label>
                            <Input
                              required={true}
                              className="form-control-alternative"
                              name="revenueCenter"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Select"
                              value={this.state.revenueCenter}
                            >
                              <option value="Select" disabled>
                                Select a Revenue Center
                              </option>
                              {revenueCenter.map(item => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Phone number *
                            </label>

                            <PhoneInput
                              international
                              // countryCallingCodeEditable={false}
                              country="US"
                              defaultCountry="US"
                              placeholder="Enter phone number"
                              value={this.state.estimatePhone}
                              onChange={this.setPhoneNumber}
                            />
                            {(!this.state.estimatePhone && showRequired) && EmptyFields}
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Address *
                            </label>
                            <GooglePlacesAutocomplete onSelect={this.handleSelect} value={this.state.address} />
                            {(this.state.address === '' && showRequired) && EmptyFields}
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Items
                            </label>

                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col"> Item Name </th>
                                  <th scope="col"> Description </th>
                                  <th scope="col"> Internal Notes </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Cost </th>
                                  <th scope="col"> Rate </th>
                                  <th scope="col"> Total </th>
                                  <th scope="col"> Options </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.items.map((el, i) => (
                                  <tr key={i}>
                                    <td> {/* el.itemName*/}

                                      <Input
                                        defaultValue={el.itemName}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemName"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemDescription}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemDescription"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}

                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemInternalNotes}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemInternalNotes"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        value={el.quantity}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="quantity"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        value={el.cost}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="cost"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>


                                    <td>
                                      <Input
                                        value={el.rate}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="rate"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        // step="0.01"
                                        // presicion={2}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>
                                    <td>$ {parseFloat(el.subtotal).toFixed(2)} </td>
                                    {/* <td> {el.itemDescription} </td>
                                    <td> {el.quantity} </td>
                                    <td>$ {el.rate} </td>
                                    <td>$ {el.subtotal} </td> */}
                                    <td>
                                      <Button
                                        onClick={e =>
                                          this.setState(prevState => {
                                            const filter =
                                              this.state.items.filter(
                                                e => e !== this.state.items[i]
                                              );
                                            this.setState({ items: filter });
                                          })
                                        }
                                        style={{
                                          width: '100px',
                                          height: '20px',
                                          fontSize: '10px',
                                          paddingTop: '0',
                                        }}
                                        className="btn icon-btn btn-danger"
                                      >
                                        <i className="nc-icon nc-simple-remove" />{' '}
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Item Name *
                            </label>
                            <Autosuggest
                              alwaysRenderSuggestions={true}
                              suggestions={itemSuggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedItem
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedItem
                              }
                              getSuggestionValue={this.getSuggestionValueItem}
                              renderSuggestion={this.renderSuggestionItem}
                              inputProps={inputPropsItem}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Description
                            </label>
                            <Input
                              value={this.state.itemDescription}
                              name="itemDescription"
                              className="form-control-alternative"
                              placeholder="Enter a description of item"
                              type="textarea"
                              rows={5}
                              id="itemDescription"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Internal Notes
                            </label>
                            <Input
                              value={this.state.itemInternalNotes}
                              name="itemInternalNotes"
                              className="form-control-alternative"
                              placeholder="Enter a internal notes of an item"
                              type="textarea"
                              rows={5}
                              id="itemInternalNotes"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Quantity *
                            </label>
                            <Input
                              value={this.state.quantity}
                              name="quantity"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="quantity"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Cost *
                            </label>
                            <Input
                              value={this.state.cost}
                              name="cost"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="cost"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Rate *
                            </label>
                            <Input
                              value={this.state.rate}
                              name="rate"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="rate"
                              onChange={this.handleInput}
                            />
                            <br />
                            <Row>
                              <Col md={6}>
                                <button
                                  type="button"
                                  onClick={e => this.addToCart(product)}
                                  className="btn btn-primary"
                                >
                                  Add Item
                                </button>
                              </Col>
                              <Col md={6}>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    id="cbReuseItem"
                                    onClick={this.handleInput}
                                    name="reuseItem"
                                  />
                                  <Label check for="cbReuseItem">
                                    Save as reusable item
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <Row style={{ paddingLeft: 15 }}>

                            {this.state.imgs.map(url => {
                              return <span >
                                <img src={url} alt='remove' style={{
                                  width: 130,
                                  height: 130,
                                  // height: 'auto'
                                }} />
                                <div onClick={this.handleRemoveImage(url)} style={{ color: 'red', cursor: 'pointer' }}>Remove</div>
                              </span>
                            })}
                          </Row>
                          {this.state.imgs && this.state.imgs.length > 0 ? (
                            <FormGroup className="text-center">
                              <label className="form-control-label">
                                <Button
                                  color="info"
                                  onClick={this.handleOpenModal}
                                >
                                  <i className="fas fa-image"></i> View Images
                                  loaded
                                </Button>
                              </label>
                            </FormGroup>
                          ) : null}
                          {/* <FormGroup>
                            <Button
                              type="button"
                              color="info"
                              onClick={e => this.toggleModalImage()}
                            >
                              Upload Images
                            </Button>
                          </FormGroup> */}


                          <Row>

                            <div className="col-8">
                              <label className="btn btn-default p-0">
                                <input
                                  type="file"
                                  multiple
                                  accept="image/*,application/pdf"
                                  onChange={this.selectFiles}
                                  ref={ref => this.fileInput = ref}
                                />
                              </label>
                            </div>

                          </Row>

                          <Row style={{ paddingLeft: 15 }}>

                            {selectedFiles && Object.keys(selectedFiles).map((key, index) => {
                              return <FileUploadPreview
                                index={key}
                                file={selectedFiles[key]}
                                handleClearFiles={this.handleClearFiles}
                                handleOpenPreviewModal={this.toggleModalImage}
                                progress={progressInfos[[index]]}
                                hideRemove={true}
                              />
                            })}</Row>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              External Comments{' '}
                            </label>
                            <Input
                              value={`${this.state.comments}`}
                              name="comments"
                              className="form-control-alternative"
                              placeholder="Enter comments (optional)"
                              type="textarea"
                              rows="6"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Internal Comments (not visible to clients){' '}
                            </label>
                            <Input
                              value={`${this.state.comments_internal}`}
                              name="comments_internal"
                              className="form-control-alternative"
                              placeholder="Enter comments (optional)"
                              type="textarea"
                              rows="6"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <h4>Estimate</h4>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Date Create{' '}
                            </label>
                            <Input
                              defaultValue={`${dateCreate}`}
                              name="dateCreate"
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                              width="50%"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Subtotal{' '}
                            </label>
                            <Input
                              name="estimateSubtotal"
                              value={parseInt(estimateSubtotal)}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-tax"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Tax{' '}
                            </label>
                            <Input
                              value={
                                this.state.estimateTax
                                  ? this.state.estimateTax
                                  : 0
                              }
                              name="tax"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Discount
                            </label>
                            <Input
                              value={
                                this.state.estimateDiscount
                                  ? this.state.estimateDiscount
                                  : 0
                              }
                              name="estimateDiscount"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Paid{' '}
                            </label>
                            <Input
                              name="estimatePaid"
                              value={
                                this.state.estimatePaid
                                  ? this.state.estimatePaid
                                  : 0
                              }
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-total"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total
                            </label>
                            <Input
                              disabled
                              name="estimateTotal"
                              value={estimateTotal ? estimateTotal.toFixed(2) : 0}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-total"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total Cost
                            </label>
                            <Input
                              disabled
                              name="estimateCost"
                              value={totalCost ? totalCost.toFixed(2) : 0}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-total"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total Profit
                            </label>
                            <Input
                              disabled
                              name="totalProfit"
                              value={totalProfit ? totalProfit.toFixed(2) : 0}
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              name="jobName"
                              value={jobName}
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              disabled
                              width="50%"
                              hidden
                            />
                          </FormGroup>

                          <Row>
                            <div onClick={this.togglePdfPreview}>
                              <div className="col" style={{ paddingBottom: 12 }}>
                                <span className="mb-0" style={{ cursor: 'pointer', color: 'blue' }}> Estimate Preview</span>
                              </div>
                            </div>

                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  className="form-control-alternative"
                                  color="info"
                                  disabled={submitting}
                                >
                                  {isOffline ? 'Save as Draft' : submitting ? 'Saving...' : 'Save'}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal}> Images loaded</ModalHeader>
          <ModalBody>
            {previewImages && (
              <PreviewFile previewImages={previewImages} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalImage}
          toggle={this.toggleModalImage}
          fullscreen={true}
          size="lg"
        >
          <ModalHeader toggle={this.toggleModalImage}>
            {' '}
            Load Images{' '}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              {/* <div className="col-8">
                <label className="btn btn-default p-0">
                  <input
                    type="file"
                    multiple
                    accept="image/*,application/pdf"
                    onChange={this.selectFiles}
                  />
                </label>
              </div> */}
              {/* 
              <div className="col-4 -justify-end">
                <button
                  className="btn btn-success btn-sm"
                  disabled={!selectedFiles}
                  onClick={this.uploadImages}
                >
                  Upload
                </button>
              </div> */}
            </div>
{/* 
            {progressInfos &&
              progressInfos.map((progressInfo, index) => (
                <div className="mb-2" key={index}>
                  <span>{progressInfo.fileName}</span>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      aria-valuenow={progressInfo.percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progressInfo.percentage}%` }}
                    >
                      {progressInfo.percentage}%
                    </div>
                  </div>
                </div>
              ))} */}

            {previewImages && (
              <PreviewFile previewImages={previewImages} />
            )}

            {message.length > 0 && (
              <div className="alert alert-secondary mt-2" role="alert">
                <ul>
                  {message.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModalImage}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.pdfModal} toggle={this.togglePdfPreview} size="lg">
          <ModalHeader toggle={this.togglePdfPreview}> Preview</ModalHeader>
          <ModalBody>


            <ReactToPdf targetRef={this.pdfRef} filename={`${this.state.name}-estimate.pdf`}>
              {({ toPdf }) => (
                <div style={{ paddingBottom: 12, paddingTop: 12 }}>

                  <Button
                    onClick={toPdf}
                    className="form-control-alternative"
                    color="info"
                  >
                    Download PDF
                  </Button>
                </div>
              )}
            </ReactToPdf>

            <div ref={this.pdfRef}>
              <EstimatePreview
                estimateNo={1}
                dateNow={this.state.dateCreate}
                name={this.state.name}
                currentAddress={this.state.address}
                currentCityZipCountry={''}
                phone={this.state.phone}
                comments={this.comments}
                amount={this.state.amount}
                rate={this.state.rate}
                total={this.state.estimateTotal}
                items={this.state.items}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.togglePdfPreview}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  connection: state.connection,
  estimates: state.job.estimates,
  clients: state.client.clients,
  items: state.item.items,
  revenueCenter: state.revenueCenter.list
});

export default connect(mapStateToProps, { updateEstimate, addItem, getItems, getRevenueCenter })(
  withRouter(UpdateEstimate)
);
