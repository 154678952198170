import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from 'reactstrap';
// core components
import { connect } from 'react-redux';
import moment from 'moment';

import Header from '../../components/Headers/Header';
import Global, { compareValues } from '../../global';
import ReportJobs from './ReportJobs';
import ReportWorkers from './ReportWorkers';
import { getUsers } from '../../redux/actions/userAction';
import { getJobs } from '../../redux/actions/jobAction';
import { getInvoices } from '../../redux/actions/invoiceAction';
import { getTimes } from '../../redux/actions/timeAction';
import { getExpenses, sendReconciled } from '../../redux/actions/expenseAction';
import UpdateExpenseModal from '../expenses/UpdateExpenseModal';
import { prepareReportDateFilters } from '../../helpers/reports';
import { quickDateFilters } from '../../constants';
import PreviewFile from '../../helpers/PreviewFile';

import { reset } from '../../redux/actions/searchAction';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';
import LoadBar from '../../components/shared/loadBar';

let loggedUser;
class Reports extends React.Component {
  state = {
    pageNumber: 0,
    numPages: 0,
    rangeDateLbl: `Last Week (${moment()
      .day(-7)
      .day('Monday')
      .format('YYYY-MMM-DD')} to ${moment()
        .day('Sunday')
        .format('YYYY-MMM-DD')})`,
    startDate: moment().startOf('week').add('days', -7).format('YYYY-MM-DD'),
    endDate: moment().startOf('week').add('days', 1).format('YYYY-MM-DD'),
    jobsFilter: [],
    workersFilter: [],
    activeTab: '1',
    activeTabDate: '1',
    buttonActive: '1',
    modal: false,
    modalEdit: false,
    extension: '',
    loadFilter: false,
    popoverOpen: false,
    expense: {},
    expensesReconciled: [],
    loading: false,
    searchItem: '',
    // jobOptions: []
  };

  constructor(props) {
    super(props);
    loggedUser = this.props.userLogged;
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleExpenseOpenModal = url => {
    this.setState(prevState => {
      let img = url;
      if (img !== '') {
        img = img && img.replace('http', 'https');
      }
      return {
        ...prevState,
        img,
        modal: true,
        extension:
          img === ''
            ? ''
            : img && img.substring(img.length - 3, img.length).toLowerCase(),
      };
    });
  };

  handleOpenModal = expenseId => {
    const expense = this.props.expenses.filter(
      item => item._id === expenseId
    )[0];
    let img = expense.image;
    this.setState(prevState => {
      if (img && img.trim()) {
        img = img && img.replace('http', 'https');
      }

      return {
        ...prevState,
        img,
        modal: true,
        extension: img && img.trim() ? img.substring(img.length - 3, img.length).toLowerCase() : ""
      };
    });
  };

  toggleModalEdit = async expense => {
    this.setState({
      modalEdit: !this.state.modalEdit,
    });
    this.updateReportDataExpense(expense);
  };

  /**
   * Update an expense in reports filtered data in state.
   * 
   * @param {object} expense 
   */
  updateReportDataExpense = (expense) => {

    const { jobsFilter, workersFilter } = this.state;

    jobsFilter.forEach(job => {
      if (job.expenses.length > 0 && expense && expense.data) {
        job.expenses.forEach((part, index, expenses) => {
          if (expenses[index]._id === expense.data.expense._id) {
            expense.data.expense.jobName = this.props.jobs.filter(
              job => job._id === expense.data.expense.jobId
            )[0].jobName;
            expenses[index] = expense.data.expense;
          }
        });
        job.expenses = _.uniqBy(job.expenses, '_id');
      }
    });

    workersFilter.forEach(worker => {
      if (worker && worker.expenses && worker.expenses.length > 0 && expense && expense.data) {
        worker.expenses.forEach((part, index, expenses) => {
          if (expenses[index]._id === expense.data.expense._id) {
            expense.data.expense.jobName = this.props.jobs.filter(
              job => job._id === expense.data.expense.jobId
            )[0].jobName;
            expenses[index] = expense.data.expense;
          }
        });
        worker.expenses = _.uniqBy(worker.expenses, '_id');
      }
    });

    this.setState(prevState => ({
      ...prevState,
      jobsFilter,
      workersFilter,
    }));
  };

  handleOpenModalEdit = expenseId => {
    const expense = this.props.expenses.filter(
      item => item._id === expenseId
    )[0];
    this.setState(prevState => ({
      ...prevState,
      expense,
      modalEdit: true,
    }));
  };

  /**
   * Reconciles expenses.
   * 
   * @param {array} items An array of expense ids
   */
  sendReconciled = async (items, status) => {
    await this.props.sendReconciled({ items: items , status: status});
    await this.props.getExpenses(loggedUser._id);
    await this.props.getJobs(loggedUser._id);

  };

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === 'startDate') {
      this.setState(prevState => ({
        ...prevState,
        rangeDateLbl: `From (${moment(e.target.value).format(
          'YYYY-MMM-DD'
        )} To ${moment(this.state.endDate).format('YYYY-MMM-DD')})`,
      }));
    }
    if (e.target.name === 'endDate') {
      this.setState(prevState => ({
        ...prevState,
        rangeDateLbl: `From (${moment(this.state.startDate).format(
          'YYYY-MMM-DD'
        )} To ${moment(e.target.value).format('YYYY-MMM-DD')})`,
      }));
    }
  };

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < Global.mobileWidth,
    }));
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    await this.props.getExpenses(loggedUser._id);
    await this.props.getJobs(loggedUser._id);
    

    // await this.props.getUsers();
    // await this.props.reset();


    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  async componentDidMount(props) {
    if (!this.props.paymentAccount.list.length) {
      await this.props.getPaymentAccounts(loggedUser._id);
    }

    await this.props.getJobs(loggedUser._id);
    await this.props.getUsers();
    await this.props.getTimes(loggedUser._id);
    await this.props.getInvoices(loggedUser._id);
    await this.props.getExpenses(loggedUser._id);
    // await this.props.reset();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);


    this.setState(
      prevState => ({
        ...prevState,
        startDate: moment()
          .startOf('week')
          .add('days', -7)
          .format('YYYY-MM-DD'),
        endDate: moment().startOf('week').add('days', 1).format('YYYY-MM-DD'),
        workersFilter: this.workersTransformer(
          this.props.users.filter(
            user =>
              user.role === 'WORKER' ||
              user.role === 'PROJECT MANAGER' ||
              user.role === 'ADMIN'
          ),
          moment().startOf('week').add('days', -6).format('YYYY-MM-DD'),
          moment().startOf('week').add('days', 0).format('YYYY-MM-DD')
        ),
      }),
      () => {
        this.filterDate();
      }
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    const { expenses } = this.props;
    if ((!_.isEqual(expenses, prevProps.expenses) && (expenses.length > 0))
    ) {
      this.filterDate()

      const workersFilter = this.workersTransformer(
        this.props.users.filter(
          user =>
            user.role === 'WORKER' ||
            user.role === 'PROJECT MANAGER' ||
            user.role === 'ADMIN'
        ),
        moment().startOf('week').add('days', -6).format('YYYY-MM-DD'),
        moment().startOf('week').add('days', 0).format('YYYY-MM-DD')
      );

      this.setState({
        workersFilter
      }); 
    }

  }

  getOpen = async () => {
    document.getElementById('spinner').style.visibility = 'visible';
    this.setState(prevState => ({
      ...prevState,
      jobsFilter: [],
      workersFilter: [],
      loading: true
    }));
    await this.props.getJobs(loggedUser._id);
    const data = this.filterJobs(
      this.props.jobs.filter(job => job.status === 'Approve'),
      []
    );
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '1',
      jobsFilter: data[0],
      workersFilter: this.workersTransformer(data[1]),
      loading: false
    }));
    document.getElementById('spinner').style.visibility = 'hidden';
  };

  getClose = async () => {
    document.getElementById('spinner').style.visibility = 'visible';
    this.setState(prevState => ({
      ...prevState,
      jobsFilter: [],
      workersFilter: [],
      loading: true
    }));
    await this.props.getJobs(loggedUser._id);
    const data = this.filterJobs(
      this.props.jobs.filter(job => job.status === 'Closed'),
      []
    );
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '2',
      jobsFilter: data[0],
      workersFilter: this.workersTransformer(data[1]),
      loading: false
    }));
    document.getElementById('spinner').style.visibility = 'hidden';
  };

  getAll = async () => {
    document.getElementById('spinner').style.visibility = 'visible';

    this.setState(prevState => ({
      ...prevState,
      jobsFilter: [],
      workersFilter: [],
      loading: true
    }));
    await this.props.getJobs(loggedUser._id);

    const data = this.filterJobs(this.props.jobs, []);

    this.setState(prevState => ({
      ...prevState,
      buttonActive: '3',
      activeTab: '1',
      loadFilter: true,
      jobsFilter: data[0],
      workersFilter: this.workersTransformer(data[1]),
      loading: false
    }));

    document.getElementById('spinner').style.visibility = 'hidden';
  };

  filterDate = async () => {
    this.setState(prevState => ({
      ...prevState,
      jobsFilter: [],
      workersFilter: [],
      popoverOpen: false,
    }));
    await this.props.getJobs(loggedUser._id);

    document.getElementById('spinner').style.visibility = 'visible';
    const data = this.filterJobs(
      this.props.jobs.filter(job => job.status === 'Approve'),
      []
    );
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '1',
      loadFilter: true,
      jobsFilter: data[0],
      workersFilter: this.workersTransformer(data[1]),
    }));
    document.getElementById('spinner').style.visibility = 'hidden';
  };

  /**
   * Filter all jobs for the report tab.
   * 
   * @param {array} jobsFilter_
   * @param {array} workers_
   * @returns array Contains filtered jobs and filtered workers
   */
  filterJobs = (jobsFilter_, workers_) => {
    // const { users } = this.props
    workers_ = [];
    jobsFilter_.forEach(job_ => {
      job_.invoices = [];
      job_.expenses = [];

      if (job_.workers.length > 0) {
        job_.workers.forEach(worker => {
          worker.time = [];
     
          this.props.times.forEach(time => {
            if (time.userId === worker.workerId && time.jobId === job_._id) {
              worker.time.push(time);
              worker.user = this.props.users.filter(
                user => user._id === worker.workerId
              )[0];
              // User must exist.
              if (worker.user) {
                workers_[worker.user._id] = worker.user;
                if (!workers_[worker.user._id].times) {
                  workers_[worker.user._id].times = [];
                }
                workers_[worker.user._id].times.push(time);

                if (!workers_[worker.user._id].ajobs) {
                  workers_[worker.user._id].ajobs = [];
                }
                workers_[worker.user._id].ajobs[job_._id] = job_;
              }
            }
          });
          worker.time = worker.time.filter(
            time_ =>
              time_.date >= this.state.startDate &&
              time_.date <= this.state.endDate
          );
        });
        job_.workers = job_.workers.filter(worker => worker.time.length > 0);
      }

      let expenses = this.props.expenses.filter(
        expense =>
          expense.jobId === job_._id &&
          expense.date >= this.state.startDate &&
          expense.date <= this.state.endDate
      );
      if (expenses.length > 0) {
        expenses.forEach((expense, index) => {
          expenses[index].users = this.props.users.filter(
            user => user._id === expense.userId
          );

          workers_[expense.userId] = this.props.userLogged;

          // if (workers_[expense.userId] === undefined) {
            
          // }

          //   workers_[expense.userId] = this.props.users.find(
          //     user => user._id === expense.userId
          //   );

     
            if (workers_[expense.userId]) {
              if (typeof workers_[expense.userId].jobs === 'undefined') {
                workers_[expense.userId].jobs = [];
              }
              if (typeof workers_[expense.userId].expenses === 'undefined') {
                workers_[expense.userId].expenses = [];
              }
            } else {
              workers_[expense.userId] = {
                _id: '',
                jobs: [],
                expenses: [],
              };
            }
          // }

          if (
            expense.userId === workers_[expense.userId]._id &&
            expense.jobId === job_._id &&
            expense.date >= this.state.startDate &&
            expense.date <= this.state.endDate
          ) {
            if (!workers_[expense.userId].expenses) {
              workers_[expense.userId].expenses = [];
            }
            expense.jobName = this.props.jobs.filter(
              job => job._id === expense.jobId
            )[0].jobName;
            workers_[expense.userId].expenses.push(expense);
          }

          job_.expenses.push(expense);
        });
        job_.expenses = _.uniqBy(job_.expenses, '_id');
        job_.expenses = job_.expenses.sort(compareValues('date', 'desc'));
      }

      this.props.invoices.forEach(invoice => {
        if (
          invoice.jobId === job_._id &&
          invoice.invoiceDate >= this.state.startDate &&
          invoice.invoiceDate <= this.state.endDate
        ) {
          job_.invoices.push(invoice);
        }
      });
      job_.invoices = job_.invoices.sort(compareValues('invoiceDate', 'desc'));
    });
    const workers__ = [];
    for (const key in workers_) {
      workers_[key].jobs = [];
      for (const keyJob in workers_[key].ajobs) {
        workers_[key].jobs.push(workers_[key].ajobs[keyJob]);
      }
      workers__.push(workers_[key]);
    }

    jobsFilter_ = jobsFilter_
      .filter(
        job_ =>
          job_.invoices.length > 0 ||
          job_.expenses.length > 0 ||
          job_.workers.length > 0
      )
      .sort(compareValues('jobName', 'asc'));

    return [jobsFilter_, workers__];
  };

  workersTransformer(users, filter = true) {
    let usersData = users.filter(user => user.expenses || user.jobs);
    usersData.sort(compareValues('name', 'asc'));
    usersData.forEach(user => {
      const hoursPerJob = [];

      if (user.times) {
        user.times.forEach(time => {
          hoursPerJob.push({
            _id: time._id,
            works: time.jobId,
            jobName: this.props.jobs.filter(job => job._id === time.jobId)[0]
              .jobName,
            hours: time.hours,
            date: time.date,
            payroll: user.payRate * time.hours,
            effective: user.effectiveRate * time.hours,
          });
        });
      }
      user.jobs = _.uniqBy(hoursPerJob, '_id');
      if (filter) {
        user.jobs = user.jobs.filter(
          job =>
            job.date >= this.state.startDate && job.date <= this.state.endDate
        );
        if (user.expenses) {
          user.expenses = user.expenses.filter(
            expense =>
              expense.date >= this.state.startDate &&
              expense.date <= this.state.endDate
          );
          user.expenses = _.uniqBy(user.expenses, '_id');
        }
      }
    });
    usersData = users.filter(
      user =>
        (user.expenses && user.expenses.length > 0) ||
        (user.jobs && user.jobs.length > 0)
    );
    return usersData;
  }

  popoverToggle = () => {
    this.setState(prevState => ({
      popoverOpen: !this.state.popoverOpen,
    }));
  };

  toggleTabDate(tab) {
    if (this.state.activeTabDate !== tab) {
      this.setState({
        activeTabDate: tab,
      });
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  /**
   * Runs when user selects Quick Date/Time report option, and
   * will re-render the report.
   * @param {string} filter
   */
  onQuickFilterChange = filter => {
    const { startDate, endDate, rangeDateLbl } =
      prepareReportDateFilters(filter);
    if (startDate && endDate && rangeDateLbl) {
      this.setState(
        prevState => ({
          ...prevState,
          popoverOpen: false,
          rangeDateLbl,
          startDate,
          endDate,
        }),
        () => {
          // We closed the popover, now load the report, super-quick-report-like
          this.filterDate();
        }
      );
    }
  };

  handleSearch = (e) => {
    e.preventDefault();

    this.setState({ searchItem: e.target.value });

  }

  render() {
    const { paymentAccount } = this.props;
    const { searchItem } = this.state;

    if (!this.state) return <LoadBar />;
    return (
      <>
        <LoadBar />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                  <div className="col">
                      {/* <FormGroup className="mb-0"> */}
                        <InputGroup className="input-group-alternative search-margin">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='search-style'
                            placeholder="Search"
                            type="text"
                            onChange={this.handleSearch}
                          />
                        </InputGroup>
                      {/* </FormGroup> */}
                    </div>
                    <div className="col text-right">
                        <Button color="primary" type="button" onClick={() => window.location.href = 'addreport'}>
                          Create Report
                        </Button>
                    </div>
                  </Row>
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Reports</h3>
                    </div>
                  </Row>
                </CardHeader>

                <Form className="card-header">
                  <Row form>
                    <Col md={4}>
                      <FormGroup  >
                        <label
                          className="form-control-label"
                          htmlFor="input-dateStart"
                        >
                          Date picker
                        </label>
                     
                          <Input
                            // disabled={true}
                            name="rangeDateLbl"
                            id="rangeDateLbl"
                            className="form-control-alternative inputRangeDate"
                            value={this.state.rangeDateLbl}
                            onChange={this.handleInput}
                            onClick={e => {
                              this.popoverToggle();
                            }}
                          />
                 
                        <Popover
                          placement="bottom"
                          isOpen={this.state.popoverOpen}
                          popperClassName="popoverDate"
                          target="rangeDateLbl"
                          // toggle={this.popoverToggle}
                        >
                          <PopoverHeader>Select Date</PopoverHeader>
                          <PopoverBody>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTabDate === '1',
                                  })}
                                  onClick={() => {
                                    this.toggleTabDate('1');
                                  }}
                                >
                                  Quick Date/Time
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: this.state.activeTabDate === '2',
                                  })}
                                  onClick={() => {
                                    this.toggleTabDate('2');
                                  }}
                                >
                                  Custom Range
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTabDate}>
                              <TabPane key={`tab-${1}`} tabId="1">
                                <Row>
                                  <Col className="pt-2">
                                    {_.isArray(quickDateFilters) &&
                                      quickDateFilters.map((filter, index) => (
                                        <FormGroup key={index}>
                                          <label>{_.startCase(filter)}</label>
                                          <Input
                                            type="select"
                                            onChange={e => {
                                              this.onQuickFilterChange(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option>Select</option>
                                            <option value={`last_${filter}`}>
                                              Last {_.startCase(filter)}
                                            </option>
                                            <option value={`past_${filter}`}>
                                              Past {_.startCase(filter)}
                                            </option>
                                            <option value={`this_${filter}`}>
                                              This {_.startCase(filter)}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      ))}
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane key={`tab-${2}`} tabId="2">
                                <Row>
                                  <Col>
                                    <Card body>
                                      <CardTitle>Select Range</CardTitle>
                                      <Row>
                                        <Col>
                                          <FormGroup>
                                            <label
                                              className="form-control-label"
                                              htmlFor="input-dateStart"
                                            >
                                              From Date
                                            </label>
                                            <Input
                                              name="startDate"
                                              className="form-control-alternative"
                                              type="date"
                                              value={this.state.startDate}
                                              onChange={this.handleInput}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col>
                                          <FormGroup>
                                            <label
                                              className="form-control-label"
                                              htmlFor="input-dateStart"
                                            >
                                              Up to Date
                                            </label>
                                            <Input
                                              name="endDate"
                                              className="form-control-alternative"
                                              type="date"
                                              value={this.state.endDate}
                                              onChange={this.handleInput}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </PopoverBody>
                        </Popover>
                      </FormGroup>
                    </Col>
                    <Col md={2} lg={2} xl={2} xs={3} sm={2}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-dateStart"
                        >
                          &nbsp;
                        </label>
                        <br />
                        <Button
                          className="form-control-alternative"
                          color="info"
                          onClick={this.filterDate}
                        >
                          <i className="fa fa-search"></i>{' '}
                          {this.state.isMobileVersion ? '' : 'Search'}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Form className="card-header">
                  <Row form>
                    {this.state.activeTab === '1' ? (
                      <Col md={12}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-dateStart"
                          >
                            Filter By Jobs
                          </label>
                          <br />
                          <span>
                            <Button
                              className="form-control-alternative"
                              color={
                                this.state.buttonActive === '1'
                                  ? 'info'
                                  : 'secondary'
                              }
                              onClick={this.getOpen}
                            >
                              {!this.state.isMobileVersion ? (
                                'Open'
                              ) : (
                                <small>Open</small>
                              )}
                            </Button>
                            <Button
                              className="form-control-alternative"
                              color={
                                this.state.buttonActive === '2'
                                  ? 'info'
                                  : 'secondary'
                              }
                              onClick={this.getClose}
                            >
                              {!this.state.isMobileVersion ? (
                                'Close'
                              ) : (
                                <small>Close</small>
                              )}
                            </Button>
                            <Button
                              className="form-control-alternative"
                              color={
                                this.state.buttonActive === '3'
                                  ? 'info'
                                  : 'secondary'
                              }
                              onClick={this.getAll}
                            >
                              {!this.state.isMobileVersion ? (
                                'All'
                              ) : (
                                <small>All</small>
                              )}
                            </Button>
                          </span>
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </Form>

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggleTab('1');
                      }}
                    >
                      P&L
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '2',
                      })}
                      onClick={() => {
                        this.toggleTab('2');
                      }}
                    >
                      Workers
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <ReportJobs
                      jobsFilter={this.state.jobsFilter}
                      openModal={this.handleOpenModal}
                      openModalEdit={this.handleOpenModalEdit}
                      sendReconciled={this.sendReconciled}
                      isMobileVersion={this.state.isMobileVersion}
                      updateReportDataExpense={this.updateReportDataExpense}
                      search={searchItem}
                      paymentAccount={paymentAccount.list || []}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <ReportWorkers
                      jobsFilter={this.state.jobsFilter}
                      workersFilter={this.state.workersFilter}
                      openModal={this.handleExpenseOpenModal}
                      openModalEdit={this.handleOpenModalEdit}
                      sendReconciled={this.sendReconciled}
                      isMobileVersion={this.state.isMobileVersion}
                      updateReportDataExpense={this.updateReportDataExpense}
                      search={searchItem}
                      paymentAccount={paymentAccount.list || []}
                    />
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size={'lg'}>
          <ModalHeader toggle={this.toggleModal}> Expense detail</ModalHeader>
          <ModalBody>
            {/* {this.state.img && this.state.extension !== 'pdf' ? (
              <img
                width="100%"
                height="100%"
                src={this.state.img}
                alt="photo_urlss"
              />
            ) : null}

            {this.state.img && this.state.extension === 'pdf' ? (
              <>
                <Document
                  file={this.state.pdfFile}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={this.state.pageNumber} />
                </Document>
                <p>
                  Page {this.state.pageNumber} of {this.state.numPages}
                </p>
              </>
            ) : null} */}
            {this.state.img ? (
              <PreviewFile previewImages={[{ viewingFrom: 'url', file: this.state.img }]} />
            ) : <p>Empty documents.</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalEdit}
          toggle={this.toggleModalEdit}
          size={'lg'}
        >
          <ModalHeader toggle={this.toggleModalEdit}> </ModalHeader>
          <ModalBody>
            <UpdateExpenseModal
              expenseId={this.state.expense._id}
              closeModal={this.toggleModalEdit}
            ></UpdateExpenseModal>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModalEdit}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user.users,
  jobs: state.job.jobs,
  expenses: state.expense.expenses,
  invoices: state.invoice.invoices,
  paymentAccount: state.paymentAccount,
  // search: state.search.search,
  times: state.time.times,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, {
  getUsers,
  getJobs,
  getExpenses,
  getInvoices,
  getTimes,
  getPaymentAccounts,
  sendReconciled,
  reset
})(Reports);
