
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_SYNCING = 'SET_SYNCING';


export const setConnectionStatus = status => async dispatch => {
    try {
        dispatch({ type: SET_CONNECTION_STATUS, status });
    } catch (err) {
        dispatch({ type: SET_CONNECTION_STATUS, status: false });
        console.error(err);
    }
}

export const setSyncing = status => async dispatch => {
    try {
        dispatch({ type: SET_SYNCING, status });
    } catch (err) {
        dispatch({ type: SET_CONNECTION_STATUS, status: false });
        console.error(err);
    }
}