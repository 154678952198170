import React from 'react';
import { withRouter } from 'react-router-dom';
// import { startCase, toLower } from 'lodash';

import _ from 'lodash';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Label,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../helpers/file';
import { addUser } from '../../redux/actions/userAction';
import { getPaymentAccounts } from '../../redux/actions/paymentAccountAction';
import { getPermissions } from '../../redux/actions/permissionAction';

const toastr = require("toastr");
class AddWorker extends React.Component {
  state = {
    submitting: false, 
    permissionName: '',
    permissionId: '',
    permissions: {
      estimates: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , jobs: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , invoices: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , expenses: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , time: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , reports: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , clients: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , workers: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , items: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , properties: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , propertyTypes: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , revenueCenter: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , propertyStages: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , lenders: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
      , paymentAccounts: {
        view: "all"
        , create: "all"
        , update: "all"
        , delete: "all"
      }
    },
    defaultPaymentAccount: ''
  };

  async componentDidMount() {
    await this.props.getPaymentAccounts();
    await this.props.getPermissions();

  }

  async componentWillUnmount() {
    await this.props.getPaymentAccounts();
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleInput = e => {
    e.persist();
    if (e.target.name === 'defaultPermission') {
      let arrayPermissions = this.props.defPermissions.find(item => item._id === e.target.value);
      this.setState(prevState => ({
        ...prevState,
        permissions: arrayPermissions.permissions,
        permissionName: arrayPermissions.name,
        permissionId: e.target.value
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }

  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if(image){
        this.setState(prevState => ({ ...prevState, img: image }));
      }
    } catch (e) {
      toastr.error('Server error.');
    }

  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true });
    await this.props.addUser(this.state);
    this.redirectToWorkers();
    this.setState({ submitting: false });
  };

  /**
   * After handleSubmit, if no error, go back to workers list
   * 
   */
  redirectToWorkers = () => {
    const { usersEr } = this.props;
    if(usersEr){
      toastr.error(usersEr)
    } else {
      this.props.history.push(`workers`);
    }
  }

  handleChange = (e) => {
    let newState = _.update(_.cloneDeep(this.state), e.target.name, () => {
      return e.target.value;
    });
    this.setState(newState);
  }

  render() {
    const { submitting, permissions } = this.state;

    const renderElements = [];
    const permissionsOrderDisplay = ['estimates', 'jobs', 'invoices', 'expenses', 'time', 'reports', 'clients', 'workers', 'items', 'properties', 'propertyTypes', 'revenueCenter', 'propertyStages', 'lenders', 'paymentAccounts'];
    const associatedInUserLogged = ['estimates', 'jobs', 'invoices', 'expenses', 'time'];
    permissionsOrderDisplay.map(moduleName => {
      const moduleActionsOrder = ['view', 'create', 'update', 'delete'];
      const renderChildElements = [];
      if (permissions[moduleName]) {
        moduleActionsOrder.map(actionName => {
          const childElmt = (
            <FormGroup key={'actionName-' + actionName} className={`-child-FG -${actionName}-fg`}>
              <Label
                className="form-control-label d-inline-block"
                htmlFor="input-name"
              >
                {_.startCase(actionName)}:
              </Label>
              <FormGroup>
                <div>
                  <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="all" 
                    checked={permissions[moduleName][actionName] === "all"} onChange={this.handleChange}
                  /><span>All</span>
                </div>
                {
                  associatedInUserLogged.indexOf(moduleName) === -1 ? null :
                  <div>
                    <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="assigned" 
                      checked={permissions[moduleName][actionName] === "assigned"} onChange={this.handleChange} 
                    /><span>Assigned</span></div>
                }
                <div>
                  <Input type="radio" name={'permissions.' + moduleName + '.' + actionName} value="none" 
                    checked={permissions[moduleName][actionName] === "none"} onChange={this.handleChange} />
                  <span>None</span></div>
              </FormGroup>
            </FormGroup>
          )
          renderChildElements.push(childElmt);
          
          return renderChildElements;
        });
      }
      const elmt = (
        <FormGroup key={moduleName}>
          <label
            className="form-control-label d-inline-block"
            htmlFor="input-name"
          >
            {_.startCase(moduleName)}
          </label>
          {renderChildElements}
        </FormGroup>
      )
      renderElements.push(elmt);

      return renderElements;

    });

    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">User Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Display Name *
                            </label>
                            <Input
                              required
                              className="form-control-alternative"
                              placeholder="Enter the worker name"
                              name="name"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>                          

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email *
                            </label>
                            <Input
                              required
                              name="email"
                              className="form-control-alternative"
                              placeholder="Enter a email"
                              type="email"
                              onChange={this.handleInput}
                              autoComplete="off"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-password"
                            >
                              Password *
                            </label>
                            <Input
                              required
                              name="password"
                              className="form-control-alternative"
                              placeholder="Enter a password"
                              type="password"
                              onChange={this.handleInput}
                              autoComplete="off"
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address *
                            </label>
                            <Input
                              required
                              name="address"
                              className="form-control-alternative"
                              placeholder="Enter an address"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-contactName"
                            >
                              Contact Name *
                            </label>
                            <Input
                              required
                              name="contactName"
                              className="form-control-alternative"
                              placeholder="Enter a contact name"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone *
                            </label>
                            <Input
                              required
                              name="phone"
                              className="form-control-alternative"
                              placeholder="Enter the phone number"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-mobile"
                            >
                              Mobile *
                            </label>
                            <Input
                              required
                              name="mobile"
                              className="form-control-alternative"
                              placeholder="Enter the mobile number"
                              type="number"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-activity"
                            >
                              Activity *
                            </label>
                            <Input
                              required
                              name="activity"
                              className="form-control-alternative"
                              placeholder="Enter an activity (plumber, carpenter)"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-type"
                            >
                              Type *
                            </label>
                            <Input
                              required
                              name="type"
                              className="form-control-alternative"
                              type="select"
                              defaultValue="Choose One"
                              onChange={this.handleInput}
                            >
                              <option disabled>
                                Choose One
                              </option>
                              <option>1099</option>
                              <option>Employee</option>
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Add Documents *
                            </label>
                            <Input
                              required
                              name="photo"
                              id="photo"
                              className="form-control-alternative"
                              type="file"
                              onChange={this.uploadPhoto}
                              multiple
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Payment Rate *
                            </label>
                            <Input
                              required
                              name="payRate"
                              className="form-control-alternative"
                              type="number"
                              placeholder="$0.00"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Effective Rate *
                            </label>
                            <Input
                              required
                              name="effectiveRate"
                              className="form-control-alternative"
                              type="number"
                              placeholder="$0.00"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                             Default Payment Account *
                            </label>
                            <Input
                              name="defaultPaymentAccount"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Select"
                            >
                              <option value="Select" disabled>
                                Select Default Payment Account
                              </option>
                              {this.props.paymentAccount.list.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                             Default Permission *
                            </label>
                            <Input
                              name="defaultPermission"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Select"
                            >
                              <option value="Select" disabled>
                                Select Default Permission
                              </option>
                              {this.props.defPermissions.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        
                      <Col lg="6">
                      {renderElements}
                      </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  paymentAccount: state.paymentAccount,
  usersEr: state.user.usersEr,
  userLogged: state.auth.userLogged,
  defPermissions: state.defPermission && state.defPermission.list !== undefined ? state.defPermission.list : []
});

export default connect(mapStateToProps, { addUser, getPaymentAccounts, getPermissions })(withRouter(AddWorker));
