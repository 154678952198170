import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Table,

    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Button
} from 'reactstrap';



import Header from '../../../../components/Headers/Header';
import { useEffect } from 'react';

// REDUX
import { getPropertyTypes, deletePropertyType } from '../../../../redux/actions/propertyAction';
import { permissions } from '../../../../helpers/utils';


// /admin/updateproperty/${props.item._id}
const ActionButton = props => {
    return (
        <UncontrolledDropdown>
            <DropdownToggle>...</DropdownToggle>
            <DropdownMenu
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => ({
                            ...data,
                            styles: {
                                ...data.styles,
                                overflow: 'auto',
                                maxHeight: 130,
                            },
                        }),
                    },
                }}
            >
                {
                    permissions(props.props.userLogged, 'propertyTypes', 'update', 0) ?
                    <DropdownItem to={`/admin/propertysettings/types/update/${props.data._id}`} tag={Link}>
                        Update
                    </DropdownItem>
                    : 
                    <DropdownItem disabled to={`/admin/propertysettings/types/update/${props.data._id}`} tag={Link}>
                        Update
                    </DropdownItem>
                }
                {
                    permissions(props.props.userLogged, 'propertyTypes', 'delete', 0) ?
                    <DropdownItem
                        onClick={() => {
                            if (window.confirm("Are you sure, you want to delete this item?")) {
                                props.deletePropertyType(props.data._id);
                            }
                        }}
                    >
                        <span className="text-danger">Delete</span>
                    </DropdownItem>
                    : 
                    <DropdownItem disabled to={`/admin/propertysettings/types/update/${props.data._id}`} tag={Link}>
                        Delete
                    </DropdownItem>
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}


const PropertyTypes = props => {

    const dispatch = useDispatch();

    const { property } = useSelector(state => {
        return {
            property: state.property
        }
    });

    useEffect(() => {
        dispatch(getPropertyTypes());
    }, [])

    const handleDeletePropertyType = id => {
        dispatch(deletePropertyType(id));
    }
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Property Types</h3>
                                    </div>
                                    <div className="col text-right">
                                        {
                                        permissions(props.userLogged, 'propertyTypes', 'create', 0) ?
     
                                        <Button color="primary" type="button" onClick={() => window.location.href = '/admin/propertysettings/types/create'}>
                                            Add Property Type
                                        </Button>
                                            : null
                                        }
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Table style={{ width: '100%' }} className="align-items-center table-flush" responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Name</th>
                                                <th>Order</th>
                                                {/* <th>Date Created</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {property.propertyTypes.map((propertyType) => {

                                                return <tr>

                                                    <td style={{ width: 80 }}>
                                                        <ActionButton
                                                            deletePropertyType={handleDeletePropertyType}
                                                            data={propertyType}
                                                            props={props}
                                                        ></ActionButton>

                                                    </td>
                                                    <td>{propertyType.name}</td>
                                                    <td>{propertyType.order}</td>

                                                </tr>
                                            })}

                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


};

const mapStateToProps = state => ({
    userLogged: state.auth.userLogged,
});
  
export default connect(mapStateToProps) (
    withRouter(PropertyTypes)
);