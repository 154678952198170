import localforage from "./plugins/localforage"
const toastr = require("toastr");

let eventListenerCache = {};

export default options => {
    let { defaultAdapter } = options
    let storage = localforage(options)

    return config => {
        config.timeout = config.timeout || 5000

        function storeRequest(data) {
            storage.setItem(String(Date.now()), data)
        }

        function removeRequest(time) {
            storage.removeItem(time)
        }

        async function sendAllRequest() {
    
            storage.iterate((data, time) => {
                defaultAdapter(data)
                    .then(() => removeRequest(time))
            })

        }

        function disconnected() {
            toastr.error('Internet connection lost');
        }

        function addWindowListenerIfNone(eventType, func) {
            if (eventListenerCache[eventType]) {
                return;
            }
            eventListenerCache[eventType] = func;
            window.addEventListener(eventType, func);
        }
        addWindowListenerIfNone('online', sendAllRequest)
        addWindowListenerIfNone('offline', disconnected)

        // window.addEventListener('online',sendAllRequest);

        // window.addEventListener('offline', (event) => {
        //   console.log("Axios Offline: The network connection has been lost.");
        // });

        let response = defaultAdapter(config)
            .catch(err => {
                let {
                    code,
                    message,
                    response
                } = err

                if (response === undefined && (code === 'ECONNABORTED' || message === 'Network Error')) {
                    storeRequest(config)
                } else {
                    sendAllRequest()
                }

                return Promise.reject(err)
            })
            .then(resolve => {
                sendAllRequest()

                return Promise.resolve(resolve)
            })

        return response
    }
}