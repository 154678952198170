import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_PROPERTIES_SENT = 'FETCH_PROPERTIES_SENT';
export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
export const FETCH_PROPERTIES_FAILURE = 'FETCH_PROPERTIES_FAILURE';

export const FETCH_PROPERTY_SENT = 'FETCH_PROPERTY_SENT';
export const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_FAILURE = 'FETCH_PROPERTY_FAILURE';


export const FETCH_PROPERTIES_ADD_SUCCESS = 'FETCH_PROPERTIES_ADD_SUCCESS';
export const FETCH_PROPERTIES_ADD_FAILURE = 'FETCH_PROPERTIES_ADD_FAILURE';

export const FETCH_PROPERTIES_UPDATE_SUCCESS = 'FETCH_PROPERTIES_UPDATE_SUCCESS';
export const FETCH_PROPERTIES_UPDATE_FAILURE = 'FETCH_PROPERTIES_UPDATE_FAILURE';

export const FETCH_PROPERTIES_REMOVE_SUCCESS = 'FETCH_PROPERTIES_REMOVE_SUCCESS';
export const FETCH_PROPERTIES_REMOVE_FAILURE = 'FETCH_PROPERTIES_REMOVE_FAILURE';



export const FETCH_PROPERTY_STAGE_ADD_SUCCESS = 'FETCH_PROPERTY_STAGE_ADD_SUCCESS';
export const FETCH_PROPERTY_STAGE_ADD_FAILURE = 'FETCH_PROPERTY_STAGE_ADD_FAILURE';

export const FETCH_PROPERTY_STAGE_UPDATE_SUCCESS = 'FETCH_PROPERTY_STAGE_UPDATE_SUCCESS';
export const FETCH_PROPERTY_STAGE_UPDATE_FAILURE = 'FETCH_PROPERTY_STAGE_UPDATE_FAILURE';

export const FETCH_PROPERTY_STAGE_SENT = 'FETCH_PROPERTY_STAGE_SENT';
export const FETCH_PROPERTY_STAGE_SUCCESS = 'FETCH_PROPERTY_STAGE_SUCCESS';
export const FETCH_PROPERTY_STAGE_FAILURE = 'FETCH_PROPERTY_STAGE_FAILURE';


export const FETCH_PROPERTY_STAGE_REMOVE_SUCCESS = 'FETCH_PROPERTY_STAGE_REMOVE_SUCCESS';
export const FETCH_PROPERTY_STAGE_REMOVE_FAILURE = 'FETCH_PROPERTY_STAGE_REMOVE_FAILURE';



export const FETCH_PROPERTY_TYPE_ADD_SUCCESS = 'FETCH_PROPERTY_TYPE_ADD_SUCCESS';
export const FETCH_PROPERTY_TYPE_ADD_FAILURE = 'FETCH_PROPERTY_TYPE_ADD_FAILURE';

export const FETCH_PROPERTY_TYPE_UPDATE_SUCCESS = 'FETCH_PROPERTY_TYPE_UPDATE_SUCCESS';
export const FETCH_PROPERTY_TYPE_UPDATE_FAILURE = 'FETCH_PROPERTY_TYPE_UPDATE_FAILURE';

export const FETCH_PROPERTY_TYPE_SENT = 'FETCH_PROPERTY_TYPE_SENT';
export const FETCH_PROPERTY_TYPE_SUCCESS = 'FETCH_PROPERTY_TYPE_SUCCESS';
export const FETCH_PROPERTY_TYPE_FAILURE = 'FETCH_PROPERTY_TYPE_FAILURE';


export const FETCH_PROPERTY_TYPE_REMOVE_SUCCESS = 'FETCH_PROPERTY_TYPE_REMOVE_SUCCESS';
export const FETCH_PROPERTY_TYPE_REMOVE_FAILURE = 'FETCH_PROPERTY_TYPE_REMOVE_FAILURE';


export const FETCH_LENDER_SENT = 'FETCH_LENDER_SENT';
export const FETCH_LENDER_SUCCESS = 'FETCH_LENDER_SUCCESS';
export const FETCH_LENDER_FAILURE = 'FETCH_LENDER_FAILURE';


export const FETCH_LENDER_ADD_SUCCESS = 'FETCH_LENDER_ADD_SUCCESS';
export const FETCH_LENDER_ADD_FAILURE = 'FETCH_LENDER_ADD_FAILURE';

export const FETCH_LENDER_UPDATE_SUCCESS = 'FETCH_LENDER_UPDATE_SUCCESS';
export const FETCH_LENDER_UPDATE_FAILURE = 'FETCH_LENDER_UPDATE_FAILURE';

export const FFETCH_LENDER_TYPE_SENT = 'FFETCH_LENDER_TYPE_SENT';
export const FETCH_LENDER_TYPE_SUCCESS = 'FETCH_LENDER_TYPE_SUCCESS';
export const FETCH_LENDER_TYPE_FAILURE = 'FETCH_LENDER_TYPE_FAILURE';


export const FETCH_LENDER_REMOVE_SUCCESS = 'FETCH_LENDER_REMOVE_SUCCESS';
export const FETCH_LENDER_REMOVE_FAILURE = 'FETCH_LENDER_REMOVE_FAILURE';




// const shouldGetList = (state, listArg) => {
//   const listInfo = state[listArg];
//   if(!listInfo || !listInfo.times) {
//     return true;
//   } else if((new Date().getTime() - listInfo.lastUpdated) > 500) {
//     return true;
//   } else {
//     return listInfo.didInvalidate;
//   }
// }

// export const addTime = data => async dispatch => {
//   try {
//     const response = await authService.addTime(data);
//     dispatch({ type: FETCH_PROPERTIES_ADD_SUCCESS, payload: response.data.time });
//   } catch (err) {
//     dispatch({ type: FETCH_PROPERTIES_ADD_FAILURE, payload: err.message });
//     console.error(err);
//   }
// };

// export const updateTime = (id, data) => async dispatch => {
//   try {
//     const response = await authService.updateTime(id, data);
//     dispatch({
//       type: FETCH_PROPERTIES_UPDATE_SUCCESS,
//       payload: { id, data: response.data.TIME },
//     });
//   } catch (err) {
//     dispatch({ type: FETCH_PROPERTIES_UPDATE_FAILURE, payload: err.message });
//     console.error(err);
//   }
// };

// export const removeTime = id => async dispatch => {
//   try {
//     await authService.removeTime(id);
//     dispatch({ type: FETCH_PROPERTIES_REMOVE_SUCCESS, payload: { id } });
//   } catch (err) {
//     dispatch({ type: FETCH_PROPERTIES_REMOVE_FAILURE, payload: err });
//     console.error(err);
//   }
// };

export const getProperties = () => async (dispatch, getState) => {

  dispatch({ type: FETCH_PROPERTIES_SENT }); // get old
  try {
    const response = await authService.getProperties();
    dispatch({
      type: FETCH_PROPERTIES_SUCCESS
      , payload: response.data.properties

    });
    
  } catch (err) {
    dispatch({ type: FETCH_PROPERTIES_FAILURE, payload: err && err.message });
    console.error(err);
  }

};


export const getProperty = (id) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PROPERTY_SENT }); // get old
  try {
    const response = await authService.getProperty(id);
    dispatch({
      type: FETCH_PROPERTY_SUCCESS
      , payload: response.data.property
    });
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_FAILURE, payload: err.message });
    console.error(err);
  }

};

export const addProperty = property => async dispatch => {
  try {
    const response = await authService.addProperty(property);

    dispatch({
      type: FETCH_PROPERTIES_ADD_SUCCESS,
      payload: response.data.properties,
    });
  } catch (err) {
    dispatch({ type: FETCH_PROPERTIES_ADD_FAILURE, payload: err });
    console.error(err);
  }
};




export const updateproperty = (id, data) => async dispatch => {
  try {

    const response = await authService.updateProperty(id, data);
    dispatch({
      type: FETCH_PROPERTIES_UPDATE_SUCCESS,
      payload: { id, data: response.data.properties },
    });
  } catch (err) {
    dispatch({ type: FETCH_PROPERTIES_UPDATE_FAILURE, payload: err.message });
    console.error(err);
  }
};

export const deleteProperty = id => async dispatch => {
  try {
    await authService.deleteProperty(id);
    dispatch({ type: FETCH_PROPERTIES_REMOVE_SUCCESS, payload: { id } });
  } catch (err) {
    dispatch({ type: FETCH_PROPERTIES_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};


export const getPropertyStages = () => async dispatch => {
  try {
    dispatch({ type: FETCH_PROPERTY_SENT }); // get old
    const response = await authService.getPropertyStages();

    dispatch({
      type: FETCH_PROPERTY_STAGE_SUCCESS,
      payload: response.data.propertyStages,
    });
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_STAGE_ADD_FAILURE, payload: err });
    console.error(err);
  }
};


export const addPropertyStage = property => async dispatch => {
  try {

    const response = await authService.addPropertyStage(property);
    dispatch({
      type: FETCH_PROPERTY_STAGE_ADD_SUCCESS,
      payload: response.data.propertyStage,
    });
    dispatch(getPropertyStages());
   
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_STAGE_ADD_FAILURE, payload: err });
    console.error(err);
  }
};


export const updatePropertyStage = property => async dispatch => {
  try {

    const response = await authService.updatePropertyStage(property);
    dispatch({
      type: FETCH_PROPERTY_STAGE_UPDATE_SUCCESS,
      payload: response.data.propertyStage,
    });
    dispatch(getPropertyStages());
   
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_STAGE_UPDATE_SUCCESS, payload: err });
    console.error(err);
  }
};

export const deletePropertyStage = id => async dispatch => {
  try {
    await authService.deletePropertyStage(id);
    dispatch({ type: FETCH_PROPERTY_STAGE_REMOVE_SUCCESS, payload: { id } });
    dispatch(getPropertyStages());
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_STAGE_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};




export const getPropertyTypes = () => async dispatch => {
  try {
    dispatch({ type: FETCH_PROPERTY_SENT }); // get old
    const response = await authService.getPropertyTypes();

    dispatch({
      type: FETCH_PROPERTY_TYPE_SUCCESS,
      payload: response.data.propertyTypes,
    });
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_TYPE_ADD_FAILURE, payload: err });
    console.error(err);
  }
};


export const addPropertyType = property => async dispatch => {
  try {
    const response = await authService.addPropertyType(property);
    dispatch({
      type: FETCH_PROPERTY_TYPE_ADD_SUCCESS,
      payload: response.data.propertyTypes,
    });
    dispatch(getPropertyTypes());
   
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_TYPE_ADD_FAILURE, payload: err });
    console.error(err);
  }
};


export const updatePropertyType = property => async dispatch => {
  try {

    const response = await authService.updatePropertyType(property);
    dispatch({
      type: FETCH_PROPERTY_TYPE_UPDATE_SUCCESS,
      payload: response.data.propertyStage,
    });
    dispatch(getPropertyTypes());
   
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_TYPE_UPDATE_SUCCESS, payload: err });
    console.error(err);
  }
};

export const deletePropertyType = id => async dispatch => {
  try {
    await authService.deletePropertyType(id);
    dispatch({ type: FETCH_PROPERTY_TYPE_REMOVE_SUCCESS, payload: { id } });
    dispatch(getPropertyTypes());
  } catch (err) {
    dispatch({ type: FETCH_PROPERTY_TYPE_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};




export const getLenders = () => async dispatch => {
  try {
    dispatch({ type: FETCH_LENDER_SENT }); // get old
    const response = await authService.getLenders();

    dispatch({
      type: FETCH_LENDER_SUCCESS,
      payload: response.data.lenders,
    });
  } catch (err) {
    dispatch({ type: FETCH_LENDER_FAILURE, payload: err });
    console.error(err);
  }
};


export const addLenders = lender => async dispatch => {
  try {
    const response = await authService.addLender(lender);
    dispatch({
      type: FETCH_LENDER_ADD_SUCCESS,
      payload: response.data.lenders,
    });
    dispatch(getLenders());
   
  } catch (err) {
    dispatch({ type: FETCH_LENDER_ADD_FAILURE, payload: err });
    console.error(err);
  }
};


export const updateLenders = property => async dispatch => {
  try {

    const response = await authService.updateLender(property);
    dispatch({
      type: FETCH_LENDER_UPDATE_SUCCESS,
      payload: response.data.propertyStage,
    });
    dispatch(getLenders());
   
  } catch (err) {
    dispatch({ type: FETCH_LENDER_UPDATE_SUCCESS, payload: err });
    console.error(err);
  }
};

export const deleteLender = id => async dispatch => {
  try {
    await authService.deleteLender(id);
    dispatch({ type:FETCH_LENDER_REMOVE_SUCCESS, payload: { id } });
    dispatch(getLenders());
  } catch (err) {
    dispatch({ type: FETCH_LENDER_REMOVE_FAILURE, payload: err });
    console.error(err);
  }
};