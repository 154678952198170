import React from 'react';
import { withRouter } from 'react-router-dom';
import { get, isArray } from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Table,
} from 'reactstrap';
import { WithContext as ReactTags } from 'react-tag-input';

// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { updateEstimate } from '../../redux/actions/jobAction';
import AuthService from '../../services/services';

const toastr = require("toastr");

const authService = new AuthService();

class SendEstimate extends React.Component {
  state = {
    name: '',
    email: '',
    items: [],
    comments: '',
    address: '',
    addressEstimate: '',
    tags: [],
    total: 0,
    estimateId: this.props.match.params.id,
    submitting: false
  };

  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidMount() {
    if (this.props.estimates.length === 0)
      this.props.history.push(`/admin/estimates`);
    const estimate = this.props.estimates.filter(
      item => item._id === this.props.match.params.id
    )[0];
    const client = this.props.clients.filter(
      item => item._id === estimate.clientId
    )[0];
    this.setState(prevState => {
      let total;
      total = estimate.items.reduce(
        (acc, current, i) => acc + current.subtotal,
        0
      );
      return {
        ...prevState,
        name: `${client.firstName} ${client.lastName}`,
        email: client.email,
        total,
        address: estimate.jobAddress,
        items: estimate.items,
        tags: [{ id: client.email, text: client.email }],
        comments: estimate.comments,
        phone: estimate.estimatePhone,
        estimateNo: estimate.estimateNo
      };
    });
  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ submitting: true });

    authService
      .sendEstimates(this.state)
      .then(response => {
        this.props.history.push(`/admin/estimates`);
      })
      .catch(err => {
        console.error(err.response);
        this.setState({ submitting: false });
        if(err.response){
          const errors = get(err, 'response.body.errors', [])
          if(isArray(errors) && errors.length > 0){
            toastr.error(`Mailer Error: ${get(errors[0], 'message', 'Unspecified error. Please check the console.')}`)
          }
        }
      });
  };

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleInputItems = index => e => {
    const { items } = this.state;
    let { name, value } = e.target;

    let updatedItems = items.map((item, itemIndex) => {
      if (index === itemIndex) {
        let subtotal = 0;

        if (name === 'quantity') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        else if (name === 'rate') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.quantity;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }

        else {
          subtotal = item.subtotal; // item.rate * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }


        return {
          ...item,
          [name]: value,
          subtotal
        }
      }

      return item;
    });

    const invoiceTotal = updatedItems.reduce(
      (acc, current) => acc + (parseFloat(current.rate) * current.quantity),
      0
    );

    this.setState({
      items: [...(updatedItems || [])],
      total: invoiceTotal
    });

  }

  render() {
    const { address, submitting } = this.state;
    if (!this.state) return <p> Loading </p>;
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        {' '}
                        Information to send in the email
                      </h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Client Name
                            </label>
                            <Input
                              defaultValue={`${this.state.name}`}
                              className="form-control-alternative"
                              placeholder="Enter the name client"
                              name="name"
                              type="text"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Address
                            </label>
                            <Input
                              defaultValue={address}
                              className="form-control-alternative"
                              placeholder="Enter the address client"
                              name="address"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-name"
                            >
                              Emails
                            </label>
                            <br />
                            <div>
                              <ReactTags
                                placeholder="Add email"
                                tags={this.state.tags}
                                handleDelete={this.handleDelete}
                                handleAddition={this.handleAddition}
                                handleDrag={this.handleDrag}
                                delimiters={this.state.delimiters}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Items
                            </label>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col"> Item Name </th>
                                  <th scope="col"> Description </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Rate </th>
                                  <th scope="col"> Total </th>
                                  <th scope="col"> Options </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.items.map((el, i) => (
                                  <tr key={i}>
                                    <td> {/* el.itemName*/}

                                      <Input
                                        defaultValue={el.itemName}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemName"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemDescription}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemDescription"
                                        type="text"
                                        // rows={5}
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>
                                    <td >
                                      <Input
                                        value={el.quantity}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="quantity"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        value={el.rate}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="rate"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>
                                    <td> $ {el.subtotal ? el.subtotal.toFixed(2) : 0}</td>
                                    <td>
                                      <Button
                                        onClick={e =>
                                          this.setState(prevState => {
                                            const filter =
                                              this.state.items.filter(
                                                e => {
                                                  this.setState({ total: this.state.total - this.state.items[i].subtotal });
                                                  return e !== this.state.items[i]
                                                }
                                              );
                                            this.setState({ items: filter });
                                          })
                                        }
                                        style={{
                                          width: '100px',
                                          height: '20px',
                                          fontSize: '10px',
                                          paddingTop: '0',
                                        }}
                                        className="btn icon-btn btn-danger"
                                      >
                                        <i className="nc-icon nc-simple-remove" />
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                            >
                              Total
                            </label>
                            <Input
                              name="total"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              value={this.state.total}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              External Comments
                            </label>
                            <Input
                              name="comments"
                              className="form-control-alternative"
                              placeholder="Enter comments (optional)"
                              type="textarea"
                              rows="6"
                              onChange={this.handleInput}
                              value={this.state.comments}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Send
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  estimates: state.job.estimates,
  clients: state.client.clients,
});

export default connect(mapStateToProps, { updateEstimate })(
  withRouter(SendEstimate)
);
