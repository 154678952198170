import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Form
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { useSelector, useDispatch } from 'react-redux';


import { addLenders, updateLenders } from '../../../../redux/actions/propertyAction';



const AddUpdateLender = props => {

    const { history, match } = props;

    const currentId = match.params.id || null;

    const dispatch = useDispatch();
    const { property } = useSelector(state => {
        return {
            property: state.property
        }
    });

    const [isSaving, setIsSaving] = useState(false);

    const [lenderDetails, setLenderDetails] = useState(null);

    useEffect(() => {
        if (currentId) {
            const currentLender = property.lenders.find(item => item._id === currentId);
            setLenderDetails(currentLender);
        }
    }, [currentId])


    const handleInput = e => {
        const { name, value } = e.target;
        setLenderDetails({
            ...(lenderDetails || {}),
            [name]: value
        })
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);

        const payload = {
            ...(lenderDetails || {})
        };

        if (currentId) {
            setIsSaving(false);
            dispatch(updateLenders(payload));
        }
        else {
            dispatch(addLenders(payload));

            setTimeout(() => {
                setIsSaving(false);
                history.push(`/admin/propertysettings/lenders`);
            }, 1500);
        }

    };


    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">{currentId ? 'Update' : 'Add'} Lender</h3>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <div className="pl-lg-4">

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Name
                                                    </label>

                                                    <Input
                                                        name="name"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.name}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Email
                                                    </label>

                                                    <Input
                                                        name="email"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="email"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.email}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Order
                                                    </label>

                                                    <Input
                                                        name="order"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.order}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Typical Acquisition %
                                                    </label>

                                                    <Input
                                                        name="typicalAcquisition"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.typicalAcquisition}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Typical Repair  %
                                                    </label>

                                                    <Input
                                                        name="typicalRepair"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.typicalRepair}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Application Fee
                                                    </label>

                                                    <Input
                                                        name="applicationFee"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.applicationFee}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Appraisal Fee
                                                    </label>

                                                    <Input
                                                        name="appraisalFee"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.appraisalFee}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Origination Points
                                                    </label>

                                                    <Input
                                                        name="originationPoints"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.originationPoints}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Interest Rate
                                                    </label>

                                                    <Input
                                                        name="interestRate"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.interestRate}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Interest Paid Monthly
                                                    </label>

                                                    <Input
                                                        name="interestPaidMonthly"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.interestPaidMonthly}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Term
                                                    </label>
                                                    <Input
                                                        name="term"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.term}
                                                    />
                                                </FormGroup>

                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Draw Fee
                                                    </label>

                                                    <Input
                                                        name="drawFee"
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        type="number"
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.drawFee}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-hours"
                                                    >
                                                        Lender Email Text
                                                    </label>

                                                    <Input
                                                        className="form-control-alternative"
                                                        placeholder=""
                                                        name="lenderEmailText"
                                                        type="textarea"
                                                        rows={5}
                                                        onChange={handleInput}
                                                        value={lenderDetails && lenderDetails.lenderEmailText}
                                                    />
                                                </FormGroup>

                                            </Col>


                                        </Row>



                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Button
                                                        disabled={isSaving}
                                                        className="form-control-alternative"
                                                        color="info"
                                                    >
                                                        {isSaving ? 'Please Wait...' : 'Save'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );


}


export default withRouter(AddUpdateLender);