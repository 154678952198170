import {
  FETCH_PROPERTY_SENT,
  FETCH_PROPERTIES_SENT,
  FETCH_PROPERTIES_ADD_SUCCESS,
  FETCH_PROPERTIES_ADD_FAILURE,
  FETCH_PROPERTIES_FAILURE,
  FETCH_PROPERTIES_REMOVE_FAILURE,
  FETCH_PROPERTIES_REMOVE_SUCCESS,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_UPDATE_FAILURE,
  FETCH_PROPERTIES_UPDATE_SUCCESS,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_STAGE_SUCCESS,
  FETCH_PROPERTY_TYPE_SUCCESS,
  FETCH_LENDER_SUCCESS
} from '../actions/propertyAction';
import { LOG_LOGOUT_USER } from '../actions/authAction';
import { merger, adder, updater, remover } from './actionReducers';

const initialState = {
  properties: [],
  propertyStages: [],
  propertyTypes: [],
  lenders: [],
  currentProperty: null,
  didInvalidate: true
};

export const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_SENT:
      return state;
    case FETCH_PROPERTY_SENT:
      return state;
    case LOG_LOGOUT_USER:
      state = initialState;
      return state;
    case FETCH_PROPERTIES_SUCCESS:
      return merger(state, {
        properties: action.payload,
        propertiesErr: undefined,
        didInvalidate: false,
        lastUpdated: action.receivedAt
      });

    case FETCH_PROPERTY_SUCCESS:
      return merger(state, {
        currentProperty: action.payload,
        propertiesErr: undefined,
        didInvalidate: false
      });


    case FETCH_PROPERTIES_ADD_SUCCESS:
      return { ...state, properties: adder(state.properties, action.payload) };

    case FETCH_PROPERTIES_UPDATE_SUCCESS:
      try {
        const index = state.properties.findIndex(u => u._id === action.payload.id);
        return {
          ...state,
          properties: updater(state.properties, action.payload.data, index),
        };
      } catch (e) {
        return state;
      }

    case FETCH_PROPERTIES_REMOVE_SUCCESS:
      try {
        return { ...state, properties: remover(state.properties, action.payload.id) };
      } catch (e) {
        return state;
      }

    case FETCH_PROPERTIES_FAILURE:
    case FETCH_PROPERTIES_ADD_FAILURE:
    case FETCH_PROPERTIES_REMOVE_FAILURE:
    case FETCH_PROPERTIES_UPDATE_FAILURE:
      return merger(state, { propertiesErr: action.payload });

    case FETCH_PROPERTY_STAGE_SUCCESS:
      return merger(state, {
        propertyStages: action.payload,
        propertiesErr: undefined,
        didInvalidate: false
      });

    case FETCH_PROPERTY_TYPE_SUCCESS:
      return merger(state, {
        propertyTypes: action.payload,
        propertiesErr: undefined,
        didInvalidate: false
      });

    case FETCH_LENDER_SUCCESS:
      return merger(state, {
        lenders: action.payload,
        propertiesErr: undefined,
        didInvalidate: false
      });



    default:
      return state;
  }
};
