import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import { updateItem } from '../../redux/actions/itemAction';
import LoadBar from '../../components/shared/loadBar';

class UpdateItem extends React.Component {
  state = {
    item: '',
    description: '',
    quantity: 0,
    rate: 0,
    submitting: false
  };

  componentDidMount() {
    const item = this.props.items.filter(
      item => item._id === this.props.match.params.id
    )[0];
    this.setState(prevState => ({
      ...prevState,
      item: item.item,
      description: item.description,
      quantity: item.quantity,
      rate: item.rate,
    }));
  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ submitting: true });
    await this.props.updateItem(this.props.match.params.id, this.state);
    this.props.history.push(`/admin/items`);
    this.setState({ submitting: false })
  };

  render() {
    const { submitting } = this.state;
    if (!this.state) return <LoadBar />;
    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Worker Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-contactName"
                            >
                              Item
                            </label>
                            <Input
                              value={this.state.item}
                              required
                              name="item"
                              className="form-control-alternative"
                              placeholder="Enter a item name"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-activity"
                            >
                              Description
                            </label>
                            <Input
                              value={this.state.description}
                              required
                              name="description"
                              className="form-control-alternative"
                              placeholder="Enter a description"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Quantity
                            </label>
                            <Input
                              value={this.state.quantity}
                              required
                              name="quantity"
                              className="form-control-alternative"
                              type="number"
                              placeholder="0"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Rate
                            </label>
                            <Input
                              value={this.state.rate}
                              required
                              name="rate"
                              className="form-control-alternative"
                              type="number"
                              placeholder="0"
                              onChange={this.handleInput}
                              step="any"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  items: state.item.items,
});

export default connect(mapStateToProps, { updateItem })(withRouter(UpdateItem));
