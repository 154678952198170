export const permissions = (user = null, table, action, allowedLevel, dynamicProps) => {

  const checkEstimesPermissions = (table, action) => {

    if (user.permissions[table][action] === 'assigned' || user.permissions[table][action] === 'all') {
      return true;
    }

    return false;

    // const test = dynamicProps && dynamicProps.length && dynamicProps.filter(item => item && item.workerId && item.workerId === user._id).length > 0;
    // console.log('checkEstimatesPermission Dynamic Props Length', dynamicProps);
    // console.log('checkEstimatesPermission', test);
    // return dynamicProps && dynamicProps.length && dynamicProps.filter(item => item && item.workerId && item.workerId === user._id).length > 0;
  }

  // const checkInvoicesPermissions = () => {
  //   const test =  dynamicProps.userId === user._id;

  //   return user._id === dynamicProps.userId;
  // }


  if (!user) {
    return false;
  } else if (user.permissions && user.permissions[table] && user.permissions[table][action]) {
    if (action === 'create') {
      return user.permissions[table][action] !== 'none';
    } else if (user.permissions[table][action] === 'none') {
      return false;
    } else if (user.permissions[table][action] === 'assigned' && (table === 'time' || table === 'invoices' || table === 'expenses')) {
      return checkEstimesPermissions(table, action);
    } else if (user.permissions[table][action] === 'assigned' && (table === 'estimates' || table === 'jobs')) {
      return checkEstimesPermissions(table, action);
    } else if (table === 'estimates' && action === 'update') {
      return checkEstimesPermissions(table, action);
    } else if (table === 'jobs' && action === 'update') {
      return checkEstimesPermissions(table, action);
    } else if (table === 'invoices' && action === 'update') {
      return checkEstimesPermissions(table, action);
    } else {
      return true;
    }
  } else {
    return false;
  }
}