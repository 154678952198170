import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment';
import { connect } from 'react-redux';
import { uploadPhoto } from '../../helpers/file';
import { getExpenses, updateExpense } from '../../redux/actions/expenseAction';
import PreviewFile from '../../helpers/PreviewFile';
import LoadBar from '../../components/shared/loadBar';

const toastr = require("toastr");

let loggedUser;

class UpdateExpenseModal extends React.Component {
  state = {
    workerId: '',
    expenses: [],
    image: '',
    date: '',
    vendor: '',
    category: '',
    description: '',
    total: 0,
    submitting: false
  };

  componentDidMount() {
    if (!this.props.userLogged) return this.props.history.push('/auth/login');
    loggedUser = this.props.userLogged;
    const expense = this.props.expenses.filter(
      item => item._id === this.props.expenseId
    )[0];
    const job = this.props.jobs.filter(item => item._id === expense.jobId)[0];
    const jobOptions = this.props.jobs.filter(job => job.status === 'Approve').sort((a, b) => a.jobName.localeCompare(b.jobName))
    
    this.setState(prevState => {
      const img = expense.image;
      const date = moment.utc(expense.date).format('YYYY-MM-DD');
      const { vendor, category, description, total, paymentAccountId } = expense;
      
      if (img && img.startsWith('http:')) {
        img.replace('http:', 'https:');
      }

      return {
        ...prevState,
        workerId: loggedUser._id,
        expenses: job.expenses,
        jobId: job._id,
        image: img,
        date,
        vendor,
        category,
        description,
        total,
        jobOptions,
        paymentAccountId
      };
    });
  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  uploadPhoto = async e => {
    try {
      const image = await uploadPhoto(e);
      if(image && image !== 'notNet.png'){
        this.setState(prevState => ({ ...prevState, image: image }));
      }
    } catch (e) {
      console.error(e);
      toastr.error('Server error.');
    }

  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.setState({ submitting: true });
    let payload = {
      ...this.state
    };

    delete payload.jobOptions;
    delete payload.expenses;
    delete payload.users;


    const expense = await this.props.updateExpense(
      this.props.expenseId,
      payload
    );
    this.props.closeModal(expense);
    this.setState({ submitting: false });
  };

  render() {
    const { jobOptions, submitting } = this.state;
    const categories = [
      { category: 'Job Materials' },
      { category: 'Gas' },
      { category: 'Supplies' },
      { category: 'Sub Contractors' },
    ];

    
    if (!this.state.workerId || this.state.workerId === '')
      return <LoadBar />;
    return (
      <>
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Update Expense</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Expense Date
                            </label>
                            <Input
                              required
                              id="date"
                              className="form-control-alternative"
                              placeholder="Select a date"
                              name="date"
                              defaultValue={this.state.date}
                              type="date"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                             Payment Account *
                            </label>
                            <Input
                              name="paymentAccountId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              defaultValue="Select"
                              value={this.state.paymentAccountId}
                            >
                              <option value="Select" disabled>
                                Select Payment Account
                              </option>
                              {this.props.paymentAccount.list.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Job Name *
                            </label>
                            <Input
                   
                              name="jobId"
                              className="form-control-alternative"
                              type="select"
                              onChange={this.handleInput}
                              value={this.state.jobId}
                            >
                              <option value="Select" disabled>
                                Select Job
                              </option>
                              {jobOptions.map((e, i) => (
                                <option key={i} value={`${e._id}`}>
                                  {e.jobName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Vendor
                            </label>
                            <Input
                              name="vendor"
                              className="form-control-alternative"
                              placeholder="Enter name of vendor"
                              type="text"
                              defaultValue={this.state.vendor}
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-category"
                            >
                              Category
                            </label>
                            <Input
                              required
                              name="category"
                              className="form-control-alternative"
                              placeholder="Enter a category"
                              type="select"
                              defaultValue={this.state.category}
                              onChange={this.handleInput}
                            >
                              {categories.map((e, i) => <option key={i}>{e.category}</option>)}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Description
                            </label>
                            <Input
                              name="description"
                              className="form-control-alternative"
                              placeholder="Enter a description (optional)"
                              type="text"
                              defaultValue={this.state.description}
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Image
                            </label>
                            <Input
                              name="photo"
                              id="photo"
                              className="form-control-alternative"
                              placeholder="Select a photo"
                              type="file"
                              onChange={this.uploadPhoto}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Total
                            </label>
                            <Input
                              name="total"
                              className="form-control-alternative"
                              type="text"
                              inputMode="decimal"
                              onChange={this.handleInput}
                              value={this.state.total}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          {this.state.image && (
                            <PreviewFile previewImages={[{ viewingFrom: 'url', file: this.state.image }]} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  expenses: state.expense.expenses,
  jobs: state.job.jobs,
  paymentAccount: state.paymentAccount,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, { getExpenses, updateExpense })(
  withRouter(UpdateExpenseModal)
);
