import AuthService from '../../services/services';

const authService = new AuthService();

export const FETCH_PAYMENT_ADD_SENT = 'FETCH_PAYMENT_ADD_SENT';
export const FETCH_PAYMENT_ADD_SUCCESS = 'FETCH_PAYMENT_ADD_SUCCESS';
export const FETCH_PAYMENT_ADD_FAILURE = 'FETCH_PAYMENT_ADD_FAILURE';


export const addPayment = data => async dispatch => {
  dispatch({ type: FETCH_PAYMENT_ADD_SENT });
  try {
 
    const response = await authService.addPayment(data);

    return response.data;
  } catch (err) {
    dispatch({ type: FETCH_PAYMENT_ADD_FAILURE, payload: err.message });
    console.error(err);
  }
};

