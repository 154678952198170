import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
  Table,
  Label
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import { connect } from 'react-redux';
import moment from 'moment';
import { getInvoices, updateInvoice } from '../../redux/actions/invoiceAction';
import Autosuggest from 'react-autosuggest';
import { addItem, getItems } from '../../redux/actions/itemAction';
import LoadBar from '../../components/shared/loadBar';


class UpdateInvoice extends React.Component {
  state = {
    workerId: '',
    invoiceDate: '',
    invoiceDescription: '',
    invoiceTotal: 0,
    jobName: '',
    submitting: false,
    description: '',
    total: 0,
    estimatedItems: [],
    clientId: '',
    items: [],
    itemName: '',
    itemDescription: '',
    itemInternalNotes: '',
    photo: [],
    quantity: '',
    cost: '',
    rate: '',
    value: '',
    suggestions: [],
    itemSuggestions: [],
    payments: []
  };

  async componentDidMount() {
    await this.props.getItems();

    if (this.props.invoices.length === 0)
      this.props.history.push(`/admin/invoices`);
    const invoice = this.props.invoices.filter(
      item => item._id === this.props.match.params.invoiceId
    )[0];

    this.setState(prevState => {
      const job = this.props.jobs.filter(job => job._id === invoice.jobId)[0];
      return {
        ...prevState,
        loggedUser: this.props.userLogged,
        workerId: this.props.userLogged._id,
        jobName: job && job.jobName,
        invoiceDate: moment(invoice.invoiceDate).format('YYYY-MM-DD'),
        invoiceDescription: invoice.invoiceDescription,
        invoiceTotal: invoice.invoiceTotal,
        items: invoice.estimatedItems,
        payments: invoice.payments
      };
    });
  }

async componentWillUnmount() {
  await this.props.getItems();
  // fix Warning: Can't perform a React state update on an unmounted component
  this.setState = (state,callback)=>{
    return;
  };
}


  handleInput = async e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.name === 'cost' || e.target.name === 'rate' || e.target.name === 'quantity' || e.target.name === 'total' ? parseFloat(e.target.value) : e.target.value,
    }));
  };

  getItemSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : this.props.items.filter(
        text => text.item.toLowerCase().includes(inputValue) > 0
      );
  };

  getSuggestionValueItem = suggestion => {
    this.setState(prevState => ({
      ...prevState,
      itemName: suggestion.item,
      itemDescription: suggestion.description,
      quantity: suggestion.quantity,
      rate: suggestion.rate,
    }));
    return suggestion.item;
  };

  renderSuggestionItem = suggestion => <div>{suggestion.item}</div>;

  onSuggestionsFetchRequestedItem = ({ value }) => {
    this.setState({
      itemSuggestions: this.getItemSuggestions(value),
    });
  };

  onSuggestionsClearRequestedItem = () => {
    this.setState({
      itemSuggestions: [],
    });
  };

  onChangeItem = async (event, { newValue }) => {
    this.setState(prevState => ({
      ...prevState,
      itemName: newValue,
    }));
  };

  addToCart = async product => {
    if (this.state.itemName === '') {
      alert('The field Item Name is required to add item');
    } else if (this.state.quantity === '') {
      alert('The field quantity is required to add item');
    } else if (this.state.rate === '') {
      alert('The field rate is required to add item');
    } else {
      if (this.state.reuseItem === 'on') {
        const data = this.props.items.filter(
          text => text.item.toLowerCase() === product.itemName.toLowerCase()
        );
        if (data.length === 0) {
          product.item = product.itemName;
          product.description = product.itemDescription;
          await this.props.addItem(product);
        }
      }
      const joined = this.state.items !== null ? this.state.items.concat(product) : [];
      this.setState({
        items: joined,
        itemName: '',
        itemDescription: '',
        itemInternalNotes: '',
        cost: 0,
        quantity: 0,
        rate: 0,
        reuseItem: 'off',
        invoiceTotal: (product.quantity * product.rate) + this.state.invoiceTotal

      });

    }

  };

  handleInputItems = index => e => {
    const { items } = this.state;
    let { name, value } = e.target;

    let updatedItems = items.map((item, itemIndex) => {
      if (index === itemIndex) {
        let subtotal = 0;

        if (name === 'quantity') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }
        else if (name === 'rate') {
          // value = parseInt(value);
          subtotal = parseFloat(value) * item.quantity;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }

        else {
          subtotal = item.subtotal; // item.rate * item.rate;
          subtotal = isNaN(subtotal) ? 0 : subtotal;
        }

        item.cost = isNaN(item.cost) ? 0 : item.cost;


        return {
          ...item,
          [name]: value,
          subtotal
        }
      }

      return item;
    });

    const invoiceTotal = updatedItems.reduce(
      (acc, current) => acc + (parseFloat(current.rate) * current.quantity),
      0
    );

    this.setState({
      items: [...(updatedItems || [])],
      invoiceTotal: invoiceTotal
    })

  }

  handleSubmit = async (e, props) => {
    e.preventDefault();
    const {payments, invoiceTotal} = this.state;
    if (payments.length > 0) {
      const paidAmount = payments.reduce((accumulator, object) => {
        return accumulator + object.paidAmount;
      }, 0);

      this.state.invoiceBalance = invoiceTotal - paidAmount;
    }

    this.setState({ submitting: true });
    await this.props.updateInvoice(
      this.props.match.params.invoiceId,
      this.state
    );
    this.props.history.push(`/admin/invoices`);
    this.setState({ submitting: false });
  };

  render() {
    const { itemName, itemSuggestions, submitting } = this.state;
    
    const inputPropsItem = {
      placeholder: 'Enter a name item',
      value: itemName,
      onChange: this.onChangeItem,
    };

    const product = {
      itemName: this.state.itemName,
      itemDescription: this.state.itemDescription,
      itemInternalNotes: this.state.itemInternalNotes,
      quantity: parseFloat(this.state.quantity),
      rate: parseFloat(this.state.rate),
      cost: parseFloat(this.state.cost),
      subtotal: parseFloat(this.state.quantity * this.state.rate)
    };

    if (!this.state.workerId || this.state.workerId === '')
      return <LoadBar />;
    return (
      <>
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Invoice Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-jobName"
                            >
                              Job Name
                            </label>
                            <Input
                              disabled
                              name="_id"
                              className="form-control-alternative"
                              type="text"
                              onChange={this.handleInput}
                              value={this.state.jobName}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label d-inline-block"
                              htmlFor="input-date"
                            >
                              Invoice Date
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Select a date"
                              value={this.state.invoiceDate}
                              name="invoiceDate"
                              type="date"
                              onChange={this.handleInput}
                            />
                          </FormGroup>

                          {/*LINE ITEMS START*/}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Items
                            </label>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Item Name</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Internal Notes</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Cost</th>
                                  <th scope="col">Rate</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.items && this.state.items.map((el, i) => (
                                  <tr key={i}>
                                    <td> {/* el.itemName*/}

                                      <Input
                                        defaultValue={el.itemName}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemName"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemDescription}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemDescription"
                                        type="text"
                                        // rows={5}
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        defaultValue={el.itemInternalNotes}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="itemInternalNotes"
                                        type="text"
                                        onChange={this.handleInputItems(i)}
                                        style={{ width: 120 }}
                                      />
                                    </td>

                                    <td >
                                      <Input
                                        value={el.quantity}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="quantity"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        value={el.cost === null || el.cost === undefined || isNaN(el.cost) ? 0 : el.cost}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="cost"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>

                                    <td>
                                      <Input
                                        value={el.rate}
                                        className="form-control-alternative"
                                        placeholder=""
                                        name="rate"
                                        // type="number"
                                        onChange={this.handleInputItems(i)}
                                        style={{
                                          width: 100
                                        }}
                                      />
                                    </td>
                                    <td> $ {el.subtotal ? el.subtotal.toFixed(2) : 0}</td>
                                    <td>
                                      <Button
                                        onClick={e =>
                                          this.setState(prevState => {
                                            const filter =
                                              this.state.items.filter(
                                                e => {
                                                  this.setState({ invoiceTotal: this.state.invoiceTotal - this.state.items[i].subtotal });
                                                  return e !== this.state.items[i]
                                                }
                                              );
                                            this.setState({ items: filter });
                                          })
                                        }
                                        style={{
                                          width: '100px',
                                          height: '20px',
                                          fontSize: '10px',
                                          paddingTop: '0',
                                        }}
                                        className="btn icon-btn btn-danger"
                                      >
                                        <i className="nc-icon nc-simple-remove" />
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Item Name *
                            </label>
                            <Autosuggest
                              alwaysRenderSuggestions={true}
                              suggestions={itemSuggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedItem
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedItem
                              }
                              getSuggestionValue={this.getSuggestionValueItem}
                              renderSuggestion={this.renderSuggestionItem}
                              inputProps={inputPropsItem}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Description
                            </label>
                            <Input
                              value={this.state.itemDescription}
                              name="itemDescription"
                              className="form-control-alternative"
                              placeholder="Enter a description of item"
                              type="textarea"
                              rows={5}
                              id="itemDescription"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Internal Notes
                            </label>
                            <Input
                              value={this.state.itemInternalNotes}
                              name="itemInternalNotes"
                              className="form-control-alternative"
                              placeholder="Enter a internal notes of an item"
                              type="textarea"
                              rows={5}
                              id="itemInternalNotes"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Quantity *
                            </label>
                            <Input
                              value={this.state.quantity}
                              name="quantity"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="quantity"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Cost *
                            </label>
                            <Input
                              value={this.state.cost}
                              name="cost"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="cost"
                              onChange={this.handleInput}
                            />
                            <br />
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Rate *
                            </label>
                            <Input
                              value={this.state.rate}
                              name="rate"
                              className="form-control-alternative"
                              placeholder="0"
                              type="number"
                              id="rate"
                              onChange={this.handleInput}
                            />
                            <br />
                            <Row>
                              <Col md={6}>
                                <button
                                  type="button"
                                  onClick={e => this.addToCart(product)}
                                  className="btn btn-primary"
                                >
                                  Add Item
                                </button>
                              </Col>
                              <Col md={6}>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    id="cbReuseItem"
                                    onClick={this.handleInput}
                                    name="reuseItem"
                                  />
                                  <Label check for="cbReuseItem">
                                    Save as reusable item
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                        {/*LINE ITEMS END!! */}

                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-merchant"
                            >
                              Total Invoice
                            </label>
                            <Input
                              disabled={true}
                              name="invoiceTotal"
                              className="form-control-alternative"
                              type="number"
                              onChange={this.handleInput}
                              value={this.state.invoiceTotal}
                              step="any"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Description
                            </label>
                            <Input
                              defaultValue={this.state.invoiceDescription}
                              name="invoiceDescription"
                              className="form-control-alternative"
                              placeholder="This is an invoice generated with the items of an estimate"
                              type="text"
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  items: state.item.items,
  invoices: state.invoice.invoices,
  jobs: state.job.jobs,
  userLogged: state.auth.userLogged,
});

export default connect(mapStateToProps, { getInvoices, updateInvoice, addItem, getItems })(
  withRouter(UpdateInvoice)
);
