import React from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import Global, { compareValues } from '../../global';
import { getTimes, removeTime } from '../../redux/actions/timeAction';
import { getJobs } from '../../redux/actions/jobAction';
import { getUsers } from '../../redux/actions/userAction';
import { reset } from '../../redux/actions/searchAction';
import { permissions } from '../../helpers/utils';
import LoadBar from '../../components/shared/loadBar';

const ButtonOne = props => (
  <UncontrolledDropdown>
    <DropdownToggle>...</DropdownToggle>
    <DropdownMenu
      modifiers={{
        setMaxHeight: {
          enabled: true,
          order: 890,
          fn: data => ({
            ...data,
            styles: {
              ...data.styles,
              overflow: 'auto',
              maxHeight: 130,
            },
          }),
        },
      }}
    >
      {
        permissions(props.props.userLogged, 'time', 'update', 0, props.item) ?
          <DropdownItem to={`/admin/time/updatetime/${props.item._id}`} tag={Link}>
            Update
          </DropdownItem>
          : 
          <DropdownItem disabled to={`/admin/time/updatetime/${props.item._id}`} tag={Link}>
            Update
          </DropdownItem>
      }

      {
        permissions(props.props.userLogged, 'time', 'delete', 4, props.item) ?
          <DropdownItem onClick={() => {
              props.props.removeTime(props.item._id);
              alert('Time was removed');
            }}
          >
            <span className="text-danger">Delete</span>
          </DropdownItem>
          : 
          <DropdownItem disabled to="/" tag={Link}>
            Delete
          </DropdownItem>
      }
    </DropdownMenu>
  </UncontrolledDropdown>
);

const ButtonTwo = props => (
  <UncontrolledDropdown>
    <DropdownToggle>...</DropdownToggle>
    <DropdownMenu
      modifiers={{
        setMaxHeight: {
          enabled: true,
          order: 890,
          fn: data => ({
            ...data,
            styles: {
              ...data.styles,
              overflow: 'auto',
              maxHeight: 100,
            },
          }),
        },
      }}
    >
      <DropdownItem to={`/admin/time/updatetime/${props.item.id}`} tag={Link}>
        Update Hours
      </DropdownItem>
        <DropdownItem
          onClick={() => {
            props.props.removeTime(props.item.id);
            alert('Time was removed');
          }}
        >
          <span className="text-danger">Delete</span>
        </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

class Time extends React.Component {
  state = {
    buttonActive: '3',
    times: [],
    isMobileVersion: false,
  };

  updateWindowDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileVersion: window.innerWidth < Global.mobileWidth,
    }));
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  async componentDidMount() {
    if (!this.props.userLogged) return this.props.history.push('/auth/login');
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    await this.props.getTimes(this.props.userLogged._id);
    this.props.times.forEach(item => {
      item.job = this.props.jobs.filter(job => job._id === item.jobId)[0];
    });
    await this.props.reset();

  }

  getOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '1',
    }));
  };

  getClose = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '2',
    }));
  };

  getAll = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonActive: '3',
    }));
  };

  searchFunction = () => {
    var input, filter, table, tr, i;
    input = document.getElementById("timeSearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("timeTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      const tableData = tr[i].getElementsByTagName("td");
      let allTextContent = '';
      for (let ind = 0; ind < tableData.length; ind++) {
          allTextContent += tableData[ind].innerText;
      }
      
      if (allTextContent) {
        if (allTextContent.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }       
    }
  }

  render() {
    let timesFilter = [];
    let { times, users, jobs, userLogged } = this.props;
    if (!this.state) return <LoadBar />;

    if (times) {
      times = times.sort(compareValues('date', 'desc'));
      times.forEach(item => {
        item.job = jobs.filter(job => job._id === item.jobId)[0];
        item.user = users.filter(user => user._id === item.userId)[0];
      });
    } else {
      times = [];
    }

    switch (this.state.buttonActive) {
      case '1':
        timesFilter = times.filter(
          time => time.job && time.job.status === 'Approve'
        );
        break;
      case '2':
        timesFilter = times.filter(
          time => time.job && time.job.status === 'Closed'
        );
        break;
      default:
        timesFilter = times;
        break;
    }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                  <div className="col">
                      {/* <FormGroup className="mb-0"> */}
                        <InputGroup className="input-group-alternative search-margin">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className='search-style'
                            placeholder="Search"
                            type="text"
                            id="timeSearchInput"
                            onChange={this.searchFunction}
                          />
                        </InputGroup>
                      {/* </FormGroup> */}
                    </div>
                    {
                      permissions(userLogged, 'time', 'create', 1) ?
                        <div className="col text-right">
                        <Button color="primary" type="button" onClick={() => window.location.href = 'addtime'}>
                          Create Time
                        </Button>
                        </div>
                        : null
                    }
                    
                  </Row>
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Employee Information</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Form className="card-header">
                  <Row form>
                    <Col md={{ size: 12 }}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-dateStart"
                        >
                          Filter
                        </label>
                        <br />
                        <span>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '1'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getOpen}
                          >
                            {!this.state.isMobileVersion ? (
                              'Open'
                            ) : (
                              <small>Open</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '2'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getClose}
                          >
                            {!this.state.isMobileVersion ? (
                              'Close'
                            ) : (
                              <small>Close</small>
                            )}
                          </Button>
                          <Button
                            className="form-control-alternative"
                            color={
                              this.state.buttonActive === '3'
                                ? 'info'
                                : 'secondary'
                            }
                            onClick={this.getAll}
                          >
                            {!this.state.isMobileVersion ? (
                              'All'
                            ) : (
                              <small>All</small>
                            )}
                          </Button>
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Table className="align-items-center table-flush" id="timeTable" responsive>
                  <thead className="thead-light">
                    <tr>
                      {!this.state.isMobileVersion ? (
                        <>
                          <th scope="col"></th>
                          <th scope="col">Date</th>
                          <th scope="col">Name</th>
                          <th scope="col">Hours</th>
                          <th scope="col">Job</th>
                        </>
                      ) : (
                        <th>Details</th>
                      )}
                    </tr>
                  </thead>
                  {timesFilter.length === 0 ? (
                    <tbody>
                      <tr>
                        <td>No times register</td>
                      </tr>
                    </tbody>
                  ) : (
                    timesFilter.map((e, i) => {
                      let { user } = e;
                      if (user) {
                        user.name = user.name ? user.name : '';
                      } else {
                        user = { name: '' };
                      }
                      let { job } = e;
                      if (job) {
                        job.jobName = job.jobName ? job.jobName : '';
                      } else {
                        job = { jobName: '' };
                      }
                      return (
                        <tbody key={i}>
                          <tr>
                            {!this.state.isMobileVersion ? (
                              <>
                                <td>
                                  <ButtonOne
                                    item={e}
                                    props={this.props}
                                  ></ButtonOne>
                                </td>
                                <td>
                                  <Moment
                                    add={{ days: 1 }}
                                    format={'MMM D, YY'}
                                  >
                                    {e.date}
                                  </Moment>
                                </td>
                                <td>{user.name}</td>
                                <td
                                  style={{
                                    height: '100%',
                                    paddingTop: '35px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'baseline',
                                    alignContent: 'center',
                                  }}
                                >
                                  {e.hours ? e.hours : '-'}
                                </td>
                                <td
                                  style={{ height: '100%', paddingTop: '35px' }}
                                >
                                  <p style={{ fontSize: '12px' }} key={i}>
                                    {job.jobName} ({job.status})
                                  </p>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <Moment
                                    add={{ days: 1 }}
                                    format={'MMM D, YY'}
                                  >
                                    {e.date}
                                  </Moment>
                                  <br />
                                  {user.name} - {e.hours ? e.hours : '-'}
                                  <br />
                                  <small>
                                    {job.jobName} ({job.status})
                                  </small>{' '}
                                  <br />
                                  <div className="buttonfloat-right buttonfloat-right-times">
                                    <ButtonOne
                                      item={e}
                                      props={this.props}
                                    ></ButtonOne>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        </tbody>
                      );
                    })
                  )}
                  {
                    // nuevo bloque
                    timesFilter && timesFilter.length > 0
                      ? timesFilter.map((e, i) => {
                          let { user } = e;
                          if (user) {
                            user.name = user.name ? user.name : '';
                          } else {
                            user = { name: '' };
                          }
                          let { job } = e;
                          if (job) {
                            job.jobName = job.jobName ? job.jobName : '';
                          } else {
                            job = { jobName: '' };
                          }
                          if (!e.userId)
                            return <thead key={'thDelete-' + i}><tr><th scope="row">Worker Delete</th></tr></thead>;
                          return (
                            <tbody key={i}>
                              <tr key={'tr-' + i}>
                                {!this.state.isMobileVersion ? (
                                  <>
                                    <td>
                                      <ButtonTwo
                                        item={e}
                                        props={this.props}
                                      ></ButtonTwo>
                                    </td>
                                    <td>
                                      <Moment
                                        add={{ days: 1 }}
                                        format={'MMM D, YY'}
                                      >
                                        {e.date}
                                      </Moment>
                                    </td>
                                    <td>{user.name}</td>
                                    <td
                                      style={{
                                        height: '100%',
                                        paddingTop: '35px',
                                        paddingLeft: '60px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        alignContent: 'center',
                                      }}
                                    >
                                      {e.hours ? e.hours : '-'}
                                    </td>
                                    <td
                                      style={{
                                        height: '100%',
                                        paddingTop: '35px',
                                        paddingLeft: '60px',
                                      }}
                                    >
                                      {job.jobName} ({job.status})
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <Moment
                                        add={{ days: 1 }}
                                        format={'MMM D, YY'}
                                      >
                                        {e.date}
                                      </Moment>
                                      <br />
                                      {e.userId.name} -{' '}
                                      {e.hours ? e.hours : '-'}
                                      <br />
                                      <small>
                                        {e.jobId.name} ({job.status})
                                      </small>
                                      <br />
                                      <div className="buttonfloat-right buttonfloat-right-times">
                                        <ButtonTwo
                                          item={e}
                                          props={this.props}
                                        ></ButtonTwo>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            </tbody>
                          );
                        })
                      : null
                  }
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.jobs,
  times: state.time.times,
  users: state.user.users,
  userLogged: state.auth.userLogged
});

export default connect(mapStateToProps, {
  getUsers,
  getTimes,
  getJobs,
  removeTime,
  reset,
})(Time);
