import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import PhoneInput from 'react-phone-number-input';
import { GooglePlacesAutocomplete } from '../../components/Form/GooglePlacesAutocomplete';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { addJobs, addJobAndClient } from '../../redux/actions/jobAction';
import { getClients } from '../../redux/actions/clientAction';
import { getRevenueCenter } from '../../redux/actions/revenueCenterAction';
import LoadBar from '../../components/shared/loadBar';

const date = new Date();
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();

class AddJob extends React.Component {
  state = {
    dateCreate:
      month < 10 ? `${day}-0${month}-${year}` : `${day}-${month}-${year}`,
    address: '',
    revenueCenter: '',
    new: false,
    submitting: false,
    clientId: '',
    clientName: '',
    itemSuggestions: [],
    newClients: [],
    email: '',
    estimatePhone: 0,
    name: ''
  };

  async componentDidMount() {
    await this.props.getRevenueCenter();
    await this.props.getClients();


    let clientsArray = this.props.clients && this.props.clients.length > 0 ? this.props.clients : [];
    if (clientsArray.length > 0) {
      for (let i = 0; i < clientsArray.length; i++) {
        clientsArray[i].concatName = clientsArray[i].firstName + ' ' + clientsArray[i].lastName + ' - ' + clientsArray[i].email;
      }
      this.setState({newClients: clientsArray});
    }

  }

  async componentWillUnmount() {
    await this.props.getRevenueCenter();
    await this.props.getClients();

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };

  }

  handleInput = e => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  handleSubmit = async (e, props) => {
    e.preventDefault();
    this.state.newClients = [];
    if (this.state.clientName === '') {
      alert('Select a Client to continue');
    } else if (this.state.revenueCenter === '') {
      alert('Select a Revenue Center to continue');
    } else if (this.state.email === '') {
      alert('Enter an email to continue');
    } else if (this.state.address === undefined || this.state.address === '') {
      alert('Enter an address to continue');
    } else if (this.state.estimatePhone === undefined || this.state.estimatePhone === 0) {
      alert('Enter a phone number to continue');
    } else {
      this.setState({ submitting: true });
      if (this.state.new) {
        await this.props.addJobAndClient(this.state);
      } else {
        await this.props.addJobs(this.state);
      }
      this.setState({ submitting: false });
      this.props.history.push(`jobs`);
    }

  };

  setPhoneNumber = e => {
    this.setState(prevState => ({
      ...prevState,
      estimatePhone: e,
    }));
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = jobAddress => {
    this.setState({ address: jobAddress });
  };

  getItemSuggestions = value => {
    const {newClients} = this.state;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : newClients.filter(client => {
          if (client.firstName !== undefined && client.lastName !== undefined && client.email !== undefined) {
            return client.concatName.toLowerCase().includes(inputValue);
          }

          return client;
        }
      );
  };

  getSuggestionValueItem = suggestion => {
    this.setState({
      clientId: suggestion._id,
      clientName: suggestion.concatName,
      name: suggestion.firstName + ' ' + suggestion.lastName,
      email: suggestion.email,
      address: suggestion.address,
      estimatePhone: suggestion.mobile,
    });
    
    return suggestion.concatName;
  };

  renderSuggestionItem = suggestion => <div>{suggestion.concatName} </div>;

  onSuggestionsFetchRequestedItem = ({ value }) => {
    this.setState({
      itemSuggestions: this.getItemSuggestions(value),
    });
  };

  onSuggestionsClearRequestedItem = () => {
    this.setState({
      itemSuggestions: [],
    });
  };

  onChangeItem = async (event, { newValue }) => {
    this.setState(prevState => ({
      ...prevState,
      clientName: newValue,
    }));
  };

  render() {
    const { revenueCenter, submitting } = this.props;
    const { itemSuggestions, clientName, email } = this.state;
    const inputPropsItem = {
      placeholder: 'Enter Client Name',
      value: clientName,
      onChange: this.onChangeItem,
    };

    return (
      <>
       {submitting && <LoadBar />}
        <Header forms={true} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">New Job</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                              <FormGroup>
                                <label
                                      className="form-control-label d-inline-block"
                                      htmlFor="input-nameClient"
                                >
                                  Client Name
                                </label>
                                <Autosuggest
                                  className='form-control-alternative'
                                  alwaysRenderSuggestions={false}
                                  suggestions={itemSuggestions}
                                  onSuggestionsFetchRequested={
                                    this.onSuggestionsFetchRequestedItem
                                  }
                                  onSuggestionsClearRequested={
                                    this.onSuggestionsClearRequestedItem
                                  }
                                  getSuggestionValue={this.getSuggestionValueItem}
                                  renderSuggestion={this.renderSuggestionItem}
                                  inputProps={inputPropsItem}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  className="form-control-label d-inline-block"
                                  htmlFor="input-nameClient"
                                >
                                  Revenue Center
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  name="revenueCenter"
                                  type="select"
                                  onChange={this.handleInput}
                                  defaultValue="Select"
                                >
                                  <option value="Select" disabled>
                                    Select a Revenue Center
                                  </option>
                                  {revenueCenter.map(item => (
                                    <option key={item._id} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <label
                                  className="form-control-label d-inline-block"
                                  htmlFor="input-nameClient"
                                >
                                  Client Email
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Enter a client email"
                                  name="email"
                                  type="email"
                                  onChange={this.handleInput}
                                  value={email || ''}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  className="form-control-label d-inline-block"
                                  htmlFor="input-nameClient"
                                >
                                  Address
                                </label>
                                <GooglePlacesAutocomplete onSelect={this.handleSelect} value={this.state.address} />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  className="form-control-label d-inline-block"
                                  htmlFor="input-name"
                                >
                                  Phone number *
                                </label>
                                <PhoneInput
                                  international={false}
                                  defaultCountry="US"
                                  placeholder="Enter phone number"
                                  value={this.state.estimatePhone}
                                  onChange={this.setPhoneNumber}
                                />
                              </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              className="form-control-alternative"
                              color="info"
                              disabled={submitting}
                            >
                              Save
                            </Button>
                            <Button
                              className="form-control-alternative"
                              color="light"
                              onClick={() =>
                                this.props.history.push('/admin/addclient')
                              }
                            >
                              Add New Client
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.client.clients,
  userLogged: state.auth.userLogged,
  revenueCenter: state.revenueCenter.list,

});

export default connect(mapStateToProps, {
  addJobs,
  addJobAndClient,
  getClients,
  getRevenueCenter
})(withRouter(AddJob));
