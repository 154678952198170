import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";

import CheckoutError from "./prebuilt/CheckoutError";
import { connect } from 'react-redux';
import { addPayment } from '../../redux/actions/paymentAction';
import { getInvoiceById, updateInvoice } from '../../redux/actions/invoiceAction';
import { getClientById } from '../../redux/actions/clientAction';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from 'reactstrap';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const CheckoutForm = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [invoiceData, setInvoiceData] = useState(null);
  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formAddress, setFormAddress] = useState('');
  const [formAmount, setFormAmount] = useState('');
  const [totalBillable, setTotalBillable] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [initInvoiceData, setInitInvoiceData] = useState(null);
  const paramsId = props.match.params.id;
  const currentUrl = window.location.href;

  const stripe = useStripe();
  const elements = useElements()


  const fetchClientById = async (id) => {
    const data =  await props.getClientById(id);
    if (data) {
      setFormEmail(data.email);
    }
    return data;
   }

  const fetchInvoiceById = async () => {
   const data =  await props.getInvoiceById(paramsId);
   if (!data) {
    return window.location.href = process.env.REACT_APP_SITE_URL + '/auth/payment/no-bill/' + paramsId;
   }

   if (data) {
    setInvoiceData(data);
    setInitInvoiceData(data);
    setTotalBillable(data.invoiceBalance !== undefined ? data.invoiceBalance : data.invoiceTotal);
    setFormAmount(data.invoiceBalance !== undefined ? data.invoiceBalance : data.invoiceTotal);
    if (data.invoiceTotal === 0) {
      return window.location.href = process.env.REACT_APP_SITE_URL + '/auth/payment/no-bill/' + paramsId;
    }

    if (data.jobId !== undefined) {
      fetchClientById(data.jobId.clientId);
      setFormName(data.jobId.jobName);
      setFormAddress(data.jobId.jobAddress);
    }
   }
   
   return data;
  }


  useEffect(() => {

    if (currentUrl.includes('invoice')) {
      fetchInvoiceById();
      setFormTitle('INVOICE INFORMATION');
    }

    if (totalBillable === 0) {
      return window.location.href = process.env.REACT_APP_SITE_URL + '/auth/payment/no-bill/' + paramsId;
    }
    
  }, []);

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formAmount > totalBillable) {
      alert('Payment Amount is Greater than the total billable');
      return;
    }


    const billingDetails = {
      name: formName,
      email: formEmail,
      address: {
        city: formAddress,
        line1: '',
        state: '',
        postal_code: ''
      },
    };

    setProcessingTo(true);

    try {

      const paymentData = await props.addPayment({amount: formAmount * 100});

      const clientSecret = paymentData.payment.client_secret;

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = mm + '/' + dd + '/' + yyyy;


      const invoiceBalance = invoiceData.invoiceBalance !== undefined ? invoiceData.invoiceBalance - formAmount : invoiceData.invoiceTotal - formAmount;
      invoiceData.items = invoiceData.estimatedItems;
      invoiceData.invoiceBalance = invoiceBalance;
      invoiceData.payments = [...invoiceData.payments, {stripe: 'card', paidAmount: parseInt(formAmount), invoiceBalance: invoiceBalance, paidDate: formattedToday}];
      
      if (invoiceData.invoiceBalance !== undefined && invoiceData.invoiceBalance === 0) {
        invoiceData.isPaid = true;
      }

      await props.updateInvoice(paramsId, invoiceData);
      window.location.href = process.env.REACT_APP_SITE_URL + '/auth/payment/success/' + paramsId;
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  const iframeStyles = {
    base: {
      color: "black",
      fontSize: "16px",
      border: "1",
      iconColor: "#adb5bd",
      "::placeholder": {
        color: "#adb5bd"
      }
    },
    invalid: {
      iconColor: "red",
      color: "black"
    },
    complete: {
      iconColor: "#adb5bd"
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
  };

  return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h2>INVOICE INFORMATION</h2>
                <h4>Total Billable: $ {totalBillable}</h4>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                <label
                  className="form-control-label"
                  htmlFor="input-last-name"
                >
                  Job Name
                </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-briefcase"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Job Name"
                      type="text"
                      name="name"
                      value={formName}
                      onChange={(e) => setFormName(e.target.value)}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-last-name"
                >
                  Email Address
                </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter Your Email"
                      type="email"
                      name="email"
                      value={formEmail}
                      onChange={(e) => setFormEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-last-name"
                >
                  Address
                </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-book"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter The Address"
                      type="text"
                      name="address"
                      value={formAddress}
                      onChange={(e) => setFormAddress(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-last-name"
                >
                  Payment Amount
                </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-dollar-sign"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Enter Amount"
                      type="text"
                      name="amount"
                      value={formAmount}
                      onChange={(e) => {
                        setFormAmount(e.target.value);
                      }}
                    />
                  </InputGroup>
                  {formAmount > totalBillable ? <p className="text-color-red">Payment Amount is greater than the total billable</p> : ''}
                </FormGroup>
                <FormGroup>
                  <CardElementContainer>
                    <CardElement
                      options={cardElementOpts}
                      onChange={handleCardDetailsChange}
                    />
                  </CardElementContainer>
                  {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}


                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={handleFormSubmit}
                    disabled={(isProcessing || !stripe) || (formAmount > totalBillable)}>
                    {isProcessing ? "Processing..." : `Pay $${formAmount}`}
                  </Button>
                  <br />
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>

    </>
  );
};

const mapStateToProps = state => ({
  payment: state.stripePayment.paymentStripe,
  invoice: state.invoice.invoice,
});


export default connect(mapStateToProps, { addPayment, getInvoiceById, getClientById, updateInvoice })(
  withRouter(CheckoutForm)
);
