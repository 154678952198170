import React from "react";

/**
 * Google PlacesAutocomplete suggestion component
 * 
 */
export const Suggestion = ({ suggestion, getSuggestionItemProps }) => {
  const className = suggestion.active
    ? 'suggestion-item--active'
    : 'suggestion-item';

  const style = suggestion.active
    ? {
        backgroundColor: '#fafafa',
        cursor: 'pointer',
        padding: '4px',
      }
    : {
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        padding: '4px',
      };
  const suggestionProps = getSuggestionItemProps(
    suggestion,
    {
      className,
      style,
    },
  )
  suggestionProps.key = suggestion.index;
  return (
    <div {...suggestionProps}>
      <span>{suggestion.description}</span>
    </div>
  );
}
